import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule }   from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AccordionModule } from 'primeng/accordion';
import { InplaceModule } from 'primeng/inplace';
import { PickListModule } from 'primeng/picklist';
import { ListboxModule } from 'primeng/listbox';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuModule } from 'primeng/menu';
import { LightboxModule } from 'primeng/lightbox';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CurrencyMaskModule } from "ng2-currency-mask";
import {GalleriaModule} from 'primeng/galleria';

// import { FullCalendarModule } from 'primeng/fullcalendar';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoginComponent } from './login-service/login/login.component';
import { Constants} from './constant/constants';
import { AddPipelineUserComponent } from './entity-service/add-pipeline-user/add-pipeline-user.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { HookComponent } from './rfq-order-service/hook/hook.component';
import { CloudproofComponent } from './rfq-order-service/cloudproof/cloudproof.component';
import { CloudProofPreviewComponent} from './rfq-order-service/cloud-proof-preview/cloud-proof-preview.component';
import { RatingModule } from 'primeng/rating';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { RfqOrderChildDataComponent } from './rfq-order-service/rfq-order-child-data/rfq-order-child-data.component';
import { TermsAndConditionComponent } from './general-service/terms-and-condition/terms-and-condition.component';
import { NotFoundComponent } from './general-service/not-found/not-found.component';
import { AddOrganiztionBasicDetailComponent } from './entity-service/add-organization-basic-detail/add-organization-basic-detail.component';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

// import { AddContactPersonComponent } from './entity-service/add-contact-person/add-contact-person.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TabViewModule,
    TranslateModule,
    MenubarModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    ScrollingModule,
    ButtonModule,
    FormsModule,
    ToastModule,
    DialogModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    ConfirmDialogModule,
    TableModule,
    CheckboxModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    CurrencyMaskModule, 
    RadioButtonModule,
    ChipsModule,
    CheckboxModule,
    ProgressBarModule,
    CalendarModule,
    AccordionModule,
    FileUploadModule,
    RatingModule,
    VirtualScrollerModule,
    GalleriaModule,
    TriStateCheckboxModule
  ],
  declarations: [
    LoginComponent,
    AddPipelineUserComponent,
    HookComponent,
    CloudproofComponent,
    CloudProofPreviewComponent,
    RfqOrderChildDataComponent,
    TermsAndConditionComponent,
    NotFoundComponent,
    AddOrganiztionBasicDetailComponent,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    TabViewModule,
    TranslateModule,
    MultiSelectModule,
    ToastModule,
    NgxLoadingModule,
    ProgressBarModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    MenubarModule,
    DropdownModule,
    GalleriaModule,
    ConfirmDialogModule,
    TableModule,
    CheckboxModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    CurrencyMaskModule, 
    RadioButtonModule,
    InputTextModule,
    ScrollingModule,
    ButtonModule,
    ChipsModule,
    LoginComponent,
    AddPipelineUserComponent,
    CalendarModule,
    AccordionModule,
    FileUploadModule,
    RatingModule,
    VirtualScrollerModule,
    RfqOrderChildDataComponent,
    AddOrganiztionBasicDetailComponent,
    TriStateCheckboxModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class SharedModule { }
