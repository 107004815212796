<div id="wrap" >
    <div id="content">
        <p-toast position="top-right"></p-toast>
         <input type="hidden" name="default_select_text" id="default_select_text" value="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}">
        <div class="container wrapBox">
            <div class="row">
                <div class="col-lg-12">
                    <div class="contact-area full-container">
                        <h3 *ngIf="isAdd && !organizationId && !addFromPopup">{{ 'ORG.ADD_ORG' | translate }}</h3>
                        <h3 *ngIf="!isAdd && organizationId">{{ 'ORG.EDIT_ORG' | translate }}</h3>
                        <div class="contact-fields content-area">
                            <form [formGroup]="organizationform.getForm()"  method="post" 
		                            (ngSubmit)="onSubmit()">
			                            <div class="row">
			                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_ORG_NO' | translate }} <small class="madatory-star">*</small></label>
			                                    <input  #org_number class="txt-theme form-control" pInputText type="text" formControlName="registrationNumber" name="registrationNumber" id="registrationNumber" placeholder="{{ 'ORG.PLACEHOLDER_ORG_NO' | translate }}" 
			                                    (change)="onOrgNoChange(org_number.value)" [(ngModel)]="orgRegNumber"/>
			                                    <div *ngIf="organizationform.hasError('registrationNumber')" class="alert alert-danger">
			                                        {{organizationform.getError('registrationNumber')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['registrationNumber']}}
			                                    </div>
			                                </div>
			                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_ORG_NAME' | translate }} <small class="madatory-star">*</small></label>
			                                    <input  class="txt-theme form-control" pInputText type="text" formControlName="name" name="name" id="name" placeholder="{{ 'ORG.PLACEHOLDER_ORG_NAME' | translate }}"/>
			                                    <div *ngIf="organizationform.hasError('name')" class="alert alert-danger">
			                                        {{organizationform.getError('name')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['name']}}
			                                    </div>
			                                </div>
			                            </div>
			                            <div class="row">
			                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_ORG_TYPE' | translate }} <small class="madatory-star">*</small></label>
			                                    
			                                    <select #orgSelect [(ngModel)]="selectedOrgType" class="txt-theme form-control" formControlName="type" id="type" name="type" (change)="manageDueDaysFields()">
			                                    	<option *ngFor="let type of orgTypeOpt" [value]="type.value">{{type.label}}</option>
			                                    </select>
			                                    <div *ngIf="organizationform.hasError('type')" class="alert alert-danger">
			                                        {{organizationform.getError('type')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['type']}}
			                                    </div>
			                                </div>
			                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_VAT' | translate }}</label>
			                                    <input  class="txt-theme form-control" pInputText type="text" formControlName="vatNumber" name="vatNumber" id="vatNumber" placeholder="{{ 'ORG.LBL_VAT' | translate }}"/>
			                                </div>			                                
			                            </div>
			                            <div class="row">
			                            	<div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'FIELDS.CONTACT_EMAIL' | translate }} </label>
			                                    <input  #email class="txt-theme form-control" pInputText type="email" formControlName="contactEmail" name="contactEmail" id="contactEmail" placeholder="{{ 'FIELDS.CONTACT_EMAIL' | translate }}"
			                                    />
			                                    <div *ngIf="organizationform.hasError('contactEmail')" class="alert alert-danger">
			                                        {{organizationform.getError('contactEmail')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['contactEmail']}}
			                                    </div>
			                                </div>
			                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_ORG_WEB' | translate }}</label>
			                                    <input  class="txt-theme form-control" pInputText type="text" formControlName="website" name="website" id="website" placeholder="{{ 'ORG.PLACEHOLDER_ORG_WEB' | translate }}" url />
			                                    <div *ngIf="organizationform.hasError('website')" class="alert alert-danger">
			                                        {{organizationform.getError('website')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['website']}}
			                                    </div>
			                                </div>
			                            </div>
			                            <div class="row">
			                            	<div class="form-group col-md-6 col-sm-6 col-xs-12">
			                            		<label>{{ 'FIELDS.INVOICE_TYPE' | translate }}</label>
			                            		 <p-dropdown #invoiceType class="searchable-select txt-theme form-control" [options]="invoiceTypeOpt" formControlName="invoiceType" [(ngModel)]="selectedInvoiceType" [filter]="true" id="invoiceType" name="invoiceType" placeholder="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}"></p-dropdown>
			                            	</div>
			                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_ORG_INVOICE_EMAIL' | translate }} <small class="madatory-star">*</small></label>
			                                    <input  #invoiceEmail class="txt-theme form-control" pInputText type="email" formControlName="invoiceEmail" name="invoiceEmail" id="invoiceEmail" placeholder="{{ 'ORG.LBL_ORG_INVOICE_EMAIL' | translate }}"
			                                    />
			                                    <div *ngIf="organizationform.hasError('invoiceEmail')" class="alert alert-danger">
			                                        {{organizationform.getError('invoiceEmail')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['invoiceEmail']}}
			                                    </div>
			                                </div>
			                            </div>
			                            <div class="row" *ngIf="!isSupplierSelected">
			                            	<div class="form-group col-md-6 col-sm-6 col-xs-12">
			                            		<label>{{ 'FIELDS.DEFAULT_PROJECT_MANAGER' | translate }}</label>
			                            		 <p-dropdown #projectManager  class="searchable-select txt-theme form-control" [options]="projectManagerOpt" formControlName="defaultProjectManagerUserId" [(ngModel)]="selectedProjectManager" [filter]="true" id="defaultProjectManagerUserId" name="defaultProjectManagerUserId" placeholder="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}"></p-dropdown>
			                            	</div>
			                            	<div class="form-group col-md-6 col-sm-6 col-xs-12">
			                            		<label>{{ 'FIELDS.DEFAULT_SALES_MANAGER' | translate }}</label>
			                            		 <p-dropdown #salesManager class="searchable-select txt-theme form-control" [options]="salesManagerOpt" formControlName="defaultSalesManagerUserId" [(ngModel)]="selectedSalesManager" [filter]="true" id="defaultSalesManagerUserId" name="defaultSalesManagerUserId" placeholder="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}"></p-dropdown>
			                            	</div>
			                            </div>
			                            
			                            <div class="row">
			                            	<div class="form-group col-md-6 col-sm-6 col-xs-12" *ngIf="(isSupplierSelected || isBothSelected)">
			                            		<label>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_CURRENCY' | translate }}</label>
			                            		 <p-dropdown #selectElem class="searchable-select txt-theme form-control" [options]="currenciesOpt" formControlName="defaultCurrencyId" [(ngModel)]="selectedCurrency" [filter]="true" id="defaultCurrencyId" name="defaultCurrencyId" placeholder="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}"></p-dropdown>
			                            	</div>
			                            	 <div class="form-group col-md-6 col-sm-6 col-xs-12" *ngIf="(!isSupplierSelected || isBothSelected)">
			                                    <div class="row">
			                                        <div class="form-group col-md-6 col-sm-12 col-xs-12">
			                                            <label>{{ 'ORG.LBL_DUE_DATE_DAYS' | translate }} <small class="madatory-star">*</small></label>
			                                            <input [(ngModel)]="selectedDueDays" type="number" min="0" max="999" class="txt-theme form-control" pInputText formControlName="paymentDueDate" name="paymentDueDate" id="paymentDueDate" placeholder="{{ 'ORG.LBL_DUE_DATE_DAYS' | translate }}" (keydown)="fileservice.forceNumericWithoutMinus($event)"/>
			                                            <div *ngIf="orgFormSubmitted && showDueDaysFields && (selectedDueDays == '')" class="alert alert-danger">
			                                                {{ 'VALIDATION.FIELD_VALIDATE' | translate }}
			                                            </div>
			                                        </div>
			                                        <div class="form-group col-md-6 col-sm-12 col-xs-12">
			                                            <label>{{ 'ORG.LBL_DUE_DATE_RULE' | translate }} <small class="madatory-star">*</small></label>
			                                            <select [(ngModel)]="selectedDueDaysRule" class="txt-theme form-control" formControlName="paymentDueDateRule" id="paymentDueDateRule" name="paymentDueDateRule" >
			                                            <option *ngFor="let type of dueDaysRuleList" [value]="type.value">
			                                            {{type.label }}
			                                            </option>
			                                            </select>
			                                            <div *ngIf="orgFormSubmitted && showDueDaysFields && selectedDueDaysRule == ''" class="alert alert-danger">
			                                                {{ 'VALIDATION.FIELD_VALIDATE' | translate }}
			                                            </div>
			                                        </div>
			                                    </div>
			                                </div>
			                            	<div class="form-group col-md-6 col-sm-6 col-xs-12">
			                                    <label>{{ 'ORG.LBL_ORG_LANG' | translate }} <small class="madatory-star">*</small></label>
			                                    <select [(ngModel)]="selectedLang" class="txt-theme form-control" formControlName="defaultLocaleId" id="defaultLocaleId" name="defaultLocaleId" >
			                                    <option *ngFor="let type of languageOpt" [value]="type.value">
			                                    {{type.label}}
			                                    </option>
			                                    </select>
			                                    <div *ngIf="organizationform.hasError('defaultLocaleId')" class="alert alert-danger">
			                                        {{organizationform.getError('defaultLocaleId')}}
			                                    </div>
			                                    <div *ngIf="isInvalid" class="alert alert-danger">
			                                        {{validationErrors['defaultLocaleId']}}
			                                    </div>
			                                </div>
			                            </div>
			                            <div class="row"  *ngIf="isSupplierSelected || isBothSelected">
			                                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
			                                    <label>{{ 'ORG.LBL_PROFILE' | translate }}</label>
			                                </div>
			                            </div>
			                            <div class="row frm-option" *ngIf="isSupplierSelected || isBothSelected">

			                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
			                                    <div> <p-checkbox  label="{{ 'RFQ.LBL_PRINTING_OPTIONS' | translate }}" [(ngModel)]="supplierProfiles" formControlName="supplierProfiles" value="PRINTING">
			                                        </p-checkbox>
			                                    </div>
			                                </div>

			                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
			                                    <div> <p-checkbox label="{{'RFQ.LBL_DISTRIBUTION_OPTIONS' | translate }}" [(ngModel)]="supplierProfiles" formControlName="supplierProfiles" value="DISTRIBUTION">
			                                        </p-checkbox>
			                                    </div>
			                                </div>

			                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
			                                    <div> <p-checkbox  label="{{ 'RFQ.LBL_OTHER_OPTIONS' | translate }}" [(ngModel)]="supplierProfiles" formControlName="supplierProfiles" value="OTHERS">
			                                        </p-checkbox>
			                                    </div>
			                                </div>
			                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
			                                </div>
			                            </div>
			                            <div class="btn-area">
			                                <button class="btn-theme" type="submit"  [disabled]="!organizationform.isValid"  *ngIf="isAdd">{{ 'ORG.BTN_LBL_ORG_ADD' | translate }}</button>
			                                <button class="btn-theme"  type="submit"  [disabled]="!organizationform.isValid"  *ngIf="!isAdd">{{ 'ORG.BTN_LBL_ORG_UPDATE' | translate }}</button>
			                                <!-- <button type="button" class="btn btn-default btn-theme-disable" *ngIf="isAdd" (click)="resetForm()">
			                                {{ 'ORG.BTN_LBL_ORG_CLEAR' | translate }}
			                                </button> -->
			                                <button type="button" class="btn btn-default btn-theme-disable" (click)="back()" *ngIf="!addFromPopup">
			                                {{ 'SUPPLIER_RFQ_PRODUCT.LBL_BTN_CANCEL' | translate }}
			                                </button>
			                            </div>
		                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-container">
        <ngx-loading [show]="loading"></ngx-loading>
    </div>
</div>