import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeneralFunction } from '../general-function';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private translate: TranslateService,
  ) {
    var locale:any = GeneralFunction.setUserLocale();
    translate.setDefaultLang(locale);
    translate.use(locale);
  }

  ngOnInit(): void {
  }

}
