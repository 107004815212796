<div id="wrap">
    <p-toast position="top-right" [baseZIndex]="10000"></p-toast>
    <div id="content">
        <div class="container wrapBox">
            <div class="row">
                <input type="hidden" name="default_select_text" id="default_select_text" value="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}">
                <input type="hidden" name="page_title" id="page_title" value="{{ 'PAGE_TITLES.CONTACT_MANAGEMENT' | translate }}">
                <input type="hidden" name="confirm_active_msg" id="confirm_active_msg" value="{{ 'PIPELINE_USER.ACTIVE_CONFIRM_MSG' | translate }}">
                <input type="hidden" name="confirm_header" id="confirm_header" value="{{ 'COMMON.CONFIRM_HEADER' | translate }}">
                <div class="col-lg-12">
                    <div class="contact-area full-container">
                        <h3 *ngIf="isAdd && !isEditProfile && !addFromPopup">{{ 'CONT_PER.ADD_CONT_PER' | translate }}</h3>
                        <h3 *ngIf="!isAdd && !isEditProfile">{{ 'CONT_PER.EDIT_CONT_PER' | translate }}</h3>
                        <h3 *ngIf="isEditProfile">{{ 'MENU.SUB_MNU_EDIT_PROFILE' | translate }}</h3>
                        <div class="contact-fields content-area">
                            <form [formGroup]="contactPersonForm.getForm()" (ngSubmit)="onSubmit()" method="post">
                                <div class="row flexWrapBox">
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_ORG' | translate }} <small class="madatory-star">*</small>
                                        </label>
                                        <p-dropdown #selectElem styleClass="searchable-select txt-theme form-control" [options]="organizationOpt" 
                                        formControlName="organizationId" optionLabel="name" optionValue="id" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" [(ngModel)]="selectedOrg" [filter]="true" [disabled]="isEditProfile || isSupplierOrCustomerUserAdmin" (onChange)="getSelectedOrgAddress(selectedOrg)"></p-dropdown>
                                        <div *ngIf="contactPersonForm.hasError('organizationId')" class="alert alert-danger">{{contactPersonForm.getError('organizationId')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['organizationId']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_FNAME' | translate }} <small class="madatory-star">*</small>
                                        </label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="firstName" name="firstName" id="firstName" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_FNAME' | translate }}" />
                                        <div *ngIf="contactPersonForm.hasError('firstName')" class="alert alert-danger">{{contactPersonForm.getError('firstName')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['firstName']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_LNAME' | translate }}</label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="lastName" name="lastName" id="lastName" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_LNAME' | translate }}" />
                                        <div *ngIf="contactPersonForm.hasError('lastName')" class="alert alert-danger">{{contactPersonForm.getError('lastName')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['lastName']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_ROLE' | translate }} <small class="madatory-star">*</small>
                                        </label>
                                        <select #selectElem [(ngModel)]="selectedRole" class="txt-theme form-control" formControlName="role" id="role" name="role" [attr.disabled]="isEditProfile ? true : null">
                                            <option *ngFor="let type of userRoleOpt" [value]="type.value">{{type.label }}</option>
                                        </select>
                                        <div *ngIf="contactPersonForm.hasError('role')" class="alert alert-danger">{{contactPersonForm.getError('role')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['role']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_EMAIL' | translate }} <small class="madatory-star">*</small>
                                        </label>
                                        <input #email (change)="onEmailChange(email.value)" class="txt-theme form-control" pInputText type="email" formControlName="email" name="email" id="email" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_EMAIL' | translate }}" [attr.disabled]="isEmailDisabled ? true : null" [(ngModel)]="emailValue"/>
                                        <div *ngIf="contactPersonForm.hasError('email')" class="alert alert-danger">{{contactPersonForm.getError('email')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['email']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12" *ngIf="!contactPersonId">
                                        <label>{{ 'LOGIN.LBL_PSWD' | translate }} <small class="madatory-star">*</small></label>
                                        <input class="txt-theme form-control" pInputText [type]="oldtype"
                                        formControlName="password" name="password" id="password" placeholder="{{ 'LOGIN.LBL_PSWD' | translate }}"/>
                                        <a class="eye-icon" (click)="togglePassword(oldPassword)">
                                            <span *ngIf="oldPassword" class="fa fa-eye icon-theme"></span>
                                            <span *ngIf="!oldPassword" class="fa fa-eye-slash icon-theme"></span>
                                        </a>
                                        <div *ngIf="contactPersonForm.hasError('password')" class="alert alert-danger">{{contactPersonForm.getError('password')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">
                                            {{validationErrors['password']}}
                                        </div>
                                    </div>
                                    
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'ORG.LBL_ORG_LANG' | translate }} <small class="madatory-star">*</small>
                                        </label>
                                        <p-dropdown #selectElem styleClass="txt-theme form-control" [options]="langOpt" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" optionLabel="name" optionValue="id" formControlName="localeId" [(ngModel)]="selectedLang" id="localeId" name="localeId"></p-dropdown>
                                        <div *ngIf="contactPersonForm.hasError('localeId')" class="alert alert-danger">{{contactPersonForm.getError('localeId')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['localeId']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_ONE' | translate }}
                                        </label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="addressLine1" name="addressLine1" id="addressLine1" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_ONE' | translate }}" />
                                        <div *ngIf="contactPersonForm.hasError('addressLine1')" class="alert alert-danger">{{contactPersonForm.getError('addressLine1')}}</div>
                                        <!-- <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['addressLine1']}}</div> -->
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_TWO' | translate }}</label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="addressLine2" name="addressLine2" id="addressLine2" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_TWO' | translate }}" />
                                        <div *ngIf="contactPersonForm.hasError('addressLine2')" class="alert alert-danger">{{contactPersonForm.getError('addressLine2')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['addressLine2']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_THREE' | translate }}</label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="addressLine3" name="addressLine3" id="addressLine3" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_THREE' | translate }}" />
                                        <div *ngIf="contactPersonForm.hasError('addressLine3')" class="alert alert-danger">{{contactPersonForm.getError('addressLine3')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['addressLine3']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_ZIPCODE' | translate }}
                                        </label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="zipCode" name="zipCode" id="zipCode" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ZIPCODE' | translate }}" />
                                        <div *ngIf="contactPersonForm.hasError('zipCode')" class="alert alert-danger">{{contactPersonForm.getError('zipCode')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['zipCode']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_COUNTRY' | translate }}
                                        </label>
                                        <p-dropdown #selectElem styleClass="searchable-select txt-theme form-control" placeholder="{{'FIELDS.SELECT' | translate}}" [options]="countryOpt" formControlName="countryId" optionLabel="name" optionValue="id" [(ngModel)]="selectedCountry" [filter]="true" id="countryId" name="countryId"></p-dropdown>
                                        <div *ngIf="contactPersonForm.hasError('countryId')" class="alert alert-danger">{{contactPersonForm.getError('countryId')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['countryId']}}</div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_CITY' | translate }} 
                                        </label>
                                        <input class="txt-theme form-control" pInputText type="text" formControlName="city" name="city" id="city" placeholder="{{ 'ORG.LBL_ORG_CITY' | translate }}" [(ngModel)]="selectedCity" />
                                        <div *ngIf="contactPersonForm.hasError('city')" class="alert alert-danger">{{contactPersonForm.getError('city')}}</div>
                                        <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['city']}}</div>
                                    </div>
                                </div>
                                 <div class="row">
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12 contact-number-dtls">
                                        <label>{{ 'CONT_PER.LBL_CONT_PER_PHONE' | translate }}</label>
                                        <form class="p-field col-12 col-xs-12 margin-bottom-15 noPadding" id="frm_add_contact" [formGroup]="contactForm" method="post">
                                            <div class="contact-nums">
                                                <div formArrayName="contact_maps" *ngFor="let data of contact_maps['controls']; let i = index">
                                                   
                                                    <div [formGroupName]="i"> 
                                                        <div class="contact-numbers" id="contact_part_{{i}}">
                                                            <p-dropdown id="contact_code_{{i}}" class="searchable-select txt-theme form-control" placeholder="{{'FIELDS.SELECT' | translate}}" [options]="phoneCodeOpt" [filter]="true" formControlName="countryId" ></p-dropdown>
                                                            <input class="max-width-100 txt-theme form-control" type="number" maxlength="50" name="contact_number_{{i}}" id="contact_number_{{i}}" formControlName="contactNumber"
                                                            placeholder="{{'CONT_PER.PLACEHOLDER_CONT_PER_Phone' | translate }}" />
                                                            <input  type="hidden" name="contact_id_{{i}}" id="contact_id_{{i}}">
                                                            
                                                            <button type="button" id="add_button_{{i}}" class="add-new fa fa-plus-circle" (click)="addContactInfo(i)" *ngIf="i==contact_maps.controls.length-1"></button>
                                                            
                                                            <button type="button" id="remove_button_{{i}}" class="add-new fa fa-minus-circle" (click)="removeContactInfo(i)"></button>
                                                            
                                                            <div *ngIf="i==contact_maps.controls.length-1 && !contactFormValid[i]" class="alert alert-danger"> {{contactNumberNotEmptyErrorMsg}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="btn-area">
                                    <button class="btn-theme" id="btn-login"  type="submit"  [disabled]="!contactPersonForm.isValid" *ngIf="isAdd">{{ 'CONT_PER.BTN_LBL_CONT_PER_ADD' | translate }}</button>
                                    <button class="btn-theme" id="btn-login"  type="submit"  [disabled]="!contactPersonForm.isValid" *ngIf="!isAdd" >{{ 'CONT_PER.BTN_LBL_CONT_PER_UPDATE' | translate }}</button>
                                    <!-- <button type="button" class="btn btn-default btn-theme-disable" (click)="resetForm()" *ngIf="isAdd">
                                    {{ 'CONT_PER.BTN_LBL_CONT_PER_CLEAR' | translate }}
                                    </button> -->
                                    <button type="button" class="btn btn-default btn-theme-disable" (click)="back()" *ngIf="!addFromPopup">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_BTN_CANCEL' | translate }}</button>
                                </div>
                                <input  type="hidden" name="default_select_text" id="default_select_text" value="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}">
                                <input  type="hidden" name="page_title" id="page_title" value="{{ 'PAGE_TITLES.PIPELINE_USER_MANAGEMENT' | translate }}"  >
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-container">
        <ngx-loading [show]="loading"></ngx-loading>
    </div>
</div>