import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent,HttpHeaders,HttpParams} from '@angular/common/http';
import { Constants } from '../constant/constants';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { GeneralFunction } from '../common/general-function';
import { ResponseHandlerService } from './response-handler.service';
import * as $ from 'jquery';
import { ApiCallingService } from './api/api-calling.service';
import { ApiEndpointConstant } from '../constant/apiEndpoints-constants';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable()
export class FilehandlerService {
    userDtls: any;
    datatable_header_title: any;
    datatable_header_RFQ_title: any;
    datatable_delivery_content_title:any;
    isSubRfqs: any;
    productsRes: any;
    rowData: any;
    dt: any;
    expanded: any;
    index: any;
    operationType: any;
    loading: boolean = false;
    customerTotalWeight: any;
    supplierTotalWeight: any;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    orderRowData: any;
    productDt: any;
    activeOrderDt: any;
    activeOrderRowData: any;
    exp_price: any;
    original_template: any;
    repro_template: any;
    originalTemplateVal: any;
    reproTemplateVal: any;
    bidSupplier: any;
    groupName: any;
    suppliersName: any;
    groups: any;
    generalGroupName: any;
    generalGroups: any;
    currentRfqStage: any;
    productDataTableWidth: any[] = [];
    addMessage: any = "";
    msgPopupRfqId: any;
    rfqId_msg: any;
    showMessages: boolean = false;
    isSupplierNotify: boolean = true;
    totalMsgsCount: number = 0;
    msgArray: any[] = [];
    supplierData: any;
    selectedMsgSortBy: any;
    selectedMsgFilterBy: any;
    pageCount: number = 1;
    formData: any;
    rfqNo_msg: any;
    supplierId: any;
    msg: any;
    msgs: any;
    msgLast: number = 0;
    msgRes: any;
    msgFirst: number = 1;
    msgValue: any;
    sort_type_opt: any;
    filter_type_opt: any;
    selectedSortBy: any;
    selectedFilterBy: any;
    selectedNotesType: any;
    locales: any;
    localeLbls : any;
    constructor(
        private http: HttpClient,
        private httpRes: ResponseHandlerService,
        private apiCallingService: ApiCallingService,
        private translate: TranslateService, 
        public formBuilder: FormBuilder
    ) {
        let userData: any = localStorage.getItem(Constants.SESSION_NAME);
        this.userDtls = JSON.parse(userData);
        this.locales = this.userDtls.defaultCurrencyLocale;
        this.getJSON().subscribe(data => {
            this.localeLbls = data.RFQ;
        });
        this.getJSON().subscribe(data => {
            this.datatable_header_title = data.ORDER;
        });
        this.getJSON().subscribe(data => {
            this.datatable_header_RFQ_title = data.RFQ;
            this.datatable_delivery_content_title = data.SUPPLIER_RFQ_PRODUCT;
        });
    }

    ngOnInit() {
        this.sort_type_opt = [];
        this.sort_type_opt.push({
            label: Constants.SORT_BY_NOTES['NEWEST'],
            value: Constants.SORT_BY_NOTES['NEWEST']
        });
        this.sort_type_opt.push({
            label: Constants.SORT_BY_NOTES['OLDEST'],
            value: Constants.SORT_BY_NOTES['OLDEST']
        });

        this.filter_type_opt = [];
        this.filter_type_opt.push({
            label: Constants.FILTER_BY_NOTES['ALL'],
            value: ""
        });
        this.filter_type_opt.push({
            label: Constants.FILTER_BY_NOTES['BY_ME_LABEL'],
            value: Constants.FILTER_BY_NOTES['BY_ME']
        });
        this.filter_type_opt.push({
            label: Constants.FILTER_BY_NOTES['BY_OTHER_LABEL'],
            value: Constants.FILTER_BY_NOTES['BY_OTHER']
        });

        this.selectedMsgSortBy = Constants.SORT_BY_NOTES['NEWEST'];
        this.selectedMsgFilterBy = "";

        this.selectedSortBy = Constants.SORT_BY_NOTES['NEWEST'];
        this.selectedFilterBy = Constants.FILTER_BY_NOTES['ALL'];
        this.selectedNotesType = Constants.NOTES_TYPE['ALL'];
    }
    // Code for downloading function ends
    getJSON(): Observable < any > {
        return this.http.get("../../assets/i18n/" + this.userDtls.i18nCode + ".json");
    }
    // To set changed currency format based on locales applied on input id
    public changeCurrencyFormat(number: any, id: any, locals: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && number.indexOf(',') > -1) {
            number = number.replace(/,/g, '.');
        }
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
            var newNumber = number.replace(/\s/g, '');
            $('#' + id).val(Number(newNumber).toLocaleString(this.locales));
        }
    }
    // To Set converted values to ng-model
    public changeCurrencyFormatVal(number: any, locals?: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && number.indexOf(',') > -1) {
            return number = number.replace(/,/g, '.');
        }
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
            var newNumber = number.replace(/\s/g, '');
            return Number(newNumber).toLocaleString(this.locales);
        }
    }

    public prepareOriginalTemplateOptions() {
        let original_template: any = []
        this.translate.get('ORIGINAL_TEMPLATE').subscribe((text: any) => {
            original_template = [
                {
                    value: "FROM_CUSTOMER_PRINT_READY_PDF_INCL_PIPELINE_ICC_PROFILES",
                    label: text.FROM_CUSTOMER_PRINT, 
                },
                {
                    value: "OTHERS",
                    label: text.OTHERS, 
                }
            ];
        })
        return original_template;
    }
    public prepareReproTemplateOptions() {
        let repro_template: any = []
        this.translate.get('REPRO_TEMPLATE').subscribe((text: any) => {
            repro_template = [
                {
                    value: "DOCUMENT_CONTROL_SCREEN_PROFFING_FROM_US",
                    label: text.DOCUMENT_CONTROL_SCREEN, 
                },
                {
                    value: "DOCUMENT_CONTROL_COLOR_SAMPLES_FROM_US",
                    label: text.DOCUMENT_CONTROL_COLOR, 
                },
                {
                    value: "DOCUMENT_CONTROL_TEST_PRINT_INDIGO",
                    label: text.DOCUMENT_CONTROL_TEST, 
                },
                {
                    value: "OTHERS",
                    label: text.OTHERS, 
                }
            ];
        })
        return repro_template;
    }
    public changeDotToComma(number: any, locals: any) {
        return parseInt(number).toLocaleString(this.locales);
    }
    // To Set converted values to datatable Purpose
    public changeCurrencyFormatValDatatable(number: any, locals=null) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number)) {
            if (typeof(number) != 'number') {
                if (number.indexOf(' ') >= 0) {
                    var newNumber = number.split(" ");
                    var numberOnly = newNumber[0];
                    var numberString = newNumber[1];
    
                    if (numberOnly.indexOf(',') > -1) {
                        return numberOnly = numberOnly.replace(/,/g, '.');
                    }
                    if (!GeneralFunction.checkForNullUndefinedBlank(numberOnly) && !isNaN(numberOnly)) {
                        var newNumber = (numberOnly.replace(/\s/g, ''));
                        return Number(newNumber).toString()+ ' ' + parseInt(numberString).toString();
                    }
                } else if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
                    if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
                        var newNumber: any = parseInt(number.replace(/\s/g, ''));
                        return newNumber.toLocaleString(this.locales);
                    } else {
                        if (number.indexOf('.') == -1) {
                            return parseInt(number).toLocaleString(this.locales);
                        }
                    }
                } else {
                    if (number.indexOf(',') > -1) {
                        return number = parseInt(number.replace(/,/g, '.')).toLocaleString(this.locales);
                    } else {
                        if (number.indexOf('.') == -1) {
                            return parseInt(number).toLocaleString(this.locales);
                        }
                    }
                }
            } else {
                let newNumber: any = number;
                return parseInt(newNumber).toLocaleString(this.locales);
            }
        }
    }
    public convertCommaTodot(number: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number)) {
            if (typeof(number) != 'number') {
                number = number.replace(/\./g, '')
                number = number.replace(/,/g, '.');
            }
        }
        return number;
    }
    public convertDotToComma(number: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number)) {
            number = number.replace(/\./g, ',');
            return number;
        }
    }

    // Function for preparing currency lists
    prepareCurrencyOptionList() {
        let currencyOptionsList = []
        if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.currencies)) {
            for (let co = 0; co < this.productsRes.currencies?.length; co++) {
                currencyOptionsList.push({
                    'value': this.productsRes.currencies[co].id,
                    'label': this.productsRes.currencies[co].name
                })
            }
        }
        return currencyOptionsList;
    }
    
    preparepProductionTimeOptionList() {
        let currencyOptionsList = []
        this.translate.get('PRODUCTION_TIME').subscribe(
            (text: any) => {
                currencyOptionsList = [
                    {label: text.ONE_TO_THREE_DAYS, value: Constants.productionTime.ONE_TO_THREE_DAYS},
                    {label: text.THREE_TO_FIVE_DAYS, value: Constants.productionTime.THREE_TO_FIVE_DAYS},
                    {label: text.FIVE_TO_SEVEN_DAYS, value: Constants.productionTime.FIVE_TO_SEVEN_DAYS},
                    {label: text.SEVEN_TO_ELEVEN_DAYS, value: Constants.productionTime.SEVEN_TO_ELEVEN_DAYS},
                    {label: text.TWELVE_TO_SEVENTEEN_DAYS, value: Constants.productionTime.TWELVE_TO_SEVENTEEN_DAYS},
                    {label: text.ONE_MONTH, value: Constants.productionTime.ONE_MONTH}
                ];
            }
        );
        return currencyOptionsList;
    }

    // Function for prepare delivery options list
    prepareDeliveryOptionsList() {
        let deliveryOptionsList = []
        if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryOptionsList)) {
            for (var co = 0; co < this.productsRes.deliveryOptionsList.ids?.length; co++) {
                deliveryOptionsList.push({
                    'value': this.productsRes.deliveryOptionsList.ids[co],
                    'label': this.productsRes.deliveryOptionsList.names[co]
                })
            }
        }
        return deliveryOptionsList;
    }


     // Function for prepare delivery options list
    prepareOtherCostList() {
        let otherCostList = []
        if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.costDataCounts)) {
            for (var co = 0; co < this.productsRes.costData.ids?.length; co++) {
                otherCostList.push({
                    'cost_value_sek': this.changeCurrencyFormatValDatatable(this.productsRes.costData.costValues[co]),
                    'description': this.productsRes.costData.descriptions[co]
                })
            }
        }
        return otherCostList;
    }

    // Function for preparing original template
    prepareProductOriginalTemplate(rowData: any, productData: any) {
        let templateVar = "";
        if(this.userDtls.type == "CUSTOMER_USER"){
          templateVar += "<div class='confirm-dtls'>"
          templateVar += "<label class='lbl-title'>"+this.localeLbls.LBL_TEMPLATE_ORGINAL+"</label>";
          for (var i = 0; i < rowData.original_template?.length; i++) {
              if (rowData.original_template[i].value == productData.printproductDtls.templateOriginal) {
                  templateVar += "<label class='lbl-value'>" + rowData.original_template[i].label + "</label>";

                  if (productData.printproductDtls.templateOriginal == 5) {
                      templateVar += "<label class='lbl-value'>" + productData.printproductDtls.templateOriginalOtherValue + "</label>"
                  }
              }
          }
          templateVar += "</div>"
        }
        else{
          templateVar += "<span class='pro-title-name'> "+this.localeLbls.LBL_TEMPLATE_ORGINAL+" </span>";
          for (var i = 0; i < rowData.original_template?.length; i++) {
              if (rowData.original_template[i].value == productData.printproductDtls.templateOriginal) {
                  templateVar += "<span class='pro-data-name'>" + rowData.original_template[i].label + "</span>";

                  if (productData.printproductDtls.templateOriginal == 5) {
                      templateVar += "<span class='pro-data-name'>" + productData.printproductDtls.templateOriginalOtherValue + "</span>"
                  }
              }
          }
        }

        return templateVar;
    }

    // Function for preparing repro template
    prepareProductReproTemplate(rowData: any, productData: any) {
        let reproTemplateVal = "";
        if(this.userDtls.type == "CUSTOMER_USER"){
          reproTemplateVal += "<div class='confirm-dtls'>"
          reproTemplateVal += "<label class='lbl-title'> "+this.localeLbls.LBL_TEMPLATE_REPRO+"</label>";
          for (var i = 0; i < rowData.repro_template?.length; i++) {
              if (rowData.repro_template[i].value == productData.printproductDtls.templateRepro) {
                  reproTemplateVal += "<label class='lbl-value'>" + rowData.repro_template[i].label + "</label>";
                  if (productData.printproductDtls.templateRepro == 6) {
                      reproTemplateVal += "<label class='lbl-value'>" + productData.printproductDtls.templateReproOtherValue + "</label>"
                  }
              }
          }
          reproTemplateVal += "</div>"
        } else{
          reproTemplateVal += "<span class='pro-title-name'>"+this.localeLbls.LBL_TEMPLATE_REPRO+"</span>";
          for (var i = 0; i < rowData.repro_template?.length; i++) {
              if (rowData.repro_template[i].value == productData.printproductDtls.templateRepro) {
                  reproTemplateVal += "<span class='pro-data-name'>" + rowData.repro_template[i].label + "</span>";
                  if (productData.printproductDtls.templateRepro == 6) {
                      reproTemplateVal += "<span class='pro-data-name'>" + productData.printproductDtls.templateReproOtherValue + "</span>"
                  }
              }
          }
        }
        return reproTemplateVal;
    }

    // Function for preparing print options
    preparePrintOptions(productData: any) {
        let printOpts = "";
        if (productData.printproductDtls.printOpts.ids != null) {
            for (var po = 0; po < productData.printproductDtls.printOpts.ids?.length; po++) {
                if (po != 0) {
                    printOpts += ", " + productData.printproductDtls.printOpts.names[po];
                } else {
                    printOpts += productData.printproductDtls.printOpts.names[po];
                }
            }
        }
        return printOpts;
    }

    // Function for preparing binding options
    prepareBindingOptions(productData: any) {
        let bindingOpts = "";
        if (productData.printproductDtls.bindingOpts.ids != null) {
            for (var bo = 0; bo < productData.printproductDtls.bindingOpts.ids?.length; bo++) {
                if (bo != 0) {
                    bindingOpts += ", " + productData.printproductDtls.bindingOpts.names[bo];
                } else {
                    bindingOpts += productData.printproductDtls.bindingOpts.names[bo];
                }
            }
            bindingOpts += "<br/>";
        }
        return bindingOpts;
    }

    // Function for preparing extra options
    prepareExtraOptions(productData: any) {
        let extraOpts = "";
        if (productData.printproductDtls.extraOpts.ids != null) {
            for (var eo = 0; eo < productData.printproductDtls.extraOpts.ids?.length; eo++) {
                if (eo != 0) {
                    extraOpts += ", " + productData.printproductDtls.extraOpts.names[eo];
                } else {
                    extraOpts += productData.printproductDtls.extraOpts.names[eo];
                }
            }
        }
        return extraOpts;
    }

    // Function for preparing supplier bids
    prepareSupplierBidsArray(productData: any) {
        let supplierBids: any = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(productData.supplierBids)){
            if (productData.supplierBids?.length > 0) {
                for (var i = 0; i < productData.supplierBids?.length; i++) {
                    var supplierbidCover: any = '';
                    var supplierbidContent: any = '';
                    if (productData.supplierBids[i].supplierBidPageDtls && productData.supplierBids[i].supplierBidPageDtls.length > 0) {
                        
                        for (var j = 0; j < productData.supplierBids[i].supplierBidPageDtls.length; j++) {
                            console.log('SupplierBidFunction', productData.supplierBids[i].supplierBidPageDtls[j].pageType);
                            if (productData.supplierBids[i].supplierBidPageDtls[j].pageType === Constants.PAPER_TYPE['COVER_TYPE'] || productData.supplierBids[i].supplierBidPageDtls[j].pageType === Constants.PAPER_TYPE['PAPER_TYPE']) {

                                supplierbidCover += "<div class='lbl-highlight'>"+GeneralFunction.capitalizeStr(productData.supplierBids[i].supplierBidPageDtls[j].pageType)+" </div>";
                                supplierbidCover += '<div class="confirm-dtls"><label class="lbl-title">'+this.localeLbls.LBL_TITLE_PAPER_NAME+'</label><label class="lbl-value">';
                                supplierbidCover += productData.supplierBids[i].supplierBidPageDtls[j].paperName + " (" + productData.supplierBids[i].supplierBidPageDtls[j].paperWeight + ")</label></div>";
                            } else {
                                supplierbidContent += "<div class='lbl-highlight'>"+"Content"+" </div>";
                                supplierbidContent += '<div class="confirm-dtls"><label class="lbl-title">'+this.localeLbls.LBL_TITLE_PAPER_NAME+'</label><label class="lbl-value">';
                                supplierbidContent += productData.supplierBids[i].supplierBidPageDtls[j].paperName + " (" + productData.supplierBids[i].supplierBidPageDtls[j].paperWeight + ")</label></div>";
                            }
                        }
                    }
                    var supplier_cover_paper_details = supplierbidCover;
                    var supplier_content_paper_details = supplierbidContent;

                    if(productData.supplierBids[i].isPayCurrencySek==false){
                        var bidPrice1 = productData.supplierBids[i].productPriceSek;
                        var bidPrice2 = productData.supplierBids[i].productPrice;
                        var additionalBidPriceSEK = productData.supplierBids[i].additionalCopiesProductPriceSek;
                        var additionalBidPrice = productData.supplierBids[i].additionalCopiesProductPrice;
                        var convertedBidPrice = this.changeCurrencyFormatValDatatable(bidPrice1, this.locales);
                        var originalBidPrice= this.changeCurrencyFormatValDatatable(bidPrice2, this.locales);
                        var convertedAdditionalBidPrice = this.convertPriceIntoCurrency(additionalBidPriceSEK, Constants.CURRENCY_VAL)
                        var originalAdditionalBidPrice = this.convertPriceIntoCurrency(additionalBidPrice, productData.supplierBids[i].payCurrencyCode);
                        var bidPrice:any = Constants.CURRENCY_VAL+' '+convertedBidPrice+' ('+productData.supplierBids[i].payCurrencyCode+' '+originalBidPrice +')';
                        var additionalCopiesBidPrice: any = convertedAdditionalBidPrice + ' ('+originalAdditionalBidPrice+')';
                    }else{
                        var bidPrice:any = Constants.CURRENCY_VAL+' '+this.changeCurrencyFormatValDatatable(productData.supplierBids[i].productPriceSek, this.locales);
                        var additionalCopiesBidPrice: any = this.convertPriceIntoCurrency(additionalBidPriceSEK, Constants.CURRENCY_VAL);
                    }

                    if(productData.supplierBids[i].isPayCurrencySek==false && !GeneralFunction.checkForNullUndefinedBlank(productData.supplierBids[i].additionalCopiesProductPrice)){
                        var additionalBidPriceSEK = productData.supplierBids[i].additionalCopiesProductPriceSek;
                        var additionalBidPrice = productData.supplierBids[i].additionalCopiesProductPrice;
                        var convertedAdditionalBidPrice = this.convertPriceIntoCurrency(additionalBidPriceSEK, Constants.CURRENCY_VAL)
                        var originalAdditionalBidPrice = this.convertPriceIntoCurrency(additionalBidPrice, productData.supplierBids[i].payCurrencyCode);
                        var additionalCopiesBidPrice: any = convertedAdditionalBidPrice + ' ('+originalAdditionalBidPrice+')';
                    } else if (!GeneralFunction.checkForNullUndefinedBlank(productData.supplierBids[i].additionalCopiesProductPrice)){
                        var additionalCopiesBidPrice: any = this.convertPriceIntoCurrency(additionalBidPriceSEK, Constants.CURRENCY_VAL);
                    } else {
                        var additionalCopiesBidPrice: any = '';
                    }

                    supplierBids.push({
                        'id': productData.supplierBids[i].id,
                        'supplierName': productData.supplierBids[i].supplierOrganizationName,
                        'supplierId': productData.supplierBids[i].supplierOrganizationId,
                        'deliveryTime': productData.supplierBids[i].productionTimeStr,
                        'totalPrice': (this.changeCurrencyFormatValDatatable(productData.supplierBids[i].productPrice, this.locales) != null ? this.changeCurrencyFormatValDatatable(productData.supplierBids[i].productPrice, this.locales) : ''),
                        'ratings': productData.supplierBids[i].systemRating,
                        'isAccepted': productData.supplierBids[i].currentStatus === 'ACCEPTED' || productData.supplierBids[i].currentStatus === 'APPROVED' ? true : false,
                        'isDeclined': productData.supplierBids[i].currentStatus === 'REJECTED' || productData.supplierBids[i].currentStatus === 'DECLINED' ? true : false,
                        'bidAcceptedBy': productData.supplierBids[i].lastProcessedPipelineUserName,
                        'bidDeclineReason': productData.supplierBids[i].declineReasonStr,
                        'bidStatus': productData.supplierBids[i].currentStatus,
                        'bidPrice': productData.supplierBids[i].productPrice,
                        //'bidPriceStr': (productData.supplierBids[i].productPrice != null ? this.changeCurrencyFormatValDatatable(productData.supplierBids[i].productPrice, this.locales) : '') + " "+ productData.supplierBids[i].payCurrencyCode,
                        'bidPriceStr':bidPrice,
                        'payCurrencyId': productData.supplierBids[i].payCurrencyId,
                        'productionTime': productData.supplierBids[i].productionTime,
                        'pages': productData.supplierBids[i].supplierBidPageDtls,
                        'supplierCoverPaperDetails': supplier_cover_paper_details,
                        'supplierContentPaperDetails': supplier_content_paper_details,
                        'bidComment': productData.supplierBids[i].comments,
                        'bidQuotationRefNo': productData.supplierBids[i].quotationReferenceNumber,
                        'additionalCopyPrice': productData.supplierBids[i].additionalCopiesProductPrice,
                        'additionalCopyPriceStr': additionalCopiesBidPrice
                    })
                }
            }
        }
        return supplierBids;
    }

    //  Function for generating bidding list columns
    prepareBiddingListColumns() {
        let columns = [{
                field: 'supplierName',
                header: this.datatable_header_title.LBL_SUPPLIER,
                colWidth: '20%'

            },
            {
                field: 'bidPriceStr',
                header: this.datatable_header_title.BID_PRICE,
                colWidth: '20%'
            },
            {
                field: 'deliveryTime',
                header: this.datatable_header_title.LBL_DELIVERY_TIME,
                colWidth: '15%'
            }
        ];
        return columns;
    }

    // Function for preparing file list
    prepareFileList(productData) {
        let fileList = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(productData.files) && productData.files.length > 0){
            for (var pf = 0; pf < productData.files.length; pf++) {
                var pistop_status;
                if (productData.files[pf].rfqOrderFileCurrentStatus === 'PITSTOP_PROCESSED' ||
                    productData.files[pf].rfqOrderFileCurrentStatus === 'APPROVED' ||
                    productData.files[pf].rfqOrderFileCurrentStatus === 'DISCARD') {
                    pistop_status = true
                } else {
                    pistop_status = false;
                }

                // console.log('paperDtls',productData.files[pf]);
                
                if (productData.files[pf].fileExtension == "pdf") {
                    var pageTypeStr = !(GeneralFunction.checkForNullUndefinedBlank(productData.files[pf].pageTypeStr)) ? productData.files[pf].pageTypeStr :'';
                    var filePaperDtls = '<b>'+this.localeLbls.LBL_PAPER_TYPE+': </b> ' + pageTypeStr + '<br/>';
                    
                    if (this.userDtls.type == "PIPELINE_USER" && (productData.printType!=Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING'] && productData.printType!=Constants.PRINT_TYPES['GLUED_COUPON_PRINTING']))  {
                        var printPaperProfileStr =  !(GeneralFunction.checkForNullUndefinedBlank(productData.files[pf].printPaperProfileStr)) ? productData.files[pf].printPaperProfileStr :'';
                        var printTray =  !(GeneralFunction.checkForNullUndefinedBlank(productData.files[pf].printTray)) ? productData.files[pf].printTray :'';

                        filePaperDtls += '<b>'+this.localeLbls.LBL_PAPER_PROFILE_TYPE+': </b>' + printPaperProfileStr + '<br/>';
                        filePaperDtls += '<b>'+this.localeLbls.LBL_TITLE_TRAY+': </b> ' + printTray;
                    }
                } else {
                    var filePaperDtls = '';
                }
                var displayStatus = '';
                var fileStatus:boolean;
                if (productData.files[pf].supplierVisibilityStatus == "HIDDEN"){
                    displayStatus = this.localeLbls.LBL_STATUS_HIDDEN;
                    fileStatus=false;
                } else{
                    displayStatus = this.localeLbls.LBL_STATUS_VISIBLE;
                    fileStatus=true;
                }
                    
                var description = GeneralFunction.makeFileDescription(productData.files, pf);
                fileList.push({
                    "fileName": productData.files[pf].originalFileName,
                    "systemFileTypeStr":productData.files[pf].systemFileTypeStr,
                    "fileFinishedSizeStr":productData.files[pf].fileFinishedSizeStr,
                    "fileExtension":productData.files[pf].fileExtension,
                    "pitstopReportFileSystemName":productData.files[pf].pitstopReportFileSystemName,
                    "no": pf + 1,
                    "systemFileId": productData.files[pf].systemFileId,
                    "id":productData.files[pf].rfqOrderProductFileId,
                    "rfqOrderProductFileId": productData.files[pf].rfqOrderProductFileId,
                    "pitstopReportSystemFileId": productData.files[pf].pitstopReportSystemFileId,
                    "pitstopOutputSystemFileId":productData.files[pf].pitstopOutputSystemFileId,
                    "description": description,
                    "fileDtls": [],
                    "pitstopStatus": pistop_status,
                    "fileStatusSupplier": displayStatus,
                    "fileStatus": fileStatus,
                    "fileDiscardedStatus": productData.files[pf].rfqOrderFileCurrentStatus,
                    "filePaperDtls": filePaperDtls,
                    "fileSizeDiscrepencies":(productData.files[pf].isFileDiscrepancy==true) ? 1 : 0,
                    "storeFileName":productData.files[pf].storeFileName,
                    "originalFileDownloadLink":productData.files[pf].originalFileDownloadLink,
                    "pitstopOutputFileDownloadLink":productData.files[pf].pitstopOutputFileDownloadLink,
                    "printPaperProfile":productData.files[pf].printPaperProfile,
                    "printPaperProfileStr" :productData.files[pf].printPaperProfileStr,
                    "rfqOrderFileCurrentStatus" :productData.files[pf].rfqOrderFileCurrentStatus,
                    "rfqOrderFileCurrentStatusStr" :productData.files[pf].rfqOrderFileCurrentStatusStr,
                    "supplierVisibilityStatus":productData.files[pf].supplierVisibilityStatus,
                    "supplierVisibilityStatusStr":productData.files[pf].supplierVisibilityStatusStr,
                    "userType": productData.files[pf].userType,
                    "userTypeStr": productData.files[pf].userTypeStr,
                });
            }
        }
        return fileList;
    }
    initBidProductEdit(rowDataObj: any = null) {
        if (rowDataObj) {
            return this.formBuilder.group({
                'pageType': [rowDataObj.pageType, []],
                'paperName': [rowDataObj.paperName, []],
                'paperWeight': [rowDataObj.paperWeight, []]
            });
        } else {
            return this.formBuilder.group({
                'pageType': ['CONTENT', []],
                'paperName': ['', []],
                'paperWeight': ['', []]
            });
        }
    }
    initBidProduct(rowDataObj: any = null) {
        if (rowDataObj) {
            return this.formBuilder.group({
                'pageType': [rowDataObj.pageTypeSupplier, []],
                'paperName': [rowDataObj.printPaperNameSupplier, []],
                'paperWeight': [rowDataObj.paperWeightSupplier, []]
            });
        } else {
            return this.formBuilder.group({
                'pageType': ['CONTENT', []],
                'paperName': ['', []],
                'paperWeight': ['', []]
            });
        }
    }
    // Function for preparing product details response
    prepareProductDetails(rowData: any, productData: any, parentTableData: any, type) {
        let productDetails: any = {}
        if (this.userDtls.type === Constants.USER_TYPE.SUPPLIER_USER) {
            if (type === 'PRINT') {
                productDetails.supplierRFQFormSubmitted = false;
                productDetails.isAccepted = false;
                productDetails.showDecline = false;
                productDetails.showBidZeroError = false;
                productDetails.showAdditionalBidZeroError = false;
                let supplierRFQForm = this.formBuilder.group({
                    'productPrice': ['', [Validators.required]],
                    'additionalCopiesProductPrice': ['', []],
                    'payCurrencyId': ['', [Validators.required]],
                    'productionTime':['', [Validators.required]],
                    'supplierBidPageDtls': this.formBuilder.array([]),
                    'quotationReferenceNumber': ['', []],
                    'comments': ['', []],
                    'notifySupplier': [false, []],
                    'termsAndCondition': [false, []],
                });
                let paperArray = this.formBuilder.array([]);
                if (!GeneralFunction.checkForNullUndefinedBlank(productData)) {
                    let supplierArray = [];
                    if (!GeneralFunction.checkForNullUndefinedBlank(productData.currentSupplierBid)) {
                        productDetails.editBid = true;
                        productDetails.editSupplierBid = productData.currentSupplierBid.id
                        productDetails.printProductId = productData.productId;
                        productDetails.additionalPrintProductPrice = !GeneralFunction.checkForNullUndefinedBlank(productData.currentSupplierBid.additionalCopiesProductPrice) ? productData.currentSupplierBid.additionalCopiesProductPrice: null;
                        productDetails.printProductPrice = productData.currentSupplierBid.productPrice;
                        productDetails.printComments = productData.currentSupplierBid.comments;
                        productDetails.printquotationReferenceNumber = productData.currentSupplierBid.quotationReferenceNumber;
                        productDetails.bidCurrency = productData.currentSupplierBid.payCurrencyId;
                        productDetails.bidExpectedDays = productData.currentSupplierBid.productionTime;
                        productDetails.paperSupplierArray = supplierArray;
                        productDetails.termsAndCondition = false;
                        productDetails.bidFormSubmitted = true;
                        if (productData.additionalCopiesSupplier && productData.additionalCopiesSupplier > 0) {
                            productDetails.isAdditionalCopiesAvailable = true;
                        } else {
                            productDetails.isAdditionalCopiesAvailable = false;
                        }
                        for (let index = 0; index < productData.currentSupplierBid.supplierBidPageDtls.length; index++) {
                            paperArray.push(this.initBidProductEdit(productData.currentSupplierBid.supplierBidPageDtls[index]));
                            supplierArray.push({
                                supplierPaperName: productData.currentSupplierBid.supplierBidPageDtls[index].paperName,
                                supplierPaperWeight: productData.currentSupplierBid.supplierBidPageDtls[index].paperWeight,
                                supplierPaperPaperType: productData.currentSupplierBid.supplierBidPageDtls[index].pageType,
                                supplierPaperPaperTypeStr: productData.currentSupplierBid.supplierBidPageDtls[index].pageTypeStr,
                            })
                        }
                    } else {
                        productDetails.editBid = false;
                        productDetails.editSupplierBid = null;
                        productDetails.printProductId = productData.productId;
                        if (productData.additionalCopiesSupplier && productData.additionalCopiesSupplier > 0) {
                            productDetails.isAdditionalCopiesAvailable = true;
                        } else {
                            productDetails.isAdditionalCopiesAvailable = false;
                        }
                        if (!GeneralFunction.checkForNullUndefinedBlank(productData.pages)) {
                            for (let index = 0; index < productData.pages.length; index++) {
                                paperArray.push(this.initBidProduct(productData.pages[index]));
                                supplierArray.push({
                                    supplierPaperName: productData.pages[index].printPaperNameSupplier,
                                    supplierPaperWeight: productData.pages[index].paperWeightSupplier,
                                    supplierPaperPaperType: productData.pages[index].pageTypeSupplier,
                                    supplierPaperPaperTypeStr: productData.pages[index].pageTypeSupplierStr,
                                });
                            }
                        }
                        productDetails.bidCurrency = this.userDtls.defaultCurrencyId ? this.userDtls.defaultCurrencyId : '';
                        productDetails.bidExpectedDays = '';
                        productDetails.additionalPrintProductPrice = null;
                        productDetails.printProductPrice = null;
                        productDetails.printComments = '';
                        productDetails.printquotationReferenceNumber = '';
                        productDetails.paperSupplierArray = supplierArray;
                        productDetails.termsAndCondition = false;
                        productDetails.bidFormSubmitted = true;
                    }
                    if (supplierArray.length === 0) {
                        supplierArray.push({
                            supplierPaperName: '',
                            supplierPaperWeight: '',
                            supplierPaperPaperType: 'CONTENT',
                            supplierPaperPaperTypeStr: 'Content',
                        })
                        productDetails.paperSupplierArray = supplierArray;
                        paperArray.push(this.initBidProduct());
                    }
                }
                supplierRFQForm = this.formBuilder.group({
                    "productPrice": ['', [Validators.required]],
                    "additionalCopiesProductPrice": ['', []],
                    "payCurrencyId": ['', [Validators.required]],
                    "productionTime": ['', [Validators.required]],
                    'supplierBidPageDtls': paperArray,
                    "quotationReferenceNumber": ['', []],
                    "comments": ['', []],
                    "notifySupplier": [true, []],
                    "termsAndCondition": [false, []],
                });
                productDetails.supplierRFQForm = supplierRFQForm;
            } else if (type === 'OTHER') {
                productDetails.isAccepted = false;
                productDetails.showDecline = false;
                productDetails.showBidZeroError = false;
                let supplierRFQGeneralForm = this.formBuilder.group({
                    'productPrice': ['', [Validators.required]],
                    'payCurrencyId': ['', [Validators.required]],
                    'productionTime':['', [Validators.required]],
                    'quotationReferenceNumber': ['', []],
                    'comments': ['', []],
                    'notifySupplier': [false, []],
                    'termsAndCondition': [false, []],
                });
                productDetails.generalProductId = productData.productId;
                if (!GeneralFunction.checkForNullUndefinedBlank(productData)) {
                    if (!GeneralFunction.checkForNullUndefinedBlank(productData.currentSupplierBid)) {
                        productDetails.editGeneralBid = true;
                        productDetails.editGeneralSupplierBid = productData.currentSupplierBid.id
                        productDetails.bidGeneralCurrency = productData.currentSupplierBid.payCurrencyId;
                        productDetails.bidGeneralExpectedDays = productData.currentSupplierBid.productionTime;
                        productDetails.generalProductPrice = productData.currentSupplierBid.productPrice;
                        productDetails.generalComments = productData.currentSupplierBid.comments;
                        productDetails.generalquotationReferenceNumber = productData.currentSupplierBid.quotationReferenceNumber;
                        productDetails.generalTermsAndCondition = false;
                        productDetails.generalBidFormSubmitted = true;
                    } else {
                        productDetails.editGeneralBid = false;
                        productDetails.editGeneralSupplierBid = null;
                        productDetails.bidGeneralCurrency = this.userDtls.defaultCurrencyId ? this.userDtls.defaultCurrencyId : '';
                        productDetails.bidGeneralExpectedDays = '';
                        productDetails.generalProductPrice = null;
                        productDetails.generalComments = '';
                        productDetails.generalquotationReferenceNumber = '';
                        productDetails.generalTermsAndCondition = false;
                        productDetails.generalBidFormSubmitted = true;
                    }
                }
                productDetails.supplierGeneralRFQForm = supplierRFQGeneralForm;
            }
        }
        productDetails.supplierBids = this.prepareSupplierBidsArray(productData);
        productDetails.biddingCols = this.prepareBiddingListColumns();
        productDetails.files = this.prepareFileList(productData);
        return productDetails;
    }

    RFQExpand(rowData: any, dt: any, expanded: boolean, index: any, current_tab: any, currentStatus: any, isProduct ? : any, data ? : any) {
        let parentTableData: any = [];
        this.loading = true;
        parentTableData = {
            rowData: rowData,
            dt: dt,
            expanded: expanded,
            index: index,
            current_tab: current_tab,
            currentStatus: currentStatus,
            isProduct: isProduct
        }
        this.productDataTableWidth = [{
            colWidth: '0%'
        }, {
            colWidth: '0%'
        }];
        this.getJSON().subscribe(data => {
            this.datatable_header_title = data.ORDER;
            this.datatable_header_RFQ_title = data.RFQ;
        });
        this.rowData = rowData;
        this.dt = dt;
        this.expanded = expanded;
        this.index = index;
        if (this.rowData?.filterStatus == 'VIEW') {
            this.operationType = 'VIEW';
        }else{
            this.operationType = current_tab;
        }
        this.currentRfqStage = currentStatus;
        if (!expanded) {            
            this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, ApiEndpointConstant.expandGetDataAPI(rowData.id)).subscribe(res => {
                rowData.products = [];
                rowData.price = [];
                rowData.subRFQbasicDetails = [];
                this.productsRes = res;
                if (rowData.isUrgent) {
                    rowData.supplierExpireHours = this.productsRes.basicDtl.rfqSupplierExpiryHours;
                }
                rowData.parentTableData = parentTableData;
                if (this.productsRes.resultCode == Constants.resultCode.SUCCESS) {
                    let currencyOptionsList = this.prepareCurrencyOptionList();
                    let deliveryOptionsList = this.prepareDeliveryOptionsList();
                    let productionOptionList = this.preparepProductionTimeOptionList();
                    let otherCostList = this.prepareOtherCostList();
                    if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes?.costDataCounts)) {
                        var totalOtherCost = 0;
                        for (var co = 0; co < this.productsRes?.costDataCounts; co++) {
                            totalOtherCost += parseInt(this.productsRes?.costData.costValues[co]);
                        }

                        rowData.grandTotal = this.changeCurrencyFormatValDatatable(totalOtherCost.toString());
                    }else{
                        rowData.grandTotal = this.productsRes?.costDataCounts;
                    }

                    

                    this.original_template = this.prepareOriginalTemplateOptions;
                    rowData.original_template = this.original_template;
                    this.repro_template = this.prepareReproTemplateOptions;
                    rowData.repro_template = this.repro_template;
                    rowData.sales_manager_opt = GeneralFunction.getAccountSalesProjectManagerData(this.productsRes?.salesManagers);
                    rowData.project_manager_opt = GeneralFunction.getAccountSalesProjectManagerData(this.productsRes?.projectManagers);
                    rowData.account_manager_opt = GeneralFunction.getAccountSalesProjectManagerData(this.productsRes?.accountManagers);
                    rowData.isRFQ = true;
                    rowData.isOrder = false;
                    rowData.otherCostList = otherCostList;
                    
                    if (this.rowData?.filterStatus == 'VIEW') {
                        rowData.operationType = 'VIEW';
                    }else{
                        rowData.operationType = current_tab;
                    }
                    rowData.currencies = currencyOptionsList;
                    rowData.produtionOptions = productionOptionList;
                    rowData.isSubRfq = false;
                    rowData.selected_rejection_reason = '';
                    rowData.requestDate = this.productsRes?.basicDtl?.rfqRequestDate;

                    rowData.referenceOrderNumber = this.productsRes?.basicDtl?.referenceOrderNumber;
                    rowData.endCustomerName = this.productsRes?.basicDtl?.endCustomerName;
                    rowData.endCustomerAddress = this.convertSlashNToBR(this.productsRes?.basicDtl?.endCustomerAddress);
                    rowData.totalWeightCustomer = this.productsRes?.basicDtl?.totalWeightCustomer;
                    rowData.totalWeightSupplier = this.productsRes?.basicDtl?.totalWeightSupplier;
                    rowData.expiryDate = this.productsRes?.basicDtl?.rfqPipelineExpiryDate;
                    rowData.expDeliveryDate = this.productsRes?.basicDtl?.expectedDeliveryDate;
                    rowData.communicationMode = this.productsRes?.basicDtl?.customerCommunicationModeStr;
                    rowData.customerContactName = this.productsRes?.basicDtl?.customerContactPersonUserName;
                    rowData.customerName = this.productsRes?.basicDtl?.customerOrganizationName;
                    rowData.customer_additional_information = this.convertSlashNToBR(this.productsRes?.basicDtl?.customerAdditionalInfo);
                    rowData.supplier_additional_information = this.convertSlashNToBR(this.productsRes?.basicDtl?.supplierAdditionalInfo);
                    rowData.accountManagerId = this.productsRes?.basicDtl?.accountManagerUserId;
                    rowData.salesManagerId = this.productsRes?.basicDtl?.salesManagerUserId;
                    rowData.projectManagerId = this.productsRes?.basicDtl?.projectManagerUserId;
                    rowData.projectName = this.productsRes?.basicDtl?.projectName;
                    rowData.description = this.productsRes?.basicDtl?.comments;
                    rowData.vatPercentage = this.productsRes?.basicDtl?.vat;
                    rowData.perviousVatPercentage = this.productsRes?.basicDtl?.vat;
                    rowData.purpose = this.productsRes?.basicDtl?.customerPurposeStr;
                    rowData.DeliveryAddressInfoDatatableCols = this.prepareDeliveryAddressInfoDatatableColumns();
                    if (this.productsRes?.deliveryDtls && this.productsRes?.deliveryDtls.length > 0) {
                        rowData.deliveryAddress = this.productsRes?.deliveryDtls[0].deliveryAddress
                    } else {
                        rowData.deliveryAddress = '';
                    }
                    if (this.productsRes?.deliveryDtls && this.productsRes?.deliveryDtls.length > 0) {
                        rowData.packagingType = this.productsRes?.deliveryDtls[0].deliveryPackagingStr
                    } else {
                        rowData.packagingType = '';
                    }
                    if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes?.printProducts) && this.productsRes?.printProducts?.length > 0) {
                        for (var i = 0; i < this.productsRes?.printProducts?.length; i++) {
                            let productDetails = this.prepareProductDetails(rowData, this.productsRes.printProducts[i], parentTableData, 'PRINT')
                            var totalPageCount = 0;
                            if (this.productsRes.printProducts[i].pages?.length > 0) {
                                for (var j = 0; j < this.productsRes.printProducts[i].pages?.length; j++) {
                                    totalPageCount += parseInt(this.productsRes.printProducts[i].pages[j].pageCountSupplier);
                                }
                            }
                            let printingOptions: any = '';
                            let bindingOptions: any = '';
                            let extraOptions: any = '';
                            let options_all: any = '';
                            let paperDetails: any = '';
                            this.translate.get('RFQ').subscribe((text: any) => {
                                
                                if (this.productsRes.printProducts[i].printingOptionsStr) {
                                    printingOptions = "<div class='confirm-dtls'><label class='lbl-title'>"+text.LBL_TITLE_PRINTING+"</label>";
                                    printingOptions.bold();
                                    printingOptions += "<label class='lbl-value'>" + this.productsRes.printProducts[i].printingOptionsStr + "</label></div>"
                                } else {
                                    printingOptions += "<label class='lbl-value'></label></div>"
                                }
                                
                                if (this.productsRes.printProducts[i].bindingOptionsStr) {
                                    bindingOptions = "<div class='confirm-dtls'><label class='lbl-title'>"+text.LBL_TITLE_BINDING+"</label>";
                                    bindingOptions.bold();
                                    bindingOptions += "<label class='lbl-value'>" + this.productsRes.printProducts[i].bindingOptionsStr + "</label></div>"
                                } else {
                                    bindingOptions += "<label class='lbl-value'></label></div>"
                                }
                                
                                if (this.productsRes.printProducts[i].extraOptionsStr) {
                                    extraOptions = "<div class='confirm-dtls'><label class='lbl-title'>"+text.LBL_TITLE_EXTRA+"</label>";
                                    extraOptions.bold();
                                    extraOptions += "<label class='lbl-value'>" + this.productsRes.printProducts[i].extraOptionsStr + "</label></div>"
                                } else {
                                    extraOptions += "<label class='lbl-value'></label></div>"
                                }
                                
                                if(!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].printingOptionsStr)){
                                    options_all += printingOptions;
                                }
                                if(!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].bindingOptionsStr)){
                                    options_all += bindingOptions;
                                }
                                if(!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].extraOptionsStr)){
                                    options_all += extraOptions;
                                }

                                paperDetails = '';
                                if (this.productsRes.printProducts[i].pages?.length > 0) {
                                    paperDetails = GeneralFunction.getPaperDetails(this.productsRes.printProducts[i].pages, this.userDtls.type);
                                    console.log('paperDetails',paperDetails);
                                    paperDetails = paperDetails?.replace(/{COVER_LBL}/g,text.LBL_TITLE_COVER)
                                    paperDetails = paperDetails?.replace(/{CONTENT_LBL}/g,text.LBL_TITLE_CONTENT)
                                    paperDetails = paperDetails?.replace(/{PAPER_NAME_LBL}/g,text.LBL_TITLE_PAPER_NAME)
                                    paperDetails = paperDetails?.replace(/{PAGE_COUNT_LBL}/g,text.LBL_TITLE_PAGE_COUNT)
                                    paperDetails = paperDetails?.replace(/{PAGE_INK_LBL}/g,text.LBL_TITLE_PRINT_INK)
                                } else {
                                    paperDetails = '';
                                }
                            })
                            if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryDtls)) {
                                if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryDtls?.length > 0)) {
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                                    for (var v = 0; v < this.productsRes.deliveryDtls?.length; v++) {
                                        deliverAddressList.push({
                                            copies_to_deliver: this.productsRes.deliveryDtls[v].deliveryQuantity,
                                            deliver_to: this.convertSlashNToBR(this.productsRes.deliveryDtls[v].deliveryAddress),
                                            delivery_notes: this.productsRes.deliveryDtls[v].deliveryNotes,
                                            delivery_packaging: this.productsRes.deliveryDtls[v].deliveryPackagingStr,
                                            other_address_value:''
                                        });
        
                                        deliverAddressListForDatatable.push({
                                            copies_to_deliver: this.productsRes.deliveryDtls[v].deliveryQuantity,
                                            deliver_to_label: this.convertSlashNToBR(this.productsRes.deliveryDtls[v]?.deliveryAddress),
                                            deliver_to: this.productsRes.deliveryDtls[v].deliveryAddress,
                                            delivery_notes: this.productsRes.deliveryDtls[v].deliveryNotes,
                                            id: this.productsRes.deliveryDtls[v].deliveryAddressId,
                                            deliver_packaging_label: this.productsRes.deliveryDtls[v].deliveryPackagingStr,
                                            delivery_packaging: this.productsRes.deliveryDtls[v].deliveryPackaging,
                                            product_name:this.productsRes.printProducts[i].productName,
                                        });
                                    }
                                }else{
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                                }
                            }else{
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                            }

                            var allowSupplierBid = '';
                            if (this.productsRes.printProducts[i].allowSupplierBid) {
                                allowSupplierBid = '<i class="fa fa-check icon-theme">';
                            }
                            var acceptedBidPrice: any = '';
                            var acceptedSupplier: any = '';
                            var acceptedBidPriceFormatted: any = '';
                            var acceptedAdditionalBidPrice: any = '';
                            
                            if(this.productsRes.printProducts[i].supplierBids && this.productsRes.printProducts[i].supplierBids.length >0){
                                var bidsArray:any = this.productsRes.printProducts[i].supplierBids;
                                for(var b=0;b<bidsArray.length;b++){
                                    if(bidsArray[b].currentStatus && (bidsArray[b].currentStatus==Constants.ACCEPTED || bidsArray[b].currentStatus==Constants.APPROVED)){
                                        acceptedBidPrice = bidsArray[b].productPriceSek;
                                        acceptedBidPriceFormatted = this.convertPriceIntoCurrency(bidsArray[b].productPriceSek, Constants.CURRENCY_VAL);
                                        acceptedSupplier = bidsArray[b].supplierOrganizationName;
                                        acceptedAdditionalBidPrice = bidsArray[b].additionalCopiesProductPriceSek;
                                        console.log('acceptedBidPriceFormatted123', acceptedBidPriceFormatted);
                                    }
                                }
                            } else {
                                var acceptedBidPrice: any = this.productsRes.printProducts[i].costPrice;
                                console.log('acceptedBidPrice10',acceptedBidPrice)
                                if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                    acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].costPrice, Constants.CURRENCY_VAL);
                                }
                                var acceptedAdditionalBidPrice = this.productsRes.printProducts[i].additionalCopiesCostPrice;

                                console.log('acceptedBidPriceFormatted345', acceptedBidPriceFormatted);
                            }

                            rowData.products.push({
                                "id": this.productsRes.printProducts[i].productId,
                                "name": this.productsRes.printProducts[i].productName,
                                "isBidAllowed": allowSupplierBid,
                                "pageSize": parseInt(this.productsRes.printProducts[i].pageSizeWidth)+" mm x "+parseInt(this.productsRes.printProducts[i].pageSizeHeight)+" mm",
                                "customerCopies": this.productsRes.printProducts[i].copiesCustomer,
                                "supplierCopies": this.productsRes.printProducts[i].copiesSupplier,
                                "customerAdditionalCopies": this.productsRes.printProducts[i].additionalCopiesCustomer && this.productsRes.printProducts[i].additionalCopiesCustomer > 0 ? this.productsRes.printProducts[i].additionalCopiesCustomer : '',
                                "supplierAdditionalCopies": this.productsRes.printProducts[i].additionalCopiesSupplier && this.productsRes.printProducts[i].additionalCopiesSupplier > 0 ? this.productsRes.printProducts[i].additionalCopiesSupplier : '',
                                "supplierWeightPerCopy":this.productsRes.printProducts[i].weightPerCopySupplier ? this.productsRes.printProducts[i].weightPerCopySupplier : '',
                                "customerWeightPerCopy":this.productsRes.printProducts[i].weightPerCopyCustomer ? this.productsRes.printProducts[i].weightPerCopyCustomer : '',
                                "supplierPaperWeight": this.productsRes.printProducts[i].totalWeightSupplier,
                                "customerPaperWeight": this.productsRes.printProducts[i].totalWeightCustomer,
                                "customerProductDesc": this.convertSlashNToBR(this.productsRes.printProducts[i].customerDescription),
                                "supplierProductDesc": this.convertSlashNToBR(this.productsRes.printProducts[i].supplierDescription),
                                "total_price": this.productsRes.printProducts[i].totalSalesPrice > 0 ? this.productsRes.printProducts[i].totalSalesPrice.toFixed(2) : '',
                                "expectedPrice": this.productsRes.printProducts[i].expectedPriceSek,
                                "displayMargin": this.productsRes.printProducts[i].markupMarginValueSek,
                                "displayMarginPercentage": this.productsRes.printProducts[i].markupMarginPercentage,
                                "originalMargin": this.productsRes.printProducts[i].markupMarginValueSek,
                                "originalMarginPercentage": this.productsRes.printProducts[i].markupMarginPercentage,
                                "additionalMargin": this.productsRes.printProducts[i].additionalCopiesMarkupMarginTypeStr,
                                "additionalMarginType": this.productsRes.printProducts[i].additionalCopiesMarkupMarginType,
                                "additionalCopiesExpectedPriceSek": this.productsRes.printProducts[i].additionalCopiesExpectedPriceSek,
                                "additionalMarginValue": this.productsRes.printProducts[i].additionalCopiesMarkupMarginValueSek,
                                "originalAdditionalMargin": this.productsRes.printProducts[i].additionalCopiesPipelineAdditionalCostSek,
                                "additionalMarginPercentage": this.productsRes.printProducts[i].additionalCopiesMarkupMarginPercentage,
                                "additionalCopiesAcceptedPrice": acceptedAdditionalBidPrice,
                                "costPrice": this.productsRes.printProducts[i].costPrice,
                                "acceptedPrice":acceptedBidPrice,
                                "acceptedSupplier":acceptedSupplier,
                                "acceptedPriceFormatted":acceptedBidPriceFormatted,
                                "margin": this.productsRes.printProducts[i].marginTypeStr,
                                "marginType": this.productsRes.printProducts[i].marginType,
                                "currentSupplierBid": this.productsRes.printProducts[i].currentSupplierBid,
                                "delivery_content" : deliverAddressList,
                                "delivery_content_datatable":deliverAddressListForDatatable,
                                "printOpts": printingOptions,
                                "extraOpts": extraOptions,
                                "bindingOpts": bindingOptions,
                                "printingOptionsStr":this.productsRes.printProducts[i].printingOptionsStr,
                                "bindingOptionsStr":this.productsRes.printProducts[i].bindingOptionsStr,
                                "extraOptionsStr":this.productsRes.printProducts[i].extraOptionsStr,
                                "options_all": options_all,
                                "paper_details": paperDetails,
                                "original_template": this.productsRes.printProducts[i].originalTemplate === 'OTHERS' ? this.productsRes.printProducts[i].originalTemplateOthersValue : this.productsRes.printProducts[i].originalTemplateStr,
                                "repro_template": this.productsRes.printProducts[i].reproTemplate === 'OTHERS' ? this.productsRes.printProducts[i].reproTemplateOthersValue : this.productsRes.printProducts[i].reproTemplateStr,
                                "groupName": this.groups,
                                "currencyOptionsList": GeneralFunction.getDropDownDataForPrimeng(this.productsRes.currencies),
                                produtionOptions: productionOptionList,
                                "parentTableData": parentTableData,
                                "additionalCopies": this.productsRes.printProducts[i].additionalCopiesSupplier && this.productsRes.printProducts[i].additionalCopiesSupplier > 0 ? this.productsRes.printProducts[i].additionalCopiesSupplier : '',
                                "operationType": this.operationType,
                                "totalPageCount":totalPageCount,
                                "additionalOtherCost": this.productsRes.printProducts[i].additionalCopiesPipelineAdditionalCostSek,
                                "productDetails": productDetails,
                                "otherCost": this.productsRes.printProducts[i].pipelineAdditionalCostSek,
                                "pages": this.productsRes.printProducts[i].pages,
                                "printTypeStr":!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.printTypeStr)?this.productsRes.printProducts[i]?.printTypeStr:'',
                                "printType":!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.printType)?this.productsRes.printProducts[i]?.printType:'',
                                "isShowCouponRelatedFields":GeneralFunction.checkCouponRelatedField(rowData.customerRegistrationNumber, this.productsRes.printProducts[i].printType),
                                "isShowCouponPrintingRelatedFields":GeneralFunction.checkPrintTypeField(this.productsRes.printProducts[i].printType),
                                "isShowCampaignRelatedFields": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.generateUniqueCouponCode)? true:false,
                            });

                            if (current_tab == 'ACCEPTANCE') {
                                rowData.productDataTableWidth = [{
                                    colWidth: '3%'
                                }, {
                                    colWidth: '5%'
                                }];
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '5%'
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '20%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '15%'
                                    },
                                    {
                                        field: 'supplierBidCount',
                                        header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                        colWidth: '6%'
                                    },
                                    {
                                        field: 'acceptedSupplier',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'display_accepted_price',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                        colWidth: '10%'
                                    },
                                ];
                            } else if (current_tab == 'CUSTOMER_ACCEPTANCE') {
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '15%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '14%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '14%'
                                    },
                                    {
                                        field: 'customer_paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '35%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '22%'
                                    },
                                ];
                            } else if (current_tab == 'CUSTOMER_REJECTED') {
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '17%'
                                    },
                                    {
                                        field: 'customer_paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '27%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '27%'
                                    },
                                ];
                            } else if (current_tab == 'SUPPLIER_ACCEPTANCE') {
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE
                                    },
                                ];
                            } else if (current_tab == 'PUBLISH') {
                                this.productDataTableWidth = [{
                                    colWidth: '3%'
                                }, {
                                    colWidth: '8%'
                                }];
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '20%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '12%'
                                    },
                                    {
                                        field: 'supplierBidCount',
                                        header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                        colWidth: '5%'
                                    },
                                    {
                                        field: 'acceptedSupplier',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'display_accepted_price',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                        colWidth: '8%'
                                    },
                                ];
                            } else if (current_tab == 'UNPUBLISH') {
                                this.productDataTableWidth = [{
                                    colWidth: '3%'
                                }, {
                                    colWidth: '8%'
                                }];
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '12%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '12%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '25%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '15%'
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                        colWidth: '10%'
                                    },
                                ];
                            } else if (current_tab == 'FREE') {
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE
                                    },
                                ];
                            } else if (current_tab == 'EXPIRED') {
                                rowData.productDataTableWidth = [{
                                    colWidth: '3%'
                                }, {
                                    colWidth: '8%'
                                }];
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '7%'
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '20%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '15%'
                                    },
                                    {
                                        field: 'supplierBidCount',
                                        header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                        colWidth: '6%'
                                    },
                                    {
                                        field: 'acceptedSupplier',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                        colWidth: '6%'
                                    },
                                    {
                                        field: 'display_accepted_price',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                        colWidth: '6%'
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                        colWidth: '6%'
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                        colWidth: '6%'
                                    },
                                ];
                            } else if (current_tab == 'REJECTED') {
                                rowData.productDataTableWidth = [{
                                    colWidth: '3%'
                                }];
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '5%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '5%'
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '20%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '15%'
                                    },
                                    // {
                                    //     field: 'isBidAllowed',
                                    //     header: this.datatable_header_RFQ_title.LBL_IS_BIDDING_ALLOWED,
                                    //     colWidth: '7%'
                                    // },
                                    {
                                        field: 'supplierBidCount',
                                        header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'acceptedSupplier',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'display_accepted_price',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                        colWidth: '8%'
                                    },
                                ];
                            } else if (current_tab == 'CONVERTED') {
                                rowData.productDataTableWidth = [{
                                    colWidth: '3%'
                                }];
                                rowData.RFQProducCols = [{
                                        field: 'name',
                                        header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                        colWidth: '10%'
                                    },
                                    {
                                        field: 'productStatus',
                                        header: this.datatable_header_RFQ_title.LBL_FILE_STATUS,
                                        colWidth: '8%'
                                    },
                                    {
                                        field: 'pageSize',
                                        header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                                        colWidth: '7%'
                                    },
                                    {
                                        field: 'customerCopies',
                                        header: this.datatable_header_RFQ_title.LBL_COPIES,
                                        colWidth: '5%'
                                    },
                                    {
                                        field: 'paper_details',
                                        header: this.datatable_header_title.LBL_PAPER_DTLS,
                                        colWidth: '23%'
                                    },
                                    {
                                        field: 'options_all',
                                        header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                                        colWidth: '15%'
                                    },
                                    {
                                        field: 'supplierBidCount',
                                        header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                        colWidth: '5%'
                                    },
                                    {
                                        field: 'acceptedSupplier',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                        colWidth: '7%'
                                    },
                                    {
                                        field: 'display_accepted_price',
                                        header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                        colWidth: '7%'
                                    },
                                    {
                                        field: 'displayMargin',
                                        header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                        colWidth: '7%'
                                    },
                                    {
                                        field: 'total_price',
                                        header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                        colWidth: '6%'
                                    }
                                ];
                            }
                        }
                    }

                    if (current_tab == 'ACCEPTANCE') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '13%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '10%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                                colWidth: '10%'
                            },
                            {
                                field: 'bidCountGeneralProduct',
                                header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                colWidth: '6%'
                            },
                            {
                                field: 'acceptedSupplier',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                colWidth: '10%'
                            },
                            {
                                field: 'display_accepted_price',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                colWidth: '8%'
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                colWidth: '8%'
                            },

                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                colWidth: '10%'
                            },
                        ];
                    } else if (current_tab == 'CUSTOMER_ACCEPTANCE') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '30%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '35%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                                colWidth: '30%'
                            },
                            // {
                            //     field: 'productTotalPrice',
                            //     header: this.datatable_header_title.PROPOSED_PRICE,
                            //     colWidth: '25%'
                            // },
                        ];
                    } else if (current_tab == 'CUSTOMER_REJECTED') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '30%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '35%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                                colWidth: '30%'
                            },
                            // {
                            //     field: 'productTotalPrice',
                            //     header: this.datatable_header_title.PROPOSED_PRICE,
                            //     colWidth: '25%'
                            // },
                        ];
                    } else if (current_tab == 'SUPPLIER_ACCEPTANCE') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY
                            },
                            {
                                field: 'price',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE
                            },
                        ];
                    } else if (current_tab == 'PUBLISH') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '11%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '11%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                                colWidth: '11%'
                            },
                            {
                                field: 'bidCountGeneralProduct',
                                header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                colWidth: '11%'
                            },
                            {
                                field: 'acceptedSupplier',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                colWidth: '11%'
                            },
                            {
                                field: 'display_accepted_price',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                colWidth: '11%'
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                colWidth: '11%'
                            },
                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                colWidth: '11%'
                            },
                        ];
                    } else if (current_tab == 'UNPUBLISH') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '17%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '15%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                                colWidth: '17%'
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                colWidth: '17%'
                            },
                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                colWidth: '17%'
                            },
                        ];
                    } else if (current_tab == 'FREE') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '11%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '11%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_title.LBL_QUANTITY,
                                colWidth: '11%'
                            },
                            {
                                field: 'bidCountGeneralProduct',
                                header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                colWidth: '11%'
                            },
                            {
                                field: 'acceptedSupplier',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                colWidth: '11%'
                            },
                            {
                                field: 'acceptedPrice',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                colWidth: '11%'
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                colWidth: '11%'
                            },
                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                colWidth: '11%'
                            },
                        ];
                    } else if (current_tab == 'EXPIRED') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '15%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                                colWidth: '15%'
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                                colWidth: '10%'
                            },
                            {
                                field: 'bidCountGeneralProduct',
                                header: this.datatable_header_RFQ_title.LBL_NO_BIDS,
                                colWidth: '8%'
                            },
                            {
                                field: 'acceptedSupplier',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                                colWidth: '11%'
                            },
                            {
                                field: 'acceptedPrice',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID,
                                colWidth: '11%'
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                colWidth: '11%'
                            },
                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                colWidth: '11%'
                            }
                        ];
                    } else if (current_tab == 'REJECTED') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                                colWidth: '11%'
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY
                            },
                            {
                                field: 'unit_price',
                                header: this.datatable_header_title.LBL_UNIT_PRICE,
                                colWidth: '15%'
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                                colWidth: '15%'
                            },
                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                                colWidth: '15%'
                            },
                        ];
                    } else if (current_tab == 'CONVERTED') {
                        rowData.RFQGeneralProducCols = [{
                                field: 'display_name',
                                header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME
                            },
                            {
                                field: 'productStatus',
                                header: this.datatable_header_RFQ_title.LBL_FILE_STATUS
                            },
                            {
                                field: 'type',
                                header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE
                            },
                            {
                                field: 'quantity',
                                header: this.datatable_header_RFQ_title.LBL_QUANTITY
                            },
                            {
                                field: 'bidCountGeneralProduct',
                                header: this.datatable_header_RFQ_title.LBL_NO_BIDS
                            },
                            {
                                field: 'acceptedSupplier',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER
                            },
                            {
                                field: 'display_accepted_price',
                                header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID
                            },
                            {
                                field: 'displayMargin',
                                header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE
                            },
                            {
                                field: 'productTotalPrice',
                                header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE
                            },
                        ];
                    }
                    rowData.generalProductCounts = this.productsRes?.generalProductCounts;

                    rowData.prepareOtherPriceDatatableCols = this.prepareOtherPriceDatatableColumns();
                    if (this.productsRes.otherProducts?.length > 0) {
                        for (var i = 0; i < this.productsRes.otherProducts?.length; i++) {
                            let generalProductDetails = this.prepareProductDetails(rowData, this.productsRes.otherProducts[i], parentTableData, 'OTHER')
                            if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.otherProducts[i].productId)) {
                                let generalisBidAllowed = '';
                                if (this.productsRes.otherProducts[i].allowSupplierBid) {
                                    generalisBidAllowed = '<i class="fa fa-check icon-theme">';
                                }
                                var acceptedBidPrice: any = '';
                                var acceptedSupplier: any = '';
                                var acceptedBidPriceFormatted: any = '';
                                
                                if(this.productsRes.otherProducts[i].supplierBids && this.productsRes.otherProducts[i].supplierBids.length >0){
                                    var bidsArray:any = this.productsRes.otherProducts[i].supplierBids;
                                    for(var b=0;b<bidsArray.length;b++){
                                        if(bidsArray[b].currentStatus && (bidsArray[b].currentStatus==Constants.ACCEPTED || bidsArray[b].currentStatus==Constants.APPROVED)){
                                            acceptedBidPrice = bidsArray[b].productPriceSek;
                                            acceptedBidPriceFormatted = this.convertPriceIntoCurrency(bidsArray[b].productPriceSek, Constants.CURRENCY_VAL);
                                            acceptedSupplier = bidsArray[b].supplierOrganizationName;
                                        }
                                    }
                                } else {
                                    var acceptedBidPrice: any = this.productsRes.otherProducts[i].costPriceSek;
                                    // var acceptedBidPrice: any = this.productsRes.otherProducts[i].expectedPriceSek;
                                    // console.log('acceptedBidPrice2',acceptedBidPrice)
                                    if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                        acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].costPriceSek, Constants.CURRENCY_VAL);
                                    }
                                }

                                rowData.price.push({
                                    "id": this.productsRes.otherProducts[i].productId,
                                    "no": i + 1,
                                    "costPrice": this.productsRes.otherProducts[i].costPrice,
                                    "display_name": this.productsRes.otherProducts[i].productName,
                                    "type": this.productsRes.otherProducts[i].type,
                                    "generalisBidAllowed": generalisBidAllowed,
                                    "quantity": this.productsRes.otherProducts[i].quantity,
                                    "productUnit": this.productsRes.otherProducts[i].unit ? this.productsRes.otherProducts[i].unit : "unit",
                                    "unit_price": this.productsRes.otherProducts[i].unitPriceSek,
                                    "price": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                    "displayMargin": this.productsRes.otherProducts[i].marginValueSek,
                                    "displayMarginPercentage": this.productsRes.otherProducts[i].marginPercentage,
                                    "supplierDesc": this.convertSlashNToBR(this.productsRes.otherProducts[i].supplierDescription),
                                    "customerDesc": this.convertSlashNToBR(this.productsRes.otherProducts[i].customerDescription),
                                    "margin": this.productsRes.otherProducts[i].markupMarginTypeStr,
                                    "marginType": this.productsRes.otherProducts[i].markupMarginType,
                                    "currentSupplierBid": this.productsRes.otherProducts[i].currentSupplierBid,
                                    "delivery_content" : deliverAddressList,
                                    "delivery_content_datatable":deliverAddressListForDatatable,
                                    "generalType": this.productsRes.otherProducts[i].type,
                                    "typeStr":this.productsRes.otherProducts[i].typeStr,
                                    "productTotalPrice": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                    "expectedPrice": this.productsRes.otherProducts[i].expectedPriceSek,
                                    "display_expected_price": this.productsRes.otherProducts[i].expectedPriceSek,
                                    "total_price": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                    "bidCountGeneralProduct": this.productsRes.otherProducts[i].bidCountGeneralProduct,
                                    "productStatus": this.productsRes.otherProducts[i].productStatus,
                                    "displayExpectedPrice": this.productsRes.otherProducts[i].expectedPriceSek,
                                    "parentTableData": parentTableData,
                                    "productDetails": generalProductDetails,
                                    "operationType": this.operationType,
                                    "otherCost": this.productsRes.otherProducts[i].pipelineAdditionalCostSek,
                                    "originalMargin": this.productsRes.otherProducts[i].marginValueSek,
                                    "originalMarginPercentage": this.productsRes.otherProducts[i].marginPercentage,
                                    "acceptedSupplier":acceptedSupplier,
                                    "acceptedPrice":acceptedBidPrice,
                                    "acceptedPriceFormatted":acceptedBidPriceFormatted,
                                    "currencyOptionsList": GeneralFunction.getDropDownDataForPrimeng(this.productsRes.currencies),
                                    produtionOptions: productionOptionList,
                                });
                            }
                        }
                    }
                    rowData.loadingFalse = false;
                    this.loading = false;
                    // if($("#rfq_expand_tr_"+rowData.id).offset()){
                    //   $('html, body').animate({
                    //       scrollTop: ($("#rfq_expand_tr_"+rowData.id).offset().top - 50)
                    //   }, 1000);
                    // }
                    // if($("#report_expand_tr_"+rowData.id).offset()){
                    //     $('html, body').animate({
                    //         scrollTop: ($("#report_expand_tr_"+rowData.id).offset().top - 50)
                    //     }, 1000);
                    // }
                } else if (this.productsRes.outputMap) {
                    rowData.loadingFalse = false;
                    this.httpErrorCode = this.productsRes.outputMap.succCode;
                    this.httpErrorMsgDisplay = true;
                    this.httpErrorMsg = this.httpRes.handleError(this.httpErrorCode);
                }
            }, error => {
                rowData.loadingFalse = false;
                this.loading = false;
                this.httpErrorMsgDisplay = true;
                this.httpErrorCode = error.status;
                this.httpErrorMsg = this.httpRes.handleError(this.httpErrorCode);
            });
        } else {
            rowData.loadingFalse = false;
        }
        dt.toggleRow(rowData);
    }


    // Function for preparing order products details
    prepareOrderProductDetail(productsRes: any, rowData: any) {
        let productDetails: any = {};
        productDetails.printproductDtls = {};
        productDetails.supplierBids = this.prepareSupplierBidsArray(productsRes);
        productDetails.biddingCols = this.prepareBiddingListColumns();
        productDetails.files = this.prepareFileList(productsRes);
        return productDetails;
    }

    // Function for getting order currency option liist
    prepareOrderCurrencyOptionsList(currencyList: any) {
        let currencyOptionsList = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(currencyList)) {
            for (let co = 0; co < currencyList.ids?.length; co++) {
                currencyOptionsList.push({
                    'value': currencyList.ids[co],
                    'label': currencyList.names[co]
                })
            }
        }
        return currencyOptionsList;
    }

    // Function for getting delivery opions list
    prepareOrderDeliveryOptionsList(deliveryOptionsList: any) {
        let deliveryOptions = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(deliveryOptionsList)) {
            for (var co = 0; co < deliveryOptionsList.ids?.length; co++) {
                deliveryOptions.push({
                    'value': deliveryOptionsList.ids[co],
                    'label': deliveryOptionsList.names[co]
                })
            }
        }
        return deliveryOptions;
    }

    // Prepare order print options
    prepareOrderPrintingOptions(printOpts: any) {
        let printOpt = "<div class='confirm-dtls'><label class='lbl-title'>"+this.localeLbls.LBL_TITLE_PRINTING+"</label>";
        let printOptLbl = "";
        if (printOpts.ids != null) {
            for (var po = 0; po < printOpts.ids?.length; po++) {
                if (po != 0) {
                    printOptLbl +=  ", " + printOpts.names[po];
                } else {
                    printOptLbl +=  printOpts.names[po];
                }
            }
        }
        printOpt += "<label class='lbl-value'>"+printOptLbl+"</label></div>";
        return printOpt;
    }

    // prepare order binding optins
    prepareOrderBindingOptions(bindingOpts: any) {
        let bindingOpt = "<div class='confirm-dtls'><label class='lbl-title'>"+this.localeLbls.LBL_TITLE_BINDING+"</label>";
        let bindOptLbl = "";
        if (bindingOpts.ids != null) {
            for (var bo = 0; bo < bindingOpts.ids?.length; bo++) {
                if (bo != 0) {
                    bindOptLbl += ", " + bindingOpts.names[bo];
                } else {
                    bindOptLbl += bindingOpts.names[bo];
                }
            }
        }
        bindingOpt += "<label class='lbl-value'>"+bindOptLbl+"</label></div>";
        return bindingOpt;
    }

    // prepare order extra options
    prepareOrderExtraOptions(extraOpts: any) {
        let extraOpt = "<div class='confirm-dtls'><label class='lbl-title'>"+this.localeLbls.LBL_TITLE_EXTRA+"</label>";
        let extOptLbl = ""; 
        if (extraOpts.ids != null) {
            for (var eo = 0; eo < extraOpts.ids?.length; eo++) {
                if (eo != 0) {
                    extOptLbl += ", " + extraOpts.names[eo] ;
                } else {
                    extOptLbl +=  extraOpts.names[eo];
                }
            }

        }
        extraOpt += "<label class='lbl-value'>"+extOptLbl+"</label></div>";
        return extraOpt;
    }


    orderExpand(rowData: any, dt: any, expanded: boolean, index: any, current_tab: any, currentStatus: any, isProduct ? : any) {
        this.loading = true;
        let parentTableData: any = [];
        parentTableData = {
            rowData: rowData,
            dt: dt,
            expanded: expanded,
            index: index,
            current_tab: current_tab,
            currentStatus: currentStatus,
            isProduct: isProduct
        }
        this.productDataTableWidth = [{
            colWidth: '0%'
        }, {
            colWidth: '0%'
        }];
        this.rowData = rowData;
        this.dt = dt;
        this.expanded = expanded;
        this.index = index;
        if (this.rowData?.filterStatus == 'VIEW') {
            this.operationType = 'VIEW';
        }else{
            this.operationType = current_tab;
        }
        
        this.currentRfqStage = currentStatus;
        if (!expanded) {
            this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, ApiEndpointConstant.expandGetDataAPI(rowData.id)).subscribe(res => {
                rowData.products = [];
                rowData.price = [];
                rowData.subRFQbasicDetails = [];
                this.productsRes = res;
                rowData.parentTableData = parentTableData;
                if (this.productsRes.resultCode == Constants.resultCode.SUCCESS) {
                    rowData.isRFQ = false;
                    rowData.isOrder = true;
                    let currencyOptionsList = this.prepareOrderCurrencyOptionsList(this.productsRes.currencyList);
                    let productionOptionList = this.preparepProductionTimeOptionList();
                    let deliveryOptionsList = this.prepareOrderDeliveryOptionsList(this.productsRes.deliveryOptionsList)

                    let otherCostList = this.prepareOtherCostList();

                    if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.costDataCounts)) {
                        var totalOtherCost = 0;
                        for (var co = 0; co < this.productsRes.costDataCounts; co++) {
                            totalOtherCost += parseInt(this.productsRes.costData.costValues[co]);
                        }
                        rowData.grandTotal = this.changeCurrencyFormatValDatatable(totalOtherCost.toString());
                    }else{
                        rowData.grandTotal = this.productsRes.costDataCounts;
                    }

                    console.log('rowData',rowData);
                    this.original_template = this.prepareOriginalTemplateOptions();
                    rowData.original_template = this.original_template;
                    this.repro_template = this.prepareReproTemplateOptions();
                    rowData.repro_template = this.repro_template;
                    rowData.sales_manager_opt = GeneralFunction.getAccountSalesProjectManagerData(this.productsRes?.salesManagers);
                    rowData.project_manager_opt = GeneralFunction.getAccountSalesProjectManagerData(this.productsRes?.projectManagers);
                    rowData.account_manager_opt = GeneralFunction.getAccountSalesProjectManagerData(this.productsRes?.accountManagers);
                    rowData.customer_additional_information = this.convertSlashNToBR(this.productsRes?.basicDtl?.customerAdditionalInfo);
                    rowData.supplier_additional_information = this.convertSlashNToBR(this.productsRes?.basicDtl?.supplierAdditionalInfo);
                    rowData.customerContactName = this.productsRes?.basicDtl?.customerContactPersonUserName;
                    rowData.requestDate = this.productsRes?.basicDtl?.orderRequestDate;
                    rowData.totalWeightCustomer = this.productsRes?.basicDtl?.totalWeightCustomer;
                    rowData.totalWeightSupplier = this.productsRes?.basicDtl?.totalWeightSupplier;
                    rowData.referenceOrderNumber = this.productsRes?.basicDtl?.referenceOrderNumber;
                    rowData.endCustomerName = this.productsRes?.basicDtl?.endCustomerName;
                    rowData.endCustomerAddress = this.convertSlashNToBR(this.productsRes?.basicDtl?.endCustomerAddress);
                    
                    rowData.expiryDate = this.productsRes?.basicDtl?.rfqPipelineExpiryDate;
                    rowData.expDeliveryDate = this.productsRes?.basicDtl?.expectedDeliveryDate;
                    rowData.communicationMode = this.productsRes?.basicDtl?.customerCommunicationModeStr;
                    rowData.customerName = this.productsRes?.basicDtl?.customerOrganizationName;
                    rowData.additionalInfo = this.convertSlashNToBR(this.productsRes?.basicDtl?.supplierAdditionalInfo);
                    rowData.customerAdditionalInfo = this.convertSlashNToBR(this.productsRes?.basicDtl?.customerAdditionalInfo);
                    rowData.produtionOptions = productionOptionList;
                    rowData.vatPercentage = this.productsRes?.basicDtl?.vat;
                    rowData.customerContactNumber = this.productsRes?.printProducts?.[0]?.clearOnPrintProductDtl?.customerContactNo; 
                    rowData.perviousVatPercentage = this.productsRes?.basicDtl?.vat;
                    // rowData.packagingType = this.productsRes?.orderBasicDtlsBean?.packagingTypeName;
                    // rowData.deliveryAddress = this.productsRes?.orderBasicDtlsBean?.deliveryAddressName;
                    rowData.accountManagerId = this.productsRes?.basicDtl?.accountManagerUserId;
                    rowData.salesManagerId = this.productsRes?.basicDtl?.salesManagerUserId;
                    rowData.projectManagerId = this.productsRes?.basicDtl?.projectManagerUserId;
                    rowData.projectName = this.productsRes?.basicDtl?.projectName;
                    rowData.description = this.convertSlashNToBR(this.productsRes?.basicDtl?.comments);
                    rowData.purpose = this.productsRes?.basicDtl?.customerPurposeStr;
                    rowData.DeliveryAddressInfoDatatableCols = this.prepareDeliveryAddressInfoDatatableColumns();
                    rowData.materialDate = this.productsRes?.basicDtl?.customerMaterialDate;
                    rowData.deliveryDate = this.productsRes?.basicDtl?.customerDeliveryDate;
                    rowData.transportDate = this.productsRes?.basicDtl?.supplierDeliveryDate;
                    rowData.printingDate = this.productsRes?.basicDtl?.supplierPrintingDate;
                    rowData.tabType = current_tab;
                    rowData.otherCostList = otherCostList;
                    if (this.productsRes?.deliveryDtls && this.productsRes?.deliveryDtls.length > 0) {
                        rowData.deliveryAddress = this.convertSlashNToBR(this.productsRes?.deliveryDtls[0].deliveryAddress)
                    } else {
                        rowData.deliveryAddress = '';
                    }
                    if (this.productsRes?.deliveryDtls && this.productsRes?.deliveryDtls.length > 0) {
                        rowData.packagingType = this.productsRes?.deliveryDtls[0].deliveryPackagingStr
                    } else {
                        rowData.packagingType = '';
                    }

                    if (this.rowData?.filterStatus == 'VIEW') {
                        rowData.operationType = 'VIEW';
                    }else{
                        rowData.operationType = current_tab;
                    }                    
                    rowData.isSubRfq = false;
                    let productDetails = {};
                    if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes?.printProducts) && this.productsRes?.printProducts?.length > 0) {
                        for (var i = 0; i < this.productsRes?.printProducts?.length; i++) {
                            productDetails = this.prepareOrderProductDetail(this.productsRes?.printProducts[i], rowData);
                            this.customerTotalWeight = 0;
                            this.supplierTotalWeight = 0;
                            let customerPaperDetails = '';
                            var totalPageCount = 0;
                            if (this.productsRes.printProducts[i].pages?.length > 0) {
                                for (var j = 0; j < this.productsRes?.printProducts[i]?.pages?.length; j++) {
                                    totalPageCount += parseInt(this.productsRes.printProducts[i].pages[j].pageCountSupplier);
                                }
                            }
                            let printingOptions: any = '';
                            let bindingOptions: any = '';
                            let extraOptions: any = '';
                            let options_all: any = '';
                            let paperDetails: any = '';
                            this.translate.get('RFQ').subscribe((text: any) => {
                                
                                if (this.productsRes.printProducts[i].printingOptionsStr) {
                                    printingOptions = "<div class='confirm-dtls'><label class='lbl-title'>"+text.LBL_TITLE_PRINTING+"</label>";
                                    printingOptions.bold();
                                    printingOptions += "<label class='lbl-value'>" + this.productsRes.printProducts[i].printingOptionsStr + "</label></div>"
                                } else {
                                    // printingOptions += "<label class='lbl-value'></label></div>"
                                }
                                
                                if (this.productsRes.printProducts[i].bindingOptionsStr) {
                                    bindingOptions = "<div class='confirm-dtls'><label class='lbl-title'>"+text.LBL_TITLE_BINDING+"</label>";
                                    bindingOptions.bold();
                                    bindingOptions += "<label class='lbl-value'>" + this.productsRes.printProducts[i].bindingOptionsStr + "</label></div>"
                                } else {
                                    // bindingOptions += "<label class='lbl-value'></label></div>"
                                }
                                
                                if (this.productsRes.printProducts[i].extraOptionsStr) {
                                    extraOptions = "<div class='confirm-dtls'><label class='lbl-title'>"+text.LBL_TITLE_EXTRA+"</label>";
                                    extraOptions.bold();
                                    extraOptions += "<label class='lbl-value'>" + this.productsRes.printProducts[i].extraOptionsStr + "</label></div>"
                                } else {
                                    // extraOptions += "<label class='lbl-value'></label></div>"
                                }
                                
                                if(!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].printingOptionsStr)){
                                    options_all += printingOptions;
                                }
                                if(!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].bindingOptionsStr)){
                                    options_all += bindingOptions;
                                }
                                if(!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].extraOptionsStr)){
                                    options_all += extraOptions;
                                }
                                paperDetails = '';
                                if (this.productsRes.printProducts[i].pages?.length > 0) {
                                    paperDetails = GeneralFunction.getPaperDetails(this.productsRes.printProducts[i].pages, this.userDtls.type);
                                    paperDetails = paperDetails?.replace(/{COVER_LBL}/g,text.LBL_TITLE_COVER)
                                    paperDetails = paperDetails?.replace(/{CONTENT_LBL}/g,text.LBL_TITLE_CONTENT)
                                    paperDetails = paperDetails?.replace(/{PAPER_NAME_LBL}/g,text.LBL_TITLE_PAPER_NAME)
                                    paperDetails = paperDetails?.replace(/{PAGE_COUNT_LBL}/g,text.LBL_TITLE_PAGE_COUNT)
                                    paperDetails = paperDetails?.replace(/{PAGE_INK_LBL}/g,text.LBL_TITLE_PRINT_INK)
                                } else {
                                    paperDetails = '';
                                }
                            })
                            if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryDtls)) {
                                if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryDtls?.length > 0)) {
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                                    for (var v = 0; v < this.productsRes.deliveryDtls?.length; v++) {
                                        if(this.productsRes.printProducts[i].productId==this.productsRes.deliveryDtls[v].productId){
                                            deliverAddressList.push({
                                                copies_to_deliver: this.productsRes.deliveryDtls[v].deliveryQuantity,
                                                deliver_to: this.convertSlashNToBR(this.productsRes.deliveryDtls[v].deliveryAddress),
                                                delivery_notes: this.productsRes.deliveryDtls[v].deliveryNotes,
                                                delivery_packaging: this.productsRes.deliveryDtls[v].deliveryPackagingStr,
                                                other_address_value:''
                                            });
            
                                            deliverAddressListForDatatable.push({
                                                copies_to_deliver: this.productsRes.deliveryDtls[v].deliveryQuantity,
                                                deliver_to_label: this.productsRes.deliveryDtls[v]?.deliveryAddress,
                                                deliver_to: this.convertSlashNToBR(this.productsRes.deliveryDtls[v].deliveryAddress),
                                                delivery_notes: this.productsRes.deliveryDtls[v].deliveryNotes,
                                                id: this.productsRes.deliveryDtls[v].deliveryAddressId,
                                                deliver_packaging_label: this.productsRes.deliveryDtls[v].deliveryPackagingStr,
                                                delivery_packaging: this.productsRes.deliveryDtls[v].deliveryPackaging,
                                                product_name:this.productsRes.printProducts[i].productName,
                                            });
                                        }
                                    }
                                }else{
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                                }
                            }else{
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                            }
                            let productFileList = [];
                            let isBidAllowed = '';
                            if (this.productsRes.printProducts[i].allowSupplierBid) {
                                isBidAllowed = '<i class="fa fa-check icon-theme">';
                            }
                            var acceptedBidPrice: any = '';
                            var acceptedSupplier: any = '';
                            var acceptedBidPriceFormatted: any = '';
                            var acceptedAdditionalBidPrice: any = '';
                            let bidPriceProduct: any = '';
                            if(this.productsRes.printProducts[i].supplierBids && this.productsRes.printProducts[i].supplierBids.length >0){
                                var bidsArray:any = this.productsRes.printProducts[i].supplierBids;
                                for(var b=0;b<bidsArray.length;b++){
                                    if(bidsArray[b]?.currentStatus && (bidsArray[b]?.currentStatus==Constants.ACCEPTED  || bidsArray[b]?.currentStatus==Constants.APPROVED)){
                                        acceptedBidPrice = bidsArray[b].productPriceSek;
                                        console.log('acceptedBidPrice3',acceptedBidPrice)
                                        if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                            acceptedBidPriceFormatted = this.convertPriceIntoCurrency(acceptedBidPrice, Constants.CURRENCY_VAL);
                                        }
                                        acceptedSupplier = bidsArray[b].supplierOrganizationName;
                                        acceptedAdditionalBidPrice = bidsArray[b].additionalCopiesProductPriceSek;
                                    } else {
                                        var acceptedBidPrice: any = this.productsRes.printProducts[i].costPriceSek;
                                        console.log('acceptedBidPrice4',acceptedBidPrice)
                                        if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                            acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].costPrice, Constants.CURRENCY_VAL);
                                        }
                                        var acceptedSupplier = this.productsRes.printProducts[i].supplierOrganizationName;
                                        acceptedAdditionalBidPrice = this.productsRes.printProducts[i].additionalCopiesCostPrice;
                                    }
                                }
                            } else {
                                var acceptedBidPrice: any = this.productsRes.printProducts[i].costPriceSek;
                                console.log('acceptedBidPrice5',acceptedBidPrice)
                                    if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                        acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].costPrice, Constants.CURRENCY_VAL);
                                    }console.log('acceptedBidPrice6',acceptedBidPrice)
                                    if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                        acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].costPrice, Constants.CURRENCY_VAL);
                                    }
                                var acceptedSupplier = this.productsRes.printProducts[i].supplierOrganizationName;
                                acceptedAdditionalBidPrice = this.productsRes.printProducts[i].additionalCopiesCostPrice;
                            }
                            if (this.userDtls.type === Constants.USER_TYPE.SUPPLIER_USER) {
                                if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].currentSupplierBid) && !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i].currentSupplierBid)) {
                                    if (this.productsRes.printProducts[i].currentSupplierBid.currentStatus == Constants.ACCEPTED || this.productsRes.printProducts[i].currentSupplierBid.currentStatus==Constants.APPROVED) {
                                        bidPriceProduct = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].currentSupplierBid.productPrice, this.productsRes.printProducts[i].currentSupplierBid.payCurrencyName)
                                    } else {
                                        bidPriceProduct = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].costPrice, this.productsRes.printProducts[i].supplierCurrencyName)
                                    }
                                } else {
                                    bidPriceProduct = this.convertPriceIntoCurrency(this.productsRes.printProducts[i].costPrice, this.productsRes.printProducts[i].supplierCurrencyName)
                                }
                            }
                            
                            rowData.products.push({
                                "id": this.productsRes.printProducts[i].productId,
                                "name": this.productsRes.printProducts[i].productName,
                                "isBidAllowed": isBidAllowed,
                                "pageSize": parseInt(this.productsRes.printProducts[i].pageSizeWidth)+" mm x "+parseInt(this.productsRes.printProducts[i].pageSizeHeight)+" mm",
                                "customerCopies": this.productsRes.printProducts[i].copiesCustomer,
                                "supplierCopies": this.productsRes.printProducts[i].copiesSupplier,
                                "total_price": this.productsRes.printProducts[i].totalSalesPrice > 0 ? this.productsRes.printProducts[i].totalSalesPrice.toFixed(2) : '',
                                "expectedPrice": this.productsRes.printProducts[i].expectedPriceSek,
                                "displayMargin": this.productsRes.printProducts[i].markupMarginValueSek,
                                "displayMarginPercentage": this.productsRes.printProducts[i].markupMarginPercentage,
                                "currentSupplierBid": this.productsRes.printProducts[i].currentSupplierBid,
                                "originalMargin": this.productsRes.printProducts[i].markupMarginValueSek,
                                "originalMarginPercentage": this.productsRes.printProducts[i].markupMarginPercentage,
                                "margin": this.productsRes.printProducts[i].marginTypeStr,
                                "marginType": this.productsRes.printProducts[i].marginType,
                                "additionalMargin": this.productsRes.printProducts[i].additionalCopiesMarkupMarginTypeStr,
                                "additionalMarginType": this.productsRes.printProducts[i].additionalCopiesMarkupMarginType,
                                "additionalCopiesExpectedPriceSek": this.productsRes.printProducts[i].additionalCopiesExpectedPriceSek,
                                "additionalMarginValue": this.productsRes.printProducts[i].additionalCopiesMarkupMarginValueSek,
                                "originalAdditionalMargin": this.productsRes.printProducts[i].additionalCopiesPipelineAdditionalCostSek,
                                "additionalMarginPercentage": this.productsRes.printProducts[i].additionalCopiesMarkupMarginPercentage,
                                "delivery_content" : deliverAddressList,
                                "delivery_content_datatable":deliverAddressListForDatatable,
                                "customerAdditionalCopies": this.productsRes.printProducts[i].additionalCopiesCustomer && this.productsRes.printProducts[i].additionalCopiesCustomer > 0 ? this.productsRes.printProducts[i].additionalCopiesCustomer : '',
                                "supplierAdditionalCopies": this.productsRes.printProducts[i].additionalCopiesSupplier && this.productsRes.printProducts[i].additionalCopiesSupplier > 0 ? this.productsRes.printProducts[i].additionalCopiesSupplier : '',
                                "supplierPaperWeight": this.productsRes.printProducts[i].totalWeightSupplier,
                                "customerPaperWeight": this.productsRes.printProducts[i].totalWeightCustomer,
                                "supplierWeightPerCopy":this.productsRes.printProducts[i].weightPerCopySupplier ? this.productsRes.printProducts[i].weightPerCopySupplier : '',
                                "customerWeightPerCopy":this.productsRes.printProducts[i].weightPerCopyCustomer ? this.productsRes.printProducts[i].weightPerCopyCustomer : '',
                                "customerProductDesc": this.convertSlashNToBR(this.productsRes.printProducts[i].customerDescription),
                                "supplierProductDesc": this.convertSlashNToBR(this.productsRes.printProducts[i].supplierDescription),
                                "printOpts": printingOptions,
                                "extraOpts": extraOptions,
                                "bindingOpts": bindingOptions,
                                "options_all": options_all,
                                "printingOptionsStr":this.productsRes.printProducts[i].printingOptionsStr,
                                "bindingOptionsStr":this.productsRes.printProducts[i].bindingOptionsStr,
                                "extraOptionsStr":this.productsRes.printProducts[i].extraOptionsStr,
                                "paper_details": paperDetails,
                                "original_template": this.productsRes.printProducts[i].originalTemplate === 'OTHERS' ? this.productsRes.printProducts[i].originalTemplateOthersValue : this.productsRes.printProducts[i].originalTemplateStr,
                                "repro_template": this.productsRes.printProducts[i].reproTemplate === 'OTHERS' ? this.productsRes.printProducts[i].reproTemplateOthersValue : this.productsRes.printProducts[i].reproTemplateStr,
                                "groupName": this.groups,
                                "currencyOptionsList": GeneralFunction.getDropDownDataForPrimeng(this.productsRes.currencies),
                                produtionOptions: productionOptionList,
                                "parentTableData": parentTableData,
                                "customerPaperDetails": customerPaperDetails,
                                "displayExpectedPrice": this.productsRes.printProducts[i].expectedPriceSek,
                                "additionalCopiesAcceptedPrice": acceptedAdditionalBidPrice,
                                // "acceptedPrice": this.productsRes.printProducts[i].expectedPriceSek, 
                                "acceptedSupplier": acceptedSupplier,
                                "acceptedPrice":acceptedBidPrice,
                                "acceptedPriceFormatted":acceptedBidPriceFormatted,
                                "additionalCopies": this.productsRes.printProducts[i].additionalCopiesSupplier && this.productsRes.printProducts[i].additionalCopiesSupplier > 0 ? this.productsRes.printProducts[i].additionalCopiesSupplier : '',
                                "productDetails": productDetails,
                                "totalPageCount":totalPageCount,
                                "costPrice": this.productsRes.printProducts[i].costPriceSek,
                                "additionalOtherCost": this.productsRes.printProducts[i].additionalCopiesPipelineAdditionalCostSek,
                                "additionalOtherCostDescription": this.productsRes.printProducts[i].pipelineAdditionalCostDescription,
                                "otherCost": this.productsRes.printProducts[i].pipelineAdditionalCostSek,
                                "operationType": this.operationType,
                                "pages": this.productsRes.printProducts[i].pages,
                                "bidPrice": bidPriceProduct,
                                "campaignEndDate": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.campaignEndDate)?this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.campaignEndDate:'',
                                "campaignNumber": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.campaignNumber)?this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.campaignNumber:'',
                                "campaignId": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.campaignId)?this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.campaignId:'',
                                "mediaCategoryId": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.mediaCategoryId)?this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.mediaCategoryId:'',
                                "couponCodeAmount": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.couponCodeAmount)?this.changeCurrencyFormatValDatatable(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.couponCodeAmount, this.locales):'',
                                "eanCode": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.eanCode)?this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.eanCode:'',
                                "endCustomerPrice":!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.endCustomerPrice)?this.convertSlashNToBR(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.endCustomerPrice):'',
                                "generateUniqueCouponCode": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.generateUniqueCouponCode)? this.translate.instant('FIELDS.BTN_YES'):this.translate.instant('FIELDS.BTN_NO'),
                                "numberOfCouponCodes": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.numberOfCouponCodes)?this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.numberOfCouponCodes:'',
                                "numberOfBlocks": this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.numberOfBlocks,
                                "blockSize": this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.blockSize,
                                "printTypeStr":!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.printTypeStr)?this.productsRes.printProducts[i]?.printTypeStr:'',
                                "printType":!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.printType)?this.productsRes.printProducts[i]?.printType:'',
                                "isShowCouponRelatedFields":GeneralFunction.checkCouponRelatedField(rowData.customerRegistrationNumber, this.productsRes.printProducts[i].printType),
                                "isShowCouponPrintingRelatedFields":GeneralFunction.checkPrintTypeField(this.productsRes.printProducts[i].printType),
                                "isShowCampaignRelatedFields": !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.printProducts[i]?.clearOnPrintProductDtl?.generateUniqueCouponCode)? true:false,
                            });
                        }
                    }

                    rowData.RFQProducCols = this.preparePrintDataTableColumns(current_tab);

                    rowData.RFQGeneralProducCols = this.prepareGeneralProductDatatableColumns(current_tab);

                    rowData.DeliveryAddressInfoDatatableCols = this.prepareDeliveryAddressInfoDatatableColumns();
                    if (this.productsRes.otherProducts?.length > 0) {
                        for (var i = 0; i < this.productsRes.otherProducts?.length; i++) {
                            let generalisBidAllowed = '';
                            if (this.productsRes.otherProducts[i].allowSupplierBid) {
                                generalisBidAllowed = '<i class="fa fa-check icon-theme">';
                            }
                            productDetails = this.prepareOrderProductDetail(this.productsRes.otherProducts[i], rowData);
                            if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryDtls)) {
                                if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.deliveryDtls?.length > 0)) {
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                                    for (var v = 0; v < this.productsRes.deliveryDtls?.length; v++) {
                                        if(this.productsRes.otherProducts[i].productId==this.productsRes.deliveryDtls[v].productId){
                                            deliverAddressList.push({
                                                copies_to_deliver: this.productsRes.deliveryDtls[v].deliveryQuantity,
                                                deliver_to: this.convertSlashNToBR(this.productsRes.deliveryDtls[v].deliveryAddress),
                                                delivery_notes: this.productsRes.deliveryDtls[v].deliveryNotes,
                                                delivery_packaging: this.productsRes.deliveryDtls[v].deliveryPackagingStr,
                                                other_address_value:''
                                            });
            
                                            deliverAddressListForDatatable.push({
                                                copies_to_deliver: this.productsRes.deliveryDtls[v].deliveryQuantity,
                                                deliver_to_label: this.convertSlashNToBR(this.productsRes.deliveryDtls[v]?.deliveryAddress),
                                                deliver_to: this.productsRes.deliveryDtls[v].deliveryAddress,
                                                delivery_notes: this.productsRes.deliveryDtls[v].deliveryNotes,
                                                id: this.productsRes.deliveryDtls[v].deliveryAddressId,
                                                deliver_packaging_label: this.productsRes.deliveryDtls[v].deliveryPackagingStr,
                                                delivery_packaging: this.productsRes.deliveryDtls[v].deliveryPackaging,
                                                product_name:this.productsRes.otherProducts[i].productName,
                                            });
                                        }
                                    }
                                }else{
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                                }
                            }else{
                                    var deliverAddressList = [];
                                    var deliverAddressListForDatatable = [];
                            }
                            var acceptedBidPrice: any = '';
                            var acceptedSupplier: any = '';
                            var acceptedBidPriceFormatted: any = '';
                            let bidPriceGeneral: any = '';
                            if(this.productsRes.otherProducts[i].supplierBids && this.productsRes.otherProducts[i].supplierBids.length >0){
                                var bidsArray:any = this.productsRes.otherProducts[i].supplierBids;
                                for(var b=0;b<bidsArray.length;b++){
                                    if(bidsArray[b].currentStatus && (bidsArray[b].currentStatus==Constants.ACCEPTED  || bidsArray[b].currentStatus==Constants.APPROVED)){
                                        acceptedBidPrice = bidsArray[b].productPriceSek;
                                        console.log('acceptedBidPrice7',acceptedBidPrice)
                                        if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                            acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].costPrice, Constants.CURRENCY_VAL);
                                        }
                                        acceptedSupplier = bidsArray[b].supplierOrganizationName;
                                    } else {
                                        var acceptedBidPrice: any = this.productsRes.otherProducts[i].costPriceSek;
                                        console.log('acceptedBidPrice8',acceptedBidPrice)
                                        if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                            acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].costPriceSek, Constants.CURRENCY_VAL);
                                        }
                                        var acceptedSupplier = this.productsRes.otherProducts[i].supplierOrganizationName;
                                    }
                                }
                            } else {
                                var acceptedBidPrice: any = this.productsRes.otherProducts[i].costPriceSek;
                                console.log('acceptedBidPrice9',acceptedBidPrice)
                                if(!GeneralFunction.checkForNullUndefinedBlank(acceptedBidPrice)){
                                    acceptedBidPriceFormatted = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].costPriceSek, Constants.CURRENCY_VAL);
                                }
                                var acceptedSupplier = this.productsRes.otherProducts[i].supplierOrganizationName;
                            }
                            if (this.userDtls.type == Constants.USER_TYPE.SUPPLIER_USER) {
                                if (!GeneralFunction.checkForNullUndefinedBlank(this.productsRes.otherProducts[i].currentSupplierBid) && !GeneralFunction.checkForNullUndefinedBlank(this.productsRes.otherProducts[i].currentSupplierBid.currentStatus)) {
                                    if (this.productsRes.otherProducts[i].currentSupplierBid.currentStatus == Constants.ACCEPTED  || this.productsRes.otherProducts[i].currentSupplierBid?.currentStatus==Constants.APPROVED) {
                                        bidPriceGeneral = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].currentSupplierBid.productPrice, this.productsRes.otherProducts[i].currentSupplierBid.payCurrencyName)
                                    } else {
                                        bidPriceGeneral = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].costPrice, this.productsRes.otherProducts[i].supplierCurrencyName)
                                    }
                                } else {
                                    bidPriceGeneral = this.convertPriceIntoCurrency(this.productsRes.otherProducts[i].costPrice, this.productsRes.otherProducts[i].supplierCurrencyName)
                                }
                            }
                            rowData.price.push({
                                "id": this.productsRes.otherProducts[i].productId,
                                "no": i + 1,
                                "costPrice": this.productsRes.otherProducts[i].costPriceSek,
                                "display_name": this.productsRes.otherProducts[i].productName,
                                "type": this.productsRes.otherProducts[i].type,
                                "generalisBidAllowed": generalisBidAllowed,
                                "delivery_content" : deliverAddressList,
                                "delivery_content_datatable":deliverAddressListForDatatable,
                                "quantity": this.productsRes.otherProducts[i].quantity,
                                "productUnit": this.productsRes.otherProducts[i].unit ? this.productsRes.otherProducts[i].unit : "unit",
                                "unit_price": this.productsRes.otherProducts[i].unitPriceSek,
                                "price": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                "displayMargin": this.productsRes.otherProducts[i].marginValueSek,
                                "displayMarginPercentage": this.productsRes.otherProducts[i].marginPercentage,
                                "supplierDesc": this.convertSlashNToBR(this.productsRes.otherProducts[i].supplierDescription),
                                "customerDesc": this.convertSlashNToBR(this.productsRes.otherProducts[i].customerDescription),
                                "originalMargin": this.productsRes.otherProducts[i].marginValueSek,
                                "currentSupplierBid": this.productsRes.otherProducts[i].currentSupplierBid,
                                "originalMarginPercentage": this.productsRes.otherProducts[i].marginPercentage,
                                "margin": this.productsRes.otherProducts[i].markupMarginTypeStr,
                                "marginType": this.productsRes.otherProducts[i].markupMarginType,
                                "generalType": this.productsRes.otherProducts[i].type,
                                "typeStr":this.productsRes.otherProducts[i].typeStr,
                                "productTotalPrice": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                "display_expected_price": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                "total_price": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                "bidCountGeneralProduct": this.productsRes.otherProducts[i].bidCountGeneralProduct,
                                "productStatus": this.productsRes.otherProducts[i].productStatus,
                                "displayExpectedPrice": this.productsRes.otherProducts[i].expectedPriceSek,
                                "expectedPrice": this.productsRes.otherProducts[i].expectedPriceSek,
                                "totalPrice": this.productsRes.otherProducts[i].totalSalesPrice > 0 ? this.productsRes.otherProducts[i].totalSalesPrice.toFixed(2) : '',
                                "parentTableData": parentTableData,
                                "productDetails": productDetails,
                                "operationType": this.operationType,
                                "otherCost": this.productsRes.otherProducts[i].pipelineAdditionalCostSek,
                                "acceptedSupplier": acceptedSupplier,
                                "acceptedPrice": acceptedBidPrice,
                                "acceptedPriceFormatted": acceptedBidPriceFormatted,
                                "currencyOptionsList": GeneralFunction.getDropDownDataForPrimeng(this.productsRes.currencies),
                                produtionOptions: productionOptionList,
                                "bidPrice": bidPriceGeneral
                            });
                            
                        }
                    }
                    rowData.loadingFalse = false;
                    this.loading = false;
                    // if($("#order_expand_tr_"+rowData.id).offset()){
                    //   $('html, body').animate({
                    //       scrollTop: ($("#order_expand_tr_"+rowData.id).offset().top - 50)
                    //   }, 1000);
                    // }
                    // if($("#report_expand_tr_"+rowData.id).offset()){
                    //     $('html, body').animate({
                    //         scrollTop: ($("#report_expand_tr_"+rowData.id).offset().top - 50)
                    //     }, 1000);
                    // }
                } else if (this.productsRes.outputMap) {
                    rowData.loadingFalse = false;
                    this.msg = [];
                    this.msg.push({
                        severity: 'error',
                        summary: 'Error',
                        detail: this.productsRes.msg
                    });
                    this.httpErrorCode = this.productsRes.outputMap.succCode;
                    this.httpErrorMsgDisplay = true;
                    this.httpErrorMsg = this.httpRes.handleError(this.httpErrorCode);
                }
            }, error => {
                rowData.loadingFalse = false;
                this.loading = false;
                this.httpErrorMsgDisplay = true;
                this.httpErrorCode = error.status;
                this.httpErrorMsg = this.httpRes.handleError(this.httpErrorCode);
            });
        } else {
            rowData.loadingFalse = false;
        }
        dt.toggleRow(rowData);
    }

    preparePrintDataTableColumns(current_tab: any) {
        let RFQProducCols: any = []
        if (current_tab == 'ACTIVE') {
            RFQProducCols = [{
                    field: 'name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '7%'
                },
                {
                    field: 'pageSize',
                    header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                    colWidth: '6%'
                },
                {
                    field: 'customerCopies',
                    header: this.datatable_header_RFQ_title.LBL_COPIES,
                    colWidth: '5%'
                },
                {
                    field: 'paper_details',
                    header: this.datatable_header_title.LBL_PAPER_DTLS,
                    colWidth: '17%'
                },
                {
                    field: 'options_all',
                    header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                    colWidth: '12%'
                },
                {
                    field: 'acceptedSupplier',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                    colWidth: '6%'
                },
                {
                    field: 'displayMargin',
                    header: this.datatable_header_title.MARGIN_VALUE,
                    colWidth: '6%'
                },
                {
                    field: 'total_price',
                    header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                    colWidth: '7%'
                }
            ];
        } else if (current_tab == 'COMPLETED') {
            RFQProducCols = [{
                    field: 'name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '7%'
                },
                {
                    field: 'pageSize',
                    header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                    colWidth: '7%'
                },
                {
                    field: 'customerCopies',
                    header: this.datatable_header_RFQ_title.LBL_COPIES,
                    colWidth: '10%'
                },
                {
                    field: 'paper_details',
                    header: this.datatable_header_title.LBL_PAPER_DTLS,
                    colWidth: '20%'
                },
                {
                    field: 'options_all',
                    header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                    colWidth: '10%'
                },
                {
                    field: 'acceptedSupplier',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                    colWidth: '10%'
                },
                {
                    field: 'displayMargin',
                    header: this.datatable_header_title.MARGIN_VALUE,
                    colWidth: '10%'
                },
                {
                    field: 'total_price',
                    header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                    colWidth: '15%'
                }
            ];
        } else if (current_tab == 'SUPPLIER_ACTIVE_ORDER') {
            RFQProducCols = [{
                    field: 'name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '10%'
                },
                {
                    field: 'pageSize',
                    header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                    colWidth: '10%'
                },
                {
                    field: 'supplierCopies',
                    header: this.datatable_header_RFQ_title.LBL_COPIES,
                    colWidth: '10%'
                },
                {
                    field: 'paper_details',
                    header: this.datatable_header_title.LBL_PAPER_DTLS,
                    colWidth: '25%'
                },
                {
                    field: 'options_all',
                    header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                    colWidth: '25%'
                },
                {
                    field: 'supplier_bid_price',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID_PRICE,
                    colWidth: '10%'
                }
            ];
        } else if (current_tab == 'SUPPLIER_COMPLETED') {
            RFQProducCols = [{
                    field: 'name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '10%'
                },
                {
                    field: 'pageSize',
                    header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                    colWidth: '10%'
                },
                {
                    field: 'supplierCopies',
                    header: this.datatable_header_RFQ_title.LBL_COPIES,
                    colWidth: '10%'
                },
                {
                    field: 'paper_details',
                    header: this.datatable_header_title.LBL_PAPER_DTLS,
                    colWidth: '25%'
                },
                {
                    field: 'options_all',
                    header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                    colWidth: '25%'
                },
                {
                    field: 'supplier_bid_price',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID_PRICE,
                    colWidth: '10%'
                }
            ];
        } else if (current_tab == 'ACTIVE_ORDER') {
            RFQProducCols = [{
                    field: 'name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '12%'
                },
                {
                    field: 'pageSize',
                    header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                    colWidth: '15%'
                },
                {
                    field: 'customerCopies',
                    header: this.datatable_header_RFQ_title.LBL_COPIES,
                    colWidth: '15%'
                },
                {
                    field: 'customerPaperDetails',
                    header: this.datatable_header_title.LBL_PAPER_DTLS,
                    colWidth: '33%'
                },
                {
                    field: 'options_all',
                    header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                    colWidth: '25%'
                },

            ];
        } else if (current_tab == 'CUSTOMER_COMPLETED') {
            RFQProducCols = [{
                    field: 'name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '12%'
                },
                {
                    field: 'pageSize',
                    header: this.datatable_header_RFQ_title.LBL_PAGE_SIZE,
                    colWidth: '11%'
                },
                {
                    field: 'customerCopies',
                    header: this.datatable_header_RFQ_title.LBL_COPIES,
                    colWidth: '11%'
                },
                {
                    field: 'customerPaperDetails',
                    header: this.datatable_header_title.LBL_PAPER_DTLS,
                    colWidth: '31%'
                },
                {
                    field: 'options_all',
                    header: this.datatable_header_RFQ_title.LBL_OPTIONS,
                    colWidth: '30%'
                },

            ];
        }
        return RFQProducCols
    }

    prepareGeneralProductDatatableColumns(current_tab: any) {
        let RFQGeneralProducCols: any = [];
        if (current_tab == 'ACTIVE') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '20%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '10%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '10%'
                },
                {
                    field: 'acceptedSupplier',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                    colWidth: '15%'
                },
                {
                    field: 'displayMargin',
                    header: this.datatable_header_title.MARGIN_VALUE,
                    colWidth: '15%'
                },
                {
                    field: 'totalPrice',
                    header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                    colWidth: '15%'
                }
            ];
        } else if (current_tab == 'COMPLETED') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '20%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '10%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '10%'
                },
                {
                    field: 'acceptedSupplier',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                    colWidth: '15%'
                },
                {
                    field: 'displayMargin',
                    header: this.datatable_header_title.MARGIN_VALUE,
                    colWidth: '15%'
                },
                {
                    field: 'totalPrice',
                    header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                    colWidth: '15%'
                }
            ];
        } else if (current_tab == 'FREE') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '15%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '15%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '15%'
                },
                {
                    field: 'acceptedSupplier',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_SUPPLIER,
                    colWidth: '15%'
                },
                {
                    field: 'displayMargin',
                    header: this.datatable_header_RFQ_title.LBL_MARGIN_PRICE,
                    colWidth: '10%'
                },
                {
                    field: 'totalPrice',
                    header: this.datatable_header_RFQ_title.LBL_TOTAL_PRICE,
                    colWidth: '15%'
                }
            ];
        } else if (current_tab == 'SUPPLIER_ACTIVE_ORDER') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '30%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '20%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '20%'
                },
                {
                    field: 'supplier_bid_price',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID_PRICE,
                    colWidth: '25%'
                }
            ];
        } else if (current_tab == 'SUPPLIER_COMPLETED') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '30%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '20%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '20%'
                },
                {
                    field: 'supplier_bid_price',
                    header: this.datatable_header_RFQ_title.LBL_ACCEPTED_BID_PRICE,
                    colWidth: '25%'
                }
            ];
        } else if (current_tab == 'ACTIVE_ORDER') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '30%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '30%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '30%'
                },
            ];
        } else if (current_tab == 'CUSTOMER_COMPLETED') {
            RFQGeneralProducCols = [{
                    field: 'display_name',
                    header: this.datatable_header_RFQ_title.LBL_PRODUCT_NAME,
                    colWidth: '33%'
                },
                {
                    field: 'type',
                    header: this.datatable_header_RFQ_title.LBL_RFQ_TYPE,
                    colWidth: '33%'
                },
                {
                    field: 'quantity',
                    header: this.datatable_header_RFQ_title.LBL_QUANTITY,
                    colWidth: '32%'
                },
            ];
        }
        return RFQGeneralProducCols;
    }

    prepareDeliveryAddressInfoDatatableColumns(){
        let deliveryContentcols = [];
        deliveryContentcols = [
            {
                field: 'copies_to_deliver',
                header: this.datatable_delivery_content_title.LBL_TOTAL_COPIES,
                colWidth: '25%'
            },
            {
                field: 'deliver_packaging_label',
                header: this.datatable_header_RFQ_title.LBL_DELIVERY_PACKAGING,
                colWidth: '25%'
            },
            {
                field: 'deliver_to_label',
                header: this.datatable_header_RFQ_title.LBL_DELIVER_TO,
                colWidth: '25%'
            },
            {
                field: 'delivery_notes',
                header: this.datatable_delivery_content_title.LBL_DELIVERY_NOTE,
                colWidth: '25%'
            }
        ];
        return deliveryContentcols;
    }


    prepareOtherPriceDatatableColumns(){
        let costToPipelineContentcols = [];
        costToPipelineContentcols = [
                {
                    field: 'cost_value_sek',
                    header: this.datatable_header_RFQ_title.LBL_COST_TO_PIPELINE,
                    colWidth: '20%'
                },
                {
                    field: 'description',
                    header: this.datatable_header_RFQ_title.LBL_DESCRIPTION,
                    colWidth: '60%'
                },
            ];
        return costToPipelineContentcols;
    }


    public refreshParentTableData(parentTableData: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(parentTableData)) {
            this.RFQExpand(parentTableData.rowData, parentTableData.dt, parentTableData.expanded, parentTableData.index, parentTableData.current_tab, '', parentTableData.isProduct);
            parentTableData.dt.toggleRow(parentTableData.rowData);
        }
    }
    public refreshParentOrderTableData(parentTableData: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(parentTableData)) {
            this.orderExpand(parentTableData.rowData, parentTableData.dt, parentTableData.expanded, parentTableData.index, parentTableData.current_tab, '', parentTableData.isProduct);
            parentTableData.dt.toggleRow(parentTableData.rowData);
        }
    }

    forceNumericWithoutMinus(e: any) {
        if(!((e.keyCode > 95 && e.keyCode < 106)
          || (e.keyCode > 47 && e.keyCode < 58) 
          || e.keyCode == 8)) {
            return false;
        }
    }

    // Function for binding input with only numeric values
    forceNumeric(e: any) {
        var key = e.which || e.keyCode;

        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            // numbers
            key >= 48 && key <= 57 ||
            // Numeric keypad
            key >= 96 && key <= 105 ||
            // comma, period and minus, . on keypad
            key == 190 || key == 188 || key == 109 || key == 110 ||
            // Backspace and Tab and Enter
            key == 8 || key == 9 || key == 13 ||
            // Home and End
            key == 35 || key == 36 ||
            // left and right arrows
            key == 37 || key == 39 ||
            // Del and Ins
            key == 46 || key == 45)
            return true;

        return false;
    }
    convertPriceIntoCurrency(value: any, currencyType: any) {
        if (!GeneralFunction.checkForNullUndefinedZeroBlank(value)) {
            if (value === 0) {
                value = "0";
            }
            let formattedValue =  this.changeCurrencyFormatValDatatable(value);
            return currencyType + " " + formattedValue;
        } else {
            return "";
        }
    }
    convertPercentage(value: any) {
        if (!GeneralFunction.checkForNullUndefinedZeroBlank(value)) {
            if (value === 0) {
                value = "0,00";
            }
            return value + " %";
        } else {
            return "";
        }
    }

    convertSlashNToBR(string: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(string)) {
            let newString = string.replace(/\n/g, "<br/>");
            return newString;
        }
    }

    convertInHours(string: any) {
        return string + 'Hours';
    }

    convertDecimaltoInteger(number: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number)) {
            return parseInt(number);
        } else {
            return '';
        }
    }

    // Function for preparing file list
    prepareFileListByFilesArray(filesArray:any) {
        let fileList = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(filesArray) && filesArray.length > 0){
            for (var pf = 0; pf < filesArray.length; pf++) {
                var pistop_status;
                if (filesArray[pf].rfqOrderFileCurrentStatus === 'PITSTOP_PROCESSED' ||
                    filesArray[pf].rfqOrderFileCurrentStatus === 'APPROVED' ||
                    filesArray[pf].rfqOrderFileCurrentStatus === 'DISCARD') {
                    pistop_status = true
                } else {
                    pistop_status = false;
                }
                
                if (filesArray[pf].fileExtension == "pdf") {

                    var pageTypeStr = !(GeneralFunction.checkForNullUndefinedBlank(filesArray[pf].pageTypeStr)) ? filesArray[pf].pageTypeStr :'';
                    var filePaperDtls = '<b>'+this.localeLbls.LBL_PAPER_TYPE+': </b> ' + pageTypeStr + '<br/>';
                    if (this.userDtls.type == "PIPELINE_USER") {
                        filePaperDtls += '<b>'+this.localeLbls.LBL_PAPER_PROFILE_TYPE+': </b>' + filesArray[pf].printPaperProfileStr + '<br/>';
                        filePaperDtls += '<b>'+this.localeLbls.LBL_TITLE_TRAY+': </b> ' + filesArray[pf].printTray;
                    }
                } else {
                    var filePaperDtls = '';
                }
                var displayStatus = '';
                var fileStatus:boolean;
                if (filesArray[pf].supplierVisibilityStatus == "HIDDEN"){
                    displayStatus = this.localeLbls.LBL_STATUS_HIDDEN;
                    fileStatus=false;
                } else{
                    displayStatus = this.localeLbls.LBL_STATUS_VISIBLE;
                    fileStatus=true;
                }
                var description = GeneralFunction.makeFileDescription(filesArray, pf);
                fileList.push({
                    "fileName": filesArray[pf].originalFileName,
                    "systemFileTypeStr":filesArray[pf].systemFileTypeStr,
                    "fileFinishedSizeStr":filesArray[pf].fileFinishedSizeStr,
                    "fileExtension":filesArray[pf].fileExtension,
                    "no": pf + 1,
                    "id": filesArray[pf].systemFileId,
                    "description": description,
                    "fileDtls": [],
                    "pitstopStatus": pistop_status,
                    "fileStatusSupplier": displayStatus,
                    "fileStatus": fileStatus,
                    "fileDiscardedStatus": filesArray[pf].rfqOrderFileCurrentStatus,
                    "filePaperDtls": filePaperDtls,
                    "fileSizeDiscrepencies":(filesArray[pf].isFileDiscrepancy==true) ? 1 : 0,
                    "storeFileName":filesArray.files[pf].storeFileName,
                    "originalFileDownloadLink":filesArray.files[pf].originalFileDownloadLink,
                    "pitstopOutputFileDownloadLink":filesArray.files[pf].pitstopOutputFileDownloadLink,
                    "printPaperProfile":filesArray.files[pf].printPaperProfile,
                    "printPaperProfileStr" :filesArray.files[pf].printPaperProfileStr,
                    "rfqOrderFileCurrentStatus" :filesArray.files[pf].rfqOrderFileCurrentStatus,
                    "rfqOrderFileCurrentStatusStr" :filesArray.files[pf].rfqOrderFileCurrentStatusStr,
                    "supplierVisibilityStatus":filesArray.files[pf].supplierVisibilityStatus,
                    "supplierVisibilityStatusStr":filesArray.files[pf].supplierVisibilityStatusStr,
                    "userType": filesArray.files[pf].userType,
                    "userTypeStr": filesArray.files[pf].userTypeStr,
                });
            }
        }
        return fileList;
    }


    showPriceValue(originalPriceCurrency, originalPriceValue, sekPriceVal){
        if(!GeneralFunction.checkForNullUndefinedBlank(originalPriceCurrency) && originalPriceCurrency!== Constants.CURRENCY_VAL && !GeneralFunction.checkForNullUndefinedBlank(originalPriceValue)){
            return Constants.CURRENCY_VAL+' '+this.changeCurrencyFormatValDatatable(sekPriceVal)+' ('+originalPriceCurrency+' '+this.changeCurrencyFormatValDatatable(originalPriceValue)+')';    
        }else{
            if(!GeneralFunction.checkForNullUndefinedBlank(sekPriceVal)){
                return Constants.CURRENCY_VAL+' '+this.changeCurrencyFormatValDatatable(sekPriceVal);
            }
        }
    }
}