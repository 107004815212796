import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { LoginComponent } from './login-service/login/login.component';
import { HookComponent } from './rfq-order-service/hook/hook.component';
import {AddOrganizationAddressComponent } from './entity-service/add-organization-address/add-organization-address.component';
import { CloudProofPreviewComponent } from './rfq-order-service/cloud-proof-preview/cloud-proof-preview.component';
import { CloudproofComponent } from './rfq-order-service/cloudproof/cloudproof.component';
import { AuthGuard } from './auth.guard';
import {Constants} from './constant/constants';
import { TermsAndConditionComponent } from './general-service/terms-and-condition/terms-and-condition.component';
import {AddContactPersonComponent} from './entity-service/add-contact-person/add-contact-person.component';
import { NotFoundComponent } from './general-service/not-found/not-found.component';


const routes: Routes = [{
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'file/hook',
        component: HookComponent
    },
    {
        path: 'file/cloudproof',
        component: CloudproofComponent
    },
    {
        path: 'file/cloudproof-preview',
        component: CloudProofPreviewComponent
    },
    {
        path: 'terms-condition',
        component: TermsAndConditionComponent
    },
    {
        path: 'clearon/customer/order',
        loadChildren: () => import('./rfq-order-service/clear-on-customer-interface/module').then(m => m.ClearOnCustomerInterfaceModule),
    },
    {
        path: 'clearon/customer/order/:orderId',
        loadChildren: () => import('./rfq-order-service/clear-on-customer-interface/module').then(m => m.ClearOnCustomerInterfaceModule),
    },
    {
        path: '404',
        component: NotFoundComponent
    },
    {
        path: 'cookie-policy',
        loadChildren: () => import('./general-service/cookie-policy/module').then(m => m.CookiePolicyModule),
        // canActivate: [AuthGuard]
    },
    {
        path: 'file/preview-file',
        loadChildren: () => import('./rfq-order-service/testpreviewimage/module').then(m => m.TestpreviewimageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'entity/forgot-password',
        loadChildren: () => import('./login-service/forgot-password/module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'entity/change-password',
        loadChildren: () => import('./login-service/first-change-password/module').then(m => m.FirstChangePasswordModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'entity/reset-password/:token',
        loadChildren: () => import('./login-service/first-change-password/module').then(m => m.FirstChangePasswordModule)
    },
    {
        path: 'entity/first-change-password',
        loadChildren: () => import('./login-service/first-change-password/module').then(m => m.FirstChangePasswordModule)
    },
    {
        path: 'entity/invalid-token',
        loadChildren: () => import('./login-service/invalid-token/module').then(m => m.InvalidTokenModule)
    },
    {
        path: 'entity/expire-token',
        loadChildren: () => import('./login-service/expired-token/module').then(m => m.ExpiredTokenModule)
    },
    {
        path: 'rfq-order/orders/list',
        loadChildren: () => import('./rfq-order-service/pipeline-user-rfq-order-management/module').then(m => m.PipelineUserRFQOrderManagementModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/rfqs/list',
        loadChildren: () => import('./rfq-order-service/pipeline-user-rfq-order-management/module').then(m => m.PipelineUserRFQOrderManagementModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/orders/bulk-invoice-generate',
        loadChildren: () => import('./rfq-order-service/bulk-invoice-generate/module').then(m => m.BulkInvoiceGenerateModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/supplier-orders/list',
        loadChildren: () => import('./rfq-order-service/pipeline-user-rfq-order-management/module').then(m => m.PipelineUserRFQOrderManagementModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['SUPPLIER_USER']],
            expectedRole: []
        }
    },
    {
        path: 'notification',
        loadChildren: () => import('./general-service/notifications/module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/supplier-rfqs/list',
        loadChildren: () => import('./rfq-order-service/pipeline-user-rfq-order-management/module').then(m => m.PipelineUserRFQOrderManagementModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['SUPPLIER_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/:rfqOrderId',
        loadChildren: () => import('./rfq-order-service/email-redirection/module').then(m => m.EmailRedirectionModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'], Constants.USER_TYPE['SUPPLIER_USER'], Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/supplier-group/add',
        loadChildren: () => import('./rfq-order-service/supplier-group-management/add-edit-group/module').then(m => m.AddEditGroupModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/supplier-group/:id/edit',
        loadChildren: () => import('./rfq-order-service/supplier-group-management/add-edit-group/module').then(m => m.AddEditGroupModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/supplier-group/list',
        loadChildren: () => import('./rfq-order-service/supplier-group-management/manage-group/module').then(m => m.ManageGroupModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/print-paper/add',
        loadChildren: () => import('./rfq-order-service/print-paper-management/add-edit-paper/module').then(m => m.AddEditPaperModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/print-paper/:id/edit',
        loadChildren: () => import('./rfq-order-service/print-paper-management/add-edit-paper/module').then(m => m.AddEditPaperModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/print-paper/list',
        loadChildren: () => import('./rfq-order-service/print-paper-management/manage-paper/module').then(m => m.ManagePaperModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'rfq-order/customer-orders/list',
        loadChildren: () => import('./rfq-order-service/pipeline-user-rfq-order-management/module').then(m => m.PipelineUserRFQOrderManagementModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: []
        }
    },
    {
        path: 'entity/pipeline-user/list',
        loadChildren: () => import('./entity-service/pipeline-user-management/module').then(m => m.PipelineUserManagementModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/pipeline-user/add',
        loadChildren: () => import('./entity-service/add-pipeline-user/module').then(m => m.AddPipelineUserModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/pipeline-user/:id/edit',
        loadChildren: () => import('./entity-service/add-pipeline-user/module').then(m => m.AddPipelineUserModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/list',
        loadChildren: () => import('./entity-service/manage-organization/module').then(m => m.ManageOrganizationModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/add',
        loadChildren: () => import('./entity-service/add-organization/module').then(m => m.AddOrganizationModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/:id/edit',
        loadChildren: () => import('./entity-service/add-organization/module').then(m => m.AddOrganizationModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/:orgId/address/list',
        loadChildren: () => import('./entity-service/manage-addresses/module').then(m => m.ManageAddressesModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/:orgId/address/:addressId/:action',
        component:AddOrganizationAddressComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'], Constants.USER_TYPE['SUPPLIER_USER'], Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/:orgId/address/:action',
        component:AddOrganizationAddressComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'], Constants.USER_TYPE['SUPPLIER_USER'], Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/address/:action',
        component:AddOrganizationAddressComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/contact-person/list',
        loadChildren: () => import('./entity-service/manage-contact-person/module').then(m => m.ManageContactPersonModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'],Constants.USER_TYPE['SUPPLIER_USER'],Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/contact-person/:contactPersonId/:action',
        // loadChildren: () => import('./entity-service/add-contact-person/module').then(m => m.AddContactPersonModule),
        component:AddContactPersonComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'],Constants.USER_TYPE['SUPPLIER_USER'],Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/contact-person/:action',
        // loadChildren: () => import('./entity-service/add-contact-person/module').then(m => m.AddContactPersonModule),
        component:AddContactPersonComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'],Constants.USER_TYPE['SUPPLIER_USER'],Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/organization/:orgId/contact-person/list',
        loadChildren: () => import('./entity-service/manage-contact-person/module').then(m => m.ManageContactPersonModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {

        path: 'rfq-order/add/:type',
        loadChildren: () => import('./rfq-order-service/add-edit-rfq-order/module').then(m => m.AddEditRfqOrderModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/:actionType/:type/:id',
        loadChildren: () => import('./rfq-order-service/add-edit-rfq-order/module').then(m => m.AddEditRfqOrderModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'entity/edit-pipeline-user-profile',
        loadChildren: () => import('./entity-service/add-pipeline-user/module').then(m => m.AddPipelineUserModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: 'entity/edit-supplier-profile',
        // loadChildren: () => import('./entity-service/add-contact-person/module').then(m => m.AddContactPersonModule),
        component:AddContactPersonComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['SUPPLIER_USER']],
            expectedRole: []
        }
    },
    {
        path: 'entity/edit-customer-profile',
        // loadChildren: () => import('./entity-service/add-contact-person/module').then(m => m.AddContactPersonModule),
        component:AddContactPersonComponent,
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: []
        }
    },
    {
        path: 'entity/organization-address/list',
        loadChildren: () => import('./entity-service/manage-addresses/module').then(m => m.ManageAddressesModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'], Constants.USER_TYPE['SUPPLIER_USER'], Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {
        path: 'entity/edit-organization-profile',
        loadChildren: () => import('./entity-service/add-organization-basic-detail/module').then(m => m.AddOrganizationBasicDetailModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['SUPPLIER_USER'], Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {

        path: 'report/:type',
        loadChildren: () => import('./rfq-order-service/reports/module').then(m => m.ReportsModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER'], Constants.USER_TYPE['CUSTOMER_USER']],
            expectedRole: [Constants.SUPPLIER_ROLES['ADMIN']]
        }
    },
    {

        path: 'rfqs-order/incident-reporting/:orderId/:orderNumber',
        loadChildren: () => import('./rfq-order-service/incident-reports/module').then(m => m.IncidentReportsModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/incident-reporting/add/:orderId/:orderNumber',
        loadChildren: () => import('./rfq-order-service/add-edit-incident-reports/module').then(m => m.AddEditIncidentReportsModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/incident-reporting/edit/:orderId/:id/:orderNumber',
        loadChildren: () => import('./rfq-order-service/add-edit-incident-reports/module').then(m => m.AddEditIncidentReportsModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/customer-proposal/:id',
        loadChildren: () => import('./rfq-order-service/customer-proposal/module').then(m => m.CustomerProposalModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    // {

    //     path: 'rfq-order/order-confirmation/:id',
    //     loadChildren: () => import('./rfq-order-service/order-confirmation/module').then(m => m.OrderConfirmationModule),
    //     canActivate: [AuthGuard],
    //     data: {
    //         expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
    //         expectedRole: []
    //     }
    // },
    {

        path: 'rfq-order/supplier-confirmation/:id',
        loadChildren: () => import('./rfq-order-service/supplier-confirmation/module').then(m => m.SupplierConfirmationModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/customer-invoice/:id',
        loadChildren: () => import('./rfq-order-service/customer-invoice/module').then(m => m.CustomerInvoiceModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {

        path: 'rfq-order/customer-invoice/bulk',
        loadChildren: () => import('./rfq-order-service/customer-invoice/module').then(m => m.CustomerInvoiceModule),
        canActivate: [AuthGuard],
        data: {
            expectedType: [Constants.USER_TYPE['PIPELINE_USER']],
            expectedRole: []
        }
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false })],
  exports: [TranslateModule, RouterModule]
})
export class AppRoutingModule {

}
