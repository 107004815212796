import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient} from '@angular/common/http';
import {Constants} from '../constant/constants';
import { Router } from '@angular/router';
import { Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlerService {
  
  errorMsg: any;
  success: Boolean = false;
  sessionName: any;
  errorMsgData: any;
  isInvalid = false;
  validationErrors: any;

  getIsValid: Subject<boolean> = new Subject<any>();
  getValidationErrors: Subject<any> = new Subject<any>();
  userLocale: any = Constants.EN;
  userDetailsSessionData: any;

  constructor(
    public router: Router,
    private http: HttpClient,
    private messageService: MessageService,
  ) {
    // this.userDetailsSessionData = localStorage.getItem(Constants.SESSION_NAME);
    // var sessionData = JSON.parse(this.userDetailsSessionData);
    // if (sessionData !== null) {
    //   this.userLocale = sessionData.locale;
    // }
    this.getJSON().subscribe(headers => {
      this.errorMsgData = headers.RESPONCE_ERROR_MESSAGES;
    });
  }
  
  public getJSON(): Observable<any> {
    return this.http.get('../../assets/i18n/'+this.userLocale+'.json');
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.errorMsg = this.errorMsgData.MSG_ERROR_0;
    } else {
      switch (error.status) {
        case Constants.RESPONSE_CODES['SERVER_ERROR_CODE']: {
          this.errorMsg = this.errorMsgData.MSG_ERROR_0;
          break;
        }
        case Constants.RESPONSE_CODES['UNAUTHORIZED_ERROR_CODE']: {
          this.errorMsg = this.errorMsgData.MSG_ERROR_401;
          localStorage.removeItem(Constants.SESSION_NAME);
          localStorage.removeItem(Constants.loginType);
          localStorage.removeItem(Constants.roleTypeSession);
          localStorage.removeItem(Constants.loginOption);
          localStorage.removeItem(Constants.sessionLocale);
          localStorage.removeItem(Constants.Authorization);
          localStorage.removeItem(Constants.organizationIDType);
          this.router.navigate(['login']);
          break;
        }
        case Constants.RESPONSE_CODES['NOT_FOUND_ERROR_CODE']: {
          this.errorMsg = this.errorMsgData.MSG_ERROR_404;
          break;
        }
        case Constants.RESPONSE_CODES['BAD_REQUEST_ERROR_CODE']: {
          this.errorMsg = this.errorMsgData.MSG_ERROR_400;
          break;
        }
        case Constants.RESPONSE_CODES['UNKNOWN_ERROR_CODE']: {
          this.errorMsg = this.errorMsgData.MSG_ERROR_0;
          break;
        }
        default: {
          this.errorMsg = this.errorMsgData.MSG_ERROR_0;
          break;
        }
      }
      return this.errorMsg;
    }
  }

  handleSuccessResponse(response: any) {
    this.setIsValid(false);
    this.setValidationErrors([]);
      switch (response.code) {
        case Constants.STATUS_CODE_6: {
          this.success = false;
          this.handleValidations(response.data);
          break;
        }
        case Constants.STATUS_CODE_2: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          break;
        }
        case Constants.STATUS_CODE_3: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          break;
        }
        case Constants.STATUS_CODE_7: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          localStorage.removeItem(Constants.SESSION_NAME);
          localStorage.removeItem(Constants.loginType);
          localStorage.removeItem(Constants.roleTypeSession);
          localStorage.removeItem(Constants.loginOption);
          localStorage.removeItem(Constants.sessionLocale);
          localStorage.removeItem(Constants.authToken);
          localStorage.removeItem(Constants.organizationIDType);
          this.router.navigate(['login']);
          break;
        }
        case Constants.STATUS_CODE_1: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          break;
        }
        case Constants.STATUS_CODE_8: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          break;
        }
        case Constants.STATUS_CODE_4: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          break;
        }
        case Constants.STATUS_CODE_5: {
          this.messageService.add({
            severity: Constants.responseStatus.ERROR,
            summary: Constants.responseStatusMessage.ERROR,
            detail: response.msg
          });
          this.success = false;
          break;
        }
        case Constants.STATUS_CODE_0: {
          this.success = true;
          break;
        }
        default: {
          this.success = false;
          break;
        }
      }
      return this.success;
    }

    handleValidations(data:any){

      this.setIsValid(true);
      for (const key in data.validation_errors) {
        if (data.validation_errors.hasOwnProperty(key)) {
         this.validationErrors[key] = data.validation_errors[key][0];
        }
      }
      this.setValidationErrors(this.validationErrors);
    }

    setIsValid(flagvalue:any) {
      this.isInvalid = flagvalue;
      this.getIsValid.next(this.isInvalid);
    }
    setValidationErrors(validations:any) {
      this.validationErrors = validations;
      this.getValidationErrors.next(this.validationErrors);
    }
    setSuccessPopupMessage(message: any) {
      this.messageService.add({
        severity: Constants.responseStatus.SUCCESS,
        summary: Constants.responseStatusMessage.SUCCESS,
        detail: message,
        life:5000,
      });
    }
    setErrorPopupMessage(message: any) {
      this.messageService.add({
        severity: Constants.responseStatus.ERROR,
        summary: Constants.responseStatusMessage.ERROR,
        detail: message,
        life:5000,        
      });
    }
}
