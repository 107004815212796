<div id="wrap">
    <div id="content" >
        <p-toast position="top-right"></p-toast>
        <div class="container wrapBox">
            <div class="row">
                <div class="col-lg-12">
                    <!-- File Downloading dialog starts -->
                    <p-dialog class="paper-dtl-dialog showFileSharingContainer" [(visible)]="showFileSharingDialog" showEffect="fade" [modal]="true" [closable]="false" [style]="{width: '50vw'}">
                        <div class="logo-area" style="text-align: center;">
                            <img src="assets/img/pipeline-logo-pdf.png" style="height: 61px; margin-left: auto;margin-right: auto;max-width: 100%;object-fit: contain;object-position: center;"/>
                        </div>
                        <div *ngIf="successFlag" class="infoBox">
                            <div>
                                <img src="{{fileExtensionImgLink}}" style="height: 140px; margin-left: auto;margin-right: auto;max-width: 100%;"/>
                            </div>
                            <p><b>{{fileName}}</b></p>
                            <p class="infoSharing">
                                <span>Shared: {{fileSharedTime}}</span>
                                <span>By: {{fileSharedByUserName}}</span>
                                <a (click)="downloadFile(fileId, fileName)"><i class="fa fa-download" aria-hidden="true"></i>
                                {{downloadText}} ({{fileSize}})</a>
                            </p>
                        </div>
                        <div class="login-detail request-link-msgs" *ngIf="expiryTokenFlag">
                            <form id="frmMsg" novalidate="novalidate">
                                <div class="request-msg">
                                    <div class="request-icons">
                                        <img src="assets/img/expired.png">
                                        </div>
                                    <h1>Expired Request..!</h1>
                                    <!-- <br> -->
                                    <br>
                                    <p>Request link has expired. Please contact your system administrator</p>
                                </div>
                                <div class="clearfix"></div>
                                <div class="separator">
                                </div>
                            </form>
                        </div>
                        <div class="login-detail request-link-msgs" *ngIf="invalidTokenFlag">
                            <form id="frmMsg" novalidate="novalidate">
                                <div class="request-msg">
                                    <div class="request-icons">
                                        <img src="assets/img/invalid.png">
                                    </div>
                                    <h1>Invalid Request</h1>
                                    <!-- <br> -->
                                    <br>
                                    <p>Request token is invalid, Please request with valid token.</p>
                                </div>
                                <div class="clearfix"></div>
                                <div class="separator"></div>
                            </form>
                        </div>
                        <p-footer></p-footer>
                    </p-dialog>
                    <!-- File Downloading dialog ends -->
                    <!--Expired Token Section Start -->
                    
                    <!--Expired Token Section Ends -->
                    <!-- Invalid Token Section Starts -->
                    
                    <!-- Invalid Token Section Ends -->
                </div>
            </div>
        </div>
    </div>
    <div class="my-container">
        <ngx-loading [show]="loading"></ngx-loading>
    </div>
</div>
