<div id="wrap" >
<div id="content">
<p-toast position="top-right"></p-toast>
<div class="container wrapBox">
    <div class="row">
        <div class="col-lg-12">
            <div class="contact-area full-container profileWrapper">
                <h3 *ngIf="isAdd && !isEditProfile">{{ 'PIPELINE_USER.ADD_PIPELINE_USER' | translate }}</h3>
                <h3 *ngIf="!isAdd && !isEditProfile">{{ 'PIPELINE_USER.EDIT_PIPELINE_USER' | translate }}</h3>
                <h3 *ngIf="isEditProfile">{{ 'MENU.SUB_MNU_EDIT_PROFILE' | translate }}</h3>
                <div class="contact-fields content-area">
                    <form [formGroup]="pipelineUserForm.getForm()" (ngSubmit)="onSubmit($event,pipelineUserForm.value)" method="post" >
                    <div class="row flexWrapBox">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_FNAME' | translate }} <small class="madatory-star">*</small></label>
                            <input  #firstName class="txt-theme form-control" pInputText type="text" formControlName="firstName"
                            (change)="addUserName(firstName.value,lastName.value)" name="firstName" id="firstName" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_FNAME' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('firstName')" class="alert alert-danger">
                                {{pipelineUserForm.getError('firstName')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['firstName']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_LNAME' | translate }}</label>
                            <input  #lastName class="txt-theme form-control" pInputText type="text" formControlName="lastName"
                            (change)="addUserName(firstName.value,lastName.value)" name="lastName" id="lastName" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_LNAME' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('lastName')" class="alert alert-danger">
                                {{pipelineUserForm.getError('lastName')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['lastName']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'PIPELINE_USER.LBL_PIPELINE_USER_UNAME' | translate }} <small class="madatory-star">*</small></label>
                            <input #userName class="txt-theme form-control" pInputText type="text" formControlName="userName" [(ngModel)]="userNameValue" name="userName" id="userName" [attr.disabled]="isEditProfile ? true : null" (change)="onUserNameChange()" placeholder="{{ 'PIPELINE_USER.PLACEHOLDER_PIPELINE_USER_UNAME' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('userName')" class="alert alert-danger">
                                {{pipelineUserForm.getError('userName')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['userName']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_EMAIL' | translate }} <small class="madatory-star">*</small></label>
                            <input #email (change)="onEmailChange(email.value)" class="txt-theme form-control" pInputText type="email" formControlName="email" name="email" id="email" placeholder="{{ 'PIPELINE_USER.PLACEHOLDER_PIPELINE_USER_EMAIL' | translate }}" [attr.disabled]="isEmailDisabled ? true : null" [(ngModel)]="emailValue"/>
                            <div *ngIf="pipelineUserForm.hasError('email')" class="alert alert-danger">
                                {{pipelineUserForm.getError('email')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['email']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'PIPELINE_USER.LBL_PIPELINE_USER_JOB_TITLE' | translate }} <small class="madatory-star">*</small></label>
                            <p-multiSelect #jobTtitleElem styleClass="txt-theme form-control" [options]="job_title_opt" ngDefaultControl [(ngModel)]="selectedJobTitle" defaultLabel="{{ 'FIELDS.SELECT' | translate }}" optionValue="value" optionLabel="label" [showHeader]="true" formControlName="titles" [disabled]="isEditProfile"></p-multiSelect>

                            <div *ngIf="pipelineUserForm.hasError('titles')" class="alert alert-danger">
                                {{pipelineUserForm.getError('titles')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['titles']}}
                            </div>
                        </div>
                        
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ROLE' | translate }} <small class="madatory-star">*</small></label>
                            <select [(ngModel)]="userTypeRole" class="txt-theme form-control" formControlName="role" id="role" name="role" [attr.disabled]="isEditProfile ? true : null">
                                <option *ngFor="let type of userRoleOpt" [value]="type.value">{{type.label}}
                                </option>
                            </select>
                            <div *ngIf="pipelineUserForm.hasError('role')" class="alert alert-danger">
                                {{pipelineUserForm.getError('role')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['role']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12" *ngIf="!contactPersonId">
                            <label>{{ 'LOGIN.LBL_PSWD' | translate }} <small class="madatory-star">*</small></label>
                            <input class="txt-theme form-control" pInputText [type]="oldtype"
                            formControlName="password" name="password" id="password"/>
                            <a class="eye-icon" (click)="togglePassword(oldPassword)">
                                <span *ngIf="oldPassword" class="fa fa-eye icon-theme"></span>
                                <span *ngIf="!oldPassword" class="fa fa-eye-slash icon-theme"></span>
                            </a>
                            <div *ngIf="pipelineUserForm.hasError('password')" class="alert alert-danger">
                                {{pipelineUserForm.getError('password')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['password']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'ORG.LBL_ORG_LANG' | translate }} <small class="madatory-star">*</small></label>
                            <select [(ngModel)]="selectedLang" class="txt-theme form-control" formControlName="localeId" id="localeId" name="localeId">
                            <option *ngFor="let type of lang_type_opt" [value]="type.value">
                            {{type.label }}
                            </option>
                            </select>
                            <div *ngIf="pipelineUserForm.hasError('localeId')" class="alert alert-danger">
                                {{pipelineUserForm.getError('localeId')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['localeId']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_ONE' | translate }} </label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="addressLine1" name="addressLine1" id="addressLine1" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_ONE' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('addressLine1')" class="alert alert-danger">
                                {{pipelineUserForm.getError('addressLine1')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressLine1']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_TWO' | translate }}</label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="addressLine2" name="addressLine2" id="addressLine2" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_TWO' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('addressLine2')" class="alert alert-danger">
                                {{pipelineUserForm.getError('addressLine2')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressLine2']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_THREE' | translate }}</label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="addressLine3" name="addressLine3" id="addressLine3" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_THREE' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('addressLine3')" class="alert alert-danger">
                                {{pipelineUserForm.getError('addressLine3')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressLine3']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ZIPCODE' | translate }} </label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="zipCode" name="zipCode" id="zipCode" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ZIPCODE' | translate }}"/>
                            <div *ngIf="pipelineUserForm.hasError('zipCode')" class="alert alert-danger">
                                {{pipelineUserForm.getError('zipCode')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['zipCode']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_COUNTRY' | translate }} <small class="madatory-star">*</small></label>
                            <p-dropdown #selectElem placeholder="{{'FIELDS.SELECT' | translate}}" (onChange)="clearFilter(selectElem)" styleClass="searchable-select txt-theme form-control" [options]="country_opt" optionLabel="name" optionValue="id" formControlName="countryId" [(ngModel)]="selectedCountry" [filter]="true" id="countryId" name="countryId"></p-dropdown>
                            <div *ngIf="pipelineUserForm.hasError('countryId')" class="alert alert-danger">
                                {{pipelineUserForm.getError('countryId')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['countryId']}}
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_CITY' | translate }} </label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="city" name="city" id="city" placeholder="{{ 'ORG.LBL_ORG_CITY' | translate }}" [(ngModel)]="selectedCity"/> 
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['city']}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12 col-sm-12 col-xs-12 contact-number-dtls">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_PHONE' | translate }}</label>
                            <form class="p-field col-12 col-xs-12 margin-bottom-15 noPadding" id="frm_add_contact" [formGroup]="contactForm" method="post">
                                <div class="contact-nums">
                                    <div formArrayName="contact_maps" *ngFor="let data of contact_maps['controls']; let i = index">
                                        <div [formGroupName]="i"> 
                                            <div class="contact-numbers" id="contact_part_{{i}}">
                                                <p-dropdown id="contact_code_{{i}}" class="searchable-select txt-theme form-control" [options]="phone_code_opt" placeholder="{{'FIELDS.SELECT' | translate}}" [filter]="true" formControlName="countryId" ></p-dropdown>
                                                <input class="txt-theme form-control" type="number" maxlength="50" name="contact_number_{{i}}" id="contact_number_{{i}}" formControlName="contactNumber"
                                                placeholder="{{'CONT_PER.PLACEHOLDER_CONT_PER_Phone' | translate }}" />
                                                <input  type="hidden" name="contact_id_{{i}}" id="contact_id_{{i}}">

                                                <button type="button" id="add_button_{{i}}" class="add-new fa fa-plus-circle" (click)="addContactInfo(i)" *ngIf="i==contact_maps.controls.length-1"></button>

                                                <button type="button" id="remove_button_{{i}}" class="add-new fa fa-minus-circle" (click)="removeContactInfo(i)"></button>
                                                
                                                <div *ngIf="i==contact_maps.controls.length-1 && !contactFormValid[i]" class="alert alert-danger"> {{contactNumberNotEmptyErrorMsg}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="btn-area">
                        <button class="btn-theme" id="btn-login"  type="submit"  [disabled]="!pipelineUserForm.isValid" *ngIf="isAdd">{{ 'CONT_PER.BTN_LBL_CONT_PER_ADD' | translate }}</button>
                        <button class="btn-theme" id="btn-login"  type="submit"  [disabled]="!pipelineUserForm.isValid" *ngIf="!isAdd" >{{ 'CONT_PER.BTN_LBL_CONT_PER_UPDATE' | translate }}</button>
                        <!-- <button type="button" class="btn btn-default btn-theme-disable" (click)="resetForm()" *ngIf="isAdd">
                        {{ 'CONT_PER.BTN_LBL_CONT_PER_CLEAR' | translate }}
                        </button> -->
                        <button type="button" class="btn btn-default btn-theme-disable" (click)="back()">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_BTN_CANCEL' | translate }}</button>
                       <!--  <button type="button" class="btn btn-default btn-theme-disable" *ngIf="!isAdd" (click)="resetForm()">
                        {{ 'CONT_PER.BTN_LBL_ORG_RESET' | translate }}
                        </button> -->
                    </div>
                    <input  type="hidden" name="default_select_text" id="default_select_text" value="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}"  >
                    <!-- <input  type="hidden" name="pipeline_user_dtl_id" formControlName="pipeline_user_dtl_id" id="pipeline_user_dtl_id" value=""  > -->
                    <input  type="hidden" name="page_title" id="page_title" value="{{ 'PAGE_TITLES.PIPELINE_USER_MANAGEMENT' | translate }}"  >
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="my-container">
        <ngx-loading [show]="loading"></ngx-loading>
    </div>
</div>