import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';


// Library Dependencies

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { HttpRequestInterceptor } from './service/interceptors/http.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GalleriaModule } from 'primeng/galleria';
import {FileUploadModule} from 'primeng/fileupload';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import {ProgressBarModule} from 'primeng/progressbar';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { Constants} from './constant/constants';
import {SharedModule} from './shared.module';
import { AuthInterceptor } from './auth-interceptor';
import { AuthGuard } from './auth.guard';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { AuthService } from './service/auth.service';
import { ApiCallingService } from './service/api/api-calling.service';
import { ResponseHandlerService } from './service/response-handler.service';
import { FilehandlerService } from './service/filehandler.service';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LightboxModule } from 'ngx-lightbox';





const cookieConfig:NgcCookieConsentConfig ={
  "cookie": {
    "domain": Constants.DOMAIN_NAME
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#ffffff",
      "text": "#000000",
      "link": "#0098b9"
    },
    "button": {
      "background": "#0098b9",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to enhance your user experience. By continuing to use our site you consent to use our cookies.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": Constants.DEV_LINK+"cookie-policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,FooterComponent
  ],
  imports: [
    // routing,
    SharedModule,
    AppRoutingModule,
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    DialogModule,
    TableModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    MultiSelectModule,
    CalendarModule,
    OverlayPanelModule,
    FileUploadModule,
    CheckboxModule,
    GalleriaModule,
    ProgressBarModule,
    RadioButtonModule,
    VirtualScrollerModule,
    ScrollingModule,
    LightboxModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot(Constants.loadingConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  // providers: [
  //   ConfirmationService,MessageService,AuthService,
  //   { provide: HTTP_INTERCEPTORS,
  //     useClass: AuthInterceptor,
  //     multi : true
  //   },
  //   {provide: LocationStrategy, useClass: PathLocationStrategy},
  //   AuthGuard
  // ],
  providers: [
    ApiCallingService,
    ResponseHandlerService,
    ConfirmationService,
    MessageService,
    AuthService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    AuthGuard,
    FilehandlerService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [HeaderComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
