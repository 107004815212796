import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ValidationManager} from "ng2-validation-manager";
import {Validators,FormControl,FormGroup,FormBuilder,FormArray} from '@angular/forms';
import {HttpClient, HttpRequest, HttpEvent,HttpHeaders} from '@angular/common/http';
import 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { Constants} from '../../constant/constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Urls } from 'src/app/constant/urls';
import {ConfirmationService} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { GeneralFunction } from '../../common/general-function';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { ValidationFields, ValidationRules } from '../../common/validationRules-constants';
import { MessageService } from 'primeng/api';
import { Messages} from '../../common/messages';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-add-pipeline-user',
  templateUrl: './add-pipeline-user.component.html',
  styleUrls: ['./add-pipeline-user.component.css']
})
export class AddPipelineUserComponent implements OnInit {
    pipelineUserForm: any;
    userData:any;
    data: any;
    back = GeneralFunction.goBack;
    job_title_opt: any[]=[];
    country_opt: any;
    countryIds: any;
    phoneCodes: any;
    phone_code_opt: any;
    cityList: any;
    city_opt: any;
    contact_id: any;
    msg: any;
    id: any;
    saveEvent: any;
    length: any;
    activateDectivateMsg: any;
    contactNumberNotEmptyErrorMsg: any;
    contact_dtls: any;
    contact_codes: any;
    contact_name: any;
    sub: any;
    lang_type_opt: any;
    //for dataTable
    contactIsBlank: boolean = false;
    isAdd: boolean = true;
    contactArray: any;
    //for dropdown selection value
    selectedJobTitle: any;
    selectedLang: any=1;
    contactCode:any=205;
    selectedCountry: any;
    selectedCity: any;
    //for show members
    loading: boolean = false;
    country_id: any;
    validationErrors: any;
    isInvalid: boolean = false;
    title: any;
    //for datatable vars
    cols: any;
    pipeUserListUrl: any;
    pipeUserData: any;
    totalRecords: number = 0;
    pipeUserList: any;
    userNameValue: any;
    rowPerPage: any;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    sessionName: any;
    page_title: any;
    start:number=0;
    end:number=0;
    resetPwdForm: any;
    showChangePwdPopup:boolean=false;
    oldPassword:boolean=true;
    oldtype:any='password';
    newPassword:boolean=true;
    newtype:any='password';
    confirmPassword:boolean=true;
    confirmtype:any='password';
    contactPersonId:any;
    datatableRowsVal:number = 0;
    formData:any;
    userRoleOpt:any;
    userTypeRole:any='';
    emailValue:any='';
    contactForm:any;
    isContactFormValid:any='';
    removeIndex:any='';
    isEditProfile = false;
    isEmailDisabled = false;
    contactFormValid: any = [];

  constructor(private formBuilder: FormBuilder, public router: Router, private translate: TranslateService, private http: HttpClient,private titleService: Title, private route: ActivatedRoute, private messageService: MessageService, private cdRef : ChangeDetectorRef, private responseHandler: ResponseHandlerService,private apiCallingService: ApiCallingService, private authenticationService: AuthService) { 
    
    this.userData = localStorage.getItem(Constants.SESSION_NAME);
    var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
    this.userData = JSON.parse(sessionData);
    if (this.userData != null) {
        translate.setDefaultLang(this.userData.i18nCode);
    } else {
        translate.setDefaultLang(Constants.DEFAULT_LANG);
    }
    this.contactForm = this.formBuilder.group({
        'contact_maps': this.formBuilder.array([
            this.createContact(),
        ]),
    });
    
    this.translate.get('FIELDS').subscribe((text: any) => {
            this.userRoleOpt = [
                {label: text.SELECT, value: ''},
                {label: text.ADMIN, value: Constants.SUPPLIER_ROLES['ADMIN']},
                {label: text.USER, value: Constants.SUPPLIER_ROLES['USER']}
            ];
        });
    this.translate.get('RFQ').subscribe((text: any) => {
        this.job_title_opt = [
            {label: text.LBL_ACCOUNT_MANAGER, value: Constants.JOB_TITLE['ACCOUNT_MANAGER']},
            {label: text.LBL_PROJECT_MNGER, value: Constants.JOB_TITLE['PROJECT_MANAGER']},
            {label: text.LBL_SALES_MNGER, value:  Constants.JOB_TITLE['SALES_MANAGER']}
        ];
    });
    if (this.router.url === Constants.URLS['EDIT_PIPELINE_PROFILE']) {
        this.isEditProfile = true;
        this.contactPersonId = this.userData.userLoginId;
        this.isEmailDisabled = true;
        this.isAdd = false;
        setTimeout(() => {
            this.titleService.setTitle(this.translate.instant("MENU.SUB_MNU_EDIT_PROFILE") + Constants.PIPELINE_TITLE);
        }, Constants.validationTimeout);
    } else {
        this.isAdd = true;
        this.isEditProfile = false;
        this.isEmailDisabled = false;
    }
    this.sub = this.route.params.subscribe(params => {
        if (params['id'] && !this.isEditProfile) {
            this.isAdd = false;
            this.contactPersonId = params['id'];
            this.isEmailDisabled = false;
            setTimeout(() => {
                this.titleService.setTitle(this.translate.instant("PIPELINE_USER.EDIT_PIPELINE_USER") + Constants.PIPELINE_TITLE);
            }, Constants.validationTimeout);
        }
    });
    if(this.contactPersonId || this.isEditProfile){
        this.pipelineUserForm = new ValidationManager(ValidationRules.editPipelineUserRules);
    }else{
        this.pipelineUserForm = new ValidationManager(ValidationRules.pipelineUserRules);
    }
    this.getInitData();
    this.pipelineUserForm.reset();
    this.translateValidationErrorMessage();

  }

    translateValidationErrorMessage() {
        setTimeout(() => {
            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.email, Constants.validationType.email, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.EMAIL")}));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.email, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.EMAIL")}));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.email, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.EMAIL"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.userName, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.USERNAME")}));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.userName, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.USERNAME"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.firstName, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.FIRST_NAME")}));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.firstName, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.FIRST_NAME"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.lastName, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.LAST_NAME"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));


            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine1, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_1"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine2, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_2"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine3, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_3"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.zipCode, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ZIP_CODE"),
                maxlength: Constants.fieldTypes.maxStringLength50
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.city, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.CITY"),
                maxlength: Constants.fieldTypes.maxStringLength250
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.countryId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.COUNTRY")}));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.localeId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
                field: this.translate.instant("FIELDS.LANGUAGE")
                
            }));


            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.titles, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
                field: this.translate.instant("FIELDS.TITLES")
                
            }));

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.contactNumber, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.CONTACT"),
                maxlength: Constants.fieldTypes.maxStringLength50
            }));

            if(GeneralFunction.checkForNullUndefinedBlank(this.contactPersonId)){
                this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.password, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
                field: this.translate.instant("FIELDS.PASSWORD")
                }));
                this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.password, Constants.validationType.rangeLength, this.translate.instant("VALIDATION_MESSAGE.RANGE", {field: this.translate.instant("FIELDS.PASSWORD"), startRange: Constants.fieldTypes.startRange, endRange: Constants.fieldTypes.endRange}));
            }

            this.pipelineUserForm.setErrorMessage(ValidationFields.pipelineUserfields.role, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ROLE")}));

        }, Constants.validationTimeout);
    }

    ngOnInit(): void {
      
    }

    createContact(): FormGroup {
        return this.formBuilder.group({
            countryId: [205],
            contactNumber: ['']
        });
        
    }

    createEmptyItem(): FormGroup {
        this.contactFormValid.push(true);
        return this.formBuilder.group({
            countryId: [205, Validators.required],
            contactNumber: ['', Validators.required]
        });
    }

    get contact_maps(): FormArray {
        return this.contactForm.get('contact_maps') as FormArray;
    }

    addContactInfo(index:any){
        var formLength:any = this.contact_maps.length-1;
        var formArrayVal:any = this.contactForm.get('contact_maps') as FormArray;
        var contactDtlsData:any = this.contactForm?.value?.contact_maps;
        if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
            $('#contact_error_'+index).hide();
            this.contactNumberNotEmptyErrorMsg ='';
            this.contact_maps.insert(this.contact_maps.length,this.createEmptyItem());
            this.contactFormValid[index] = true;
        }else{
            if(GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && 
                !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                $('#contact_error_'+index).show();
                this.contactFormValid[index] = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.COUNTRY"));
            }else if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                $('#contact_error_'+index).show();
                this.contactFormValid[index] = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
            }else{
                $('#contact_error_'+index).show();
                this.contactFormValid[index] = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
            }
            
        }
    }

    removeContactInfo(index: any){
        this.removeIndex = index;
        this.contact_maps.removeAt(this.removeIndex);
        this.contactFormValid.splice(index, 1);
    }

    getInitData() {        
        this.loading = true;
        if (!this.contactPersonId){
            this.isEmailDisabled = false
            var apiEndPoint:any = ApiEndpointConstant.getAddPipelineUser;
            this.getAddUpdateData(apiEndPoint);
            setTimeout(() => {
                  this.titleService.setTitle(this.translate.instant("PIPELINE_USER.ADD_PIPELINE_USER") + Constants.PIPELINE_TITLE);
            }, Constants.validationTimeout);
        } else if (this.contactPersonId) {
            var apiEndPoint:any = ApiEndpointConstant.editPipelineUser+this.contactPersonId+'/edit';
            this.getAddUpdateData(apiEndPoint);
        }
    }

    getAddUpdateData(apiEndPoint:any){
        this.selectedCountry = "";
        this.contactArray = [];
        this.contactIsBlank = false;
        this.contact_dtls = [];
        this.selectedJobTitle=[];
        this.contact_dtls.push({
            isShow: true
        });
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, apiEndPoint)
        .subscribe(result => {
            this.data = result;
            if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                this.country_opt = [];
                this.phone_code_opt = [];
                this.lang_type_opt = [];
                this.country_opt = this.data.countries;
                this.lang_type_opt = GeneralFunction.getDropDownData(this.data.locales);
                this.phone_code_opt = GeneralFunction.getPhoneCode(this.data.countries);
                if (this.contactPersonId) {
                    this.pipelineUserForm.setValue({
                        firstName: this.data.firstName,
                        lastName: this.data.lastName,
                        userName: this.data.userName,
                        email: this.data.email,
                        addressLine1: this.data.addressLine1,
                        addressLine2: this.data.addressLine2,
                        addressLine3: this.data.addressLine3,
                        zipCode: this.data.zipCode,
                        city: this.data.city,
                        countryId: this.data.countryId,
                        localeId: this.data.localeId,
                        role:this.data.role
                    });
                    this.selectedLang = (this.data.localeId) ? this.data.localeId : 1;
                    this.selectedCountry = this.data.countryId?this.data.countryId:205;
                    this.contact_dtls = [];
                    var contactDtls:any[] = this.data.contactDtls;
                    if(contactDtls && contactDtls?.length > 0) {
                        this.setValue(contactDtls);
                    }
                    var selectData:any = [];
                    var jobTitle:any[] = this.data.jobTitles;
                    if(jobTitle && jobTitle?.length>0){
                        for(var i = 0; i < jobTitle.length; i++){
                        selectData.push(jobTitle[i]?.title);
                        }
                    }
                    setTimeout(() => {
                        this.selectedJobTitle = selectData;
                    }, Constants.validationTimeout);
                }

            }
            this.loading = false;
        }, error => {
            this.loading = false;
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }
    /**
     * RESET FORM
     */
    resetForm() {
        this.ngOnInit();
    }

    setValue(items: any) {
        const formArray = new FormArray([]);
        for (let x of items) {
          formArray.push(this.formBuilder.group({
            countryId: x.countryId,
            contactNumber: x.contactNumber
          }));
        }
        this.contactForm.setControl('contact_maps', formArray);
      }


    onSubmit($event:any, value: string) {
        var formLength:any = this.contact_maps.length-1;
        var formArrayVal:any = this.contactForm.get('contact_maps') as FormArray;
        var contactDtlsData:any = this.contactForm?.value?.contact_maps;
        var contactFormValid: any;
        contactDtlsData.forEach((contact: any, index: any) => {
            if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                $('#contact_error_'+index).hide();
                this.contactNumberNotEmptyErrorMsg ='';
                this.contactFormValid[index] = true;
            }else{
                if(GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && 
                    !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                    $('#contact_error_'+index).show();
                    this.contactFormValid[index] = false;
                    this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.COUNTRY_CODE"));
                }else if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                    $('#contact_error_'+index).show();
                    this.contactFormValid[index] = false;
                    this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
                }else{
                    // $('#contact_error_'+index).show();
                    // this.contactFormValid[index] = false;
                    // this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
                     $('#contact_error_'+index).hide();
                    this.contactNumberNotEmptyErrorMsg ='';
                    this.contactFormValid[index] = true;
                }
            }
        });
        let formSubmit: any;
        if (this.contactFormValid.includes(false)) {
            formSubmit = false;
        } else {
            formSubmit = true;
        }
        if (this.pipelineUserForm.isValid() && this.contactForm.valid && $('.contact-error:visible').length == 0 && formSubmit) {
            this.formData = this.pipelineUserForm.getData();
            this.loading = true;
            this.formData.contactDtls = [];            
            var contactDtlsData:any = this.contactForm?.value?.contact_maps;

            if(contactDtlsData && contactDtlsData[0] && contactDtlsData[0]?.countryId == '' && contactDtlsData[0]?.contactNumber == ''){
                this.formData.contactDtls = [];    
            }else{
                this.formData.contactDtls = contactDtlsData;
            }
           
            if (GeneralFunction.checkForNullUndefinedBlank(this.contactPersonId)) {
                //Add contact form
                this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.POST, ApiEndpointConstant.addPipelineUser)
                .subscribe(result => {
                    this.data = result;
                    if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                        localStorage.setItem('redirectMsg', this.data.message);
                        const url = Constants.URLS['PIPELINE_USER_MANAGEMENT'];
                        this.router.navigateByUrl(url);
                    }else{
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.isInvalid = true;
                    this.validationErrors = [];
                    if(error?.error?.errors){
                        for (var i = 0; i < error.error.errors.length; i++) {
                            this.validationErrors[error.error.errors[i].field] = error.error.errors[i].message;
                        }
                    }
                });
                
            } else { //Update contact form

                this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.PUT, ApiEndpointConstant.editPipelineUser+this.contactPersonId+'/edit')
                .subscribe(result => {
                    this.data = result;
                    if (this.data.resultCode == Constants.resultCode.SUCCESS) {                        
                        if (!this.isEditProfile) {
                            localStorage.setItem('redirectMsg', this.data.message);
                            const url = Constants.URLS['PIPELINE_USER_MANAGEMENT'];
                            this.router.navigateByUrl(url);
                        } else {
                            this.setLocalStorage(this.formData);
                            localStorage.setItem('redirectLanguageMsg', 'EDIT_PROFILE.SUCCESS');
                            const url = Constants.URLS['ORDERS'];
                            this.router.navigateByUrl(url);
                            this.authenticationService.setHeaderReload(true);
                        }
                    }else{
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.isInvalid = true;
                    this.validationErrors = [];
                    if(error?.error?.errors){
                        for (var i = 0; i < error.error.errors.length; i++) {
                            this.validationErrors[error.error.errors[i].field] = error.error.errors[i].message;
                        }
                    }
                });
                
            }
        }
    }

    setLocalStorage(formData: any) {
        this.userData.fname = formData.firstName;
        this.userData.lname = formData.lastName;
        let localeId: any = formData.localeId;
        this.userData.localeId = localeId
        let localeValue: any = Constants.language.filter(function (locale: any) {
            return locale.id === parseInt(localeId);
        });
        if (localeValue.length > 0) {
            this.userData.i18nCode = localeValue[0].i18nCode;
            this.userData.localeUtf8Code = localeValue[0].localeUtf8Code;
            localStorage.setItem(Constants.SESSION_NAME, JSON.stringify(this.userData));
            localStorage.setItem(Constants.acceptLanguage, this.userData.localeUtf8Code);
            localStorage.setItem(Constants.sessionLocale, this.userData.i18nCode);
        }
    }

    addMore(rowValue:any) {
        this.length = this.contact_dtls.length - 1;
        if (GeneralFunction.checkForNullUndefinedBlank($('#contact_number_' + this.length).val()) || GeneralFunction.checkForNullUndefinedBlank($('#contact_code_' + this.length).val())) {
            $('#contact_error_' + this.length).show();
            this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
        } else {
            var countryId:any = $('#contact_code_' + rowValue).val();
            var contactNumber:any = $('#contact_number_' + rowValue).val();
            var contactId:any = $('#contact_id_' + rowValue).val();
            if (contactNumber.length > 4 && !GeneralFunction.checkForNullUndefinedBlank(contactNumber)) {
                this.contactArray.splice(rowValue, 1);
                if (this.contactArray.indexOf($('#contact_code_' + rowValue).val() + '-' + $('#contact_number_' + rowValue).val()) == -1) {
                    this.contact_dtls[this.contact_dtls.length - 1].isShow = false;
                    $('#contact_error_' + this.length).hide();
                    this.contactNumberNotEmptyErrorMsg = '';
                    this.data = new FormControl();
                    this.data.isShow = true;
                    this.contact_dtls.push(this.data);
                } else {
                    $('#contact_error_' + rowValue).show();
                    this.contactNumberNotEmptyErrorMsg = Messages.UNIQUE_CONTACT_ERROR_MSG;
                }
            }
        }
    }

    /**
     *
     * @param rowValue
     */
    validateContactNumber(rowValue:any) {
        if (GeneralFunction.checkForNullUndefinedBlank($('#contact_number_' + rowValue).val()) || GeneralFunction.checkForNullUndefinedBlank($('#contact_code_' + rowValue).val())) {
            $('#contact_error_' + rowValue).show();
            // this.contactNumberNotEmptyErrorMsg = Messages.VALID_CONTACT_ERROR_MSG;
        } else {
            var countryId:any = $('#contact_code_' + rowValue).val();
            var contactNumber:any = $('#contact_number_' + rowValue).val();
            var contactId:any = $('#contact_id_' + rowValue).val();
            if (contactNumber.length > 4 && !GeneralFunction.checkForNullUndefinedBlank(contactNumber)) {
                this.contactArray.splice(rowValue, 1);
                if (this.contactArray.indexOf($('#contact_code_' + rowValue).val() + '-' + $('#contact_number_' + rowValue).val()) == -1) {
                    $('#contact_error_' + rowValue).hide();
                    this.contactArray = [];
                    var phoneCount = this.contact_dtls.length - 1;
                    for (var i = 0; i <= phoneCount; i++) {
                        this.contactArray.push($('#contact_code_' + i).val() + '-' + $('#contact_number_' + i).val());
                    }
                } else {
                    $('#contact_error_' + rowValue).show();
                    this.contactNumberNotEmptyErrorMsg = Messages.UNIQUE_CONTACT_ERROR_MSG;
                }
            }
        }
    }

    /**
     * REMOVE CONTACT NUMBER
     * @param index
     */
    removeContactNumber(index: number) {
        this.contact_dtls.splice(index, 1);
        this.contactArray.splice(index, 1);
        $("#contact_part_" + index).remove();
    }

     /**
     * On Email change
     * @param value
     */
    onEmailChange(value:any) {
        if(value){
            this.loading = true;
            if(this.contactPersonId){
                var endPoint:any = ApiEndpointConstant.changeUserStatus+this.contactPersonId+'/chkEmail/'+value;
            }else{
                var endPoint:any = ApiEndpointConstant.checkUniqueEmail+value;
            }
            this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, endPoint).subscribe(result => {
                this.data = result;
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                }else{
                    this.responseHandler.setErrorPopupMessage(this.data.message);
                    this.emailValue='';
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
        }        
    }
    /**
     * On Email change
     * @param value
     */
    onUserNameChange() {
       if(this.userNameValue){
           this.loading = true;
           if(this.contactPersonId){
                var endPoint:any = ApiEndpointConstant.changeUserStatus+this.contactPersonId+'/chkUsername/'+this.userNameValue;
            }else{
                var endPoint:any = ApiEndpointConstant.checkUniqueUserName+this.userNameValue;
            }

            this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, endPoint).subscribe(result => {
                this.data = result;
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                }else{
                    this.responseHandler.setErrorPopupMessage(this.data.message);
                    this.userNameValue = '';
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
       }
        
    }

    
    addUserName(firstName:any, lastName:any) {
        if (!this.isEditProfile) {
            if (firstName.length >= 2 && lastName.length >= 2) {
                var userName = firstName.substring(0, 2) + lastName.substring(0, 2);
                this.userNameValue = userName.toUpperCase();
                this.onUserNameChange();
            }
        }
    }

    clearFilter(dropdown:any) {
      dropdown.resetFilter();
    }

    togglePassword(status:any) {
        if (status) {
            this.oldPassword = false;
            this.oldtype = Constants.text;
        } else {
            this.oldPassword = true;
            this.oldtype = Constants.password;
        }
    }
    
}
