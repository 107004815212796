<div id="wrap" >
  <div id="content">
    <p-toast position="top-right"></p-toast>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="mobile-connect-logo">
            <img src="assets/img/connect-logo.png"/>
          </div>
          <div class="login-area">
            <div class="company-info">
              <div class="pipeline-logo">
                <img src="assets/img/pipeline-logo.png"/>
              </div>
              <div class="connect-logo">
                <img src="assets/img/connect-2.png"/>
                <span class="version">{{version}}</span>
              </div>
            </div>

            <div class="login-detail">
              <h2 translate>LOGIN.TITLE</h2>
              <form class="login-form" [formGroup]="loginform.getForm()" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label>{{ 'LOGIN.LBL_USERNAME' | translate }}</label>
                  <input trim class="txt-theme form-control" pInputText type="text" formControlName="emailUsername" placeholder="{{ 'LOGIN.PLACEHOLDER_USERNAME' | translate}}"/>
                  <div *ngIf="loginform.hasError('emailUsername')" class="alert alert-danger">
                    {{loginform.getError('emailUsername')}}
                  </div>
                  <div *ngIf="isInvalid" class="alert alert-danger">
                    {{validationErrors['emailUsername']}}
                  </div>
                </div>

                <div class="form-group password-control">
                    <label>{{ 'LOGIN.LBL_PSWD' | translate }}</label>
                    <input class="txt-theme form-control" pInputText formControlName="password" name="password" maxlength="20" placeholder="{{'LOGIN.PLACEHOLDER_PSWD' | translate}}" 
                    [type]="type">
                    <a class="eye-icon" (click)="togglePassword(showPassword)">
                        <span *ngIf="showPassword" class="fa fa-eye icon-theme"></span>
                        <span *ngIf="!showPassword" class="fa fa-eye-slash icon-theme"></span>
                    </a>
                    <div *ngIf="loginform.hasError('password')" class="alert alert-danger">
                        {{loginform.getError('password')}}
                    </div>
                    <div *ngIf="isInvalid" class="alert alert-danger">
                        {{validationErrors['password']}}
                    </div>
                </div>

                <div class="btn-area">
                  <button class="btn-theme" id="btn-login" type="submit"  [disabled]="!loginform.isValid" >{{ 'LOGIN.BTN_LBL_LOGIN' | translate }}</button>
                </div>
                <div class="go-link">
                  {{ 'LOGIN.LBL_FORGOT_PSWD' | translate }} <a routerLink="/entity/forgot-password" routerLinkActive="active">{{ 'LOGIN.LBL_CLICK_HERE_LINK' | translate }}</a> {{ 'LOGIN.LBL_RESET' | translate }}
                </div>
                <p class="footerBox">{{ 'COMMON.LBL_CONTACT' | translate }} <a href="{{mailToLink}}">{{ 'COMMON.LBL_CONTACT_LINK_TEXT' | translate }}</a>. </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dialogue box for user type-->
<p-dialog header="{{ 'COMMON.USER_TYPE_SELECT_HEADER' | translate }}" [(visible)]="userTypePopup" 
[ngClass]="[userTypePopup ? 'ui-dialog-overlay' : '']" [style]="{'width':'500px'}">

  <form [formGroup]="userTypeform.getForm()" (ngSubmit)="onuserTypeSubmit()" method="post" >
    <div class="form-group">
      
      <select [(ngModel)]="selectedUserType" class="txt-theme form-control" formControlName="user_type" id="user_type" name="user_type" >
        <option [value]="type.value" *ngFor="let type of userTypeOpt">{{type.label}}</option>
      </select>
    </div>
    <div *ngIf="userTypeform.hasError('user_type')" class="alert alert-danger">
      {{userTypeform.getError('user_type')}}
    </div>
    <!-- <input type="hidden" formControlName="user_id" [(ngModel)]="userLoginId"> -->
    <div class="btn-area">
      <button class="btn-theme" id="btn-user-type" type="submit"  [disabled]="!userTypeform.isValid" >{{ 'LOGIN.LBL_SAVE' | translate }} </button>
    </div>
  </form>
</p-dialog>
<!-- <p-dialog class="add-notes-popup1" [(visible)]="httpErrorMsgDisplay" showEffect="fade" [modal]="true">
  <p-header>
    {{ 'COMMON.LBL_ERROR_MSG' | translate }}
  </p-header>
  <p>{{httpErrorMsg}}</p>
  <p-footer>
    <button type="button" class="btn-theme" icon="fa-check" (click)="redirectLinkIfError(httpErrorCode)">{{ 'COMMON.OK_LABEL' | translate }}</button>
  </p-footer>
</p-dialog> -->
<!--Loader -->
<div class="my-container">
  <ngx-loading [show]="loading"></ngx-loading>
</div>
