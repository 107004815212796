import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Constants} from './constant/constants';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GeneralFunction } from './common/general-function';

@Injectable()
export class AuthGuard implements CanActivate {
    islogin:boolean = false;
    userType : any;
	userRole : any;
    userLoginDetails:any;
    constructor(public router: Router){}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if(localStorage.getItem(Constants.SESSION_NAME) != null){
            var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
            this.userLoginDetails = JSON.parse(sessionData);
	        this.userType = this.userLoginDetails.type;
			this.userRole = this.userLoginDetails.role;
    	    this.islogin = (!GeneralFunction.checkForNullUndefinedBlank(this.userType)) ? true :false;
        	if(!GeneralFunction.checkForNullUndefinedBlank(next)){
        		if(!GeneralFunction.checkForNullUndefinedBlank(next.data)){
        			if(!GeneralFunction.checkForNullUndefinedBlank(next.data.expectedType)){
    					let typeArray = next.data.expectedType;
						let roleArray = next.data.expectedRole;
    					if(typeArray.includes(this.userType)){
							if (typeArray.includes(Constants.USER_TYPE.SUPPLIER_USER) || 
                                typeArray.includes(Constants.USER_TYPE.PIPELINE_USER)) {
								if(roleArray.includes(this.userRole)) {
								} else if (roleArray.length === 0) {
								} else {
                                    if(typeArray.includes(Constants.USER_TYPE.SUPPLIER_USER)){
                                        this.router.navigateByUrl(Constants.URLS['SUPPLIER_ORDERS']);    
                                    }else if(typeArray.includes(Constants.USER_TYPE.PIPELINE_USER)){
                                        this.router.navigateByUrl(Constants.URLS['ORDERS']);    
                                    }
								}
							}
        				} else {
							if(this.userType == Constants.USER_TYPE.SUPPLIER_USER){
								this.router.navigateByUrl(Constants.URLS['SUPPLIER_ORDERS']);    
							} else if (this.userType == Constants.USER_TYPE.PIPELINE_USER){
								this.router.navigateByUrl(Constants.URLS['ORDERS']);    
							} else if (this.userType == Constants.USER_TYPE.CUSTOMER_USER){
								this.router.navigateByUrl(Constants.URLS['CUSTOMER_ORDERS']);    
							} else {
								this.router.navigate([''], { queryParams: { returnUrl: state.url }});
							}
        				}
    	    		}
        		}
    		}
		    return this.islogin;
        } else {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}
