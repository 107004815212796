import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderConstant } from '../../constant/header'
import { Constants } from 'src/app/constant/constants';
import { Urls } from 'src/app/constant/urls';

@Injectable({
  providedIn: 'root'
})
export class ApiCallingService {
  httpHeaders: any;
  token: any;
  acceptLanguage: any;
  constructor(
    public http: HttpClient,
    private router: Router
  ) {}

  getHeader() {
    const httpHeaders = new HttpHeaders(HeaderConstant.simpleHeader);
    httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    return httpHeaders;
  }

  getAuthHeader() {
    const httpHeaders = new HttpHeaders(HeaderConstant.authHeader);
    return httpHeaders;
  }
  getAuthHeaderWithMultipart() {
    const token = localStorage.getItem(Constants.Authorization);
    const httpHeaders = new HttpHeaders(HeaderConstant.authHeaderWithMultipart);
    return httpHeaders;
  }

  callLoginAPI(body: any, type: string, url: string): Observable<any> {
    const headers: HttpHeaders = this.getHeader();
    const options = {
      headers: headers,
      observe: 'response' as 'response',
      credentials: 'same-origin'
    };
    return this.http.post(url, body, options);    
  }

  callAPI(body: any, type: string, url: string): Observable<any> {
    const headers: HttpHeaders = this.getHeader();
    const options = {
      headers: headers,
      observe: 'response' as 'response',
      withCredentials: true
    };
    if (type === Constants.requestType.POST) {
      return this.http.post(url, body, options);
    } else if (type === Constants.requestType.PUT) {
      return this.http.put(url, body, options);
    } else {
      return this.http.get(url, options);
    }
  }

  callUserTypeChangeAPI(body: any, type: string, url: string): Observable<any> {
    // const headers: HttpHeaders = this.getHeader();
    const options = {
      // headers: headers,
      observe: 'response' as 'response',
      credentials: 'same-origin'
    };
    if (type === Constants.requestType.POST) {
      return this.http.post(url, body, options);
    } 
  }

  callAuthAPI(body: any, type: string, url: string): Observable<any> {
    if (type === Constants.requestType.POST) {
      return this.http.post(url, body);
    } else if (type === Constants.requestType.PUT) {
      return this.http.put(url, body);
    } else if (type === Constants.requestType.GET) {
      return this.http.get(url);
    } else if (type === Constants.requestType.DELETE) {
      return this.http.delete(url);
    }
  }

  getHeaderFormData() {
        let httpHeaders = new HttpHeaders();
        httpHeaders.append('Content-Type', 'multipart/form-data');
        return httpHeaders;
    }

  callAuthAPIWithMultipart(body: any, url: string): Observable<any>{
    let headers: HttpHeaders = this.getHeaderFormData();
    return this.http.post(url, body, {reportProgress: true,observe: 'events',headers: headers});
  }

  callDownloadFile(url: string): Observable<any>{
    return this.http.get(url, {responseType: 'blob'});
  }

  
}
