// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Constants } from "src/app/constant/constants";
export const environment = {
  production: Constants.BOOLEAN_FALSE,
  // apiEndpoint: 'https://pipelinemsapi.pipelinenordic.se/',
  // apiEndpoint: 'http://devpipelinemsapi.56-north.com/',
  apiEndpoint: ' http://devpipelineapi.56-north.com/',
  // apiEndpoint: ' http://192.168.0.104:7010/',

  // apiEndpoint: 'https://devpipelinemsapi.56-north.com/',
  // http://coupons.pipelinenordic.se/
  //apiEndpoint: 'http://0f73eca870f5.ngrok.io/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
