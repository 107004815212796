import { environment } from '../../environments/environment';
import {Constants} from '../constant/constants';
export class ApiEndpointConstant {
    public static forgot = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/forgotPwd';
    public static checkForgotToken = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/valResetPwdReqData/';
    public static resetPassword = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/resetPwd';
    public static adminResetPassword = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/';
    public static login = environment.apiEndpoint+Constants.MIDDLEWARE_SERVICE_URL+'login';
    public static logout = environment.apiEndpoint+Constants.MIDDLEWARE_SERVICE_URL+'logoff';
    public static changePassword = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/changePwd';
    public static setRole = environment.apiEndpoint+Constants.MIDDLEWARE_SERVICE_URL+'setUserType/';
    public static getAddPipelineUser = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/pipeline/add';
    public static pipelineUserList = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/pipeline/list';
    public static checkUniqueUserName = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/chkUsername/';
    public static checkUniqueEmail = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/chkEmail/';
    public static addPipelineUser = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/pipeline/add';
    public static editPipelineUser = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/pipeline/';
    public static changeUserStatus = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/';
    public static organization = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'organization/';
    public static contactPerson = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/contactPerson/';
    public static groupList = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/list';
    public static BulkInvoiceOrderList = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/orderList';
    public static GenerateInvoice = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/getRfqOrders';

    public static supplierGroupEdit(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/'+id+'/edit';
    }
    public static supplierGroupAdd = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/add';
    public static checkSupplierGroupCode(code: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/chkGroupCode/'+code;
    }
    public static checkSupplierGroupCodeEdit(id: any, code: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/'+id+'/chkGroupCode/'+code;
    }
    public static supplierGroupActivateDeactivate(id: any, status: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/'+id+'/status/'+status;
    }
    public static supplierGroupDelete(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierGroup/'+id+'/delete';
    }
    public static paperList = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/list';
    public static paperEdit(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/'+id+'/edit';
    }
    public static checkPaperCode(code: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/chkPaperCode/'+code;
    }
    public static checkPaperCodeEdit(id: any, code: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/'+id+'/chkPaperCode/'+code;
    }
    public static paperAdd = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/add';
    public static paperActivateDeactivate(id: any, status: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/'+id+'/status/'+status;
    }
    public static paperDelete(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'printPaper/'+id+'/delete';
    }
    public static addContactPerson = environment.apiEndpoint+Constants.ENTITY_SERVICE_URL+'user/contactPerson/add';
    public static checkAddresTypeAdd(orgId: any, addressType:any) {
        return ApiEndpointConstant.organization+orgId+'/chkAddressType/'+addressType;
    }
    public static checkAddresTypeEdit(orgId: any, addressId:any,addressType:any) {
        return ApiEndpointConstant.organization+orgId+'/address/'+addressId+'/chkAddressType/'+addressType;
    }
    public static editRfqOrderFieldData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/editRfqOrderFieldData';
    }
    public static acceptRfq(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/acceptRfq';
    }
    public static rejectRfq(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/rejectRfq';
    }
    public static rfqOrderList(type: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+type+'/list';
    }
    public static rfqOrderMarginAddEdit(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/editRfqOrderProductMarginData';
    }
    public static expireToPublishRfqOrder(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/convertExpiredToPublished';
    }
    public static finalizeRfqOrder(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/finalize';
    }
    public static expandGetDataAPI(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id;
    }
    public static supplierBid =  environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'productSupplierBid/';
    public static acceptBid(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'productSupplierBid/'+id+'/accept';
    }
    public static addSupplierBid(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'productSupplierBid/product/'+id+'/add';
    }
    public static declineBid(id: any, reason: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'productSupplierBid/'+id+'/decline/reason/'+reason;
    }
    public static editSupplierBid(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'productSupplierBid/'+id+'/edit';
    }
    public static notes =  environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'note/';
    public static notesList(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'note/'+id+'/note/list';
    }
    public static notesAdd(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'note/rfqOrder/'+id+'/add';
    }
    public static notesEdit(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'note/'+id+'/edit';
    }
    public static notesDelete(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'note/'+id+'/delete';
    }
    public static cancelOrder(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/cancelRfqOrder';
    }
    public static supplierMsg =  environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierMsg/rfqOrder/';
    public static supplierMsgList(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierMsg/rfqOrder/'+id+'/list';
    }
    public static supplierMsgConversationList(id: any, supplierId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierMsg/rfqOrder/'+id+'/supplier/'+supplierId+'/conversationList';
    }
    public static pipelineMsgClick(id: any, supplierId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierMsg/rfqOrder/'+id+'/supplier/'+supplierId+'/pipelineMsgsClick';
    }
    public static supplierMsgClick(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierMsg/rfqOrder/'+id+'/supplierMsgsClick';
    }
    public static supplierMsgAdd(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierMsg/rfqOrder/'+id+'/add';
    }
    public static getAddRfqOrderDtls = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/add';
    
    public static getContactPersonDtlsByOrgId(customerId:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'common/contactPerson/organization/'+customerId;
    }

    public static getAddressDtlsByOrgID(orgId:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'common/organizationAddress/organization/'+orgId+'/type/DELIVERY';
    }

    public static addEditRfqOrder(){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/add';
    }

    public static getRfqOrderSearch(type:any, searchStr:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/type/'+type+'/search/'+searchStr;
    }

    public static getEditRfqOrderDetails(id:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/edit';
    }
    public static searchSupplier = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'common/organization/type/SUPPLIER/search/';
    public static searchCustomer = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'common/organization/type/CUSTOMER/search/';
    public static notification = environment.apiEndpoint+Constants.GENERAL_SERVICE_URL+'systemNotification/';

    public static getEmailRedirectionDtls(id:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+id+'/getRfqOrderSummaryResponse';
    }
    public static uploadFileURL = environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/upload';
    public static clearOnUploadFileURL = environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/clearOn/upload';

    public static getFileListByOrderRFQId(id:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/list';
    }

    public static getFileDtlsById(id:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/rfqOrderProductFileDtls';
    }

    public static deleteFile(id:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/delete';
    }

    public static changeFileStatus(id:any, status:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/status/'+status;
    }

    public static downloadOriginalFile(fileName:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/download/connect/upload/'+fileName;
    }

    public static downloadPrintFile(fileName:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/download/pitstopOutput/'+fileName;
    }

    public static fileShare = environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/share/add';

    public static validateFileShareToken(token:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/share/validate/token/'+token;
    }    

    public static downloadSharedFile(token:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/share/download/token/'+token;
    }

    public static emailFileShare(token:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/share/email/token/'+token;
    }

    public static pitstopFileShare(token:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/share/pitstopPreview/token/'+token;
    }

    public static pitstopPreviewFile(fileId:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/pitstop/preview/'+fileId;
    }

    public static changeSupplierVisibilty(id:any, status:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/supplierVisibilityStatus/status/'+status;
    }
    public static reportList = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/report';
    public static invoiceReportList = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/multipleInvoice/list';
    public static reportDownload(type) {
        return `${environment.apiEndpoint}${Constants.RFQ_ORDER_SERVICE_URL}rfqOrder/reportExport/${type}`;
    };
    public static reportDownloadInvoice(type) {
        return `${environment.apiEndpoint}${Constants.RFQ_ORDER_SERVICE_URL}customerInvoice/reportExport/${type}`;
    };
    public static reportDownloadXLSX = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/reportExport/EXCEL';
    public static reportFileDownload = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'file/download/connect/report/';
    public static incidentReportList(id:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'incidentReport/rfqOrder/'+id+'/list';
    }
    public static incidentReportDelete(id:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'incidentReport/'+id+'/delete';
    }
    public static incidentReportEdit(id: any, orderId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'incidentReport/rfqOrder/'+orderId+'/'+id+'/edit';
    }
    public static incidentReportAdd(orderId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'incidentReport/rfqOrder/'+orderId+'/add';
    }
    public static getPipelineUserIncidentUsers(orderId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+orderId+'/pipelineUsers';
    }
    public static getContactPersonByOrganizationId(organizationId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'common/contactPerson/organization/'+organizationId;
    }
    public static incidentReportAddData(orderId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'incidentReport/rfqOrder/'+orderId+'/add';
    }
    public static incidentReportUpdate(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'incidentReport/'+id+'/edit';
    }
    public static loadCustomerProposalData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerProposal/rfqOrderId/'+id;
    }
    public static saveCustomerProposalData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerProposal/save/rfqOrderId/'+id;
    }
    public static downloadCustomerProposalData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerProposal/download/rfqOrderId/'+id;
    }
    public static mailCustomerProposalData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerProposal/mail/rfqOrderId/'+id;
    }
    public static printCustomerProposalData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerProposal/print/rfqOrderId/'+id;
    }
    public static loadOriginalCustomerProposalData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerProposal/reloadOldCustomerProposal/rfqOrderId/'+id;
    }
    public static showActionHistoryAPI(type: any, id: any) {
        return environment.apiEndpoint+Constants.GENERAL_SERVICE_URL+'interimSave/actionHistory/'+type+'/refId1/'+id
    }
    public static bulkShowActionHistoryAPI(type: any) {
        return environment.apiEndpoint+Constants.GENERAL_SERVICE_URL+'interimSave/actionHistory/'+type
    }
    public static loadCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/rfqOrderId/'+id;
    }
    public static saveCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/save/rfqOrderId/'+id;
    }
    public static saveCustomerMultipleInvoiceData = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/saveMultipleCustomerInvoice';
    public static downloadCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/download/rfqOrderId/'+id;
    }
    public static downloadCustomerMultipleInvoiceData = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/downloadMultipleCustomerInvoice';
    public static generateCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/generateInvoice/rfqOrderId/'+id;
    }
    public static generateCustomerMultipleInvoiceData = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/generateMultipleInvoice';
    public static mailCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/mail/rfqOrderId/'+id;
    }
    public static mailCustomerMultipleInvoiceData = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/mailMultipleCustomerInvoice';
    public static printCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/print/rfqOrderId/'+id;
    }
    public static printCustomerMultipleInvoiceData = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/printMultipleCustomerInvoice';
    public static loadOriginalCustomerInvoiceData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/reloadOldCustomerInvoice/rfqOrderId/'+id;
    }
    public static loadOriginalBulkCustomerInvoiceData = environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'customerInvoice/reloadMultipleOldCustomerInvoice';
    public static loadSupplierProducts(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierConfirmation/supplierProductList/rfqOrderId/'+id;
    }
    public static loadSupplierConfirmationData(id: any, supplierId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierConfirmation/productDtls/rfqOrderProductId/'+id+'/supplierOrgId/'+supplierId;
    }
    public static saveSupplierConfirmationData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierConfirmation/save/rfqOrderProductId/'+id;
    }
    public static downloadSupplierConfirmationData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierConfirmation/download/rfqOrderProductId/'+id;
    }
    public static printSupplierConfirmationData(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierConfirmation/print/rfqOrderProductId/'+id;
    }
    public static loadOriginalSupplierConfirmationData(id: any, supplierId: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'supplierConfirmation/reloadOldSupplierConfirmation/rfqOrderProductId/'+id+'/supplierOrgId/'+supplierId;
    }
    public static downloadDeliveryAddress(id: any) {
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'deliveryAddress/download/rfqOrderId/'+id;
    }

    public static getReferenceOrderNumber(orderNo:any){
        return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/getRfqOrderByReferenceOrderNumber/'+orderNo;
    }

    public static getClearOnFileDtlsById(id:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/clearOn/rfqOrderProductFileDtls';
    }

    public static changeClearOnFileStatus(id:any, status:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/status/clearOn/'+status;
    }

    public static deleteClearOnFile(id:any){
        return environment.apiEndpoint+Constants.FILE_SERVICE_URL+'file/rfqOrder/'+id+'/clearOn/delete';
    }

    public static checkUniqueReferenceNumber(referenceNumber:any, customerOrganizationId:any, rfqOrderId?:any){
        if(rfqOrderId){
            return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/'+rfqOrderId+'/chkReferenceOrderNumber/organization/'+customerOrganizationId+'/referenceOrderNumber/'+referenceNumber;  
        }else{
            return environment.apiEndpoint+Constants.RFQ_ORDER_SERVICE_URL+'rfqOrder/chkReferenceOrderNumber/organization/'+customerOrganizationId+'/referenceOrderNumber/'+referenceNumber;    
        }

        
    }
}