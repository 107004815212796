<div id="wrap" >
    <div id="content">
        <p-toast position="top-right"></p-toast>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                	<div id="legend" style="position: relative; top: 0px; left: 152.5px;" *ngIf="successFlag">
                		<div class="logo-area" style="text-align: center;width:100%;margin-bottom:1.5em;">
                            <img src="assets/img/pipeline-logo-pdf.png" style="height: 61px; margin-left: auto;margin-right: auto;max-width: 100%;"/>
                        </div>
			            <div>
			                <div class="legend-section" style="text-align: center;margin-bottom: 1.2em;">
			                    <p class="legend-header">Welcome to CloudProof</p>
			                    <p style="width: 90%;margin: 8px auto;">You have received this link because someone wants you to review and comment on a document. Use the controls and tools described below to do so.</p>
			                    <br>
			                </div>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-comment icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Page comment</p>
			                Brings up the page comment dialog. If there are previous comments on the current page the dialog will open automatically.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-info icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Information</p>
			                Display technical data on the current PDF.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-fast-backward icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">First page</p>
			                Go to page one.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-fast-backward icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Previous page</p>
			                Go to the previous page.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol heighlightedPageIndicator">
			                    <!-- <img src="http://cloudproof.se/images/page_indicator_legend.png"> -->
			                    	<span class="heighlightedPage">1</span>
			                    	<span>/ 24</span>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Page indicator</p>
			                Displays the current page number. Can also be used for fast page navigation by entering a page number and pressing enter.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-forward icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Next page</p>
			                Go to the next page.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-fast-forward icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Last page</p>
			                Go to the last page.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-search-plus icon-theme"></i> <i class="fa fa-search-minus icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Zoom level</p>
			                Toggle zoom level.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-toggle-off icon-theme"></i><span id="preview-print-simulation-label" class="preview-print-simulation-label">Soft proof</span> 
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Soft proofing (Not always available)</p>
			                Toggle soft proofing on/off. When active and if your display is calibratet for use in graphic design, colors will be very close to the printed result.
			                <p></p>
			            </div>
			            <div class="legend-section">
			                <p class="legend-symbol">
			                    <i class="fa fa-check-square-o icon-theme"></i>
			                </p>
			                <p class="legend-comment">
			                </p><p class="legend-comment-header">Toggle preflight information (Not always available)</p>
			                When active, objects containing errors and warnings found during automated document inspection (preflight) are highlighted on each page. When clicked, a brief description is shown. Additionally, the finished page size, according to PDF information, is marked.
			                <p></p>
			            </div>
			            <div class="btn-area">
			                <button class="btn-theme" (click)="infoBtnClicked()">Continue</button>
			            </div>
        			</div>
        			<div id="legend" style="position: fixed;top: 50%;left: 50%;text-align: center;transform: translate(-50%, -50%);" class="login-detail request-link-msgs" *ngIf="expiryTokenFlag">
        				<div class="logo-area">
                            <img src="assets/img/pipeline-logo-pdf.png" style="height: 61px; margin-left: auto;margin-right: auto;max-width: 100%;"/>
                        </div>
                        <form id="frmMsg" novalidate="novalidate">
                            <div class="request-msg">
                                <div class="request-icons">
                                    <img src="assets/img/expired.png">
                                    </div>
                                <h1>Expired Request..!</h1>
                                <br>
                                <p>Request link has expired. Please contact your system administrator</p>
                            </div>
                            <div class="clearfix"></div>
                            <div class="separator">
                            </div>
                        </form>
                    </div>
                    <div id="legend" style="position: fixed;top: 50%;left: 50%;text-align: center;transform: translate(-50%, -50%);" class="login-detail request-link-msgs" *ngIf="invalidTokenFlag">
                    	<div class="logo-area">
                            <img src="assets/img/pipeline-logo-pdf.png" style="height: 61px; margin-left: auto;margin-right: auto;max-width: 100%;"/>
                        </div>
                        <form id="frmMsg" novalidate="novalidate">
                            <div class="request-msg">
                                <div class="request-icons">
                                    <img src="assets/img/invalid.png">
                                </div>
                                <h1>Invalid Request</h1>
                                <br>
                                <p>Request token is invalid, Please request with valid token.</p>
                            </div>
                            <div class="clearfix"></div>
                            <div class="separator"></div>
                        </form>
                    </div>
        		</div>
        	</div>
        </div>
    </div>
</div>

<style type="text/css">
	#close-legend {
	    cursor: pointer;
	    position: absolute;
	    right: 50px;
	    color: #ffffff;
	    padding: 4px;
	}
	#legend {
	    position: absolute;
	    top: 0;
	    left: 0;
	    padding: 48px;
	    width: 900px;
	    background-color: #ffffff;
	    color: #5a5a5a;
	    opacity: 0.98;
	}
	.legend-header {
	    font-size: 24px;
	    margin-bottom: 8px;
	    color: #222222;
	}
	.legend-comment-header {
	    font-size: 16px;
	    margin-bottom: 4px;
	    color: #222222;
	}
	#footer{
		display: none
	}
</style>

<script type="text/javascript">
	// document.getElementById("footer").style.display='none';
	//document.getElementById("footer").style.visibility = "hidden";

</script>