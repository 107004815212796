import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  headerReload = false;
  getHeaderReload: Subject<boolean> = new Subject<any>();
  itemValue = new Subject<string>();
  constructor() {}
  setHeaderReload(flagvalue: any) {
    this.headerReload = flagvalue;
    this.getHeaderReload.next(this.headerReload);
  }
}
