import { Component, OnInit,Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {ValidationManager} from "ng2-validation-manager";
import {Validators,FormControl,FormGroup,FormBuilder,FormArray,FormArrayName} from '@angular/forms';
import {HttpClient, HttpRequest, HttpEvent,HttpHeaders} from '@angular/common/http';
import 'rxjs/Rx';
import { Constants} from '../constant/constants';
// import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
@Injectable()
export class GeneralFunction {
    cityData: any;
    constructor(private translate: TranslateService, private route: ActivatedRoute,
    public router: Router) {

    }
    public static getSelectionData(data1: any, data2: any, data3 = '') {
        var selectData = [];
        selectData.push({
            label: $('#default_select_text').val(),
            value: Constants.DEFAULT_SELECT_VALUE
        });
        if (!GeneralFunction.checkForNullUndefinedBlank(data1)) {
          for (var i = 0; i < data1.length; i++) {
              for (var j = 0; j < data2.length; j++) {
                  if (i == j) {
                      if (!GeneralFunction.checkForNullUndefinedBlank(data3)) {
                          selectData.push({
                              label: data1[j],
                              value: data2[j],
                              code: data3[j]
                          });
                      } else {
                          selectData.push({
                              label: data1[j],
                              value: data2[j]
                          });
                      }
                  }
              }
          }
        }
        return selectData;
    }

    public static setUserLocale() {
        let locale = localStorage.getItem(Constants.sessionLocale);
        if (localStorage.getItem(Constants.sessionLocale)) {
            return locale;
        } else {
            return Constants.EN;
        }
    }

    public static goBack(){
        return window.history.back();
    }

    public static getNewSelectionData(data1: any, data2: any, data3 = '') {
        var selectData = [];
        // selectData.push({
        //     label: $('#default_select_text').val(),
        //     value: Constants.DEFAULT_SELECT_VALUE
        // });
        if (!GeneralFunction.checkForNullUndefinedBlank(data1)) {
          for (var i = 0; i < data1.length; i++) {
              for (var j = 0; j < data2.length; j++) {
                  if (i == j) {
                      if (!GeneralFunction.checkForNullUndefinedBlank(data3)) {
                          selectData.push({
                              label: data1[j],
                              value: data2[j],
                              code: data3[j]
                          });
                      } else {
                          selectData.push({
                              label: data1[j],
                              value: data2[j]
                          });
                      }
                  }
              }
          }
        }
        return selectData;
    }
    public static getAddressList(addressList:any) {
        var deliverOpt = [];
        deliverOpt.push({
            label: $('#default_select_text').val(),
            value: Constants.DEFAULT_SELECT_VALUE
        });
        for (var i = 0; i < addressList.length; i++) {
            let address = addressList[i].addressLine1;
            if (!GeneralFunction.checkForNullUndefinedBlank(addressList[i].addressLine2)) {
                address += Constants.COMMA_DELIMITER + '  ' + addressList[i].addressLine2;
            }
            if (!GeneralFunction.checkForNullUndefinedBlank(addressList[i].addressLine3)) {
                address += Constants.COMMA_DELIMITER + '  ' + addressList[i].addressLine3;
            }
            //address+=  addressList[i].addressLine3 != '' ? Constants.COMMA_DELIMITER +addressList[i].addressLine3 : '';
            address += addressList[i].cityName != '' ? Constants.COMMA_DELIMITER + '  ' + addressList[i].cityName : '';
            address += addressList[i].countryName != '' ? Constants.COMMA_DELIMITER + '  ' + addressList[i].countryName : '';
            address += addressList[i].zip != '' ? Constants.COMMA_DELIMITER + '  ' + addressList[i].zip : '';
            deliverOpt.push({
                label: address,
                value: addressList[i].orgAddressId
            });
        }
        return deliverOpt;
    }

    public static getDeliveryAddressList(addressList:any) {
        var deliverOpt = [];
        deliverOpt.push({
            label: $('#default_select_text').val(),
            value: Constants.DEFAULT_SELECT_VALUE
        });
        for (var i = 0; i < addressList?.length; i++) {
            deliverOpt.push({
                label: addressList[i].addressStr,
                value: addressList[i].id
            });
        }
        return deliverOpt;
    }

    // to display console msgs or not
    public static SHOW_CONSOLE = false;
    public static showConsole(msg: string) {
        if (this.SHOW_CONSOLE) {
        }
    }
    public static makeFileDescription(fileData:any, index:any) {
        var description = "<b>Type: </b>" + fileData[index].storeFileName.split('.').pop() + "</br>";
        description += "<b>Size: </b>" + '100kb' + "</br>";
        description += "<b>Status: </b>" + fileData[index].supplierVisibilityStatusStr;
        return description;
    }
    /**
     * CHECK FOR NULL UNDEFINED BLANK VALUE
     * @param value
     * @returns {boolean}
     */
    public static checkForNullUndefinedBlank(value: any) {
        if (value == '' || value == null || value == 'null' || value == undefined) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * CHECK FOR NULL UNDEFINED BLANK VALUE
     * @param value
     * @returns {boolean}
     */
     public static checkForNullUndefinedZeroBlank(value: any) {
        if (value == '' || value == null || value == 'null' || value == undefined || value == 0) {
            return true;
        } else {
            return false;
        }
    }

    public static trimField(value:any) {
        return value?.trim();
    }
    public static removeLastcomma(value:any) {
        var lastChar = value.slice(-1);
        if (lastChar == ',') {
            value = value.slice(0, -1);
        }
        return value;
    }
    public static set_session_data(data:any) {
        let localData: any = {
            'fname': data.firstName,
            'email':data.email,
            'lname': data.lastName,
            'role': data.role,
            'i18nCode': data.localeI18nCode,
            'localeUtf8Code':data.localeUtf8Code,
            'localeId': data.localeId,
            'userLoginId': data.id,
            'organizationId':data.organizationId,
            'organizationName':data.organizationName,
            'type':data.type,
            'username':data.username,
            'organizationRegistrationNumber': data.organizationRegistrationNumber,
            // 'subrole': data.subrole,
            // 'sess_token': data.sessToken,
            'defaultCurrencyLocale':(data.defaultCurrencyLocale) ? data.defaultCurrencyLocale :Constants.DEFAULT_CURRENCY_LOCALE
        };
        if (data.type === Constants.USER_TYPE.SUPPLIER_USER) {
            localData.defaultCurrencyCode = data.defaultCurrencyCode;
            localData.defaultCurrencyId = data.defaultCurrencyId;
        }
        localStorage.setItem(Constants.sessionLocale, data.localeI18nCode)
        return localData;
    }

    public static underScoreToSpaced(str:any) {
        str = str.toLowerCase()
            .replace(/[-_]+/g, ' ');
        var str = str.charAt(0).toUpperCase() + str.substr(1);
        return str;
    }

    // functiona for generating paper details
    public static getPaperDetails(paper: any, role :any, type: any = '') {
        let paper_details = '';
        if (paper?.length > 0) {
            for (var j = 0; j < paper?.length; j++) {
                if (role == "CUSTOMER_USER") {
                    if (paper[j].pageTypeCustomerStr) {
                        if (type == 'clearon') {
                            paper_details += "<div class='product-dtl-section'>{"+paper[j].pageTypeCustomer+"}</div>";
                        } else {
                            paper_details += "<div class='product-dtl-section'>"+ paper[j].pageTypeCustomerStr+"</div>";
                        }
                    } else {
                        paper_details += "";
                    }
                    // paper_details += "<div class='confirm-dtls'>";
                    if (paper[j].printPaperNameCustomer) {
                        paper_details += "<div class='confirm-dtls'><label class='lbl-title'>{PAPER_NAME_LBL}</label><label class='lbl-value'>" + paper[j].printPaperNameCustomer + " (" + paper[j].paperWeightCustomer + ")</label></div>";
                    } else {
                        paper_details += "";
                    }
                    // paper_details += "<div class='confirm-dtls'>";
                    if (paper[j].pageCountCustomer) {
                        paper_details += "<div class='confirm-dtls'><label class='lbl-title'>{PAGE_COUNT_LBL}</label><label class='lbl-value'>" + paper[j].pageCountCustomer + "</label></div>";
                    } else {
                        paper_details += "";
                    }
                    // paper_details += "<div class='confirm-dtls'>";
                    if (paper[j].printInkCustomer) {
                        paper_details += "<div class='confirm-dtls'><label class='lbl-title'>{PAGE_INK_LBL}</label><label class='lbl-value'>" + paper[j].printInkCustomer + "</label></div>";
                    } else {
                        paper_details += "";
                    }
                } else {
                    if (paper[j].pageTypeSupplierStr) {
                        paper_details += "<div class='product-dtl-section'>"+paper[j].pageTypeSupplierStr+"</div>";
                    } else {
                        paper_details += "";
                    }
                    // paper_details += "<div class='confirm-dtls'>";
                    if (paper[j].printPaperNameSupplier) {
                        paper_details += "<div class='confirm-dtls'><label class='lbl-title'>{PAPER_NAME_LBL}</label><label class='lbl-value'>" + paper[j].printPaperNameSupplier + " (" + paper[j].paperWeightSupplier + ")</label></div>";
                    } else {
                        paper_details += "";
                    }
                    // paper_details += "<div class='confirm-dtls'>";
                    if (paper[j].pageCountSupplier) {
                        paper_details += "<div class='confirm-dtls'><label class='lbl-title'>{PAGE_COUNT_LBL}</label><label class='lbl-value'>" + paper[j].pageCountSupplier + "</label></div>";
                    } else {
                        paper_details += "";
                    }
                    // paper_details += "<div class='confirm-dtls'>";
                    if (paper[j].printInkSupplier) {
                        paper_details += "<div class='confirm-dtls'><label class='lbl-title'>{PAGE_INK_LBL}</label><label class='lbl-value'>" + paper[j].printInkSupplier + "</label></div>";
                    } else {
                        paper_details += "";
                    }
                }
            }
        }
        return paper_details;
    }

    public static getCustomerPaperDetails(paper:any) {
        let paper_details = '';
        paper_details += "<b>{COVER_LBL}</b></br>";
        if(!this.checkForNullUndefinedBlank(paper.supplierPaperIds)){
            for (var j = 0; j < paper.supplierPaperIds.length; j++) {
                if (paper.supplierPaperType[j] == "Cover") {
                    paper_details += "<b>{PAPER_NAME_LBL}</b>: " + paper.customerPaperName[j] + " (" + paper.customerPaperWeight[j] + ")</br>";
                    paper_details += "<b>{PAGE_COUNT_LBL}</b>: " + paper.customerPageCount[j] + "</br>";
                    if (!this.checkForNullUndefinedBlank(paper.customerPrintInk[j])) {
                        paper_details += "<b>{PAGE_INK_LBL}</b>: " + paper.customerPrintInk[j] + "</br>" + "</br>";
                    } else {
                        paper_details += "<b>{PAGE_INK_LBL}</b>: " + paper.customerPrintInk[j] + "</br>" + "</br>";
                    }
                }
                if (paper.supplierPaperType[j] == "Content") {
                    paper_details += "<b>{CONTENT_LBL}</b></br>";
                    paper_details += "<b>{PAPER_NAME_LBL}</b>: " + paper.customerPaperName[j] + " (" + paper.customerPaperWeight[j] + ")</br>"
                    paper_details += "<b>{PAGE_COUNT_LBL}</b>: " + paper.customerPageCount[j] + "</br>"
                    //paper_details += "<b>Page Ink</b>: " +paper.supplierPrintInk[j] + "</br>"
                    if (!this.checkForNullUndefinedBlank(paper.customerPrintInk[j])) {
                        paper_details += "<b>{PAGE_INK_LBL}</b>: " + paper.customerPrintInk[j] + "</br>" + "</br>";
                    } else {
                        paper_details += "<b>{PAGE_INK_LBL}</b>: " + paper.customerPrintInk[j] + "</br>" + "</br>";
                    }
                }
            }
        }
        return paper_details;
    }

    public static getCoverContentDetailsFromArray(paper:any) {
        // let customerCoverDetails = [];
        // let customerContentDetails = [];
        // let supplierCoverDetail = [];
        // let supplierContentDetails = [];
        // let supplierTotalWeight: any = 0;
        // let customerTotalWeight: any = 0;

        let customerPaperDetails:any = {
          'totalWeight':0,
          'paperDetails':[]
        }
        let supplierPaperDetails:any = {
          'totalWeight':0,
          'paperDetails':[]
        }

        let coverContentDetails:any = {
            'customerPaperDetails': {},
            'supplierPaperDetails': {}
        }
        let customerTotalWeight:any = 0;
        let supplierTotalWeight:any = 0;

        if(!this.checkForNullUndefinedBlank(paper.customerPaperIds)){
            for (var j = 0; j < paper.customerPaperIds.length; j++) {
                // if (paper.customerPaperType[j] == "Cover") {
                    customerPaperDetails.paperDetails.push({
                        'paper_type':paper.customerPaperType[j],
                        'paper_id': paper.customerPaperIds[j],
                        'paper_name': paper.customerPaperName[j],
                        'paper_weight': paper.customerPaperWeight[j],
                        'page_count': paper.customerPageCount[j],
                        'page_ink': paper.customerPrintInk[j]
                    })

                    customerTotalWeight = parseInt(customerTotalWeight) +
                        parseInt(paper.customerPaperWeight[j]);
                    supplierTotalWeight = parseInt(supplierTotalWeight) +
                        parseInt(paper.supplierPaperWeight[j]);

                    supplierPaperDetails.paperDetails.push({
                        'paper_type':paper.supplierPaperType[j],
                        'paper_id': paper.supplierPaperIds[j],
                        'paper_name': paper.supplierPaperName[j],
                        'paper_weight': paper.supplierPaperWeight[j],
                        'page_count': paper.supplierPageCount[j],
                        'page_ink': paper.supplierPrintInk[j]
                    })
                    // supplierCoverDetail.push({
                    //     'paper_id': paper.supplierPaperIds[j],
                    //     'paper_name': paper.supplierPaperName[j],
                    //     'paper_weight': paper.supplierPaperWeight[j],
                    //     'page_count': paper.supplierPageCount[j],
                    //     'page_ink': paper.supplierPrintInk[j]
                    // })
                // }

                // if (paper.customerPaperType[j] == "Content") {
                //     customerContentDetails.push({
                //         'paper_id': paper.customerPaperIds[j],
                //         'paper_name': paper.customerPaperName[j],
                //         'paper_weight': paper.customerPaperWeight[j],
                //         'page_count': paper.customerPageCount[j],
                //         'page_ink': paper.customerPrintInk[j]
                //     })
                //
                //     supplierContentDetails.push({
                //         'paper_id': paper.supplierPaperIds[j],
                //         'paper_name': paper.supplierPaperName[j],
                //         'paper_weight': paper.supplierPaperWeight[j],
                //         'page_count': paper.supplierPageCount[j],
                //         'page_ink': paper.supplierPrintInk[j]
                //     })
                //     supplierTotalWeight = parseInt(supplierTotalWeight) +
                //         parseInt(paper.supplierPaperWeight[j]);
                //     customerTotalWeight = parseInt(customerTotalWeight) +
                //         parseInt(paper.customerPaperWeight[j]);
                // }
            }
        }
        customerPaperDetails.totalWeight = customerTotalWeight;
        supplierPaperDetails.totalWeight = supplierTotalWeight;
        coverContentDetails.customerPaperDetails = customerPaperDetails;
        coverContentDetails.supplierPaperDetails = supplierPaperDetails;
        return coverContentDetails;
    }

    public static getprintOpts(printOpts:any) {
        let printingOpts = "";
        for (var po = 0; po < printOpts.ids.length; po++) {
            if (po != 0) {
                printingOpts += ", " + printOpts.names[po];
            } else {
                printingOpts += printOpts.names[po];
            }
        }
        return printingOpts;
    }

    public static getBindingOptinos(bindingOpts:any) {
        let bindOpts = '';
        for (var bo = 0; bo < bindingOpts.ids.length; bo++) {
            if (bo != 0) {
                bindOpts += ", " + bindingOpts.names[bo];
            } else {
                bindOpts += bindingOpts.names[bo];
            }
        }
        return bindOpts;
    }

    public static getExtraOptions(extraOpts:any) {
        let extraOptions = '';
        for (var eo = 0; eo < extraOpts.ids.length; eo++) {
            if (eo != 0) {
                extraOptions += ", " + extraOpts.names[eo];
            } else {
                extraOptions += extraOpts.names[eo];
            }
        }
        return extraOptions;
    }


    public static getDropDownData(data:any){
        // console.log('data',data);
        var selectData = [];
        selectData.push({
            label: $('#default_select_text').val(),
            value: Constants.DEFAULT_SELECT_VALUE
        });
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].name,
                        value: data[i].id
                    });
                }
            }
        }
        return selectData;
    }

    public static getDropDownDataForCausedBy(data:any){
        var selectData = [];
        selectData.push({
            label: Constants.PIPLINE_CAUSED_BY_TITLE,
            value: 0
        })
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].organizationName,
                        value: data[i].organizationId,
                        entity: data[i].causedByEntity
                    });
                }
            }
        }
        return selectData;
    }

    public static getStageOptions() {
        var selectData = [
            {
                label: "File",
                value: "FILE"
            },
            {
                label: "Printing",
                value: "PRINTING"
            },
            {
                label: "Postpress",
                value: "POSTPRESS"
            },
            {
                label: "Packaging",
                value: "PACKAGING"
            },
            {
                label: "Delivery",
                value: "DELIVERY"
            }
        ];
        return selectData;
    }
    public static getDueDateRuleOptions() {
        var selectData = [
            {
                label: "Days Net",
                value: "DAYS_NET"
            },
            {
                label: "Next Month First Day",
                value: "NEXT_MONTH_FIRST_DAY"
            }
        ];
        return selectData;
    }

    public static getSeverityOptions() {
        var selectData = [
            {
                label: "Minor",
                value: "MINOR"
            },
            {
                label: "Major",
                value: "MAJOR"
            },
            {
                label: "Severe",
                value: "SEVERE"
            }
        ];
        return selectData;
    }

    public static getOutComeOptions() {
        var selectData = [
            {
                label: "Redo",
                value: "REDO"
            },
            {
                label: "Discount",
                value: "DISCOUNT"
            },
            {
                label: "Redo And Discount",
                value: "REDO_AND_DISCOUNT"
            }
        ];
        return selectData;
    }

    public static getSupplierProductOptions(data: any) {
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].acceptedSupplierOrganizationName+Constants.AUTOSUGGEST_DELIMITER+data[i].rfqOrderProductName,
                        value: data[i].rfqOrderProductId,
                        supplierId: data[i].acceptedSupplierOrganizationId
                    });
                }
            }
        }
        return selectData;
    }

    public static getDropDownDataForOrganization(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].firstName+' '+ data[i].lastName,
                        value: data[i].id
                    });
                }
            }
        }
        return selectData;
    }

    public static getDropDownDataForCausedByContact(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].fullName,
                        value: data[i].id
                    });
                }
            }
        }
        return selectData;
    }


    public static getDropDownDataForPrimengInStringVal(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    
                    selectData.push({
                        label: data[i].name,
                        value: data[i].id.toString()
                    });
                    console.log('TYPE', typeof(data[i].id.toString()));
                }
            }
        }
        return selectData;
    }


    

    public static getDropDownDataForPrimeng(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].name,
                        value: data[i].id
                    });
                }
            }
        }
        return selectData;
    }

    public static getDropDownDataForPrimengPrintPaper(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].name,
                        value: data[i].id,
                        code: data[i].paperCode
                    });
                }
            }
        }
        return selectData;
    }

    public static getSupplierDropDownDataForPrimeng(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                if (!GeneralFunction.checkForNullUndefinedBlank(data[i])) {
                    selectData.push({
                        label: data[i].name +'('+data[i].registrationNumber+')',
                        value: data[i].id
                    });
                }
            }
        }
        // console.log('selectData',selectData);
        return selectData;
    }

    public static getPhoneCode(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                var label = data[i].name + ' (+' + data[i].phoneCode + ')';
                selectData.push({
                    label: label,
                    value: data[i].id
                });
            }
        }
        return selectData;
    }

    public static getAccountSalesProjectManagerData(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                var label = data[i].firstName;
                var lastName = !GeneralFunction.checkForNullUndefinedBlank(data[i].lastName) ? data[i].lastName :'';
                label+=' '+lastName;
                selectData.push({
                    label: label,
                    value: data[i].id
                });
            }
        }
        return selectData;
    }

    public static getCurrencyDropDownData(data:any){
        var selectData = [];
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            for (var i = 0; i < data.length; i++) {
                // var label = data[i].firstName +' '+data[i].lastName;
                selectData.push({
                    label: data[i].code,
                    value: data[i].id
                });
            }
        }
        return selectData;
    }


    public static getJobTitle(data:any){
       var selectData:any = [];
       if(data.length>0){
           for(var i = 0; i <= data.length; i++){
               // selectData.push({'value':data[i].title, 'label':data[i].titleStr});
           }
       }
        return selectData;
    }

    public static getNameById(data:any, searchStr:any){
         for (var i=0; i < data.length; i++) {
            if (data[i].value == searchStr) {
                return data[i].label;
            }
        }
    }

    public static capitalizeStr(str:any)
    {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    }

    public static getValidationError(error:any){
        var validationErrors:any = [];
        if(error?.error?.errors){
            for (var i = 0; i < error.error.errors.length; i++) {
                validationErrors[error.error.errors[i].field] = error.error.errors[i].field +' '+ error.error.errors[i].message;
            }
        }
        return validationErrors;
    }  

    public static redirectNotificationRoute(data: any, type: any, router: any) {
        if (data) {
            if (type === Constants.USER_TYPE.PIPELINE_USER) {
                if (data.currentType === Constants.NOTIFICATION_MODULES.RFQ) {
                    if(data.isRfqOrderFree){
                        router.navigate([Constants.URLS.RFQS], {
                            queryParams: {
                                tab_type: Constants.URLS['FREE_RFQ_TAB'],
                                rfqOrderNo: data.rfqNumber
                            }
                        });
                    }else{
                        router.navigate([Constants.URLS.RFQS], {
                            queryParams: {
                                tab_type: data.currentStatus,
                                rfqOrderNo: data.rfqNumber
                            }
                        });
                    }
                    
                } else {
                    if(data.isRfqOrderFree){
                        router.navigate([Constants.URLS.ORDERS], {
                            queryParams: {
                                tab_type: Constants.URLS['FREE_ORDER_TAB'],
                                rfqOrderNo: data.orderNumber
                            }
                        });
                    }else{
                        router.navigate([Constants.URLS.ORDERS], {
                            queryParams: {
                                tab_type: data.currentStatus,
                                rfqOrderNo: data.orderNumber
                            }
                        });
                    }
                }
            } else if (type === Constants.USER_TYPE.SUPPLIER_USER) {
                if (data.currentType === Constants.NOTIFICATION_MODULES.RFQ) {
                    if (data.currentStatus === Constants.TAB_TYPES.RFQ.PUBLISHED) {
                        router.navigate([Constants.URLS.SUPPLIER_RFQS], {
                            queryParams: {
                                tab_type: data.currentStatus,
                                rfqOrderNo: data.rfqNumber
                            }
                        });
                    }
                } else {
                    if (data.currentStatus != Constants.TAB_TYPES.ORDER.FREE) {
                        router.navigate([Constants.URLS.SUPPLIER_ORDERS], {
                            queryParams: {
                                tab_type: data.currentStatus,
                                rfqOrderNo: data.orderNumber
                            }
                        });
                    }
                }
            } else if (type === Constants.USER_TYPE.CUSTOMER_USER) {
                if (data.currentType === Constants.NOTIFICATION_MODULES.ORDER) {
                    if (data.currentStatus != Constants.TAB_TYPES.ORDER.FREE) {
                        router.navigate([Constants.URLS.SUPPLIER_ORDERS], {
                            queryParams: {
                                tab_type: data.currentStatus,
                                rfqOrderNo: data.orderNumber
                            }
                        });
                    }
                }
            }
        }
    }

    public static getDefaultDtSort(event:any){
        if(event){
            if(!event?.sortField || event?.sortField ==''){
                event.sortField = 'srNo';
                event.sortOrder = '-1';
            }
        }
        return event;
    }

    public static checkCouponRelatedField(customerRegistrationNumber:any, printType:any){
        console.log(customerRegistrationNumber+'=='+Constants.CLEARON +' && '+ printType+'=='+Constants.PRINT_TYPES['GLUED_COUPON_PRINTING']);
        if(!this.checkForNullUndefinedBlank(customerRegistrationNumber) && !this.checkForNullUndefinedBlank(printType)){
            if(customerRegistrationNumber==Constants.CLEARON && (printType==Constants.PRINT_TYPES['GLUED_COUPON_PRINTING'] || printType==Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING'])){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    public static checkPrintTypeField(printType:any){
        if(!this.checkForNullUndefinedBlank(printType)){
            if(printType==Constants.PRINT_TYPES['GLUED_COUPON_PRINTING'] || printType==Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING']){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    public static pageSizeStr(pageSizeWidth:any,pageSizeHeight:any){
        return parseInt(pageSizeWidth)+" "+Constants.PAGE_SIZE_VAL+" x "+parseInt(pageSizeHeight)+" "+Constants.PAGE_SIZE_VAL
    }

    public static pageSize(pageSizeWidth:any,pageSizeHeight:any){
        return (pageSizeWidth+"x"+pageSizeHeight).replace(/\s+/g, '');
    }
}
