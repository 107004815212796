export class ValidationRules {
    public static passwordLength = '4,20';
    public static maxStringLength20 = '20';
    public static maxStringLength250 = '250';
    public static maxStringLength500 = '500';
    public static maxStringLength1000 = '1000';
    public static maxStringLength5000 = '5000';
    public static minForgotTokenLength32 = '32';
    public static maxStringLength50 = '50';
    public static maxStringLength100='100';
    public static maxStringLength4='4';

    public static websiteURLPattern =/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    
    public static loginRules: any = {
        emailUsername: 'required|maxLength:'+ValidationRules.maxStringLength500,
        password: 'required|rangeLength:'+ValidationRules.passwordLength,
    }
    public static forgotPasswordRules: any = {
        email: 'required|email|maxLength:'+ValidationRules.maxStringLength500
    }
    public static changePasswordRules: any = {
        oldPwd: 'required|rangeLength:'+ValidationRules.passwordLength,
        newPwd: 'required|rangeLength:'+ValidationRules.passwordLength,
        confirmNewPwd: 'required|equalTo:newPwd',
    }
    public static resetPasswordRules: any = {
        newPwd: 'required|rangeLength:'+ValidationRules.passwordLength,
        confirmNewPwd: 'required|rangeLength:'+ValidationRules.passwordLength+'|equalTo:newPwd'
    }

    public static setUserRoleRules:any = {
        user_type: 'required',
    }

    public static userProfileRules: any = {
        'first_name': 'required|maxLength:'+ValidationRules.maxStringLength250,
        'last_name': 'maxLength:'+ValidationRules.maxStringLength250,
        'email': 'required|email|maxLength:'+ValidationRules.maxStringLength500,
        'contact_number': 'maxLength:'+ValidationRules.maxStringLength20,
        'country_id': 'required',
        'locale_id': 'required',
    }

    public static pipelineUserRules: any = {
        'email':'required|email|maxLength:'+ValidationRules.maxStringLength500,
        'userName':'required|maxLength:'+ValidationRules.maxStringLength500,
        'firstName':'required|maxLength:'+ValidationRules.maxStringLength500,
        'lastName':'maxLength:'+ValidationRules.maxStringLength500,
        'role':'required',
        'addressLine1':'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2':'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3':'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode':'maxLength:'+ValidationRules.maxStringLength50,
        'city':'maxLength:'+ValidationRules.maxStringLength250,
        'countryId':'required|number',
        'localeId':'required|number',
        'titles':'required',
        'contactDtls':'',
        'contactNumber':'maxLength:'+ValidationRules.maxStringLength50,
        'password':'required|rangeLength:'+ValidationRules.passwordLength
    }

    public static editPipelineUserRules: any = {
        'email':'required|email|maxLength:'+ValidationRules.maxStringLength500,
        'userName':'required|maxLength:'+ValidationRules.maxStringLength500,
        'firstName':'required|maxLength:'+ValidationRules.maxStringLength500,
        'lastName':'maxLength:'+ValidationRules.maxStringLength500,
        'role':'required',
        'addressLine1':'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2':'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3':'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode':'maxLength:'+ValidationRules.maxStringLength50,
        'city':'maxLength:'+ValidationRules.maxStringLength250,
        'countryId':'required|number',
        'localeId':'required|number',
        'titles':'required',
        'contactDtls':'',
        'contactNumber':'maxLength:'+ValidationRules.maxStringLength50
    }

    public static organizationRules: any = {
        // 'organization_id': '',
        'registrationNumber': 'required|maxLength:'+ValidationRules.maxStringLength250,
        'name': 'required|maxLength:'+ValidationRules.maxStringLength500,
        'type': 'required',
        'defaultLocaleId': 'required',
        'defaultProjectManagerUserId': 'number',
        'defaultSalesManagerUserId': 'number',
        'defaultAccountManageruserId': 'number',
        'invoiceType': '',
        'invoiceEmail': 'required|email|maxLength:'+ValidationRules.maxStringLength500,
        'contactEmail': 'email|maxLength:'+ValidationRules.maxStringLength500,
        'website': 'maxLength:'+ValidationRules.maxStringLength500,
        'vatNumber': 'maxLength:'+ValidationRules.maxStringLength250,
        'defaultCurrencyId': 'number',
        'paymentDueDate': 'number',
        'paymentDueDateRule':'',
        'supplierProfiles':''
    }

    public static orgAddressRules:any = {
        'addressType':'required',
        'addressLine1':'required|maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2':'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3':'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode':'required|maxLength:'+ValidationRules.maxStringLength50,
        'city':'maxLength:'+ValidationRules.maxStringLength250,
        'organization':'required',
        'countryId':'required|number'
    }

    public static contactPersonRules:any = {
        'firstName': 'required|maxLength:'+ValidationRules.maxStringLength500,
        'lastName': 'maxLength:'+ValidationRules.maxStringLength500,
        'organizationId': 'required',
        'role': 'required',
        'email': 'required|email|maxLength:'+ValidationRules.maxStringLength500,
        'addressLine1': 'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2': 'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3': 'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode': 'maxLength:'+ValidationRules.maxStringLength50,
        'countryId': '',
        'city': 'maxLength:'+ValidationRules.maxStringLength250,
        'localeId': 'required',
        // 'contactNumber': '',
        'password':'required|rangeLength:'+ValidationRules.passwordLength
    }

    public static ManagerDtlsRules:any = {
        'projectManagerUserId':'required',
        'salesManagerUserId':'required',
        // 'account_manager':'required'
    }

    public static addAddressRules:any={
        'addressLine1': 'required|maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2': 'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3': 'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode': 'required|maxLength:'+ValidationRules.maxStringLength50,
        'countryId': 'required',
        'city': 'required|maxLength:'+ValidationRules.maxStringLength250
    }

    public static addNewDeliveryAddressRules:any={
        'addressLine1': 'required|maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2': 'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3': 'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode': 'required|maxLength:'+ValidationRules.maxStringLength50,
        'countryId': 'required',
        'city': 'maxLength:'+ValidationRules.maxStringLength250
    }

    public static editContactPersonRules:any = {
        'firstName': 'required|maxLength:'+ValidationRules.maxStringLength500,
        'lastName': 'maxLength:'+ValidationRules.maxStringLength500,
        'organizationId': 'required',
        'role': 'required',
        'email': 'required|email|maxLength:'+ValidationRules.maxStringLength500,
        'addressLine1': 'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine2': 'maxLength:'+ValidationRules.maxStringLength500,
        'addressLine3': 'maxLength:'+ValidationRules.maxStringLength500,
        'zipCode': 'maxLength:'+ValidationRules.maxStringLength50,
        'countryId': '',
        'city': 'maxLength:'+ValidationRules.maxStringLength250,
        'localeId': 'required',
    }

    public static rfqOrderBasicDetailRule:any={
        'rfqPipelineExpiryDate': '',
        'projectName': 'required|maxLength:'+ValidationRules.maxStringLength100+'|minLength:'+ValidationRules.maxStringLength4,
        'customerOrganizationId': 'required',
        'customerContactPersonUserId': 'required',
        'customerCommunicationMode': '',
        'customerPurpose': 'required',
        'supplierAdditionalInfo': 'maxLength:'+ValidationRules.maxStringLength1000,
        'customerAdditionalInfo': 'maxLength:'+ValidationRules.maxStringLength1000,
        'customer_id': '',
        'rfqRequestDate': '',
        'isRfqOrderUrgent': '',
        'rfqSupplierExpiryHours': '',
        'isRfqOrderFree': '',
        'order_type': '',
        'order_number': '',
        'is_copy_rfq': '',
        'copy_rfq_number': '',
        'rfq_parent_id': '',
        'expectedDeliveryDate':'',
        'customerMaterialDate': '',
        'supplierPrintingDate': '',
        'customerDeliveryDate': '',
        'supplierDeliveryDate': '',
        'delivery_packaging': '',
        'deliver_to': '',
        'other_address_value': '',
        'projectManagerUserId':'required',
        'salesManagerUserId':'required',
        'vat': '',
        'referenceOrderNumber':'',
        'endCustomerName':'',
        'endCustomerAddress':'',
        // 'contactNumber': '',
    }
    
    public static supplierGroupRules: any = {
        'code' : 'required|maxLength:'+ValidationRules.maxStringLength500,
        'name' : 'required|maxLength:'+ValidationRules.maxStringLength500,
        'description': 'maxLength:'+ValidationRules.maxStringLength5000,
        'groupMembersOrgIds' : 'required'
    }
    public static printPaperRules: any = {
        'paperCode' : 'required|maxLength:'+ValidationRules.maxStringLength500,
        'name' : 'required|maxLength:'+ValidationRules.maxStringLength500,
        'profile': 'required',
        'weights': 'required',
        'vendorName' : '',
        'standardDeviation': ''
    }
    public static addSupplierBidPrintProduct: any = {
        'productId': '',
        'price' : 'required|maxLength:'+ValidationRules.maxStringLength20,
        'additional_copies': 'maxLength:'+ValidationRules.maxStringLength20,
        'currency' : 'required',
        'expected_days' : 'required',
        'cover_name' : '',
        'cover_weight' : '',
        'content_name' : '',
        'content_weight' : '',
        'quotation_ref_number': '',
        'comments': '',
        'isAcceptedBid': '',
    }
    public static reportListRules: any = {
        'fromDate' : '',
        'toDate' : '',
        'customerIds': '',
        'salesManagerIds': '',
    }
    public static addNoteRules: any = {
        'note': 'required|minLength:4|maxLength:100',
    }
    public static addMessageRules: any = {
        'message': 'required|maxLength:500',
        'supplierOrganizationId': ''
    }
    public static rejectRfqForm: any = {
        'rejectionReason': 'required',
        'rfqRejectionComments': ''
    }
    public static incidentReportRules: any = {
        'causedByEntity': 'required',
        'causedByOrganizationId': '',
        'contactUserId': 'required',
        'stage': 'required',
        'severity': 'required',
        'delayCausedDays': '',
        'problemDescription': '',
        'responseFromCauser': '',
        'outcome': 'required',
        'ourCost': '',
        'didCauserRectified': '',
        'isResolved': '',
    }
}
export class ValidationFields {
    public static loginfields: any = {
        emailUsername: 'emailUsername',
        password: 'password',
    }
    public static setUserfields: any = {
        user_type: 'user_type'
    }
    public static forgotfields: any = {
        email: 'email',
    }
    public static changePasswordfields: any = {
        old_password: 'oldPwd',
        new_password: 'newPwd',
        confirm_password: 'confirmNewPwd',
    }
    public static resetPasswordfields: any = {
        old_password: 'oldPwd',
        new_password: 'newPwd',
        confirm_password: 'confirmNewPwd',
    }
    public static pipelineUserfields: any = {
        email:'email',
        userName:'userName',
        firstName:'firstName',
        lastName:'lastName',
        role:'role',
        addressLine1:'addressLine1',
        addressLine2:'addressLine2',
        addressLine3:'addressLine3',
        zipCode:'zipCode',
        city:'city',
        countryId:'countryId',
        localeId:'localeId',
        titles:'titles',
        contactDtls:'contactDtls',
        contactNumber:'contactNumber',
        password:'password',
    }
    public static organizationfields: any = {
        registrationNumber:'registrationNumber',
        name:'name',
        type:'type',
        defaultLocaleId:'defaultLocaleId',
        defaultProjectManagerUserId:'defaultProjectManagerUserId',
        defaultSalesManagerUserId:'defaultSalesManagerUserId',
        defaultAccountManageruserId:'defaultAccountManageruserId',
        invoiceType:'invoiceType',
        invoiceEmail:'invoiceEmail',
        contactEmail:'contactEmail',
        website:'website',
        vatNumber:'vatNumber',
        defaultCurrencyId:'defaultCurrencyId',
        paymentDueDate:'paymentDueDate',
    }

    public static orgAddressfields:any = {
        addressLine1:'addressLine1',
        addressLine2:'addressLine2',
        addressLine3:'addressLine3',
        zipCode:'zipCode',
        city:'city',
        countryId:'countryId',
        addressType: 'addressType',
        organization:'organization'
    }
    public static supplierGroupfields:any = {
        name:'name',
        code:'code',
        description:'description',
        groupMembersOrgIds: 'groupMembersOrgIds'
    }
    public static printPaperfields:any = {
        name:'name',
        paperCode:'paperCode',
        profile:'profile',
        weights: 'weights',
        vendorName: 'vendorName',
        standardDeviation: 'standardDeviation'
    }

    public static contactPersonFields:any = {
        organizationId:'organizationId'
    }

    public static managerDtlsFields:any = {
        projectManagerUserId:'projectManagerUserId',
        salesManagerUserId : 'salesManagerUserId'
    }


    public static rfqOrderBasicDetailsFields:any={
        rfqPipelineExpiryDate: 'rfqPipelineExpiryDate',
        projectName: 'projectName',
        customerOrganizationId: 'customerOrganizationId',
        customerContactPersonUserId:'customerContactPersonUserId',
        customerCommunicationMode:'customerCommunicationMode',
        customerPurpose:'customerPurpose',
        supplierAdditionalInfo:'supplierAdditionalInfo',
        customerAdditionalInfo:'customerAdditionalInfo',
        customer_id:'customer_id',
        rfqRequestDate:'rfqRequestDate',
        isRfqOrderUrgent:'isRfqOrderUrgent',
        rfqSupplierExpiryHours:'rfqSupplierExpiryHours',
        isRfqOrderFree:'isRfqOrderFree',
        order_type:'order_type',
        order_number:'order_number',
        is_copy_rfq:'is_copy_rfq',
        copy_rfq_number:'copy_rfq_number',
        rfq_parent_id:'rfq_parent_id',
        expected_delivery_date:'expected_delivery_date',
        customerMaterialDate:'customerMaterialDate',
        supplierPrintingDate:'supplierPrintingDate',
        customerDeliveryDate:'customerDeliveryDate',
        supplierDeliveryDate:'supplierDeliveryDate',
        delivery_packaging:'delivery_packaging',
        deliver_to:'deliver_to',
        other_address_value:'other_address_value',
        projectManagerUserId:'projectManagerUserId',
        salesManagerUserId:'salesManagerUserId',     
        referenceOrderNumber:'referenceOrderNumber',
        endCustomerName:'endCustomerName',
        endCustomerAddress:'endCustomerAddress',   
    }
    public static reportListfields:any = {
        fromDate: 'fromDate',
        toDate: 'toDate',
        customerIds: 'customerIds',
        salesManagerIds: 'salesManagerIds',
    }
    public static incidentReportfields: any = {
        causedByEntity: 'causedByEntity',
        contactUserId: 'contactUserId',
        stage: 'stage',
        severity: 'severity',
        delayCausedDays: 'delayCausedDays',
        problemDescription: 'problemDescription',
        responseFromCauser: 'responseFromCauser',
        outcome: 'outcome',
        ourCost: 'ourCost',
        didCauserRectified: 'didCauserRectified',
        isResolved: 'isResolved'
    }
}
