var currentPreviewPage = 1;
var currentPreviewOrdernumber;
var currentPreviewSubfolder;
var currentPreviewFilename;
var currentPreviewUid;
var currentPreviewPagecount;
var currentPreviewThumbnail = 1;
var previewZoomedIn = false;
var previewSpreads = false;
var previewVersion = 'preview';
var previewThumbnailNavigatorScroll = 0;
var previewPreflightData = undefined;
var preflightBoxesScalingFactor = 1;
var showPreflightBoxes = false;
var showPageBoxes = false;
var pixelRatioFactor = 1;
var preflightPageStatus = new Object();
var defaultCMYKProfile;
var xOffset = 0;
var yOffset = 0;
var urlBase = 'http://devpipelineconnect.56-north.com/pipeline/prod/';
var imagesArray = [];
var preflightWidth;
var preflightHeight;
var metadata;
var zoomSize=1;
var token;
var dev_url;
// var constants = document.write('<script type="text/javascript" src="../../../../app/common/connect-constants/constants.js"></script>');



// DEV_LINK
// console.log('Constants',constants);

var getName = function() {
    return localStorage.getItem(username + "_name");

};

function adjustPreviewBox(scroll) {

    var previewImage = $('#preview-image');
    var previewBox = $('#preview-box');

    if (!scroll) {

        $('.preflight-message-box').hide();
        $('.preflight-location-indicator.selected').removeClass('selected');

        if (previewZoomedIn) {
            pixelRatioFactor = 1 / window.devicePixelRatio;
            previewBox.height($(window).height()).width($(window).width());
        } else {
            pixelRatioFactor = 1;
            previewBox.height($(window).height() - 59).width($(window).width() - 186);
        }

        $('#preview-navigation-box').width($(window).width() - 186);

        preflightBoxesScalingFactor = (previewImage.width() / $('#preflight-overlay').width()) * pixelRatioFactor;
    }

    try {

        var left = ((previewImage.offset().left - 1.5) / preflightBoxesScalingFactor) * pixelRatioFactor;
        var top = ((previewImage.offset().top + 1) / preflightBoxesScalingFactor) * pixelRatioFactor;
        $("#preflight-overlay").css({
            "zoom": preflightBoxesScalingFactor
        });
        $("#preflight-overlay").css({
            "left": left
        });
        $("#preflight-overlay").css({
            "top": top
        });


    } catch (ex) {}

}

function adjustPreviewThumbnailBox() {

    var thumbnailBox = $('#preview-thumbnail-navigation');
    var scrollFactor = previewThumbnailNavigatorScroll;

    if (previewThumbnailNavigatorScroll < 38) {
        thumbnailBox.css('top', 49 - previewThumbnailNavigatorScroll);
    } else {
        scrollFactor = 37;
        thumbnailBox.css('top', 12);
    }

    $('#logo-box').css('top', previewThumbnailNavigatorScroll * -1);

    thumbnailBox.height((currentPreviewPagecount * 100) + 24).css('max-height', $(window).height() - (64 - scrollFactor));

}

export function showPreview(ordernumber, orderNo, page, subfolder, filename, pagecount, softproofable, uid, images, width, height, dev_url) {
    // console.log(ordernumber);
    currentPreviewPage = page;
    dev_url = dev_url;
    currentPreviewOrdernumber = ordernumber;
    currentPreviewSubfolder = subfolder;
    currentPreviewFilename = filename;
    currentPreviewUid = uid;
    currentPreviewPagecount = ordernumber.pageCount;
    previewZoomedIn = false;
    previewVersion = 'preview';
    currentPreviewThumbnail = 1;
    previewSpreads = false;
    previewThumbnailNavigatorScroll = 0;
    previewPreflightData = ordernumber;
    showPreflightBoxes = false;
    preflightPageStatus = new Object();
    defaultCMYKProfile = "ISOcoated_v2_eci.icc";
    xOffset = 0;
    yOffset = 0;
    imagesArray = images;
    preflightHeight = height;
    preflightWidth = width;
    // console.log(imagesArray);
    metadata = ordernumber;
    token = ordernumber.token;
    $('body').scrollTop(0);

    if (ordernumber != null) {
        var preflightData = ordernumber;
        if (preflightData !== null) {
            previewPreflightData = preflightData;
            preflightPageStatus = getPageStatusClasses();
            defaultCMYKProfile = previewPreflightData.defaultCMYKProfile;
            if (previewPreflightData.boxes) {
                var mediaBox = previewPreflightData.boxes.mediaBox;
                xOffset = mediaBox.minX * -1;
                yOffset = mediaBox.minY * -1;

            }
        }
    }

    $('#modalizer').fadeIn('slow', function() {

        var fullwindower = $('#fullwindower');
        var centeringHelper = $('<span id="preview-centering-helper"></span>');
        var cloudProofLogo = $('<div id="cloudproof-logo-box"><img id="cloudproof-logo" src="http://connect.pipelinenordic.se/images/connect_oneline.png"></div>');
        var previewBox = $('<div id="preview-box" class="preview-box-zoomed-out">');
        var navigationBox = $('<div id="preview-navigation-box">');
        var thumbnailNavigator = $('<div id="preview-thumbnail-navigation">');
        var previewImage = $('<img page="' + page + '" class="not_valid preview-image-zoomed-out" id="preview-image" src="' + buildPreviewUrl(ordernumber) + '">');
        var previewImageR = $('<img page="' + page + '" class="not_valid preview-image-zoomed-out spread" id="preview-image-r" style="display:none">');
        var preflightOverlay = $('<div class="preflight-overlay" id="preflight-overlay" style="display:none">');
        var preflightOverlayR = $('<div class="preflight-overlay" id="preflight-overlay-r" style="display:none">');
        var preflightButton = $('<span id="preview-preflight-button" class="preview-navigation-component"><i class="fa fa-check-square-o"></i></span>');
        var spreadButton = $('<span class="preview-navigation-component"><i class="fa fa-columns"></i></span>');
        var firstPageButton = $('<span class="preview-navigation-component"><i class="fa fa-fast-backward"></i></span>');
        var previousPageButton = $('<span class="preview-navigation-component"><i class="fa fa-step-backward"></i></span>');
        var pageIndicatorBox = $('<span class="preview-navigation-component" id="preview-page-indicator">');
        var currentPageField = $('<input type="text" value="' + page + '" id="preview-current-page-field">');
        var nextPageButton = $('<span class="preview-navigation-component"><i class="fa fa-step-forward"></i></span>');
        var lastPageButton = $('<span class="preview-navigation-component"><i class="fa fa-fast-forward"></i></span>');
        var zoomButton = $('<span class="preview-navigation-component"><i id="preview-zoom-in-icon" class="fa fa-search-plus"></i><i id="preview-zoom-out-icon" style="display:none" class="fa fa-search-minus"></i></span>');
        var zoomOutButton = $('<span class="preview-navigation-component"><i id="preview-zoom-out-icon" class="fa fa-search-minus"></i></span>');
        var softproofButton = $('<span id="preview-softproof-button" class="preview-navigation-component"><i id="softproof-off" class="fa fa-toggle-off" style="display: none;"></i><i id="softproof-on" style="display: inline-block;" class="fa fa-toggle-on"></i><span id="preview-print-simulation-label" class="preview-print-simulation-label component-active">Soft proof</span></span>');
        // var infoButton = $('<span class="preview-navigation-component"><i class="fa fa-info"></i></span>');
        // var commentButton = $('<span id="comment-button" class="preview-navigation-component"><i class="fa fa-comment"></i></span>');
        var closeButton = $('<div id="preview-close-button"><i class="fa fa-times"></i></div>');

        //$('#fullwindower').height($(window).height()  - 96).width($(window).width() - 96);
        preflightOverlay.width(preflightWidth);
        preflightOverlay.height(preflightHeight);

        previewImage.on('load', function() {
            previewImage.removeClass('not_valid', 'slow', function() {
                adjustPreviewBox();
            });
            if (previewVersion === 'softproof') {
                $('#preview-print-simulation-label').addClass('component-active');
            } else {
                $('#preview-print-simulation-label').removeClass('component-active');
            }
            if (showPreflightBoxes) {
                setPreflightInfo(preflightOverlay, previewBox, currentPreviewPage);
                adjustPreviewBox();
            } else if (preflightPageStatus != null && preflightPageStatus[currentPreviewPage] != undefined) {
                $('#preview-preflight-button').trigger('click');
            }
        });

        previewImageR.on('load', function() {
            previewImageR.removeClass('not_valid', 'slow')
                .fadeIn('slow');
        });

        preflightButton.on('click', function() {
            $(this).toggleClass('component-active');
            if (showPreflightBoxes) {
                showPreflightBoxes = false;
                preflightOverlay.html('');
                preflightOverlayR.html('');
                $('.preflight-message-box').remove();
            } else {
                showPreflightBoxes = true;
                setPreflightInfo(preflightOverlay, previewBox, currentPreviewPage);
                adjustPreviewBox();
            }
        });

        closeButton.on('click', function() {
            hidePreview();
            window.close();
        });

        softproofButton.on('click', function() {
            $('#softproof-off').toggle(0);
            $('#softproof-on').toggle(0);
            if (previewVersion === 'preview') {
                previewVersion = 'softproof';
            } else {
                previewVersion = 'preview';
            }
            $('#preview-image').attr('src', buildPreview(previewVersion, currentPreviewOrdernumber, $('#preview-image').attr('page'), currentPreviewSubfolder, currentPreviewUid, defaultCMYKProfile));
            if (previewSpreads) {
                $('#preview-image-r').attr('src', buildPreview(previewVersion, currentPreviewOrdernumber, $('#preview-image-r').attr('page'), currentPreviewSubfolder, currentPreviewUid, defaultCMYKProfile));
            }
        });

        $('body').css('overflow', 'hidden');

        // commentButton.on('click', function() {
        //     $(this).toggleClass('component-active');
        //     $('#page-comment-box').toggle();
        //     if ($('#page-comment-handle').val().length < 1) {
        //         $('#page-comment-handle').focus();
        //     } else {
        //         $('#page-comment').focus();
        //     }
        // });

        spreadButton.on('click', function() {
            $(this).toggleClass('component-active');
            previewImage.toggleClass('spread');
            if (previewSpreads) {
                previewSpreads = false;
            } else {
                previewSpreads = true;
            }
            if (currentPreviewPage > 1 &&
                currentPreviewPage < currentPreviewPagecount) {
                currentPageFieldChanged();
            }
        });

        zoomButton.on('click', function() {
            // thumbnailNavigator.toggle(0);
            zoomSize+=0.1;
            // previewImageR.toggleClass('preview-image-zoomed-out', 0).toggleClass('preview-image-zoomed-in', 0);
            // previewImage.toggleClass('preview-image-zoomed-out', 0).toggleClass('preview-image-zoomed-in', 0);
            // previewBox.toggleClass('preview-box-zoomed-out', 0).toggleClass('preview-box-zoomed-in', 0);
            // $('#preview-zoom-in-icon').toggle(0);
            // $('#preview-zoom-out-icon').toggle(0);
            // $('#logo-box').toggle('fade', 'slow');
            // if (!previewSpreads) {
            // centeringHelper.toggle(0);
            // }
            // if (previewZoomedIn) {
            //     previewZoomedIn = false;
            // } else {
            //     previewZoomedIn = true;
            // }
            // adjustPreviewBox();

            $('#preview-box').css('transform', 'translate(-50%, -50%) scale(' + zoomSize + ')');

            
        });

        zoomOutButton.on('click', function(){
            zoomSize-=0.1;
            $('#preview-box').css('transform', 'translate(-50%, -50%) scale(' + zoomSize + ')');
        })

        // navigationBox.append(commentButton).append(infoButton);

        if (pagecount > 1) {
            pageIndicatorBox.append(currentPageField).append('/' + pagecount);
            navigationBox.append(spreadButton)
            navigationBox.append(firstPageButton)
                .append(previousPageButton)
                .append(pageIndicatorBox)
                .append(nextPageButton)
                .append(lastPageButton);
            firstPageButton.on('click', function() {
                $('#preview-current-page-field').val(1);
                currentPageFieldChanged();
            });

            previousPageButton.on('click', function() {
                if (currentPreviewPage > 1) {
                    if (previewSpreads &&
                        currentPreviewPage > 2) {
                        currentPreviewPage -= 2;
                    } else {
                        currentPreviewPage--;
                    }
                    $('#preview-current-page-field').val(currentPreviewPage);
                    currentPageFieldChanged();
                }
            });

            nextPageButton.on('click', function() {
                if (currentPreviewPage < currentPreviewPagecount) {
                    if (previewSpreads &&
                        currentPreviewPage > 1 &&
                        currentPreviewPage < (currentPreviewPagecount - 1)) {
                        currentPreviewPage += 2;
                    } else {
                        currentPreviewPage++;
                    }
                    $('#preview-current-page-field').val(currentPreviewPage);
                    currentPageFieldChanged();
                }
            });

            lastPageButton.on('click', function() {
                $('#preview-current-page-field').val(currentPreviewPagecount);
                currentPageFieldChanged();
            });
        }

        // infoButton.on('click', function() {
        //     $(this).toggleClass('component-active');
        //     $('#preview-info-box').toggle(0);
        // });

        navigationBox.append(zoomButton);
        navigationBox.append(zoomOutButton);

        if (previewPreflightData !== undefined) {
            navigationBox.append(softproofButton);
            navigationBox.append(preflightButton);
        }

        if (previewPreflightData !== undefined) {
            navigationBox.append(preflightButton);
        }

        previewBox.html(previewImage)
            .append(previewImageR)
            .append(preflightOverlay)
            .append(preflightOverlayR)
            .append(generateInfoBox(metadata))
            .append(generatePageCommentBox())
            .on('mousewheel', function(event) {
                // Prevent two finger trackpad history navigation feature under Mac OS X
                event.preventDefault();
                $(this).scrollLeft($(this).scrollLeft() + event.deltaX).scrollTop($(this).scrollTop() - event.deltaY);
                adjustPreviewBox();
            }).on('click', function() {
                $('.preflight-message-box:visible').hide();
                $('.preflight-location-indicator.selected').removeClass('selected');
            });

        populatePageCommentBox();

        thumbnailNavigator.on('mousewheel', function() {
            previewThumbnailNavigatorScroll = $(this).scrollTop();
            adjustPreviewThumbnailBox();
        });

        fullwindower
            .append(centeringHelper)
            .append(previewBox)
            .append(navigationBox)
            .append(thumbnailNavigator);

        if (typeof externalCloudProof === 'undefined') {
            fullwindower.append(closeButton).append(cloudProofLogo);
            $('body').bind('keyup.preview', function(event) {
                if (event.which == 27) {
                    hidePreview();
                    event.preventDefault();
                    $('body').unbind('keyup.preview');
                }
            });
        }

        fullwindower.show(function() {
            addPreviewThumbnailBoxes(thumbnailNavigator);
            setPreviewThumbnailBoxes(thumbnailNavigator);
        });

        currentPageField.on('change', function() {
            currentPageFieldChanged();
        });

        adjustPreviewBox();

        $('#preview-info-box').draggable();
        $('#page-comment-box').draggable({
            cancel: '.page-comment-comment'
        });
    });

}

function showCommentBox() {

    $('#page-comment-box').show();
    $('#comment-button').addClass('component-active');
    if ($('#page-comment-handle').val().length < 1) {
        $('#page-comment-handle').focus();
    } else {
        $('#page-comment').focus();
    }

}

function populatePageCommentBox() {

    //    $.getJSON(urlBase + 'servlet/cloudproof', {
    //        action: 'get_page_comments',
    //        uid: '77166b75',
    //        page: '11'
    //    }, function (commentList) {
    var commentList = [];
    var previousCommentsBox = $('#page-comment-previous-comments');

    previousCommentsBox.html('');

    $.each(commentList, function(i, commentData) {

        var handleBox = $('<p class="page-comment-handle">' + commentData.handle + ' [' + formatDateTimeFromTimestamp(commentData.commentTime) + ']:</p>');
        var commentBox = $('<p class="page-comment-comment">' + commentData.comment + '</p>');

        previousCommentsBox.append(handleBox).append(commentBox);

    });

    if (commentList.length > 0) {
        showCommentBox();
    }

    //    });

    //    $.getJSON(urlBase + 'servlet/cloudproof', {
    //        action: 'get_pages_with_comments',
    //        uid: currentPreviewUid,
    //    }, function (pageList) {
    var pageList = [];
    $.each(pageList, function(i, page) {
        $('#preview-thumbnail-commentsindicator-' + page).html('<i class="fa fa-comment"></i>');
    });



    //    });

}

function generatePageCommentBox() {

    var pageCommentBox = $('<div style="display:none" id="page-comment-box" class="ui-widget-content">');
    var pageCommentBoxHeader = $('<p class="page-comment-box-header">Page comments</p>');
    var pageCommentPreviousComments = $('<div id="page-comment-previous-comments">');
    var pageCommentHandleBox = $('<div class="page-comment-form-box">');
    var pageCommentHandleLabel = $('<label class="page-comment-form-label">Your name:</label>');
    var pageCommentHandle = $('<input type="text" class="page-comment-form-field" id="page-comment-handle">');
    var pageCommentFieldLabel = $('<label class="page-comment-form-label">Comment:</label>');
    var pageCommentFieldBox = $('<div class="page-comment-form-box">');
    var pageCommentField = $('<textarea class="page-comment-form-field" id="page-comment"></textarea>');
    var pageCommentAddButton = $('<button id="page-comment-add-button" disabled="disabled" class="ui-helper-reset ui-state-default ui-state-active ui-corner-all not_valid">Add page comment</button>');

    pageCommentField.on('keyup', function() {

        if (pageCommentField.val().length > 0) {
            pageCommentAddButton.removeAttr('disabled').removeClass('not_valid');
        } else {
            pageCommentAddButton.attr('disabled', 'disabled').addClass('not_valid');
        }

    });

    pageCommentAddButton.click(function() {

        var handle = pageCommentHandle.val();
        var comment = pageCommentField.val();

        if (handle.length > 0 &&
            comment.length > 0) {

            $.ajax({
                type: 'POST',
                url: urlBase + 'servlet/cloudproof',
                dataType: 'json',
                data: {
                    action: 'add_page_comment',
                    uid: currentPreviewUid,
                    page: currentPreviewPage,
                    handle: handle,
                    comment: comment
                },
                async: true,
                success: function() {
                    pageCommentField.val('');
                    pageCommentAddButton.attr('disabled', 'disabled').addClass('not_valid');
                    populatePageCommentBox();
                }
            });

            setName(handle);

        }

    });

    if (getName() !== null) {
        pageCommentHandle.val(getName());
    }

    pageCommentHandleBox.append(pageCommentHandleLabel).append(pageCommentHandle);
    pageCommentFieldBox.append(pageCommentFieldLabel).append(pageCommentField);

    pageCommentBox.append(pageCommentBoxHeader).append(pageCommentPreviousComments).append(pageCommentHandleBox).append(pageCommentFieldBox).append(pageCommentAddButton);

    return pageCommentBox;

}

function generateInfoBox(metadata) {
    var infoBox = $('<div style="display:none" id="preview-info-box" class="ui-widget-content ui-draggable ui-draggable-handle">');
    infoBox.append('<pre class="preview-info-label">Filename</pre>')
        .append('<pre class="preview-info-content">' + currentPreviewFilename + '</pre>');

    if (metadata !== undefined) {

        infoBox.append('<pre class="preview-info-label">PDF version</pre>')
            .append('<pre class="preview-info-content">' + metadata.version + '</pre>')
            .append('<pre class="preview-info-label">Pages</pre>')
            .append('<pre class="preview-info-content">' + metadata.pagecount + '</pre>')
            .append('<pre class="preview-info-label">Size</pre>')
            .append('<pre class="preview-info-content">' + pointsToEvenMm(metadata.width) + ' x ' + pointsToEvenMm(metadata.height) + ' mm</pre>');

    }

    if (previewPreflightData != null) {
        var coordinates = previewPreflightData.boxes.trimBox;

        if (coordinates !== undefined && coordinates != null) {
            infoBox.append('<pre class="preview-info-label">Finished size</pre>')
                .append('<pre class="preview-info-content">' + pointsToEvenMm(coordinates.maxX - coordinates.minX) + ' x ' + pointsToEvenMm(coordinates.maxY - coordinates.minY) + ' mm</pre>');

        }
        infoBox.append('<pre class="preview-info-label">Creation date</pre>')
            .append('<pre class="preview-info-content">' + previewPreflightData.creationDate + '</pre>')
            .append('<pre class="preview-info-label">Preflight date</pre>')
            .append('<pre class="preview-info-content">' + previewPreflightData.preflightDate + '</pre>');

        var errorArray = new Array();
        var warningArray = new Array();
        if (previewPreflightData.messageList != undefined) {
            $.each(previewPreflightData.messageList, function(messageId, messageDtls) {
                if (messageDtls.level === 'ERROR') {
                    errorArray.push(resolvePreflightString(messageDtls.baseString, messageDtls.preflightMessageOccurenceList));
                } else if (previewPreflightData.messageList.level === 'WARNING') {
                    warningArray.push(resolvePreflightString(messageDtls.baseString, messageDtls.preflightMessageOccurenceList));
                }
            });
        }

        if (errorArray.length > 0) {
            infoBox.append('<pre class="preview-info-label">Preflight errors</pre>');
            $.each(errorArray, function(index, errorMessage) {
                infoBox.append('<pre class="preview-info-content list-item">' + errorMessage + '</pre>');
            });
        }

        if (warningArray.length > 0) {

            infoBox.append('<pre class="preview-info-label">Preflight warnings</pre>');

            $.each(warningArray, function(index, warningMessage) {
                infoBox.append('<pre class="preview-info-content list-item">' + warningMessage + '</pre>');
            });

        }

    }

    return infoBox;

}

function setPageBoxes(target) {
    if (previewPreflightData != null) {
        var trimBox = previewPreflightData.boxes.trimBox;
        if (trimBox != undefined && trimBox != null) {
            var str = "width: " + (trimBox.maxX - trimBox.minX) + "px; height: " + (trimBox.maxY - trimBox.minY) + "px; left: " + (trimBox.minX + xOffset) +
                "px; bottom: " + (trimBox.minY + yOffset) + "px; ";
            var box = $('<div id="trimbox" class="page-box" style="' + str + '">');
            target.append(box);
        }
    }
}

function getPageStatusClasses() {
    var pageStatusClasses = new Object();
    // console.log(imagesArray);
    $.each(imagesArray, function(imageId, imageInfo) {
        var currentStatus = pageStatusClasses[(imageInfo.page_no)];
        var messageLevel = imageInfo.thumbnail_class.toLowerCase();
        if (currentStatus == null || messageLevel === 'error') {
            pageStatusClasses[(imageInfo.page_no)] = messageLevel;
            $('.preview-thumbnail-pageindicator-' + (imageInfo.page_no)).addClass(messageLevel);
        }
    });
    return pageStatusClasses;
}

function setPreflightInfo(indicatorTarget, messageBoxTarget, page) {
    // console.log(page);
    indicatorTarget.html('').hide();
    setPageBoxes(indicatorTarget);
    if (previewPreflightData != null) {
        if (previewPreflightData.messageList != undefined || previewPreflightData.messageList) {
            $.each(previewPreflightData.messageList, function(messageId, messageDtls) {
                if (!(messageDtls.level === 'WARNING' && messageDtls.errorCategory === 'INK_COVERAGE_TOO_GREAT')) {
                    var message = messageDtls.baseString;
                    var resolvedMessage = resolvePreflightString(message, messageDtls.preflightMessageOccurenceList);
                    var messageLevel = messageDtls.level.toLowerCase();
                    $.each(messageDtls.locationList, function(locationId, coordinates) {
                        if (parseInt(page) == (parseInt(coordinates.page) + 1)) {
                            var styleStr = " width: " + parseInt(coordinates.maxX - coordinates.minX) + "px; height: " + parseInt(coordinates.maxY - coordinates.minY) +
                                "px; left: " + parseInt(coordinates.minX + xOffset) + "px; bottom: " + parseInt(coordinates.minY + yOffset) + "px;";
                            var locationIndicator = $('<div class="preflight-location-indicator" style="' + styleStr + '">');
                            var messageBox = $('<div class="preflight-message-box">');
                            var messageArrow = $('<div class="preflight-message-arrow">');
                            var messageFrame = $('<div class="preflight-message-frame">');
                            // console.log(locationIndicator);
                            messageArrow.addClass(messageLevel);
                            messageFrame.addClass(messageLevel).html(resolvedMessage);
                            messageBox.append(messageArrow).append(messageFrame);
                            locationIndicator.on('click', function(event) {
                                event.stopPropagation();
                                $('.preflight-location-indicator.selected').removeClass('selected');
                                $(this).addClass('selected');
                                $('.preflight-message-box').hide();
                                var offset = messageBoxTarget.offset();
                                messageBox.css('left', event.clientX - offset.left + messageBoxTarget.scrollLeft())
                                    .css('top', event.clientY - offset.top + messageBoxTarget.scrollTop()).show();
                            });
                            indicatorTarget.append(locationIndicator);
                            messageBoxTarget.append(messageBox);
                        }
                    });
                }
            });
        }
    }
    indicatorTarget.show();
    // console.log(indicatorTarget);
}

function resolvePreflightString(baseString, variableMap) {
    if (baseString != undefined && variableMap != undefined) {
        variableMap = JSON.parse(variableMap);
        $.each(variableMap, function(key, value) {
            var concatenatedValue = '';
            var usedValues = new Array();

            //$.each(array, function (index, value) {

            // if ($.inArray(value, usedValues) === -1) {
            concatenatedValue += ", " + value;
            usedValues.push(value);
            //}

            //});

            concatenatedValue = concatenatedValue.substr(2);

            var varName = '%' + key + '%';
            var arrayVarName = '%[]' + key + '%';


            baseString = baseString.replace(varName, concatenatedValue);
            baseString = baseString.replace(arrayVarName, concatenatedValue);

        });
    }
    return baseString;

}

function addPreviewThumbnailBoxes(target) {
    for (var i = 1; i <= currentPreviewPagecount; i++) {
        var thumbnailBox = $('<div class="preview-thumbnail-box" >');
        var thumbnailImage = $('<img class="preview-thumbnail-image">');
        var thumbnailPage = $('<div class="preview-thumbnail-pageindicator">' + i + '</div>');
        var thumbnailComments = $('<div class="preview-thumbnail-commentsindicator">');

        thumbnailBox.attr('id', 'preview-thumbnail-box-' + i);
        thumbnailImage.attr('id', 'preview-thumbnail-image-' + i);
        thumbnailPage.attr('id', 'preview-thumbnail-pageindicator-' + i);
        thumbnailComments.attr('id', 'preview-thumbnail-commentsindicator-' + i);

        thumbnailBox.append(thumbnailImage).append(thumbnailPage).append(thumbnailComments);
        target.append(thumbnailBox);

    }

}

function setPreviewThumbnailBoxes(target, images) {
    var thumbnailBox = $('#preview-thumbnail-box-' + currentPreviewThumbnail);
    var thumbnailImage = $('#preview-thumbnail-image-' + currentPreviewThumbnail);
    var thumbnailPage = $('#preview-thumbnail-pageindicator-' + currentPreviewThumbnail);

    if (preflightPageStatus != null &&
        preflightPageStatus[currentPreviewThumbnail] != null) {
        thumbnailPage.addClass(preflightPageStatus[currentPreviewThumbnail]);
    }

    thumbnailImage.on('load', function() {
        thumbnailBox.fadeIn('slow');
        var targetPage = currentPreviewThumbnail;
        thumbnailBox.on('click', function() {
            $('#preview-current-page-field').val(targetPage);
            // console.log($('#preview-current-page-field'));
            currentPageFieldChanged(targetPage);
        });

        if (++currentPreviewThumbnail <= currentPreviewPagecount) {

            setPreviewThumbnailBoxes(target);

        } else {
            /* $.getJSON(urlBase + 'servlet/cloudproof', {
                 action: 'get_pages_with_comments',
                 uid: currentPreviewUid
             }, function (pageList) {

                 $.each(pageList, function (i, page) {
                     $('#preview-thumbnail-commentsindicator-' + page).html('<i class="fa fa-comment"></i>');
                 });

             });*/
            adjustPreviewThumbnailBox();

        }

    }).on('error', function(e) {

        setPreviewThumbnailBoxes(target);
        e.preventDefault();

    });
    thumbnailImage.attr('src', imagesArray[currentPreviewThumbnail - 1]['source']);
}

function hidePreview() {

    $('#preview-thumbnail-navigation').hide().scrollTop(0);
    $('#logo-box').animate({
        top: 0
    }, 'fast').fadeIn('slow');
    $('#modalizer').fadeOut('slow');
    $('#fullwindower').fadeOut('slow', function() {
        $('#fullwindower').html('');
    });
    $('body').css('overflow', 'inherit');

}

// function currentPageFieldChanged(targetPage) {
//     var requestedPage = targetPage;
//     var fileId = imagesArray[0]['id'];
//     var fileType = imagesArray[0]['type'];
//     if (!isNaN(requestedPage) &&
//         requestedPage > 0 &&
//         requestedPage <= currentPreviewPagecount) {
//         // window.open("http://devconnect.pipelinenordic.se/#/test_image?show_box=true&type=" + fileType + "&page_no=" + requestedPage + "&file_id=" + fileId, "_self");
//         // window.open("http://devpipelineconnect.56-north.com/#/preview_file?show_box=true&type="+fileType+"&page_no="+requestedPage+"&file_id="+fileId,"_self");
//         // window.open("http://localhost:4200/#/cloudproofPreview?token="+token+"&page_no="+requestedPage+"&file_id="+fileId,"_self");
//         // window.open(window.location.origin+"/file/cloudproof-preview?token="+token+"&page_no="+requestedPage+"&file_id="+fileId,"_self");

//         currentPreviewPage = parseInt(requestedPage);

//         populatePageCommentBox();

//         if (previewSpreads &&
//             currentPreviewPage > 1 &&
//             currentPreviewPage < currentPreviewPagecount) {

//             var lPage = 0;
//             var rPage = 0;

//             if ((requestedPage % 2) == true) {
//                 lPage = currentPreviewPage - 1;
//                 rPage = currentPreviewPage;
//                 currentPreviewOrdernumber.currentPage = lPage;
//             } else {
//                 lPage = currentPreviewPage;
//                 rPage = currentPreviewPage + 1;
//                 currentPreviewOrdernumber.currentPage = rPage;
//             }
//             // console.log(currentPreviewPage);
//             if ($('#preview-image-r').is(':hidden') ||
//                 $('#preview-image').attr('page') != lPage) {

//                 $('#preview-centering-helper').hide();
//                 // console.log("current preview url : " + currentPreviewOrdernumber.previewUrl);
//                 $('#preview-image').attr('page', lPage).removeClass('preview-single-page-image').addClass('not_valid').attr('src', buildPreviewUrl(currentPreviewOrdernumber));
//                 $('#preview-image-r').attr('page', rPage).addClass('not_valid').attr('src', buildPreviewUrl(currentPreviewOrdernumber));

//             }

//         } else {

//             $('#preview-centering-helper').show();
//             $('#preview-image-r').hide();
//             currentPreviewOrdernumber.currentPage = currentPreviewPage;
//             currentPreviewOrdernumber.previewUrl = imagesArray[currentPreviewPage - 1]['title'];
//             $('#preview-image').attr('page', currentPreviewPage).addClass('preview-single-page-image').addClass('not_valid').attr('src', buildPreviewUrl(currentPreviewOrdernumber));

//         }

//     } else {

//         $('#preview-current-page-field').val(currentPreviewPage);

//     }

// }

function currentPageFieldChanged(targetPage) {
    var requestedPage = targetPage;
    var fileId = imagesArray[0]['id'];
    var fileType = imagesArray[0]['type'];
    if (!isNaN(requestedPage) &&
        requestedPage > 0 &&
        requestedPage <= currentPreviewPagecount) {
        currentPreviewPage = parseInt(requestedPage);
        populatePageCommentBox();
        if (previewSpreads &&
            currentPreviewPage > 1 &&
            currentPreviewPage < currentPreviewPagecount) {
            var lPage = 0;
            var rPage = 0;
            if ((requestedPage % 2) == true) {
                lPage = currentPreviewPage - 1;
                rPage = currentPreviewPage;
                currentPreviewOrdernumber.currentPage = lPage;
            } else {
                lPage = currentPreviewPage;
                rPage = currentPreviewPage + 1;
                currentPreviewOrdernumber.currentPage = rPage;
            }
            if ($('#preview-image-r').is(':hidden') ||
                $('#preview-image').attr('page') != lPage) {

                $('#preview-centering-helper').hide();
                $('#preview-image').attr('page', lPage).removeClass('preview-single-page-image').addClass('not_valid').attr('src', buildPreviewUrl(currentPreviewOrdernumber));
                $('#preview-image-r').attr('page', rPage).addClass('not_valid').attr('src', buildPreviewUrl(currentPreviewOrdernumber));
            }
        } else {

            $('#preview-centering-helper').show();
            $('#preview-image-r').hide();
            currentPreviewOrdernumber.currentPage = currentPreviewPage;
            currentPreviewOrdernumber.previewUrl = imagesArray[currentPreviewPage - 1]['title'];
            $('#preview-image').attr('page', currentPreviewPage).addClass('preview-single-page-image').addClass('not_valid').attr('src', buildPreviewUrl(currentPreviewOrdernumber));

        }

    } else {

        $('#preview-current-page-field').val(currentPreviewPage);

    }

}

function buildPreviewUrl(ordernumber) {

    return ordernumber.previewUrl;
}

function pointsToEvenMm(points) {
    return Math.round(points * 0.352777778);
}