import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpRequest, HttpEvent,HttpHeaders,HttpParams} from '@angular/common/http';
import { Validators, FormControl, FormGroup, FormBuilder, FormArray, FormArrayName} from '@angular/forms';
import {ValidationManager} from "ng2-validation-manager";
import { Constants } from 'src/app/constant/constants';
import { GeneralFunction } from 'src/app/common/general-function';
import { Title } from '@angular/platform-browser';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import * as $ from 'jquery';
import * as WebProof from '../../../resources/js/custom/production/webproof_withoutlogin.js';
import * as utilities from '../../../resources/js/framework/utilities.js';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { Observable } from 'rxjs/Observable';

@Component({
    selector: 'app-cloud-proof-preview',
    templateUrl: './cloud-proof-preview.component.html',
    styleUrls: ['./cloud-proof-preview.component.css'],
    providers: [ResponseHandlerService]
})

export class CloudProofPreviewComponent implements OnInit {
    images : any[] = [];
    viewImage:any;
    @ViewChild('previewImageBox', {static: false}) previewImageBox: any;
    hidePreview:boolean;
    url : any;
    fileDtlsRes : any;
    currExt : any;
    info : any;
    sub:any;
    httpErrorMsgDisplay :any;
    httpErrorMsg:any;
    httpErrorCode:any;
    loading:boolean;
    responseReceive:boolean = false;
    data:any;
    pageNo:any=0;
  
    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient,public formBuilder: FormBuilder, private titleService: Title,private confirmationService: ConfirmationService, private apiCallingService: ApiCallingService,private responseHandler: ResponseHandlerService,) {
    }
    ngOnInit() {
  		this.sub = this.route.queryParams.subscribe(params => {
          	this.loading = true;
            var url:any= ApiEndpointConstant.pitstopFileShare(params['token']);
            this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, url).subscribe(result => {
            this.loading = false;
              if (result.resultCode == Constants.resultCode.SUCCESS) {
                  this.fileDtlsRes=result;
                    this.images = [];
                    let errorsArray = [];
                    let warningArray = [];
                    var msgLevel = "";
                    var newMessageList = [];
                    if(!GeneralFunction.checkForNullUndefinedBlank(this.fileDtlsRes.pitstopMsgSummaryResponseList && this.fileDtlsRes.pitstopMsgSummaryResponseList.length>0)){
                        var pitstopMsgSummaryResponseList = this.fileDtlsRes.pitstopMsgSummaryResponseList;
                        for(var i=0; i < pitstopMsgSummaryResponseList.length ; i++){
                            
                            var preflightMessageOccurenceList = pitstopMsgSummaryResponseList[i].pitstopMsgvariableSummaryResponse.reduce((obj, item) => Object.assign(obj, { [item.variableType]: item.variableValue }), {});

                            newMessageList.push({
                                baseString:pitstopMsgSummaryResponseList[i].message,
                                level:pitstopMsgSummaryResponseList[i].pitstopMsgType,
                                errorCategory:pitstopMsgSummaryResponseList[i].pitstopMsgType,
                                locationList:pitstopMsgSummaryResponseList[i].pitstopMsgLocationSummaryResponseList,
                                preflightMessageOccurenceList:JSON.stringify(preflightMessageOccurenceList),
                                preflightConstantList:''
                            });



                            if(pitstopMsgSummaryResponseList[i].pitstopMsgType==Constants.responseStatus['ERROR_1']){
                                var pitstopMsgLocation = pitstopMsgSummaryResponseList[i].pitstopMsgLocationSummaryResponseList;
                                if(!GeneralFunction.checkForNullUndefinedBlank(pitstopMsgLocation) && pitstopMsgLocation.length >0 ){
                                    for(var j=0; j <pitstopMsgLocation.length; j++){
                                        errorsArray.push(pitstopMsgLocation[j].page);
                                    }
                                }
                            }

                            if(pitstopMsgSummaryResponseList[i].pitstopMsgType==Constants.responseStatus['WARNING_1']){
                                var pitstopMsgLocation = pitstopMsgSummaryResponseList[i].pitstopMsgLocationSummaryResponseList;
                                if(!GeneralFunction.checkForNullUndefinedBlank(pitstopMsgLocation) && pitstopMsgLocation.length >0 ){
                                    for(var j=0; j <pitstopMsgLocation.length; j++){
                                        warningArray.push(pitstopMsgLocation[j].page);
                                    }
                                }
                            }
                        }
                    }
                    var uniqueWarningArray = warningArray.filter((v, i, a) => a.indexOf(v) === i);
                    var uniqueErrorsArray = errorsArray.filter((v, i, a) => a.indexOf(v) === i);

                    if(this.fileDtlsRes && this.fileDtlsRes.thumbnailImages && this.fileDtlsRes.thumbnailImages.length >0 ){
                        for (var img = 0; img < this.fileDtlsRes?.thumbnailImages?.length; img++) {
                            if(uniqueErrorsArray.includes(img)){
                                msgLevel = Constants.responseStatus['ERROR_1'];
                            }else if(uniqueWarningArray.includes(img)){

                                msgLevel = Constants.responseStatus['WARNING_1'];
                            } else{
                                msgLevel = '';
                            }

                            this.images.push({
                                id: this.fileDtlsRes.pitstopOutputId,
                                source: this.fileDtlsRes?.thumbnailImages[img],
                                alt: Constants.FILE_EXT['PDF'],
                                title:  this.fileDtlsRes?.previewImages[img],
                                page_no:img + 1,
                                thumbnail_class : msgLevel,
                                type:Constants.FILE_VIEW_STATUS['PITSTOP_OUTPUT_FILE_PREVIEW']
                            });
                        }

                        var data = [];

                        data.push({
                            contentType:this.fileDtlsRes.contentType,
                            criticalFailureCount:this.fileDtlsRes.criticalFailureCount,
                            errorCount:this.fileDtlsRes.errorCount,
                            fileSize:this.fileDtlsRes.fileSize,
                            fixCount:this.fileDtlsRes.fixCount,
                            nonCriticalFailureCount:this.fileDtlsRes.nonCriticalFailureCount,
                            originalFileName:this.fileDtlsRes.originalFileName,
                            pitstopOutputId:this.fileDtlsRes.pitstopOutputId,
                            signoffCount:this.fileDtlsRes.signoffCount,
                            storeFileName:this.fileDtlsRes.storeFileName,
                            warningCount:this.fileDtlsRes.warningCount,
                            height:this.fileDtlsRes.height,
                            width:this.fileDtlsRes.width
                        });

                        var boxes:any = [];
                        
                        var boxeDtls = this.fileDtlsRes.pitstopBoxInfoSummaryResponseList;

                        for(var i=0;i<boxeDtls.length;i++){
                            if(boxeDtls[i].boxName==Constants.BOX_IMAGES['BLEED_BOX']){
                                delete boxeDtls[i].boxName;
                                boxes['bleedBox'] = boxeDtls[i];    
                            }
                            if(boxeDtls[i].boxName==Constants.BOX_IMAGES['ART_BOX']){
                                delete boxeDtls[i].boxName;
                                boxes['artBox'] = boxeDtls[i];    
                            }
                            if(boxeDtls[i].boxName==Constants.BOX_IMAGES['CROP_BOX']){
                                delete boxeDtls[i].boxName;
                                boxes['cropBox'] = boxeDtls[i];    
                            }
                            if(boxeDtls[i].boxName==Constants.BOX_IMAGES['MEDIA_BOX']){
                                delete boxeDtls[i].boxName;
                                boxes['mediaBox'] = boxeDtls[i];    
                            }
                            if(boxeDtls[i].boxName==Constants.BOX_IMAGES['TRIM_BOX']){
                                delete boxeDtls[i].boxName;
                                boxes['trimBox'] = boxeDtls[i];    
                            }
                        }

                        // console.log('boxes',boxes);
                        // console.log('boxes',boxes.length);

                        // var baseString = this.fileDtlsRes.pitstopMsgSummaryResponseList[0].message;
                        // var level = this.fileDtlsRes.pitstopMsgSummaryResponseList[0].pitstopMsgType;
                        // var errorCategory = this.fileDtlsRes.pitstopMsgSummaryResponseList[0].pitstopMsgType;
                        // var locationList =  this.fileDtlsRes.pitstopMsgSummaryResponseList[0].pitstopMsgLocationSummaryResponseList;
                        
                        // var preflightMessageOccurenceList = this.fileDtlsRes.pitstopMsgSummaryResponseList[0].pitstopMsgvariableSummaryResponse.reduce((obj, item) => Object.assign(obj, { [item.variableType]: item.variableValue }), {});
                        // var messageList = [];
                        // messageList.push({
                        //     baseString:baseString,
                        //     level:level,
                        //     errorCategory:errorCategory,
                        //     locationList:locationList,
                        //     preflightMessageOccurenceList:JSON.stringify(preflightMessageOccurenceList)
                        // });
                        data[0]['boxes'] = boxes;
                        data[0]['pageCount'] = this.fileDtlsRes?.thumbnailImages?.length;
                        data[0]['previewUrl'] = this.fileDtlsRes.previewImages[0];
                        data[0]['images'] = this.images;
                        data[0]['messageList'] = newMessageList;
                        data[0]['token'] = params['token'];
                        this.displayPreview(data[0]);
                    }
                    this.loading = false;
                } else {
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
                this.loading=false;
            }, error => {
                this.loading=false;
            });            
        });
    }
	displayPreview(info : any){
        this.pageNo++;
        WebProof.showPreview(info, info.images, this.pageNo, info.subfolder, info.filename, info.pagecount, info.softproofable, info.uid, info.images, info.width, info.height, Constants.DEV_LINK);
    }

}
