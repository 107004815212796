import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ValidationManager } from 'ng2-validation-manager';
import { GeneralFunction } from 'src/app/common/general-function';
import { ValidationFields, ValidationRules } from 'src/app/common/validationRules-constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Constants } from 'src/app/constant/constants';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';

@Component({
  selector: 'app-edit-add-organization-basic-detail',
  templateUrl: './add-organization-basic-detail.component.html',
  styleUrls: ['./add-organization-basic-detail.component.css']
})
export class AddOrganiztionBasicDetailComponent implements OnInit {
    @ViewChild("child") addressComponent: any;
    @Output() loadingCustomer = new EventEmitter<any>();
    @Input() addFromPopup: any;
    orgTypeOpt: any[]=[];
    accountManagerOpt: any;
    back = GeneralFunction.goBack;
    currenciesOpt:any;
    selectedCurrency:any;
    salesManagerOpt: any;
    projectManagerOpt: any;
    formData: any;
    organizationform: any;
    languageOpt: any;
    data: any;
    organizationId: any;
    chkUniqueFlag: boolean = false;
    msg: any;
    id: any;
    sub: any;
    isAdd: boolean = true;
    loading: boolean = false;
    isInvalid: boolean = false;
    validationErrors: any;
    //for dropdown selection value
    selectedOrgType: any='';
    selectedLang: any=1;
    selectedCountry: any;
    selectedCity: any;
    userData: any;
    rowPerPage: any;
    printOpt: any;
    selected_print_opt: any;
    group_opt: any;
    selectedGroup: any;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    sessionName: any;
    isEditProfile: boolean = false;
    is_print_opt: any = '';
    is_distribution_opt: any = '';
    is_other_opt: any = '';
    page_title: any;
    selectedDueDays: any=30;
    selectedDueDaysRule: any='DAYS_NET';
    selectedVatNo: any;
    dueDaysRuleList: any[]=[];
    showDueDaysFields: any;
    orgFormSubmitted: any;
    selectedProjectManager: any;
    selectedAccountManager: any;
    selectedSalesManager: any;
    isSupplierSelected:boolean=false;
    isBothSelected:boolean=false;
    invoiceTypeOpt:any[]=[];
    selectedInvoiceType:any='';
    orgRegNumber:any='';
    isOrganizationTabDisabled:boolean=false;
    supplierProfiles: any = [];

  constructor(public router: Router, private translate: TranslateService,private titleService: Title, private route: ActivatedRoute, private cdRef : ChangeDetectorRef, private responseHandler: ResponseHandlerService, private apiCallingService: ApiCallingService) {
    this.userData = localStorage.getItem(Constants.SESSION_NAME);
    var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
    this.userData = JSON.parse(sessionData);
    if (this.userData != null) {
        translate.setDefaultLang(this.userData.i18nCode);
    } else {
        translate.setDefaultLang(Constants.DEFAULT_LANG);
    }
    this.organizationform = new ValidationManager(ValidationRules.organizationRules);
    this.translateValidationErrorMessage();
}
  ngOnInit(): void {    
    this.organizationform.reset();
    this.isAdd = true;
    this.loading = true;
    setTimeout(() => {
        this.orgTypeOpt.push(
            {
                label: this.translate.instant("COMMON.DEFAULT_SELECT_TEXT"),
                value: ''
            },
            {
                label: this.translate.instant("ORDER.LBL_SUPPLIER"),
                value: Constants.ORG_TYPES['SUPPLIER']
            },
            {
                label: this.translate.instant("ORDER.LBL_ORDER_CUSTOMER"),
                value: Constants.ORG_TYPES['CUSTOMER']
            },
            {
                label: this.translate.instant("ORDER.LBL_BOTH"),
                value: Constants.ORG_TYPES['BOTH']
            }
        );
        this.dueDaysRuleList.push(
            {
                label: this.translate.instant("FIELDS.DAYS_NET"),
                value: Constants.DAY_RULE['DAYS_NET']
            },
            {
                label: this.translate.instant("FIELDS.NEXT_MONTH_FIRST_DAY"),
                value: Constants.DAY_RULE['NEXT_MONTH_FIRST_DAY']
            }
        );
        this.invoiceTypeOpt.push(
            {
                label: this.translate.instant("FIELDS.POST"),
                value: Constants.INVOICE_TYPE['POST']
            },
            {
                label: this.translate.instant("FIELDS.EMAIL"),
                value: Constants.INVOICE_TYPE['EMAIL']
            }
        );
    }, Constants.validationTimeout);
    
    this.sub = this.route.params.subscribe(params => {
    if (params['id'] && !this.isAdd) {
        this.isAdd = false;
        this.organizationId = params['id'];
        var apiEndPoint:any = ApiEndpointConstant.organization+this.organizationId+'/edit';
        this.getAddUpdateData(apiEndPoint);
        setTimeout(() => {
              this.titleService.setTitle(this.translate.instant("ORG.EDIT_ORG") + Constants.PIPELINE_TITLE);
        }, Constants.validationTimeout);
    }else{
        var apiEndPoint:any = ApiEndpointConstant.organization+'add';
        this.getAddUpdateData(apiEndPoint);
        setTimeout(() => {
              this.titleService.setTitle(this.translate.instant("ORG.ADD_ORG") + Constants.PIPELINE_TITLE);
        }, Constants.validationTimeout);
    }
  });
}

translateValidationErrorMessage() {
    setTimeout(() => {
        this.organizationform.setErrorMessage(ValidationFields.organizationfields.invoiceEmail, Constants.validationType.email, this.translate.instant("VALIDATION_MESSAGE.EMAIL", {field: this.translate.instant("FIELDS.INVOICE_EMAIL")}));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.invoiceEmail, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {field: this.translate.instant("FIELDS.INVOICE_EMAIL"),
            maxlength: Constants.fieldTypes.maxStringLength500
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.contactEmail, Constants.validationType.email, this.translate.instant("VALIDATION_MESSAGE.EMAIL", {field: this.translate.instant("FIELDS.CONTACT_EMAIL")}));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.invoiceEmail, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
            field: this.translate.instant("FIELDS.INVOICE_EMAIL_SMALL"),
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.contactEmail, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
            field: this.translate.instant("FIELDS.CONTACT_EMAIL"),
            maxlength: Constants.fieldTypes.maxStringLength500
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.registrationNumber, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.REGISTRATION_NUMBER")}));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.registrationNumber, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {field: this.translate.instant("FIELDS.REGISTRATION_NUMBER"),
            maxlength: Constants.fieldTypes.maxStringLength250
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.name, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.NAME")}));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.name, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
            field: this.translate.instant("FIELDS.NAME"),
            maxlength: Constants.fieldTypes.maxStringLength500
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.type, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.TYPE")}));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.defaultLocaleId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.LANGUAGE")
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.website, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
            field: this.translate.instant("FIELDS.WEBSITE"),
            maxlength: Constants.fieldTypes.maxStringLength500
        }));

        this.organizationform.setErrorMessage(ValidationFields.organizationfields.vatNumber, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
            field: this.translate.instant("FIELDS.VAT_NUMBER"),
            maxlength: Constants.fieldTypes.maxStringLength250
        }));
    }, Constants.validationTimeout);
}
  onOrgNoChange(value:any) {
    this.loading = true;

    if(this.organizationId){
        var url:any = ApiEndpointConstant.organization+this.organizationId+'/chkRegistrationNumber/'+value;
    }else{
        var url:any = ApiEndpointConstant.organization+'chkRegistrationNumber/'+value;
    }
    this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, url)
    .subscribe(result => {
        this.data = result;
        this.loading = false;
        if (this.data.resultCode == Constants.resultCode.SUCCESS) {
        }else{
            this.responseHandler.setErrorPopupMessage(this.data.message);
            this.orgRegNumber = '';
        }
    }, error => {
        this.loading = false;
        this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
    });
}
getAddUpdateData(apiEndPoint:any){
    this.selectedLang = 1;
    this.selectedCountry = "";
    this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, apiEndPoint)
    .subscribe(result => {
        this.data = result;
        if (this.data.resultCode == Constants.resultCode.SUCCESS) {
            this.selectedProjectManager = this.data.defaultProjectManagerUserId;
            this.selectedSalesManager = this.data.defaultSalesManagerUserId;
            this.selectedAccountManager = this.data.defaultAccountManageruserId;
            
            this.selectedOrgType = !GeneralFunction.checkForNullUndefinedBlank(this.data.type) ? this.data.type :'';
            this.selectedCurrency = this.data.defaultCurrencyId;
            this.organizationform.setValue({
                registrationNumber:this.data.registrationNumber,
                name:this.data.name,
                type:this.data.type,
                defaultLocaleId:this.data.defaultLocaleId,
                invoiceEmail:this.data.invoiceEmail,
                contactEmail:this.data.contactEmail,
                website:this.data.website,
                vatNumber:this.data.vatNumber,
                // invoiceType:this.data.invoicetype,
                paymentDueDate:this.data.paymentDueDate,
                paymentDueDateRule:this.data.paymentDueDateRule
            });


            setTimeout(() => {
                this.selectedInvoiceType = this.data.invoicetype;
            }, Constants.validationTimeout);
            
            this.manageDueDaysFields();

            if(this.data.supplierProfiles && this.data.supplierProfiles?.length >0){
                for(var i=0; i < this.data.supplierProfiles?.length; i++){
                    if(this.data.supplierProfiles[i].supplierProfile===Constants.PROFILE_TYPES['PRINTING']){
                        this.supplierProfiles.push(this.data.supplierProfiles[i].supplierProfile);
                    }else if(this.data.supplierProfiles[i].supplierProfile===Constants.PROFILE_TYPES['DISTRIBUTION']){
                        this.supplierProfiles.push(this.data.supplierProfiles[i].supplierProfile);
                    }else if(this.data.supplierProfiles[i].supplierProfile===Constants.PROFILE_TYPES['OTHER']){
                        this.supplierProfiles.push(this.data.supplierProfiles[i].supplierProfile);
                    }
                }
            }

            // this.selectedLang = (this.data.defaultLocaleId)?this.data.defaultLocaleId:1;
            // this.selectedProjectManager = (this.data.defaultProjectManagerUserId)?this.data.defaultProjectManagerUserId:'';
            // this.selectedAccountManager = (this.data.defaultAccountManagerUserId)?this.data.defaultAccountManagerUserId:'';
            // this.selectedSalesManager = (this.data.defaultSalesManagerUserId)?this.data.defaultSalesManagerUserId:'';
            // this.selectedCurrency = (this.data.defaultCurrencyId)?this.data.defaultCurrencyId:'';
            this.languageOpt = GeneralFunction.getDropDownData(this.data.locales);
            this.currenciesOpt = GeneralFunction.getDropDownData(this.data.currencies);
            this.accountManagerOpt = GeneralFunction.getAccountSalesProjectManagerData(this.data.accountManagers);
            this.salesManagerOpt = GeneralFunction.getAccountSalesProjectManagerData(this.data.salesManagers);
            this.projectManagerOpt = GeneralFunction.getAccountSalesProjectManagerData(this.data.projectManagers);
        }
        this.loading = false;
    }, error => {
        this.loading = false;
        this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
    });
}
manageDueDaysFields() {
    if (this.selectedOrgType == Constants.ORG_TYPES.SUPPLIER) {
        this.showDueDaysFields = true;
        this.isSupplierSelected = true;
        this.isBothSelected= false;
    } else if (this.selectedOrgType == Constants.ORG_TYPES.CUSTOMER) {
        this.showDueDaysFields = false;
        this.isSupplierSelected= false;
        this.isBothSelected= false;
    }else if (this.selectedOrgType == Constants.ORG_TYPES.BOTH) {
        this.showDueDaysFields = true;
        this.isBothSelected= true;
        this.isSupplierSelected = false;
    }
}
setLoading(val: any): void {
    this.loadingCustomer.emit(val);
}
onSubmit() {
    if (this.organizationform.isValid()){
        this.loading = true;
        this.formData = this.organizationform.getData();        
        this.formData.supplierProfiles = [];
        if(this.formData.type!=Constants.ORG_TYPES.CUSTOMER){
            let supplierProfilesArray = this.supplierProfiles
            this.formData.supplierProfiles = supplierProfilesArray.filter(function(item: any, pos: any) {
              return supplierProfilesArray.indexOf(item) == pos;
            });
        }

        if(!this.organizationId){
            var apiEndPoint:any = ApiEndpointConstant.organization+'add';
            this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.POST, apiEndPoint)
            .subscribe(result => {
                this.data = result;
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                    if(!this.addFromPopup){
                        localStorage.setItem('redirectMsg', this.data.message);
                        const url = Constants.URLS['CONTACT_PER_MANAGEMENT'];
                        this.router.navigateByUrl(url);    
                    }else{
                        this.setLoading(result.object);
                    }                   
                }else{
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.isInvalid = true;
                this.validationErrors = [];
                this.validationErrors = GeneralFunction.getValidationError(error);
            });
        }else if(this.organizationId){
            var apiEndPoint:any = ApiEndpointConstant.organization+this.organizationId+'/edit';
            this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.PUT, apiEndPoint)
            .subscribe(result => {
                this.data = result;
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                    localStorage.setItem('redirectMsg', this.data.message);
                    const url = Constants.URLS['ORG_MANAGEMENT'];
                    this.router.navigateByUrl(url);
                }else{
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.isInvalid = true;
                this.validationErrors = [];
                this.validationErrors = GeneralFunction.getValidationError(error);
            });
        }
    }
}
}
