<div id="wrap">
<div id="content">
<p-toast position="top-right"></p-toast>
<div class="container wrapBox">
    <div class="row">
        <div class="col-lg-12">
            <div class="contact-area full-container">
                <h3 *ngIf="isAdd && (!lastInsertedOrganizationIdStatus)">{{ 'ORG_ADDRESS.ADD_ADDRESS' | translate }}</h3>
                <h3 *ngIf="!isAdd && (!lastInsertedOrganizationIdStatus)">{{ 'ORG_ADDRESS.EDIT_ADDRESS' | translate }}</h3>
                <div class="contact-fields content-area">
                    <form [formGroup]="orgAddressForm.getForm()" (ngSubmit)="onSubmit()" method="post" >
                    <div class="row">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'FIELDS.ORGANIZATION' | translate }} <small class="madatory-star">*</small></label>  
                            
                            <p-dropdown #selectElem  styleClass="searchable-select txt-theme form-control" [options]="organizationOpt" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" formControlName="organization" [(ngModel)]="orgId" [filter]="true" id="organization" name="organization" [disabled]="(lastInsertedOrganizationId || paramsOrgId)"></p-dropdown>
                            
                            <div *ngIf="orgAddressForm.hasError('organization')" class="alert alert-danger">
                                {{orgAddressForm.getError('organization')}}
                            </div>
                        </div>
                    	<div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'FIELDS.TYPE' | translate }} <small class="madatory-star">*</small></label> 
                            <select #selectAddressTypeElem [(ngModel)]="selectedAddressType" class="txt-theme form-control" formControlName="addressType" id="addressType" name="addressType" (change)="checkAddressType()" [attr.disabled]="!isAdd ? '' : null">
                                <option *ngFor="let type of addressTypeOpt" [value]="type.value">{{type.label}}</option>
                            </select>
                            <div *ngIf="orgAddressForm.hasError('addressType')" class="alert alert-danger">
                                {{orgAddressForm.getError('addressType')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressType']}}
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_ONE' | translate }} <small class="madatory-star">*</small></label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="addressLine1" name="addressLine1" id="addressLine1" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_ONE' | translate }}"/>
                            <div *ngIf="orgAddressForm.hasError('addressLine1')" class="alert alert-danger">
                                {{orgAddressForm.getError('addressLine1')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressLine1']}}
                            </div>
                        </div>
                    	<div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_TWO' | translate }}</label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="addressLine2" name="addressLine2" id="addressLine2" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_TWO' | translate }}"/>
                            <div *ngIf="orgAddressForm.hasError('addressLine2')" class="alert alert-danger">
                                {{orgAddressForm.getError('addressLine2')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressLine2']}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ADDLINE_THREE' | translate }}</label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="addressLine3" name="addressLine3" id="addressLine3" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ADDLINE_THREE' | translate }}"/>
                            <div *ngIf="orgAddressForm.hasError('addressLine3')" class="alert alert-danger">
                                {{orgAddressForm.getError('addressLine3')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['addressLine3']}}
                            </div>
                        </div>
                    	<div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_ZIPCODE' | translate }} <small class="madatory-star">*</small></label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="zipCode" name="zipCode" id="zipCode" placeholder="{{ 'CONT_PER.PLACEHOLDER_CONT_PER_ZIPCODE' | translate }}"/>
                            <div *ngIf="orgAddressForm.hasError('zipCode')" class="alert alert-danger">
                                {{orgAddressForm.getError('zipCode')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['zipCode']}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_COUNTRY' | translate }} <small class="madatory-star">*</small></label>
                            <p-dropdown #selectElem  styleClass="searchable-select txt-theme form-control" [options]="countryOpt" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" formControlName="countryId" [(ngModel)]="selectedCountry" [filter]="true" id="countryId" name="countryId"></p-dropdown>
                            <div *ngIf="orgAddressForm.hasError('countryId')" class="alert alert-danger">
                                {{orgAddressForm.getError('countryId')}}
                            </div>
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['countryId']}}
                            </div>
                        </div>
                    	<div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label>{{ 'CONT_PER.LBL_CONT_PER_CITY' | translate }} </label>
                            <input  class="txt-theme form-control" pInputText type="text" formControlName="city" name="city" id="city" placeholder="{{ 'ORG.LBL_ORG_CITY' | translate }}" [(ngModel)]="selectedCity"/> 
                            <div *ngIf="isInvalid" class="alert alert-danger">
                                {{validationErrors['city']}}
                            </div>
                        </div>
                    </div>
                    
                    <div class="clearfix"></div>
                    <div class="btn-area">
                        <!-- <button type="button" class="btn btn-default btn-theme-disable" (click)="skip()" *ngIf="isAdd && lastInsertedOrganizationId">{{ 'FIELDS.SKIP' | translate }}</button> -->
                        <button class="btn-theme" id="btn-login"  type="submit"  [disabled]="!orgAddressForm.isValid" *ngIf="isAdd">{{ 'CONT_PER.BTN_LBL_CONT_PER_ADD' | translate }}</button>
                        <button class="btn-theme" id="btn-login"  type="submit"  [disabled]="!orgAddressForm.isValid" *ngIf="!isAdd" >{{ 'CONT_PER.BTN_LBL_CONT_PER_UPDATE' | translate }}</button>
                        <!-- <button type="button" class="btn btn-default btn-theme-disable" (click)="resetForm()" *ngIf="isAdd">
                        {{ 'CONT_PER.BTN_LBL_CONT_PER_CLEAR' | translate }}
                        </button> -->
                        <button type="button" class="btn btn-theme" (click)="skip()" *ngIf="isAdd && lastInsertedOrganizationId">
                        {{ 'FORGOT_PSWD.BTN_LBL_SUBMIT' | translate }}
                        </button>                        
                        <button type="button" class="btn btn-default btn-theme-disable" (click)="back()">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_BTN_CANCEL' | translate }}</button>
                    </div>
                    <input  type="hidden" name="default_select_text" id="default_select_text" value="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}">
                    <!-- <input  type="hidden" name="pipeline_user_dtl_id" formControlName="pipeline_user_dtl_id" id="pipeline_user_dtl_id" value=""> -->
                    </form>
                </div>
            </div>
            <div class="table-responsive organizationAddressTableWrapper" *ngIf="isAdd && lastInsertedOrganizationId">
                <p-table #orgAddressDt [value]="organizationAddressesList" [rows]="datatableRowsVal" 
                [showCurrentPageReport]="true" [totalRecords]="totalRecords" [rowsPerPageOptions]="rowPerPage" styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="false" currentPageReportTemplate="{{ 'FIELDS.SHOWING' | translate }} {first} {{ 'FIELDS.TO' | translate }} {last} {{ 'FIELDS.OF' | translate }} {totalRecords} {{ 'FIELDS.ENTRIES' | translate }}">
                    <ng-template pTemplate="caption">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                    </div>
                                </div>
                            </div>
                            <div class="p-d-flex searchbox col-lg-3 col-md-4 col-sm-12">
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let tableHeading of cols" [pSortableColumn]="tableHeading?.sortField" [style.width]="tableHeading.colWidth">{{tableHeading.colName}}<p-sortIcon *ngIf="tableHeading.isSort" [field]="tableHeading?.sortField"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-orgAddress>
                        <tr>
                            <td>{{orgAddress.srNo}}</td>
                            <td>{{orgAddress.addressType}}</td>
                            <td>{{orgAddress.addressStr}}</td>
                            <td>{{orgAddress.countryName}}</td>
                            <td>{{orgAddress.cityName}}</td>
                            <td>{{orgAddress.zipCode}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-organization>
                        <tr>
                            <td [attr.colspan]="cols?.length">{{emptyMessage}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="my-container">
        <ngx-loading [show]="loading"></ngx-loading>
    </div>
</div>