import { Constants } from "./constants"

export class HeaderConstant {
    
    public static field: any = {
        acceptLabel: 'Accept',
        contentType: 'Content-Type',
        xRequestWith: 'X-Requested-With',
        authorization: 'Authorization',
    }
    public static value: any = {
        contentTypeValue: 'application/json',
        requestType: 'XMLHttpRequest',
        authorizationType: 'Bearer ',
    }

    public static authHeader: any = {
        //'Accept': 'application/json',
        //'Content-Type':  'application/json',
        //'Authorization': localStorage.getItem(Constants.Authorization),
        //'Accept-Language':localStorage.getItem(Constants.acceptLanguage)
    }
    public static simpleHeader = {
        Accept: 'application/json',
        'Content-Type':  'application/json'
    }

   
    public static authHeaderWithMultipart = {
         // Accept: 'application/json',
        'Content-Type': 'undefined'
    }
}