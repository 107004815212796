import { Component, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ValidationManager} from "ng2-validation-manager";
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {HttpClient, HttpRequest, HttpEvent,HttpHeaders} from '@angular/common/http';
import 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { Constants} from '../../constant/constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Urls } from 'src/app/constant/urls';
import {ConfirmationService} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { GeneralFunction } from '../../common/general-function';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { ValidationFields, ValidationRules } from '../../common/validationRules-constants';
import { MessageService } from 'primeng/api';
import { Messages} from '../../common/messages';

@Component({
  selector: 'app-add-organization-address',
  templateUrl: './add-organization-address.component.html',
  styleUrls: ['./add-organization-address.component.css']
})
export class AddOrganizationAddressComponent implements OnInit {
  userData:any;
  isAdd:boolean = true;
  loading:boolean=false;
  sub:any;
  addressId:any;
  orgId:any = '';
  data:any;
  orgAddressForm:any;
  isInvalid:boolean=false;
  validationErrors: any;
  countryOpt:any;
  selectedCountry:any;
  selectedCity:any;
  selectedAddressType:any='';
  addressTypeOpt:any[]=[];
  formData:any;
  back = GeneralFunction.goBack;
  @Input() lastInsertedOrganizationId: any;
  @Input() lastInsertedOrganizationIdStatus:boolean=false;
  emptyMessage:any;
  cols:any;
  totalRecords:any;
  organizationOpt:any[]=[];
  datatableRowsVal: number = 10;
  rowPerPage: any = Constants.ROW_PER_PAGE;
  organizationAddressesList:any[]=[];
  disableOrganization: boolean;
  paramsOrgId:any;
  constructor(private formBuilder: FormBuilder, public router: Router, private translate: TranslateService, private http: HttpClient,private titleService: Title, private route: ActivatedRoute, private messageService: MessageService, private cdRef : ChangeDetectorRef, private responseHandler: ResponseHandlerService,private apiCallingService: ApiCallingService) { 
    
    this.userData = localStorage.getItem(Constants.SESSION_NAME);
    var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
    this.userData = JSON.parse(sessionData);
    if (this.userData != null) {
        translate.setDefaultLang(this.userData.i18nCode);
    } else {
        translate.setDefaultLang(Constants.DEFAULT_LANG);
    }
    this.orgAddressForm = new ValidationManager(ValidationRules.orgAddressRules);
    this.translateValidationErrorMessage();
    this.getInitData();
    
  }

  ngOnInit(): void {
    
    setTimeout(() => {
            this.emptyMessage = this.translate.instant('SUCCESS_MSG.NO_RECORD_FOUND');
            this.cols = [{
                    colName: this.translate.instant('FIELDS.NO'),
                    isSort: true,
                    sortField: 'srNo',
                    colWidth: '4%'
                },
                {
                    colName: this.translate.instant('ORG.PLACEHOLDER_ORG_NAME'),
                    isSort: true,
                    sortField: 'organizationName',
                    colWidth: '15%'
                },
                {
                    isSort: false,
                    colName: this.translate.instant('FIELDS.ADDRESS'),
                    colWidth: '30%',

                },
                {
                    isSort: true,
                    colName: this.translate.instant('FIELDS.COUNTRY'),
                    colWidth: '10%',
                    sortField: 'countryName'
                },
                {
                    isSort: true,
                    colName: this.translate.instant('FIELDS.CITY'),
                    colWidth: '10%',
                    sortField: 'city'
                },
                {
                    isSort: true,
                    colName: this.translate.instant('FIELDS.ZIP_CODE'),
                    colWidth: '10%',
                    sortField: 'zipCode'
                },
                {
                    colName: this.translate.instant('PIPE_USER.COL_LBL_PIPE_USER_LIST_ACTION'),
                    isSort: false,
                    colWidth: '8%'
                }
            ];
        }, Constants.validationTimeout);
    setTimeout(() => {
        this.emptyMessage = this.translate.instant('SUCCESS_MSG.NO_RECORD_FOUND');
        this.cols = [{
                colName: this.translate.instant('FIELDS.NO'),
                isSort: false,
                colWidth: '4%',
            },
            {
                colName: this.translate.instant('FIELDS.TYPE'),
                isSort: false,
                colWidth: '15%',
            },
            {
                isSort: false,
                colName: this.translate.instant('FIELDS.ADDRESS'),
                colWidth: '30%',

            },
            {
                isSort: false,
                colName: this.translate.instant('FIELDS.COUNTRY'),
                colWidth: '10%',
            },
            {
                isSort: false,
                colName: this.translate.instant('FIELDS.CITY'),
                colWidth: '10%',
            },
            {
                isSort: false,
                colName: this.translate.instant('FIELDS.ZIP_CODE'),
                colWidth: '10%',
            },
            // {
            //     colName: this.translate.instant('PIPE_USER.COL_LBL_PIPE_USER_LIST_ACTION'),
            //     isSort: false,
            //     colWidth: '8%'
            // }
        ];
    }, Constants.validationTimeout);

    this.translate.get('ORG_ADDRESS').subscribe((text: any) => {
        this.addressTypeOpt = [
            {label: text.DEFAULT_SELECT_TEXT, value: ''},
            {label: text.ADDRESS_TYPE_INVOICE, value: Constants.ADDRESS_TYPES['INVOICE']},
            {label: text.ADDRESS_TYPE_DELIVERY, value: Constants.ADDRESS_TYPES['DELIVERY']},
            {label: text.ADDRESS_TYPE_CONTACT, value: Constants.ADDRESS_TYPES['CONTACT'] }
        ];
    });
  }
    translateValidationErrorMessage() {
        setTimeout(() => {
            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.addressLine1, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_1"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.addressLine2, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_2"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.addressLine3, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_3"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.zipCode, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ZIP_CODE"),
                maxlength: Constants.fieldTypes.maxStringLength50
            }));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.city, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.CITY"),
                maxlength: Constants.fieldTypes.maxStringLength250
            }));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.organization, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ORGANIZATION")}));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.addressLine1, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ADDRESS_LINE_1")}));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.zipCode, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ZIP_CODE")}));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.addressType, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ADDRESS_TYPE")}));

            this.orgAddressForm.setErrorMessage(ValidationFields.orgAddressfields.countryId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.COUNTRY")}));
        }, Constants.validationTimeout);
    }

    getInitData() {
        // this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            if(params['action']){
                if(params['action']==Constants.ACTION['ADD']){
                    this.isAdd = true;
                    if (this.userData.type === Constants.USER_TYPE.PIPELINE_USER) {
                        this.disableOrganization = false;
                    } else {
                        this.disableOrganization = true;
                    }
                    setTimeout(() => {
                          this.titleService.setTitle(this.translate.instant("ORG_ADDRESS.ADD_ADDRESS") + Constants.PIPELINE_TITLE);
                    }, Constants.validationTimeout);

                }else if(params['action']==Constants.ACTION['EDIT']){
                    this.isAdd = false;
                    this.disableOrganization = true;
                    setTimeout(() => {
                          this.titleService.setTitle(this.translate.instant("ORG_ADDRESS.EDIT_ADDRESS") + Constants.PIPELINE_TITLE);
                    }, Constants.validationTimeout);
                }
                if (!GeneralFunction.checkForNullUndefinedBlank(params['addressId']) && 
                    !GeneralFunction.checkForNullUndefinedBlank(params['orgId'])) {
                    this.isAdd = false;
                    this.disableOrganization = true;
                    this.addressId = params['addressId'];
                    this.orgId = params['orgId'];
                    this.paramsOrgId = params['orgId'];
                    var apiEndPoint:any = ApiEndpointConstant.organization+this.orgId+'/address/'+this.addressId+'/edit';
                    this.getAddUpdateData(apiEndPoint);
                }else if(GeneralFunction.checkForNullUndefinedBlank(params['addressId']) && 
                    !GeneralFunction.checkForNullUndefinedBlank(params['orgId'])){
                    this.orgId = params['orgId'];
                    this.paramsOrgId = params['orgId'];
                    var apiEndPoint:any = ApiEndpointConstant.organization+'address/add';
                    this.getAddUpdateData(apiEndPoint);
                }else{
                    var apiEndPoint:any = ApiEndpointConstant.organization+'address/add';
                    this.getAddUpdateData(apiEndPoint);
                }               
            }else{
                var apiEndPoint:any = ApiEndpointConstant.organization+'address/add';
                this.getAddUpdateData(apiEndPoint);
            }
        });
    }

    getAddUpdateData(apiEndPoint:any){
       this.loading = true;
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, apiEndPoint)
        .subscribe(result => {
            this.data = result;
            if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                this.countryOpt = GeneralFunction.getDropDownData(this.data.countries);
                if(!GeneralFunction.checkForNullUndefinedBlank(this.data.organizations)){
                    this.organizationOpt = GeneralFunction.getDropDownDataForPrimengInStringVal(this.data.organizations)
                }
                this.orgAddressForm.setValue({
                    addressLine1: this.data.addressLine1,
                    addressLine2: this.data.addressLine2,
                    addressLine3: this.data.addressLine3,
                    zipCode: this.data.zipCode,
                    city: this.data.cityName,
                    countryId: this.data.countryId,
                    // organization:this.data.organization.id
                });
                if (!GeneralFunction.checkForNullUndefinedBlank(this.lastInsertedOrganizationId)) {
                    console.log('lastInsertedOrganizationId',this.lastInsertedOrganizationId.toString());
                    this.orgId = this.lastInsertedOrganizationId.toString();
                }
                this.selectedCountry = this.data.countryId;
                this.selectedAddressType = (!GeneralFunction.checkForNullUndefinedBlank(this.data.addressType)) ? this.data.addressType:'';
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }

    onSubmit(){
      if (this.orgAddressForm.isValid()) {
            this.formData = this.orgAddressForm.getData();
            console.log('this.formData',this.formData);
            if (!GeneralFunction.checkForNullUndefinedBlank(this.addressId) && !GeneralFunction.checkForNullUndefinedBlank(this.orgId)) {
                this.loading = true; 
                //Update contact form
                this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.PUT, ApiEndpointConstant.organization+this.orgId+'/address/'+this.addressId+'/edit')
                .subscribe(result => {
                    this.data = result;
                    if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                        localStorage.setItem('redirectMsg', this.data.message);
                        if(this.userData.type==Constants.USER_TYPE['PIPELINE_USER']){
                            var url:any = Constants.URLS['ORG_ADDRESS_LIST'].replace(':id', this.orgId);
                        }else{
                            var url:any = Constants.URLS['MANAGE_ADDRESS'];
                        }
                        
                        // console.log('url',url);
                        this.router.navigateByUrl(url);
                    }else{
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.isInvalid = true;
                    this.validationErrors = [];
                    this.validationErrors = GeneralFunction.getValidationError(error);
                });
                
            }else if(GeneralFunction.checkForNullUndefinedBlank(this.addressId) && (!GeneralFunction.checkForNullUndefinedBlank(this.orgId) || !GeneralFunction.checkForNullUndefinedBlank(this.lastInsertedOrganizationId))) {
                this.loading = true;
                if(!GeneralFunction.checkForNullUndefinedBlank(this.orgId)){
                    var orgId:any = this.orgId;
                }else{
                    var orgId:any = this.lastInsertedOrganizationId;
                }
                this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.POST, ApiEndpointConstant.organization+orgId+'/address/add')
                .subscribe(result => {
                    this.data = result;
                    if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                        var address:any[] = [this.formData.addressLine1];
                        if (!GeneralFunction.checkForNullUndefinedBlank(this.formData.addressLine2)) {
                            address.push(this.formData.addressLine2);
                        }
                        if (!GeneralFunction.checkForNullUndefinedBlank(this.formData.addressLine3)) {
                            address.push(this.formData.addressLine3);
                        }
                        var countryName:any = GeneralFunction.getNameById(this.countryOpt, this.formData.countryId);
                        var addressType:any = GeneralFunction.capitalizeStr(this.formData.addressType);
                        this.organizationAddressesList.push({
                            srNo: this.organizationAddressesList.length+1,
                            addressType:addressType,
                            addressStr:address.join(),
                            cityName:this.formData.city,
                            zipCode:this.formData.zipCode,
                            countryName:countryName
                        });
                        this.responseHandler.setSuccessPopupMessage(result.message);
                        // this.resetForm();
                        if(!this.lastInsertedOrganizationId){
                            setTimeout(() => {
                                this.titleService.setTitle(this.translate.instant("ORG_ADDRESS.ADD_ADDRESS") + Constants.PIPELINE_TITLE);
                            }, Constants.validationTimeout);
                            window.history.back();
                        }else{
                            this.orgAddressForm.setValue({
                                addressLine1: '',
                                addressLine2: '',
                                addressLine3: '',
                                zipCode: '',
                                city: '',
                                countryId: '',
                                addressType:''
                                // organization:this.data.organization.id
                            });
                            this.orgId = this.lastInsertedOrganizationId.toString();
                        }
                    }else{
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.isInvalid = true;
                    this.validationErrors = [];
                    this.validationErrors = GeneralFunction.getValidationError(error);
                });
            }
        }
    }
    resetForm(){
        this.orgAddressForm.reset();
    }

    skip(){
        let orgId:any;
        let url: any;
        if (this.userData.type === Constants.USER_TYPE.PIPELINE_USER) {
            if(!GeneralFunction.checkForNullUndefinedBlank(this.orgId)){
                orgId = this.orgId;
            }else if(!GeneralFunction.checkForNullUndefinedBlank(this.orgId)){
                orgId = this.lastInsertedOrganizationId;
            }
            if (!GeneralFunction.checkForNullUndefinedBlank(orgId)) {
                url = Constants.URLS['ORG_ADDRESS_LIST'].replace(':id', orgId);
            } else {
                url = Constants.URLS['MANAGE_ADDRESS'];
            }
        } else {
            url = Constants.URLS['MANAGE_ADDRESS'];
        }
        console.log('url',url)
        this.router.navigateByUrl(url);
    }


    checkAddressType(){
        if(!GeneralFunction.checkForNullUndefinedBlank(this.selectedAddressType) && this.selectedAddressType!=Constants.ADDRESS_TYPES['DELIVERY']){
            if(!GeneralFunction.checkForNullUndefinedBlank(this.orgId) && !GeneralFunction.checkForNullUndefinedBlank(this.addressId)){
                var endPoint:any = ApiEndpointConstant.checkAddresTypeEdit(this.orgId, this.addressId, this.selectedAddressType);
            }else if(!GeneralFunction.checkForNullUndefinedBlank(this.orgId) && GeneralFunction.checkForNullUndefinedBlank(this.addressId)){
                var endPoint:any = ApiEndpointConstant.checkAddresTypeAdd(this.orgId, this.selectedAddressType);
            }
            this.loading = true;
            this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.GET, endPoint).subscribe(result => {
                this.data = result;
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                }else{
                    this.responseHandler.setErrorPopupMessage(result.message);
                    this.orgAddressForm.setValue('addressType', '');
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.isInvalid = true;
                this.validationErrors = [];
                this.validationErrors = GeneralFunction.getValidationError(error);
            });
        }
    }
}
