<p-accordion id="basic_and_other_details" [multiple]="true" styleClass="basic-other-dtls">
    <p-accordionTab [selected]="true" header="{{'RFQ.LBL_BASIC_AND_OTHER_DETAILS' | translate}}">
        <div class="row rfq_confirm">
            <div class="form-group product-general-dtls col-md-6 col-sm-6 col-xs-12">
                <h4>{{ 'RFQ.LBL_BASIC_DTLS' | translate }}</h4>
                <div class="confirm-dtls" *ngIf="(this.userData.type == 'SUPPLIER_USER' && data.isOrder) || this.userData.type == 'PIPELINE_USER' || this.userData.type == 'CUSTOMER_USER'">
                    <label class="lbl-title">{{ 'RFQ.LBL_PROJECT_NAME' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.projectName}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.operationType == 'REJECTED' && data.isRFQ && data.rejectionReasonComment">
                    <label class="lbl-title">{{ 'RFQ.LBL_REJECTION_REASON' | translate }}</label>
                    <label class="lbl-value verticletop">{{data.rejectionReason}} <span *ngIf="data.rejectionReasonComment">({{data.rejectionReasonComment}})</span></label>
                </div>
                <div class="confirm-dtls" *ngIf="data?.customer_additional_information && this.userData.type=='PIPELINE_USER'">
                    <label class="lbl-title">{{ 'RFQ.PLACEHOLDER_CUSTOMER_ADDITIONAL_INFO' | translate }}</label>
                    <label class="lbl-title" *ngIf="this.userData.type=='CUSTOMER_USER' && data?.customer_additional_information">{{ 'RFQ.LBL_ADDITIONAL_INFO' | translate }}</label>
                    <label *ngIf="this.userData.type=='PIPELINE_USER' || this.userData.type=='CUSTOMER_USER'" class="lbl-value verticletop" [innerHTML]="data?.customer_additional_information"></label>
                </div>
                <div class="confirm-dtls" *ngIf='data?.supplier_additional_information'>
                    <label class="lbl-title" *ngIf="this.userData.type=='PIPELINE_USER' && data?.supplier_additional_information">{{ 'RFQ.PLACEHOLDER_SUPPLIER_ADDITIONAL_INFO' | translate }}</label>
                    <label class="lbl-title" *ngIf="this.userData.type=='SUPPLIER_USER' && data?.supplier_additional_information">{{ 'RFQ.LBL_ADDITIONAL_INFO' | translate }}</label>
                    <label *ngIf="this.userData.type=='PIPELINE_USER' || this.userData.type=='SUPPLIER_USER'" class="lbl-value verticletop" [innerHTML]="data?.supplier_additional_information"></label>
                </div>
                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && data?.customerOrganizationName">
                    <label class="lbl-title">{{ 'LOGIN.CUSTOMER_TEXT' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.customerOrganizationName}}</label>
                </div>

                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && data?.customerContactName">
                    <label class="lbl-title">{{ 'COMMON.LBL_CUSTOMER_CONTACT' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.customerContactName}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="(this.userData.type == 'PIPELINE_USER' || this.userData.type == 'CUSTOMER_USER') && data?.referenceOrderNumber">
                    <label class="lbl-title">{{ 'RFQ.LBL_REFERENCE_ORDER_NO' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.referenceOrderNumber}}</label>
                </div>

                <div class="confirm-dtls" *ngIf="(this.userData.type == 'PIPELINE_USER' || this.userData.type == 'CUSTOMER_USER') && data?.endCustomerName">
                    <label class="lbl-title">{{ 'RFQ.LBL_END_CUSTOMER_NAME' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.endCustomerName}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="(this.userData.type == 'PIPELINE_USER' || this.userData.type == 'CUSTOMER_USER') && data?.endCustomerAddress">
                    <label class="lbl-title">{{ 'RFQ.LBL_END_CUSTOMER_ADDRESS' | translate }}</label>
                    <label class="lbl-value verticletop" [innerHTML]="data?.endCustomerAddress"></label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isRFQ && data.isUrgent && this.userData.type=='PIPELINE_USER'">
                    <label class="lbl-title">{{ 'RFQ.LBL_IS_URGENT' | translate }}</label>
                    <label class="lbl-value verticletop"><i class="fa fa-check icon-theme"></i>
                    </label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isRFQ && data.supplierExpireHours">
                    <label class="lbl-title">{{ 'RFQ.LBL_EXPIRY_TIME' | translate }}</label>
                    <label class="lbl-value verticletop" *ngIf="data.supplierExpireHours">{{data.supplierExpiry}} ({{data.supplierExpireHours}} {{ 'FIELDS.HOURS' | translate }})</label>
                    <label class="lbl-value verticletop" *ngIf="!data.supplierExpireHours">{{data.supplierExpiry}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && data?.communicationMode">
                    <label class="lbl-title">{{ 'RFQ.LBL_COMMUNICATION_MODE' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.communicationMode}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data?.isOrder && data?.customerContactNumber">
                    <label class="lbl-title">{{ 'RFQ.LBL_CUSTOMER_CONTACT_NUMBER' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.customerContactNumber}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="(data.isRFQ) || (this.userData.type!='PIPELINE_USER') && data?.deliveryAddress">
                    <label class="lbl-title">{{ 'RFQ.LBL_DELIVER_TO' | translate }}</label>
                    <label class="lbl-value lbl-address" [innerHTML]="data?.deliveryAddress"></label>
                </div>
                <div class="confirm-dtls" *ngIf="(data.isRFQ) || (this.userData.type!='PIPELINE_USER') && data?.packagingType">
                    <label class="lbl-title">{{ 'RFQ.LBL_DELIVERY_PACKAGING' | translate }}</label>
                    <label class="lbl-value verticletop">{{data?.packagingType}}</label>
                </div>
                <div class="confirm-dtls">
                    <label class="lbl-title">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_TOTAL_WEIGHT' | translate }}</label>
                    <label class="lbl-value verticletop">{{this.userData.type=='CUSTOMER_USER' ? data?.totalWeightCustomer : data?.totalWeightSupplier}}</label>
                </div>
                <div class="confirm-dtls">
                    <label class="lbl-title">{{ 'RFQ.LBL_CONTRACTUAL_TERMS' | translate }}</label>
                    <a href="../../../assets/docs/pipeline-agreement-20150909.pdf" target="_blank">GCGP 2010</a>
                </div>
            </div>
            <div class="form-group product-general-dtls otherDetailsBox col-md-6 col-sm-6 col-xs-12">
                <h4>{{ 'RFQ.LBL_OTHER_DTLS' | translate }}</h4>
                <div class="confirm-dtls" *ngIf="data.isRFQ && this.userData.type!='CUSTOMER_USER' && this.userData.type != 'SUPPLIER_USER'">
                    <label class="lbl-title">{{ 'RFQ.LBL_REQUEST_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="this.userData.type=='PIPELINE_USER' &&
                    (data.operationType!='REJECTED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REPORT')">
                        <p-calendar [(ngModel)]="data.rfqRequestDate" yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="todayDate" (onSelect)="updateData('REQUEST_DATE', data.rfqRequestDate, data.id, true)"></p-calendar>
                    </label>
                    <label *ngIf="(this.userData.type=='PIPELINE_USER' && (data.operationType=='REJECTED' || data.operationType=='CONVERTED' || data.operationType=='VIEW')) || (this.userData.type=='CUSTOMER_USER' && (data.operationType=='CUSTOMER_ACCEPTANCE' || data.operationType=='CUSTOMER_REJECTED' || data.operationType=='REPORT'))" class="lbl-value">{{data.rfqRequestDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isRFQ && this.userData.typr!='CUSTOMER_USER'">
                    <label class="lbl-title">{{ 'RFQ.LBL_RFQ_EXPIRY_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="this.userData.type=='PIPELINE_USER' &&
                    (data.operationType!='REJECTED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REPORT')">
                        <p-calendar [(ngModel)]="data.pipelineExpiryDate" [monthNavigator]="true"  yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="requestDate" (onSelect)="updateData('EXPIRY_DATE', data.pipelineExpiryDate, data.id, true)"></p-calendar>
                    </label>
                    <label class="lbl-value" *ngIf="(this.userData.type=='PIPELINE_USER' && (data.operationType=='REJECTED'  || data.operationType=='CONVERTED' || data.operationType=='VIEW' || data.operationType=='REPORT')) || this.userData.type == 'SUPPLIER_USER'">{{data.pipelineExpiryDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isRFQ && this.userData.type!='CUSTOMER_USER' && this.userData.type != 'SUPPLIER_USER'">
                    <label class="lbl-title">{{ 'RFQ.LBL_EXPECTED_DELIVERY_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="this.userData.type=='PIPELINE_USER' &&
                    (data.operationType!='REJECTED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REPORT')">
                        <p-calendar [(ngModel)]="data.expDeliveryDate" [monthNavigator]="true" yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="expiryDate" (onSelect)="updateData('EXPECTED_DELIVERY_DATE', data.expDeliveryDate, data.id, true)"></p-calendar>
                    </label>
                    <label class="lbl-value" *ngIf="(this.userData.type=='PIPELINE_USER') && (data.operationType=='REJECTED' || data.operationType=='CONVERTED' || data.operationType=='VIEW' || data.operationType=='REPORT')">{{data?.expDeliveryDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isOrder && this.userData.type!='SUPPLIER_USER'">
                    <label class="lbl-title" *ngIf="this.userData.type=='PIPELINE_USER'">{{ 'ORDER.MATERIAL_DATE' | translate }}</label>
                    <label class="lbl-title" *ngIf="this.userData.type=='CUSTOMER_USER'">{{ 'ORDER.LBL_CUSTOMER_MATERIAL_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="data.operationType!='REJECTED' && data.operationType!='COMPLETED' && this.userData.type=='PIPELINE_USER' && data.operationType!='REPORT'">
                        <p-calendar [(ngModel)]="data.customerMaterialDate" [monthNavigator]="true"  yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="todayDate" (onSelect)="updateData('CUSTOMER_MATERIAL_DATE', data.customerMaterialDate, data.id, true)"></p-calendar>
                    </label>
                    <label class="lbl-value" *ngIf="data.operationType=='REJECTED' || data.operationType=='COMPLETED' || this.userData.type=='CUSTOMER_USER' || data.operationType=='REPORT'">{{data.customerMaterialDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isOrder && this.userData.type!='CUSTOMER_USER'">
                    <label class="lbl-title" *ngIf="userData.type=='PIPELINE_USER'">{{ 'ORDER.PRINTING_DATE' | translate }}</label>
                    <label class="lbl-title" *ngIf="userData.type=='SUPPLIER_USER'">{{ 'ORDER.SUPPLIER_PRINTING_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="data.operationType!='REJECTED' && data.operationType!='COMPLETED' && userData.type!='CUSTOMER_USER' && data.operationType!='REPORT'">
                        <p-calendar [(ngModel)]="data.supplierPrintingDate" [monthNavigator]="true"  yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="materialDate" (onSelect)="updateData('SUPPLIER_PRINTING_DATE', data.supplierPrintingDate, data.id, true)"></p-calendar>
                    </label>
                    <label class="lbl-value" *ngIf="data.operationType=='REJECTED' || data.operationType=='COMPLETED' || data.operationType=='REPORT'">{{data.supplierPrintingDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isOrder && this.userData.type!='CUSTOMER_USER'">
                    <label class="lbl-title" *ngIf="this.userData.type=='PIPELINE_USER'">{{ 'ORDER.LBL_SUPPLIER_DELIVERY_DATE' | translate }}</label>
                    <label class="lbl-title" *ngIf="this.userData.type=='SUPPLIER_USER'">{{ 'ORDER.LBL_DELIVERY_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="data.operationType!='REJECTED' && data.operationType!='COMPLETED' && this.userData.type!='CUSTOMER_USER' && data.operationType!='REPORT'">
                        <p-calendar [(ngModel)]="data.supplierDeliveryDate" [monthNavigator]="true"  yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="printingDate" (onSelect)="updateData('SUPPLIER_DELIVERY_DATE', data.supplierDeliveryDate, data.id, true)"></p-calendar>
                    </label>
                    <label class="lbl-value" *ngIf="data.operationType=='REJECTED' || data.operationType=='COMPLETED' || data.operationType=='REPORT'">{{data.supplierDeliveryDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.isOrder && this.userData.type!='SUPPLIER_USER'">
                    <label class="lbl-title" *ngIf="this.userData.type!='CUSTOMER_USER'">{{ 'ORDER.LBL_CUSTOMER_DELIVERY_DATE' | translate }}</label>
                    <label class="lbl-title" *ngIf="this.userData.type=='CUSTOMER_USER'">{{ 'ORDER.LBL_DELIVERY_DATE' | translate }}</label>
                    <label class="lbl-value" *ngIf="data.operationType!='REJECTED' && data.operationType!='COMPLETED' && this.userData.type!='CUSTOMER_USER' && data.operationType!='REPORT'">
                        <p-calendar [(ngModel)]="data.customerDeliveryDate" [monthNavigator]="true"  yearRange="1990:2030" [showIcon]="true" dateFormat="{{dateFormat}}" [showButtonBar]="true" [minDate]="deliveryDate" (onSelect)="updateData('CUSTOMER_DELIVERY_DATE', data.customerDeliveryDate, data.id, true)"></p-calendar>
                    </label>
                    <label class="lbl-value" *ngIf="data.operationType=='REJECTED' || data.operationType=='COMPLETED' || this.userData.type=='CUSTOMER_USER' || data.operationType=='REPORT'">{{data.customerDeliveryDate}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.projectManagerName">
                    <label class="lbl-title">{{ 'RFQ.LBL_PROJECT_MNGER' | translate }}</label>
                    <label class="lbl-value" *ngIf="userData.type=='PIPELINE_USER'">
                        <p-dropdown #projectManager styleClass="searchable-select txt-theme form-control" [options]="data.project_manager_opt" [(ngModel)]="data.projectManagerId" [filter]="true" name="project_manager" placeholder="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}" [disabled]="(this.userData.type=='PIPELINE_USER' && (data.operationType=='REJECTED'  || data.operationType=='COMPLETED' || data.operationType=='CONVERTED' || data.operationType=='VIEW' )) || (this.userData.type=='CUSTOMER_USER' && (data.operationType=='CUSTOMER_ACCEPTANCE' || data.operationType=='CUSTOMER_REJECTED')) || data.operationType=='COMPLETED' || data.operationType=='REPORT'" (onChange)="updateData('PROJECT_MANAGER',data.projectManagerId,data.id,false)"></p-dropdown>
                    </label>
                    <label class="lbl-value" *ngIf="this.userData.type=='SUPPLIER_USER' || userData.type=='CUSTOMER_USER'">{{data.projectManagerName}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="data.salesManagerName">
                    <label class="lbl-title">{{ 'RFQ.LBL_SALES_MNGER' | translate }}</label>
                    <label class="lbl-value" *ngIf="userData.type=='PIPELINE_USER'">
                        <p-dropdown #salesManager styleClass="searchable-select txt-theme form-control" [options]="data.sales_manager_opt" [(ngModel)]="data.salesManagerId" [filter]="true" name="sales_manager" placeholder="{{ 'COMMON.DEFAULT_SELECT_TEXT' | translate }}" [disabled]="(this.userData.type=='PIPELINE_USER' && (data.operationType=='REJECTED' || data.operationType=='CONVERTED' || data.operationType=='VIEW')) || (this.userData.type=='CUSTOMER' && (data.operationType=='CUSTOMER_ACCEPTANCE' || data.operationType=='CUSTOMER_REJECTED' )) || data.operationType=='COMPLETED' || data.operationType=='REPORT'" (onChange)="updateData('SALES_MANAGER',data.salesManagerId,data.id,false)"></p-dropdown>
                    </label>
                    <label class="lbl-value" *ngIf="this.userData.type=='SUPPLIER_USER' || userData.type=='CUSTOMER_USER'">{{data.salesManagerName}}</label>
                </div>
                <div class="confirm-dtls" *ngIf="userData.type=='PIPELINE_USER' && data.vatPercentage">
                    <label class="lbl-title">{{ 'RFQ.LBL_VAT' | translate }}</label>
                    <label class="lbl-value" *ngIf="userData.type=='PIPELINE_USER'">
                        <input class="txt-theme form-control" pInputText type="text" name="name" placeholder="{{ 'RFQ.LBL_VAT' | translate }}" currencyMask [options]="currency_params_percentage" [(ngModel)]="data.vatPercentage" (blur)="updateVatData(data.vatPercentage,data.id)" [disabled]="((this.userData.type=='PIPELINE_USER' && (data.operationType=='REJECTED' || data.operationType=='CONVERTED' || data.operationType=='VIEW')) || (this.userData.type=='CUSTOMER' && (data.operationType=='CUSTOMER_ACCEPTANCE' || data.operationType=='CUSTOMER_REJECTED' )) || data.operationType=='COMPLETED' || data.operationType=='REPORT') ? true : false">
                    </label>
                </div>
            </div>
        </div>
    </p-accordionTab>
</p-accordion>
<p-tabView cache="false" *ngIf="(data.products && data.products.length > 0) || (data.price && data.price.length > 0)">
    <!-- Tab for print product details starts here -->
    <p-tabPanel *ngIf="data.products && data.products.length > 0" [selected]="data.products.length > 0 ? true: false">
        <ng-template pTemplate="header" dynamic="true"> <span>
                {{ 'RFQ.LBL_PRINT_PRODUCTS' | translate}}
            </span>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="">
                <p-accordion [ngClass]="{'products-accordion': data.operationType=='ACCEPTANCE' || data.operationType == 'CUSTOMER_ACCEPTANCE'}" [multiple]="true">
                    <p-accordionTab class="product-select" [selected]="data.operationType=='ACCEPTANCE' ? false : true" *ngFor="let print_product of data.products; let i=index;">
                        <ng-template pTemplate="header">
                            <div class="checkbox inline-block chk-field margin0" *ngIf="data.operationType == acceptanceConstant && this.userData.type === 'PIPELINE_USER'">
                                <label>
                                    <p-checkbox name="products" (onChange)="oncheckboxChecked($event,print_product.id, 'PRINT')" (click)="checkPropogation($event)" value="{{print_product.id}}"></p-checkbox>
                                </label>
                            </div>
                            {{print_product?.name}}
                            <div class="product-actions" *ngIf="data.operationType!='CONVERTED' && data.operationType!='VIEW' && this.userData.type!='CUSTOMER_USER' && this.userData.type!='SUPPLIER_USER' && data.operationType!='COMPLETED' && data.operationType!='REPORT' && data.operationType != 'REJECTED'">
                                <button class="btn-icon-theme" id="margin_{{print_product.id}}" title="{{'RFQ.LBL_SET_MARGIN' | translate}}" (click)="setMarginClick(print_product,$event,'PRINT')"><span class="fa fa-line-chart icon-theme"></span>
                                </button>
                                <button class="btn-icon-theme" id="margin_{{print_product.id}}" title="{{'RFQ.LBL_ADD_SUPPLIER_BID'| translate}}" (click)="setBidClick(print_product,$event,'PRINT')"><span class="fa fa-gavel icon-theme"></span>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="row rfq-confirm">
                                <!-- Left Part starts here -->
                                <div class="col-md-4 col-sm-5 col-xs-12 border-right padding-0 product-general-dtls products-basic-dtls">
                                    <div class="confirm-dtls" *ngIf="print_product.name">
                                        <label class="lbl-title">{{ 'RFQ.LBL_PRODUCT_NAME' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.name}}</label>
                                    </div>
                                     <div class="confirm-dtls" *ngIf="print_product.printTypeStr">
                                        <label class="lbl-title">{{ 'RFQ.LBL_PRINT_TYPE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.printTypeStr}}</label>
                                    </div>

                                    <div class="confirm-dtls" *ngIf="print_product.isShowCouponRelatedFields && (userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.generateUniqueCouponCode">
                                        <label class="lbl-title">{{ 'COMMON.GENERATE_UNIQUE_COUNPON_CODE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.generateUniqueCouponCode}}</label>
                                    </div>
                                    
                                    <div class="confirm-dtls" *ngIf="print_product.isShowCouponRelatedFields && 
                                    (userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.endCustomerPrice">
                                        <label class="lbl-title">{{ 'ORDER.END_CUSTOMER_PRICE' | translate}}</label>
                                        <label class="lbl-value verticletop" [innerHTML]="print_product.endCustomerPrice"></label>
                                    </div>

                                    <div class="confirm-dtls" *ngIf="(userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.campaignNumber">
                                        <label class="lbl-title">{{ 'COMMON.CAMPAIGN_NUMBER' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product?.campaignNumber}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.campaignNumber">
                                        <label class="lbl-title">{{ 'COMMON.CAMPAIGN_ID' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product?.campaignId}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.campaignNumber">
                                        <label class="lbl-title">{{ 'COMMON.MEDIA_CATEGORY' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product?.mediaCategoryId}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="print_product.isShowCouponRelatedFields && print_product.isShowCampaignRelatedFields && (userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.campaignEndDate">
                                        <label class="lbl-title">{{ 'COMMON.CAMPAIGN_END_DATE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.campaignEndDate}}</label>
                                    </div>
                                     <div class="confirm-dtls" *ngIf="print_product.isShowCouponRelatedFields && print_product.isShowCampaignRelatedFields && (userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.numberOfCouponCodes">
                                        <label class="lbl-title">{{ 'COMMON.NO_COUPON_CODE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.numberOfCouponCodes}}</label>
                                    </div>
                                     <div class="confirm-dtls" *ngIf="print_product.isShowCouponRelatedFields && print_product.isShowCampaignRelatedFields && (userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.couponCodeAmount">
                                        <label class="lbl-title">{{ 'COMMON.COUNPON_CODE_AMOUNT' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.couponCodeAmount}}</label>
                                    </div>
                                     <div class="confirm-dtls" *ngIf="print_product.isShowCouponRelatedFields && print_product.isShowCampaignRelatedFields && (userData.type=='PIPELINE_USER'|| userData.type=='CUSTOMER_USER') && print_product.eanCode">
                                        <label class="lbl-title">{{ 'COMMON.EAN_CODE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.eanCode}}</label>
                                    </div>


                                    <div class="confirm-dtls" *ngIf="print_product.pageSize">
                                        <label class="lbl-title">{{ 'RFQ.LBL_PAGE_SIZE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{print_product.pageSize}}</label>
                                    </div>
                                    
                                    <div class="confirm-dtls">
                                        
                                        <label *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting)" class="lbl-title">{{ 'RFQ.LBL_BLOCKS' | translate}}</label>

                                        <label *ngIf="(data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting)" class="lbl-title">{{ 'RFQ.LBL_COPIES' | translate}}</label>


                                        <label class="lbl-value verticletop" *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting)">{{print_product?.numberOfBlocks}}</label>

                                        <label class="lbl-value verticletop" *ngIf="(data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting)">{{userData.type=='CUSTOMER_USER' ? print_product.customerCopies : print_product.supplierCopies}}</label>

                                    </div>

                                    <div class="confirm-dtls" *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting)">
                                        
                                        <label class="lbl-title">{{ 'RFQ.LBL_BLOCKS_SIZE' | translate}}</label>

                                        <label class="lbl-value verticletop">{{print_product?.blockSize}}</label>
                                        
                                    </div>

                                    <div class="confirm-dtls" *ngIf="(userData.type=='CUSTOMER_USER') ? print_product.customerAdditionalCopies : print_product.additionalCopies">
                                        <label *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting)" class="lbl-title">{{ 'RFQ.LBL_ADDITIONAL_BLOCK' | translate}}</label>
                                        <label *ngIf="(data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting)" class="lbl-title">{{ 'RFQ.LBL_ADDITIONAL_COPIES' | translate}}</label>
                                        <label class="lbl-value verticletop">{{userData.type=='CUSTOMER_USER' ? print_product.customerAdditionalCopies : print_product.additionalCopies}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(userData.type=='CUSTOMER_USER') ? print_product.customerWeightPerCopy : print_product.supplierWeightPerCopy">
                                        <label class="lbl-title">{{ 'RFQ.LBL_WEIGHT_COPY' | translate}}</label>
                                        <label class="lbl-value verticletop">{{userData.type=='CUSTOMER_USER' ? print_product.customerWeightPerCopy : print_product.supplierWeightPerCopy}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="userData.type=='CUSTOMER_USER' ? print_product?.customerPaperWeight : print_product?.supplierPaperWeight">
                                        <label class="lbl-title">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_TOTAL_WEIGHT' | translate}}</label>
                                        <label class="lbl-value verticletop">{{userData.type=='CUSTOMER_USER' ? print_product?.customerPaperWeight : print_product?.supplierPaperWeight}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(this.userData.type=='CUSTOMER_USER' && print_product?.customerProductDesc) || (this.userData.type=='SUPPLIER_USER' && print_product?.supplierProductDesc)">
                                        <label class="lbl-title">{{ 'RFQ.LBL_SUPPLIER_ONLY_DESC' | translate}}</label>
                                        <label class="lbl-value verticletop" [innerHTML]="userData.type=='SUPPLIER_USER' ? print_product?.supplierProductDesc : print_product?.customerProductDesc"></label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && print_product.acceptedSupplier">
                                        <label class="lbl-title">{{ 'RFQ.LBL_ACCEPTED_SUPPLIER_NAME' | translate}}</label>
                                        <label class="lbl-value">{{print_product.acceptedSupplier}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(this.userData.type=='PIPELINE_USER') && data.isRFQ && print_product.expectedPrice">
                                        <label class="lbl-title">{{ 'RFQ.LBL_EXPECTED_PRICE' | translate}}</label>
                                        <label class="lbl-value">{{this.fileservice.convertPriceIntoCurrency(print_product.expectedPrice, currencySEK)}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && print_product.acceptedPriceFormatted">
                                        <label class="lbl-title">{{ 'RFQ.LBL_ACCEPTED_PRICE_FOR_MARGIN' | translate}} / {{ 'ORDER.LBL_COST_PRICE'| translate }}</label>
                                        <label class="lbl-value">{{print_product.acceptedPriceFormatted}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(this.userData.type=='SUPPLIER_USER') && data.isOrder && print_product.bidPrice">
                                        <label class="lbl-title">{{ 'ORDER.BID_PRICE' | translate}}</label>
                                        <label class="lbl-value">{{print_product.bidPrice}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && print_product.otherCost">
                                        <label class="lbl-title">{{ 'RFQ.LBL_COST_TO_PIPELINE_WITHOUT_CURRENCY' | translate}}</label>
                                        <label class="lbl-value verticletop">{{this.fileservice.convertPriceIntoCurrency(print_product?.otherCost, currencySEK)}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && print_product.additionalOtherCost">
                                        <label class="lbl-title">{{ 'RFQ.LBL_ADDITIONAL_COST_TO_PIPELINE' | translate}}</label>
                                        <label class="lbl-value verticletop">{{this.fileservice.convertPriceIntoCurrency(print_product?.additionalOtherCost, currencySEK)}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="(this.userData.type=='PIPELINE_USER') && (print_product.displayMargin || print_product.displayMarginPercentage)">
                                        <label class="lbl-title">{{ 'RFQ.LBL_MARGIN_PRICE' | translate}}</label>
                                        <label class="lbl-value verticletop" *ngIf="print_product.marginType == 'BY_VALUE'">{{this.fileservice.convertPriceIntoCurrency(print_product?.displayMargin, currencySEK)}}
                                        </label>

                                        <label class="lbl-value verticletop" *ngIf="print_product.marginType == 'BY_PERCENTAGE'">{{print_product.displayMarginPercentage}} %
                                        </label>
                                    </div>
                                   
                                    <div class="confirm-dtls" *ngIf="((userData.type=='PIPELINE_USER') || (userData.type=='CUSTOMER_USER' && this.userData.organizationRegistrationNumber==clearonType)) && print_product.total_price">
                                        <label class="lbl-title">{{ 'RFQ.LBL_TOTAL_PRICE' | translate}} </label>
                                        <label class="lbl-value">{{this.fileservice.convertPriceIntoCurrency(print_product.total_price, currencySEK)}}</label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && print_product?.isBidAllowed">
                                        <label class="lbl-title">{{ 'RFQ.LBL_IS_BIDDING_ALLOWED' | translate}}</label>
                                        <label class="lbl-value verticletop" [innerHTML]="print_product?.isBidAllowed"></label>
                                    </div>
                                    <div class="confirm-dtls" *ngIf="print_product?.totalPageCount">
                                        <label class="lbl-title">{{ 'RFQ.LBL_TOTAL_PAGES' | translate}}</label>
                                        <label class="lbl-value verticletop" [innerHTML]="print_product?.totalPageCount"></label>
                                    </div>
                                    <div *ngIf="print_product.printingOptionsStr || print_product.bindingOptionsStr || print_product.extraOptionsStr">
                                        <div class="product-dtl-section" *ngIf="print_product.options_all">{{ 'RFQ.LBL_OPTIONS' | translate}}</div>
                                        <div [innerHTML]="print_product.options_all"></div>
                                    </div>
                                    <div *ngIf="print_product.paper_details">
                                        <div [innerHTML]="print_product.paper_details"></div>
                                    </div>
                                    <div *ngIf="this.userData.type=='CUSTOMER_USER' && (print_product?.productDetails?.original_template || print_product?.productDetails?.repro_template)">
                                        <div class="product-dtl-section">{{'RFQ.LBL_EXP_TEMPLATE' | translate}}</div>
                                        <div [innerHTML]="print_product?.productDetails?.original_template"></div>
                                        <div [innerHTML]="print_product?.productDetails?.repro_template"></div>
                                    </div>
                                    <div class="section-values-users">

                                        <p-accordion [multiple]="true">
                                            <p-accordionTab *ngIf="data.isOrder && !data.isRFQ && print_product.delivery_content_datatable.length >0" header="{{'RFQ.LBL_DELIVERY_DTLS' | translate}} ({{print_product.delivery_content_datatable.length}})">
                                                <div class="clearfix product-dtls deliveryDtlsBox">
                                                    <ul *ngFor="let dtValue of print_product.delivery_content_datatable">
                                                        <li>
                                                            <div class="deliveryDetails" *ngIf="dtValue.copies_to_deliver">
                                                                <div class='confirm-dtls'>
                                                                    <label class='lbl-title'>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_TOTAL_COPIES' | translate}}</label>
                                                                    <label class='lbl-value'>{{dtValue.copies_to_deliver}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="deliveryDetails" *ngIf="dtValue.deliver_packaging_label">
                                                                <div class='confirm-dtls'>
                                                                    <label class='lbl-title'>{{ 'RFQ.LBL_DELIVERY_PACKAGING' | translate}}</label>
                                                                    <label class='lbl-value'>{{dtValue.deliver_packaging_label}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="deliveryDetails" *ngIf="dtValue.deliver_to_label">
                                                                <div class='confirm-dtls'>
                                                                    <label class='lbl-title'>{{ 'RFQ.LBL_DELIVER_TO' | translate}}</label>
                                                                    <label class='lbl-value' [innerHTML]="fileservice.convertSlashNToBR(dtValue.deliver_to_label)"></label>
                                                                </div>
                                                            </div>
                                                            <div class="deliveryDetails" *ngIf="dtValue.delivery_notes">
                                                                <div class='confirm-dtls'>
                                                                    <label class='lbl-title'>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_DELIVERY_NOTE' | translate}}</label>
                                                                    <label class='lbl-value'>{{dtValue.delivery_notes}}</label>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </p-accordionTab>
                                            <p-accordionTab header="{{'RFQ.LBL_EXP_TEMPLATE' | translate}}" *ngIf="(print_product?.original_template || print_product?.repro_template) && this.userData.type=='PIPELINE_USER'">
                                                <div class="clearfix product-dtls">
                                                    <ul>
                                                        <li>
                                                            <div class='confirm-dtls' *ngIf="this.userData.type=='CUSTOMER_USER' && print_product?.original_template">
                                                                <label class='lbl-title'>{{ 'RFQ.LBL_TEMPLATE_ORGINAL' | translate }}</label>
                                                                <label class='lbl-value'>{{print_product?.original_template}}</label>
                                                            </div> <span class='pro-title-name' *ngIf="this.userData.type!='CUSTOMER_USER' && print_product?.original_template">
                                                                {{ 'RFQ.LBL_TEMPLATE_ORGINAL' | translate }}
                                                            </span>
                                                            <span class='pro-data-name'>
                                                                {{print_product?.original_template}}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <div class='confirm-dtls' *ngIf="this.userData.type=='CUSTOMER_USER' && print_product?.repro_template">
                                                                <label class='lbl-title'>{{ 'RFQ.LBL_TEMPLATE_REPRO' | translate }}</label>
                                                                <label class='lbl-value'>{{print_product?.repro_template}}</label>
                                                            </div> <span class='pro-title-name' *ngIf="this.userData.type!='CUSTOMER_USER' && print_product?.repro_template">
                                                                {{ 'RFQ.LBL_TEMPLATE_REPRO' | translate }}
                                                            </span>
                                                            <span class='pro-data-name'>
                                                                {{print_product?.repro_template}}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </p-accordionTab>
                                            <p-accordionTab header="{{'RFQ.LBL_VALUES_SUPPLIER' | translate}}" *ngIf="print_product?.supplierCopies > 0 && this.userData.type=='PIPELINE_USER'">
                                                <div class="clearfix product-dtls">
                                                    <ul>
                                                        <li *ngIf="print_product?.supplierCopies"> 
                                                            <!-- <span class="pro-title-name" >{{ 'SUPPLIER_RFQ_PRODUCT.LBL_COPIES' | translate}}</span> -->
                                                            <span class="pro-title-name" *ngIf="data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting && print_product?.supplierCopies">
                                                            {{ 'RFQ.LBL_BLOCKS' | translate}}</span>
                                                            <span class="pro-title-name" *ngIf="(print_product?.supplierCopies) && (data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting)">{{ 'RFQ.LBL_COPIES' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.supplierCopies}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.supplierAdditionalCopies"> 
                                                            <!-- <span class="pro-title-name" >{{ 'SUPPLIER_RFQ_PRODUCT.LBL_ADDITIONAL_COPIES' | translate}}</span> -->
                                                            <span class="pro-title-name" *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting && print_product?.supplierAdditionalCopies)">{{ 'RFQ.LBL_ADDITIONAL_BLOCK' | translate}}</span>
                                                            <span class="pro-title-name" *ngIf="(print_product?.supplierAdditionalCopies && (data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting))">{{ 'RFQ.LBL_ADDITIONAL_COPIES' | translate}}</span>

                                                            <span class="pro-data-name">{{print_product?.supplierAdditionalCopies}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.supplierWeightPerCopy"> <span class="pro-title-name">{{ 'RFQ.LBL_WEIGHT_COPY' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.supplierWeightPerCopy}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.supplierPaperWeight"> <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_TOTAL_WEIGHT' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.supplierPaperWeight}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.supplierProductDesc"> <span class="pro-title-name">{{ 'RFQ.LBL_SUPPLIER_DESC' | translate}}</span>
                                                            <span class="pro-data-name" [innerHtml]="print_product?.supplierProductDesc"></span>
                                                        </li>
                                                        <div *ngFor="let row of print_product?.pages">
                                                            <li class="lbl-highlight" *ngIf="row?.pageTypeSupplierStr">{{row?.pageTypeSupplierStr ? row?.pageTypeSupplierStr : ''}}</li> <span>
                                                        <li *ngIf="row?.printPaperNameSupplier || row?.paperWeightSupplier">
                                                            <span class="pro-title-name" *ngIf="row?.printPaperNameSupplier">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_PAPER_NAME' | translate}}</span>
                                                            <span class="pro-data-name">
                                                            {{row?.printPaperNameSupplier ? row?.printPaperNameSupplier : ''}} ({{row?.paperWeightSupplier ? row?.paperWeightSupplier : ''}})</span>
                                                        </li>
                                                        <li *ngIf="row?.pageCountSupplier"> <span class="pro-title-name">{{ 'RFQ.LBL_PAGE_COUNT' | translate}}</span>
                                                                <span class="pro-data-name">{{row?.pageCountSupplier ? row?.pageCountSupplier : ''}}</span>
                                                        </li>
                                                        <li *ngIf="row?.printInkSupplier"> <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_PRINT_INK' | translate}}</span>
                                                                <span class="pro-data-name">{{row?.printInkSupplier ? row?.printInkSupplier : ''}}</span>
                                                            </li>
                                                            </span>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </p-accordionTab>
                                            <p-accordionTab header="{{'RFQ.LBL_VALUES_CUSTOMER' | translate}}" *ngIf="print_product?.customerCopies > 0 && this.userData.type=='PIPELINE_USER'">
                                                <div class="clearfix product-dtls">
                                                    <ul>
                                                        <li *ngIf="print_product?.customerCopies"> 
                                                            <!-- <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_COPIES' | translate}}</span> -->
                                                            <span class="pro-title-name" *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting && print_product?.customerCopies)">{{ 'RFQ.LBL_BLOCKS' | translate}}</span>
                                                            <span class="pro-title-name" *ngIf="(print_product?.customerCopies && (data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting))">{{ 'RFQ.LBL_COPIES' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.customerCopies}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.customerAdditionalCopies"> 
                                                            <!-- <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_ADDITIONAL_COPIES' | translate}}</span> -->
                                                            <span class="pro-title-name" *ngIf="(data.customerRegistrationNumber===clearonType && print_product.printType===gluedCouponPrinting && print_product?.customerAdditionalCopies)">{{ 'RFQ.LBL_ADDITIONAL_BLOCK' | translate}}</span>
                                                            <span class="pro-title-name" *ngIf="(print_product?.customerAdditionalCopies && (data.customerRegistrationNumber!==clearonType || print_product.printType!==gluedCouponPrinting))">{{ 'RFQ.LBL_ADDITIONAL_COPIES' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.customerAdditionalCopies}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.customerPaperWeight"> <span class="pro-title-name">{{ 'RFQ.LBL_WEIGHT_COPY' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.customerWeightPerCopy}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.customerPaperWeight"> <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_TOTAL_WEIGHT' | translate}}</span>
                                                            <span class="pro-data-name">{{print_product?.customerPaperWeight}}</span>
                                                        </li>
                                                        <li *ngIf="print_product?.customerProductDesc"> <span class="pro-title-name">{{ 'RFQ.LBL_CUSTOMER_DESC' | translate}}</span>
                                                            <span class="pro-data-name" [innerHtml]="print_product?.customerProductDesc"></span>
                                                        </li>

                                                        <div *ngFor="let row of print_product?.pages">

                                                            <li class="lbl-highlight" *ngIf="row.pageTypeCustomerStr">{{row.pageTypeCustomerStr ? row.pageTypeCustomerStr : ''}}</li> <span>
                                                            <li *ngIf="row?.printPaperNameCustomer">
                                                            <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_PAPER_NAME' | translate}}</span>
                                                            <span class="pro-data-name">{{row.printPaperNameCustomer ? row.printPaperNameCustomer : ''}} ({{row.paperWeightCustomer ? row.paperWeightCustomer : ''}})</span>
                                                            </li>
                                                            <li *ngIf="row?.pageCountCustomer"> <span class="pro-title-name">{{ 'RFQ.LBL_PAGE_COUNT' | translate}}</span>
                                                                <span class="pro-data-name">{{row.pageCountCustomer ? row.pageCountCustomer : ''}}</span>
                                                            </li>
                                                            <li *ngIf="row?.printInkCustomer"> <span class="pro-title-name">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_PRINT_INK' | translate}}</span>
                                                                <span class="pro-data-name">{{row.printInkCustomer ? row.printInkCustomer : ''}}</span>
                                                            </li>
                                                            </span>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </div>
                                </div>
                                <!-- Left Part ends here -->
                                <!-- Right Part starts here -->
                                <div class="col-md-8 col-sm-7 col-xs-12 border-left padding-0">

                                    <div class="bidWrapper" *ngIf="print_product?.productDetails?.supplierBids.length>0">
                                        <p-accordion [multiple]="true" *ngIf="this.userData.type=='PIPELINE_USER'">
                                            <p-accordionTab header="{{'RFQ.LBL_BIDS' | translate}}" [selected]="data.isRFQ">
                                                <div class="panel-body inner-table-panel-body">
                                                    <div class="table-responsive">
                                                        <p-table class="rfq-bids" #biddingDt [columns]="print_product?.productDetails?.biddingCols" [value]="print_product?.productDetails?.supplierBids" [paginator]="true" [rows]="5" [totalRecords]="print_product?.supplierBidCount" dataKey="id" persistState="true" table table-hover>
                                                            <ng-template pTemplate="header" let-columns>
                                                                <tr>
                                                                    <th width="11%"></th>
                                                                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.colWidth">{{col.header}}
                                                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                                                    </th>
                                                                    <th width="16%">{{'RFQ.LBL_PROCESSED_BY' | translate}}</th>
                                                                    <th width="23%">{{'RFQ.LBL_DECLINE_REASON' | translate}}</th>
                                                                    <th width="23%" class="rating">{{'RFQ.LBL_RATING' | translate}}</th>
                                                                    <th width="17%" *ngIf="data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' &&  data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REJECTED' && data.operationType!='REPORT'">{{'DATATABLE.HEAD_ACTION' | translate}}</th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded" let-rowIndex="rowIndex">
                                                                <tr id="bids_list_print_{{rowData.id}}">
                                                                    <td> <span id="toggle" (click)="bidExpand(rowData,biddingDt,'bids_list_print_'+rowData.id)">
                                                                            <span *ngIf="expanded" class="add-new fa fa-minus-circle"></span>
                                                                        <span *ngIf="!expanded" class="add-new fa fa-plus-circle"></span>
                                                                        </span>
                                                                    </td>
                                                                    <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
                                                                    <td>{{rowData.bidAcceptedBy}}</td>
                                                                    <td>{{rowData.bidDeclineReason}}</td>
                                                                    <td>
                                                                        <p-rating *ngIf="rowData.bidStatus != 'DECLINED'" [(ngModel)]="rowData.ratings" [disabled]=true [cancel]=false></p-rating>
                                                                    </td>

                                                                    <td class="paddingtop5" *ngIf="data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' &&  data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REJECTED' && data.operationType!='REPORT'">
                                                                        <button *ngIf="rowData.bidStatus != 'DECLINED' && data.operationType!='REJECTED'" class="btn-icon-theme" title="{{'TOOLTIP_TITLE.LBL_EDIT_BID' | translate }}" (click)="onEditBid(rowData.id,print_product, 'PRINT')">
                                                                            <span class="fa fa-edit icon-theme"></span>
                                                                        </button>
                                                                        <button *ngIf="rowData.bidStatus == 'YET_TO_PROCESS' || rowData.bidStatus == 'REJECTED'" class="btn-icon-theme" title="{{'RFQ.LBL_ACCEPT' | translate}}" name="btn-accept" (click)="onAcceptBid(rowData.id, print_product)"> <span class="fa fa-check-square-o icon-theme"></span>
                                                                        </button>
                                                                        <button *ngIf="rowData.bidStatus == 'YET_TO_PROCESS' && data.operationType!='REJECTED' && !data.isOrder" class="btn-icon-theme" title="{{'SUPPLIER_RFQ_PRODUCT.LBL_DECLINE_RFQ' | translate}}" name="btn-decline" (click)="onDeclineBid(rowData.id,print_product)"> <span class="fa fa-times-circle-o icon-theme"></span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="rowexpansion" let-childData let-columns="columns">
                                                                <tr>
                                                                    <td *ngIf="data.operationType=='CONVERTED' || data.operationType=='VIEW' || data.operationType=='COMPLETED' || data.operationType=='REJECTED'" [attr.colspan]="8" class="pro-inner-content">
                                                                        <div class="row">
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix">
                                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12  product-general-dtls">
                                                                                    <div class="confirm-dtls" *ngIf="childData?.supplierName">
                                                                                        <label class="lbl-title">{{ 'ORDER.LBL_SUPPLIER' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.supplierName"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidDeclineReason">
                                                                                        <label class="lbl-title">{{ 'RFQ.LBL_DECLINE_REASON' | translate }}</label>
                                                                                        <label class="lbl-value verticletop">{{childData?.bidDeclineReason}}</label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidPriceStr">
                                                                                        <label class="lbl-title">{{ 'ORDER.BID_PRICE' | translate }}</label>
                                                                                        <label class="lbl-value verticletop">{{childData?.bidPriceStr}}</label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.deliveryTime">
                                                                                        <label class="lbl-title">{{ 'ORDER.LBL_DELIVERY_TIME' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.deliveryTime"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.additionalCopyPriceStr">
                                                                                        <label class="lbl-title">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_ADDITIONAL_COPIES_PRICE' | translate}}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.additionalCopyPriceStr"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidQuotationRefNo">
                                                                                        <label class="lbl-title">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_QUATATION_REFERENCE' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.bidQuotationRefNo"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidComment">
                                                                                        <label class="lbl-title">{{ 'TOOLTIP_TITLE.LBL_COMMENTS' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.bidComment"></label>
                                                                                    </div>
                                                                                    <div [innerHtml]=""></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td *ngIf="data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='REJECTED'" [attr.colspan]="8" class="pro-inner-content">
                                                                        <div class="row">
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix">
                                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 product-general-dtls">
                                                                                    <div class="confirm-dtls" *ngIf="supplierName">
                                                                                        <label class="lbl-title">{{ 'ORDER.LBL_SUPPLIER' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="supplierName"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidDeclineReason">
                                                                                        <label class="lbl-title">{{ 'RFQ.LBL_DECLINE_REASON' | translate }}</label>
                                                                                        <label class="lbl-value verticletop">{{childData?.bidDeclineReason}}</label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidPriceStr">
                                                                                        <label class="lbl-title">{{ 'ORDER.BID_PRICE' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.bidPriceStr"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.deliveryTime">
                                                                                        <label class="lbl-title">{{ 'ORDER.LBL_DELIVERY_TIME' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.deliveryTime"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.additionalCopyPriceStr">
                                                                                        <label class="lbl-title">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_ADDITIONAL_COPIES_PRICE' | translate}}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.additionalCopyPriceStr"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidQuotationRefNo">
                                                                                        <label class="lbl-title">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_QUATATION_REFERENCE' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.bidQuotationRefNo"></label>
                                                                                    </div>
                                                                                    <div class="confirm-dtls" *ngIf="childData?.bidComment">
                                                                                        <label class="lbl-title">{{ 'TOOLTIP_TITLE.LBL_COMMENTS' | translate }}</label>
                                                                                        <label class="lbl-value verticletop" [innerHtml]="childData?.bidComment"></label>
                                                                                    </div>
                                                                                    <div *ngIf="supplierCoverPaperDetails" [innerHtml]="supplierCoverPaperDetails"></div>
                                                                                    <div *ngIf="supplierContentPaperDetails" [innerHtml]="supplierContentPaperDetails"></div>
                                                                                    <!-- <div class="divider-line"></div> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="paginatorleft" let-state>{{'RFQ.LBL_TABLE_FOOTER_SHOWING' | translate}} {{biddingDt.totalRecords
                                                                < 1 ? 0 : (state.first + 1)}} {{ 'RFQ.LBL_TABLE_FOOTER_TO' | translate}} {{(state.page + 1)*(state.rows)>= biddingDt.totalRecords ? biddingDt.totalRecords : (state.page + 1)*(state.rows)}} {{'RFQ.LBL_TABLE_FOOTER_OF' | translate}} {{biddingDt.totalRecords}} {{'RFQ.LBL_TABLE_FOOTER_ENTRIES' | translate}}</ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="this.userData.type=='SUPPLIER_USER' && type==='RFQ'">
                                        <form method="post" [formGroup]="print_product?.productDetails?.supplierRFQForm" (ngSubmit)="onSubmitPrintProduct(print_product.productDetails.printProductId, print_product.productDetails, print_product.productDetails.supplierRFQForm, index)">
                                            <div class="pro-price-fields">
                                                <div class="supplier-selection">
                                                    <div class="row">
                                                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <label>{{'ORDER.LBL_PRODUCT_PRICE' | translate}} <small class="madatory-star">*</small>
                                                            </label>
                                                            <input #productPrice formControlName="productPrice" class="txt-theme form-control" type="text" [(ngModel)]="print_product.productDetails.printProductPrice" name="productPrice" placeholder="{{'ORDER.LBL_PRODUCT_PRICE' | translate}}" currencyMask [options]="currency_params" />
                                                            <div *ngIf="print_product?.productDetails?.supplierRFQForm.hasError('required',['productPrice']) && !print_product.productDetails?.bidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                            <div *ngIf="print_product.productDetails.showBidZeroError" class="alert alert-danger">
                                                                {{'VALIDATION.BID_PRODUCT_PRICE_GRATERTHAN_ZERO' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="print_product.productDetails?.isAdditionalCopiesAvailable">
                                                            <label>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_ADDITIONAL_COPIES_PRICE' | translate}}</label>
                                                            <input #additionalCopyPrice formControlName="additionalCopiesProductPrice" class="txt-theme form-control" [(ngModel)]="print_product.productDetails.additionalPrintProductPrice" type="text" name="additionalCopiesProductPrice" id="additionalCopiesProductPrice" placeholder="{{ 'SUPPLIER_RFQ_PRODUCT.LBL_ADDITIONAL_COPIES_PRICE' | translate}}" currencyMask [options]="currency_params" />
                                                            <div *ngIf="print_product?.productDetails?.supplierRFQForm.hasError('required',['additionalCopiesProductPrice']) && !print_product.productDetails?.bidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                            <div *ngIf="print_product.productDetails.showAdditionalBidZeroError" class="alert alert-danger">
                                                                {{'VALIDATION.BID_ADDITION_PRICE_GRATERTHAN_ZERO' | translate}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <label>{{ 'RFQ.LBL_PAY_CURRENCY' | translate }} <small class="madatory-star">*</small>
                                                            </label>
                                                            <select #payCurrencyId [(ngModel)]="print_product.productDetails.bidCurrency" class="txt-theme form-control" formControlName="payCurrencyId" name="payCurrencyId">
                                                                <option value="">{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}</option>
                                                                <option *ngFor="let type of data.currencies" [value]="type.value">{{type.label}}</option>
                                                            </select>
                                                            <div *ngIf="print_product?.productDetails?.supplierRFQForm.hasError('required',['payCurrencyId']) && !print_product.productDetails?.bidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                        </div>
                                                        <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <label>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_DELIVERY_BY' | translate}} <small class="madatory-star">*</small>
                                                            </label>
                                                            <select #bidExpectedDays [(ngModel)]="print_product.productDetails.bidExpectedDays" class="txt-theme form-control" formControlName="productionTime">
                                                                <option value="">{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}</option>
                                                                <option *ngFor="let type of data?.produtionOptions" [value]="type.value" [selected]="type.value == bidExpectedDays">{{type.label}}</option>
                                                            </select>
                                                            <div *ngIf="print_product?.productDetails?.supplierRFQForm.hasError('required',['productionTime']) && !print_product.productDetails?.bidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="paper-suggestion supplierBidPageDtlsWrapper">
                                                        <div class="row">
                                                            <div formArrayName="supplierBidPageDtls" *ngFor="let paper of print_product?.productDetails?.paperSupplierArray; let pc = index">
                                                                <div class="clearfix" [formGroupName]="pc">
                                                                    <ul class="clearfix">
                                                                        <li>
                                                                            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                                <label>{{'RFQ.LBL_TITLE_PAPER_NAME' |translate}} ({{paper.supplierPaperPaperTypeStr}})</label>
                                                                                <input [(ngModel)]="paper.supplierPaperName" formControlName="paperName" class="txt-theme form-control" type="text" name="txt-paper-paper1" id="txt-paper-paper1" placeholder="{{'RFQ.LBL_TITLE_PAPER_NAME' |translate}} ({{paper.supplierPaperPaperTypeStr}})" />
                                                                            </div>
                                                                            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                                <label>{{'RFQ.PLACEHOLDER_PAPER_WEIGHT' |translate}} ({{paper.supplierPaperPaperTypeStr}})</label>
                                                                                <input [(ngModel)]="paper.supplierPaperWeight" formControlName="paperWeight" class="txt-theme form-control" type="text" name="txt-paper-paper2" id="txt-paper-paper2" placeholder="{{'RFQ.PLACEHOLDER_PAPER_WEIGHT' |translate}} ({{paper.supplierPaperPaperTypeStr}})" />
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>{{'SUPPLIER_RFQ_PRODUCT.LBL_QUATATION_REFERENCE' |translate}}</label>
                                                            <input formControlName="quotationReferenceNumber" [(ngModel)]="print_product.productDetails.printquotationReferenceNumber" class="txt-theme form-control" type="text" name="txt-quot-ref" placeholder="{{'SUPPLIER_RFQ_PRODUCT.LBL_QUATATION_REFERENCE' |translate}}" />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>{{'SUPPLIER_RFQ_PRODUCT.LBL_COMMENT' |translate}}</label>
                                                            <input formControlName="comments" [(ngModel)]="print_product.productDetails.printComments" class="txt-theme form-control" type="text" name="txt-comment" id="txt-comment" placeholder="{{'SUPPLIER_RFQ_PRODUCT.LBL_COMMENT' |translate}}" />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                            <div class="form-group">
                                                                <div class="checkbox chk-field">
                                                                    <label>
                                                                        <p-checkbox formControlName="termsAndCondition" [(ngModel)]="print_product.productDetails.termsAndCondition" name="accept_terms" id="accept_terms_general" binary="productDetails.termsAndCondition == true?true:false"></p-checkbox><span>{{'RFQ.ACCEPT_TERMS_CONDITION_TEXT' | translate}}<a class='theme-link' (click)='termsAndConditionPopup()'>{{'RFQ.TERMS_AND_CONDITION' | translate}}</a>{{'RFQ.TERMS_ACCEPT' | translate}}</span> 
                                                                    </label>
                                                                    <div *ngIf="!print_product.productDetails?.bidFormSubmitted && !print_product.productDetails?.termsAndCondition" class="alert alert-danger">{{'VALIDATION.ACCEPT_TERMS' | translate}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="btn-area">
                                                        <div class="col-md-12 col-sm-12 col-xs-12 text-right">
                                                            <button type="submit" class="btn-theme margin-right-10" id="btn_add" name="btn_add">{{'SUPPLIER_RFQ_PRODUCT.LBL_BTN_SUBMIT' | translate}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 product-upload-area" *ngIf="(this.userData.type=='SUPPLIER_USER' && data.operationType!='PUBLISHED' && data.operationType!='COMPLETED') || ((this.userData.type=='CUSTOMER_USER' || this.userData.type=='PIPELINE_USER') && data.operationType!='REJECTED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED')">
                                        <div class="pro-upload-file" *ngIf="data.operationType!='REPORT'">
                                            <div class="drag-drop-area">
                                                <p-fileUpload #fileInput [showUploadButton]="false" [maxFileSize]="fileSizeLimit" mode="advanced" [multiple]="true" name="product_file[]" [accept]="allowedFileExt" (uploadHandler)="onFileUpload($event,print_product?.id,print_product,'','PRINT',i)" (onSelect)="onSelect($event,print_product,i)" (onRemove)="handleFileRemove()" (onClear)="handleClear()">
                                                    <ng-template pTemplate="content">
                                                        <div class="upload-placeholder"  *ngIf="showPlaceHolder"> <i class="fa fa-cloud-upload"></i>
                                                            <p>{{ 'ORDER.UPLOAD_PLACEHOLDER' | translate}}</p>
                                                        </div>
                                                    </ng-template>
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bidWrapper" *ngIf="print_product?.productDetails?.files.length>0">
                                        <p-accordion [multiple]="true" *ngIf="print_product.productDetails && print_product.productDetails.files && ((userData.type=='SUPPLIER_USER' && data.isOrder) || userData.type == 'PIPELINE_USER' || userData.type == 'CUSTOMER_USER')">
                                            <p-accordionTab header="{{'RFQ.LBL_FILES' | translate}}" [selected]="data.isOrder">
                                                <div class="table-responsive">
                                                    <p-table id="file-detail-table" #FileDt [columns]="productFileCols" [value]="print_product?.productDetails?.files" [paginator]="true" [rows]="5" [totalRecords]="print_product?.productDetails?.files?.length" dataKey="no" persistState="true" rowExpandMode="single">
                                                        <ng-template pTemplate="header" let-columns>
                                                            <tr>
                                                                <th width="3%"></th>
                                                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.colWidth">{{col.header}}
                                                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                                                </th>
                                                                <th width="20%">{{'RFQ.LBL_FILE_STATUS' | translate}}</th>
                                                                <!-- <th width="20%">{{ 'ORDER.LBL_PAPER_DTLS' | translate}}</th> -->
                                                                <th width="20%" *ngIf="this.userData.type=='PIPELINE_USER'">{{ 'ORDER.LBL_DISPLAY_STATUS' | translate}}</th>
                                                                <th>{{ 'DATATABLE.HEAD_ACTION' | translate}}</th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-childRowData let-childColumns="columns" let-childExpanded="expanded" let-childRowIndex="rowIndex">
                                                            <!-- {{childRowData | json}} -->
                                                            <tr id="file_row_print_{{childRowData.id}}" [ngClass]="{'highlight-bids': childRowData.fileSizeDiscrepencies && childRowData.fileSizeDiscrepencies == 1 }">
                                                                <td> <span id="toggle" (click)="fileExpand(childRowData, FileDt, print_product.productDetails, childRowIndex,'print',childExpanded, 'file_row_print_'+childRowData.id)">
                                                                <span *ngIf="childExpanded" class="add-new fa fa-minus-circle"></span>
                                                                    <span *ngIf="!childExpanded" class="add-new fa fa-plus-circle"></span>
                                                                    </span>
                                                                </td>
                                                                <td *ngFor="let col of childColumns">{{childRowData[col.field]}}</td>
                                                                <td>{{childRowData?.rfqOrderFileCurrentStatusStr}}</td>
                                                               
                                                                <td *ngIf="this.userData.type=='PIPELINE_USER'" [innerHtml]="childRowData.fileStatusSupplier"></td>
                                                                
                                                                <td class="action-items text-right">
                                                                    
                                                                    <button *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf' && !print_product?.isShowCouponPrintingRelatedFields" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DOWNLOAD_FILE' | translate}}" (click)="downloadFile(childRowData?.id,'PROCESSED',childRowData)"> <span class="fa fa-cloud-download icon-theme"></span>
                                                                    </button>
                                                                    <button type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DOWNLOAD_ORIGINAL_FILE' | translate}}" (click)="downloadFile(childRowData?.id,'ORIGINAL',childRowData)" *ngIf="!childRowData.pitstopStatus || childRowData?.fileExtension!='pdf' || print_product?.isShowCouponPrintingRelatedFields"> <span class="fa fa-download icon-theme"></span>
                                                                    </button>
                                                                    <button *ngIf="this.userData.type=='CUSTOMER_USER' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}" (click)="deleteFiles(print_product,'',childRowData?.id, i)"> <span class="fa fa-trash-o icon-theme"> </span>
                                                                    </button>
                                                                    <!-- {{childRowData | json}} -->

                                                                    <button *ngIf="this.userData.type=='SUPPLIER_USER' && childRowData.userType =='SUPPLIER_USER' && data.operationType!='COMPLETED' && childRowData.rfqOrderFileCurrentStatus!='APPROVED'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}" (click)="deleteFiles(print_product,'',childRowData?.id, i)"> <span class="fa fa-trash-o icon-theme"> </span>
                                                                    </button>

                                                                    <!-- {{childRowData | json}} -->
                                                                    <div class="dropdown action-icon" *ngIf="this.userData.type=='PIPELINE_USER'">
                                                                        <button title="{{'RFQ.TOOLTIP_MORE_ACTIONS'|translate}}" (click)="toggleActions(childRowData?.id)" class="dropbtn"><span class="fa fa-ellipsis-v icon-theme"> </span>
                                                                        </button>
                                                                        <div id="actions_dropdown_{{childRowData.id}}" class="dropdown-content">
                                                                            <!-- File Share Icon -->
                                                                            <!-- {{childRowData | json}} -->
                                                                            <button *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf' && !print_product?.isShowCouponPrintingRelatedFields" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_SHARE_OUTPUT_FILE' | translate}}" (click)="openCommentsPopup(childRowData?.pitstopReportSystemFileId, childRowData?.fileName,'print_output')"> <span class="fa fa-share-square-o icon-theme"></span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_SHARE_OUTPUT_FILE' | translate}}</label>
                                                                            </button>
                                                                            <button *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf' && !print_product?.isShowCouponPrintingRelatedFields" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_SHARE_PROCESSED_FILE' | translate}}" (click)="openCommentsPopup(childRowData?.pitstopOutputSystemFileId, childRowData?.fileName, 'PROCESSED')"> <span class="fa fa-share-alt icon-theme"></span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_SHARE_PROCESSED_FILE' | translate}}</label>
                                                                            </button>
                                                                            <button type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_SHARE_ORIGINAL_FILE' | translate}}" (click)="openCommentsPopup(childRowData?.systemFileId, childRowData?.fileName, 'ORIGINAL')"> <span class="fa fa-share icon-theme"></span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_SHARE_ORIGINAL_FILE' | translate}}</label>
                                                                            </button>
                                                                            <!-- File Share Icon -->
                                                                            <button type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DOWNLOAD_ORIGINAL_FILE' | translate}}" (click)="downloadFile(childRowData?.id,'ORIGINAL',childRowData)" *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf' && !print_product?.isShowCouponPrintingRelatedFields"> <span class="fa fa-download icon-theme"></span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_DOWNLOAD_ORIGINAL_FILE' | translate}}</label>
                                                                            </button>
                                                                            <!-- {{childRowData.userType}} -->
                                                                            <button *ngIf="this.userData.type=='PIPELINE_USER' && (childRowData?.fileStatus===false) && (childRowData?.fileDiscardedStatus!='DECLINED') && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && childRowData.userType!=supplierUserType" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_CONVERT_SHOW' | translate}}" (click)="showFile(childRowData?.id, childRowData?.fileStatus)"> <span class="fa fa-eye icon-theme"></span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_CONVERT_SHOW' | translate}}</label>
                                                                            </button>

                                                                            <button *ngIf="this.userData.type=='PIPELINE_USER' && 
                                                                            (childRowData?.fileStatus===true) &&  
                                                                            (childRowData?.fileDiscardedStatus!='DECLINED') && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && childRowData.userType!=supplierUserType" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_CONVERT_HIDDEN' | translate}}" (click)="showFile(childRowData?.id, childRowData?.fileStatus)"> <span class="fa fa-eye-slash icon-theme"></span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_CONVERT_HIDDEN' | translate}}</label>
                                                                            </button>
                                                                            <button *ngIf="this.userData.type!='SUPPLIER_USER' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}" (click)="deleteFiles(print_product,'',childRowData?.id, i)"> <span class="fa fa-trash-o icon-theme"> </span>
                                                                                <label>{{'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}</label>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="rowexpansion" let-childData let-columns="columns">
                                                            <tr>
                                                                <td [attr.colspan]="6" class="pro-inner-content" *ngIf="this.userData.type=='PIPELINE_USER'">
                                                                    <div class="row">
                                                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                            <div class="pro-img" *ngIf="images[childData.id]">
                                                                                <!-- {{images[childData.id].type}} -->
                                                                                <p-galleria *ngIf="(images[childData.id].type==1 && !print_product?.isShowCouponPrintingRelatedFields)" class="gallery-img" [(value)]="images[childData.id].thumbs" [responsiveOptions]="responsiveOptions"  [showItemNavigators]="true" [showThumbnails]="false">
                                                                                    <ng-template pTemplate="item" let-item>
                                                                                        <img [src]="item.title" style="width: 100%; display: block;" (click)="displayPreview(item.source, item.pitstopReportFileId)"/>
                                                                                    </ng-template>
                                                                                </p-galleria>

                                                                                <div *ngIf="(images[childData.id].type==2) && !print_product?.isShowCouponPrintingRelatedFields">
                                                                                    <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                        <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="(images[childData.id].type==2 || images[childData.id].type==1 || images[childData.id].type==4 || images[childData.id].type==3) && print_product?.isShowCouponPrintingRelatedFields" class="backcolor">
                                                                                    <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                        <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                    </div>
                                                                                </div>

                                                                                <div *ngIf="( images[childData.id].type==4 || images[childData.id].type==3) && !print_product?.isShowCouponPrintingRelatedFields" class="backcolor">
                                                                                    <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                        <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 pro-img-dtl-area">
                                                                            <ul class="pro-img-dtl">
                                                                                <li *ngIf="childData.fileDtls.uploadedFileDate"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_UPLOADED' | translate }}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.uploadedFileDate}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.uploadedFileSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_SIZE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.uploadedFileSizeStr}}</span>
                                                                                </li>

                                                                                <li *ngIf="childData.fileDtls.fileFinishedSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_FINISHED_SIZE' | translate}}</span>  <span class="pro-data-name">{{childData.fileDtls.fileFinishedSizeStr}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.rfqOrderFileCurrentStatusStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_STATUS' | translate }}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.rfqOrderFileCurrentStatusStr}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.paperType"> <span class="pro-title-name">{{'RFQ.LBL_PAPER_TYPE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.paperType}}</span>
                                                                                </li>
                                                                                <li *ngIf="(print_product.printType!=looseCouponPrinting && print_product.printType!=gluedCouponPrinting) && childData.fileDtls.printPaperProfileStr"> <span class="pro-title-name" >{{'RFQ.LBL_PROFILE_TYPE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.printPaperProfileStr}}</span>
                                                                                </li>
                                                                                <li *ngIf="(print_product.printType!=looseCouponPrinting && print_product.printType!=gluedCouponPrinting) && childData.fileDtls.printTray"> <span class="pro-title-name">{{'RFQ.LBL_TRAY' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.printTray}}</span>
                                                                                </li>
                                                                                <li *ngIf="fileExtention"> <span class="pro-title-name">{{'RFQ.LBL_RFQ_TYPE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{fileExtention}}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                                            <div class="alert-msgs">
                                                                                <div class="error-msgs msg-combo" *ngIf="childData.fileDtls.pitstopErrorMessages != null ">
                                                                                    <h4>{{'COMMON.ERRORS' | translate}}</h4>
                                                                                    <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopErrorMessages;  index as i">
                                                                                        <p>{{i+1}} {{data}}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="warning-msgs msg-combo" *ngIf="childData.fileDtls.pitstopWarningMessages != null">
                                                                                    <h4>{{'COMMON.WARNINGS' | translate}}</h4>
                                                                                    <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopWarningMessages;  index as i">
                                                                                        <p>{{i+1}} {{data}}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-lg-12 col-md-12 col-sm-12 text-right">
                                                                            <div class="btn-groups" *ngIf="(childData.fileDtls.rfqOrderFileCurrentStatus == 'PITSTOP_PROCESSED' || childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalStatus || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalPipelineStatus) || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingPipelineApprovalStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalCustomerStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalClearOnStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingClearOnApprovalStatus) || childData.fileDtls.rfqOrderFileCurrentStatus==pendingPipelineClearOnApprovalStatus) && 
                                                                            userData.type=='PIPELINE_USER' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW'">
                                                                                <button type="button" class="btn-theme" id="btn_add_cust" name="btn_add_cust" (click)="changeFileStatus(rfqOrderProductFileId,print_product,'','APPROVE')">{{ 'ORDER.LBL_BTN_APPROVE_RES' | translate }}</button>
                                                                                <button type="button" class="btn-theme-decline" id="" name="btn_add_cust" (click)="changeFileStatus(rfqOrderProductFileId,print_product,'','DECLINE')">{{ 'ORDER.LBL_BTN_DECLINE_RES' | translate }}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td [attr.colspan]="4" class="pro-inner-content" *ngIf="this.userData.type!='PIPELINE_USER'">
                                                                    <div class="row">
                                                                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-4 text-right">
                                                                            <div class="pro-img" *ngIf="images[childData.id]">

                                                                                <p-galleria *ngIf="(images[childData.id].type==1 && !print_product?.isShowCouponPrintingRelatedFields)" class="gallery-img" [(value)]="images[childData.id].thumbs" [responsiveOptions]="responsiveOptions"  [showItemNavigators]="true" [showThumbnails]="false">
                                                                                    <ng-template pTemplate="item" let-item>
                                                                                        <img [src]="item.title" style="width: 100%; display: block;" (click)="displayPreview(item.source, item.pitstopReportFileId)"/>
                                                                                    </ng-template>
                                                                                </p-galleria>

                                                                                <div *ngIf="(images[childData.id].type==2) && !print_product?.isShowCouponPrintingRelatedFields">
                                                                                   <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                        <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                    </div>
                                                                                </div>


                                                                                <div *ngIf="(images[childData.id].type==2 || images[childData.id].type==1 ||  images[childData.id].type==4 || images[childData.id].type==3) && print_product?.isShowCouponPrintingRelatedFields">
                                                                                    <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                        <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                    </div>
                                                                                </div>

                                                                                <!-- {{images[childData.id] | json}} -->

                                                                                <div *ngIf="(images[childData.id].type==4 || images[childData.id].type==3) && !print_product?.isShowCouponPrintingRelatedFields">
                                                                                    <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                        <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-8 pro-img-dtl-area">
                                                                            <ul class="pro-img-dtl">
                                                                                <li *ngIf="childData.fileDtls.uploadedFileDate"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_UPLOADED' | translate }}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.uploadedFileDate}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.uploadedFileSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_SIZE' | translate }}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.uploadedFileSizeStr}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.fileFinishedSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_FINISHED_SIZE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.fileFinishedSizeStr}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.rfqOrderFileCurrentStatusStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_STATUS' | translate }}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.rfqOrderFileCurrentStatusStr}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.fileThumbnailExtList=='PDF' && childData.fileDtls.paperType"> <span class="pro-title-name">{{'RFQ.LBL_PAPER_TYPE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.paperType}}</span>
                                                                                </li>
                                                                                <li *ngIf="childData.fileDtls.fileThumbnailExtList=='PDF' && this.userData.type!='SUPPLIER' && childData.fileDtls.fileProfile"> <span class="pro-title-name">{{'RFQ.LBL_PROFILE_TYPE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{childData.fileDtls.fileProfile}}</span>
                                                                                </li>
                                                                                <li *ngIf="fileExtention"> <span class="pro-title-name">{{'RFQ.LBL_RFQ_TYPE' | translate}}</span>
                                                                                    <span class="pro-data-name">{{fileExtention}}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                                                            <div class="alert-msgs">
                                                                                <div class="error-msgs msg-combo" *ngIf="childData.fileDtls.pitstopErrorMessages != null ">
                                                                                    <h4>{{'COMMON.ERRORS' | translate}}</h4>
                                                                                    <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopErrorMessages;  index as i">
                                                                                        <p>{{i+1}} {{data}}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="warning-msgs msg-combo" *ngIf="childData.fileDtls.pitstopWarningMessages != null">
                                                                                    <h4>{{'COMMON.WARNINGS' | translate}}</h4>
                                                                                    <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopWarningMessages;  index as i">
                                                                                        <p>{{i+1}} {{data}}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-lg-12 col-md-12 col-sm-12 text-right">
                                                                            <div class="btn-groups" *ngIf="(childData.fileDtls.rfqOrderFileCurrentStatus == 'PITSTOP_PROCESSED' || childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalStatus || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalPipelineStatus) || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingPipelineApprovalStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalCustomerStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalClearOnStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingClearOnApprovalStatus) || childData.fileDtls.rfqOrderFileCurrentStatus==pendingPipelineClearOnApprovalStatus) && (
                                                                            userData.type=='PIPELINE_USER' || userData.type=='CUSTOMER_USER') && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='CUSTOMER_REJECTED'">
                                                                                <!-- {{userData.type}} -->
                                                                                <button type="button" class="btn-theme" id="btn_add_cust" name="btn_add_cust" (click)="changeFileStatus(childData.id,print_product,'','APPROVE')">{{ 'ORDER.LBL_BTN_APPROVE_RES' | translate }}</button>
                                                                                <button type="button" class="btn-theme-decline" id="" name="btn_add_cust" (click)="changeFileStatus(childData.id,print_product,'','DECLINE')">{{ 'ORDER.LBL_BTN_DECLINE_RES' | translate }}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorleft" let-state>{{'RFQ.LBL_TABLE_FOOTER_SHOWING' | translate}} {{FileDt.totalRecords
                                                            < 1 ? 0 : (state.first + 1)}} {{ 'RFQ.LBL_TABLE_FOOTER_TO' | translate}} {{(state.page + 1)*(state.rows)>= FileDt.totalRecords ? FileDt.totalRecords : (state.page + 1)*(state.rows)}} {{'RFQ.LBL_TABLE_FOOTER_OF' | translate}} {{FileDt.totalRecords}} {{'RFQ.LBL_TABLE_FOOTER_ENTRIES' | translate}}</ng-template>
                                                    </p-table>
                                                </div>
                                            </p-accordionTab>
                                        </p-accordion>
                                    </div>
                                    
                                </div>
                                <!-- Right Part ends here -->
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </ng-template>
    </p-tabPanel>
    <!-- Tab for print product details ends here -->
    <!-- Tab for general product details starts here -->
    <p-tabPanel *ngIf="data.price && data.price.length > 0" [selected]="data.price.length > 0 && data.products.length == 0 ? true: false">
        <ng-template pTemplate="header" dynamic="true"> <span>
                {{ 'RFQ.LBL_GENERAL_PRODUCTS' | translate}}
            </span>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="">
                <p-accordion [ngClass]="{'products-accordion': data.operationType=='ACCEPTANCE' || data.operationType == 'CUSTOMER_ACCEPTANCE'}" [multiple]="true">
                    <p-accordionTab class="product-select" [selected]="data.operationType=='ACCEPTANCE' ? false : true" *ngFor="let general_product of data.price; let j=index;">
                        <p-header>
                            <div class="checkbox inline-block chk-field margin0" *ngIf="data.operationType == acceptanceConstant && this.userData.type === 'PIPELINE_USER'">
                                <label>
                                    <p-checkbox name="products" value="{{general_product.id}}" (onChange)="oncheckboxChecked($event,general_product.id, generalType)" (click)="checkPropogation($event)"></p-checkbox>
                                </label>
                            </div>{{general_product.display_name}}
                            <div class="product-actions" *ngIf="data.operationType!='CONVERTED' && data.operationType!='VIEW' && this.userData.type!='CUSTOMER_USER' && this.userData.type!='SUPPLIER_USER' && data.operationType!='COMPLETED' && data.operationType!='REPORT' && data.operationType != 'REJECTED'">
                                <button class="btn-icon-theme" id="margin_{{general_product.id}}" title="{{'RFQ.LBL_SET_MARGIN' | translate}}" (click)="setMarginClick(general_product,$event,generalType)"> <span class="fa fa-line-chart icon-theme">
                                        </span>
                                </button>
                                <button class="btn-icon-theme" id="margin_{{general_product.id}}" title="{{'RFQ.LBL_ADD_SUPPLIER_BID'| translate}}" (click)="setBidClick(general_product,$event,generalType)"><span class="fa fa-gavel icon-theme"></span>
                                </button>
                            </div>
                        </p-header>

                        <div class="row rfq-confirm">
                            <!-- left part starts here -->
                            <div class="col-md-4 col-sm-5 col-xs-12 border-right padding-0 product-general-dtls products-basic-dtls">
                                <div class="confirm-dtls" *ngIf="general_product.display_name">
                                    <label class="lbl-title">{{ 'RFQ.LBL_PRODUCT_NAME' | translate}}</label>
                                    <label class="lbl-value verticletop">{{general_product.display_name}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="general_product.typeStr">
                                    <label class="lbl-title">{{ 'ORG.LBL_ORG_TYPE' | translate }}</label>
                                    <label class="lbl-value">{{general_product.typeStr}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="general_product?.quantity">
                                    <label class="lbl-title">{{ 'ORDER.LBL_QUANTITY' | translate }}</label>
                                    <label class="lbl-value" *ngIf="general_product?.quantity">{{general_product?.quantity}} {{general_product?.productUnit}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type!='CUSTOMER_USER' && general_product?.supplierDesc">
                                    <label class="lbl-title" *ngIf="this.userData.type=='PIPELINE_USER'">{{ 'RFQ.PLACEHOLDER_SUPPLIER_DESCRIPTION' | translate }}</label>
                                    <label class="lbl-title" *ngIf="this.userData.type=='SUPPLIER_USER'">{{'RFQ.PLACEHOLDER_DESCRIPTION' | translate }}</label>
                                    <label class="lbl-value" [innerHTML]="general_product?.supplierDesc"></label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type!='SUPPLIER_USER' && general_product?.customerDesc">
                                    <label class="lbl-title" *ngIf="this.userData.type=='PIPELINE_USER'">{{ 'RFQ.PLACEHOLDER_CUSTOMER_DESCRIPTION' | translate }}</label>
                                    <label class="lbl-title" *ngIf="this.userData.type=='CUSTOMER_USER'">{{'RFQ.PLACEHOLDER_DESCRIPTION' | translate }}</label>
                                    <label class="lbl-value" [innerHTML]="general_product?.customerDesc"></label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && data.isRFQ && general_product.displayExpectedPrice">
                                    <label class="lbl-title">{{ 'RFQ.LBL_EXPECTED_PRICE' | translate}}</label>
                                    <label class="lbl-value">{{this.fileservice.convertPriceIntoCurrency(general_product.displayExpectedPrice, currencySEK)}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && general_product.acceptedSupplier">
                                    <label class="lbl-title">{{ 'RFQ.LBL_ACCEPTED_SUPPLIER_NAME' | translate}}</label>
                                    <label class="lbl-value">{{general_product.acceptedSupplier}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && general_product.acceptedPriceFormatted">
                                    <label class="lbl-title">{{ 'RFQ.LBL_ACCEPTED_PRICE_FOR_MARGIN' | translate}} / {{ 'ORDER.LBL_COST_PRICE'| translate }}</label>
                                    <label class="lbl-value">{{general_product.acceptedPriceFormatted}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type=='SUPPLIER_USER' && this.userData.role == 'ADMIN' && data.isOrder && general_product.bidPrice">
                                    <label class="lbl-title">{{ 'ORDER.BID_PRICE' | translate}}</label>
                                    <label class="lbl-value">{{general_product?.bidPrice}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type=='PIPELINE_USER' && general_product.otherCost">
                                    <label class="lbl-title">{{ 'RFQ.LBL_COST_TO_PIPELINE_WITHOUT_CURRENCY' | translate}}</label>
                                    <label class="lbl-value verticletop">{{this.fileservice.convertPriceIntoCurrency(general_product.otherCost, currencySEK)}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type == 'PIPELINE_USER' && (general_product.displayMargin || general_product.displayMarginPercentage)">
                                    <label class="lbl-title">{{ 'RFQ.LBL_MARGIN_PRICE' | translate }}</label>
                                    <label class="lbl-value verticletop" *ngIf="general_product.displayMargin && general_product.marginType == 'BY_VALUE'">{{this.fileservice.convertPriceIntoCurrency(general_product.displayMargin, currencySEK)}}</label>
                                    <label class="lbl-value verticletop" *ngIf="general_product.displayMarginPercentage && general_product.marginType == 'BY_PERCENTAGE'">{{general_product.displayMarginPercentage}} %</label>
                                </div>
                                
                                <div class="confirm-dtls" *ngIf="((userData.type=='PIPELINE_USER') || (userData.type=='CUSTOMER_USER' && userData.organizationRegistrationNumber==clearonType) || userData.type=='SUPPLIER_USER') && general_product.total_price">
                                    <label class="lbl-title">{{ 'RFQ.LBL_TOTAL_PRICE' | translate}} </label>
                                    <label class="lbl-value" *ngIf="general_product.total_price">{{this.fileservice.convertPriceIntoCurrency(general_product.total_price, currencySEK)}}</label>
                                </div>
                                <div class="confirm-dtls" *ngIf="this.userData.type == 'PIPELINE_USER' && general_product.generalisBidAllowed">
                                    <label class="lbl-title">{{ 'RFQ.LBL_IS_BIDDING_ALLOWED' | translate}}</label>
                                    <label class="lbl-value verticletop" [innerHTML]="general_product.generalisBidAllowed"></label>
                                </div>
                                <p-accordion [multiple]="true" *ngIf="data.isOrder && general_product?.delivery_content_datatable?.length > 0">
                                    <p-accordionTab header="{{'RFQ.LBL_DELIVERY_DTLS' | translate}} ({{general_product?.delivery_content_datatable?.length}})">
                                        <div class="clearfix product-dtls deliveryDtlsBox" *ngIf="general_product.delivery_content_datatable">
                                            <ul *ngFor="let dtValue of general_product.delivery_content_datatable">
                                                <li>
                                                    <div class="deliveryDetails" *ngIf="dtValue.copies_to_deliver">
                                                        <div class='confirm-dtls'>
                                                            <label class='lbl-title'>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_TOTAL_COPIES' | translate}}</label>
                                                            <label class='lbl-value'>{{dtValue.copies_to_deliver}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="deliveryDetails" *ngIf="dtValue.deliver_packaging_label">
                                                        <div class='confirm-dtls'>
                                                            <label class='lbl-title'>{{ 'RFQ.LBL_DELIVERY_PACKAGING' | translate}}</label>
                                                            <label class='lbl-value'>{{dtValue.deliver_packaging_label}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="deliveryDetails" *ngIf="dtValue.deliver_to_label">
                                                        <div class='confirm-dtls'>
                                                            <label class='lbl-title'>{{ 'RFQ.LBL_DELIVER_TO' | translate}}</label>
                                                            <label class='lbl-value' [innerHTML]="dtValue.deliver_to_label"></label>
                                                        </div>
                                                    </div>
                                                    <div class="deliveryDetails" *ngIf="dtValue.delivery_notes">
                                                        <div class='confirm-dtls'>
                                                            <label class='lbl-title'>{{ 'SUPPLIER_RFQ_PRODUCT.LBL_DELIVERY_NOTE' | translate}}</label>
                                                            <label class='lbl-value'>{{dtValue.delivery_notes}}</label>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                            <!-- left part ends here -->
                            <!-- right part starts here -->
                            <div class="col-md-8 col-sm-7 col-xs-7 col-xs-12 border-left padding-0">
                                <div class="bidWrapper" *ngIf="general_product?.productDetails?.supplierBids.length>0">
                                    <p-accordion [multiple]="true" *ngIf="this.userData.type=='PIPELINE_USER'">
                                        <p-accordionTab header="{{'RFQ.LBL_BIDS' | translate}}" [selected]="data.isRFQ">
                                            <div class="panel-body inner-table-panel-body">
                                                <div class="table-responsive">
                                                    <p-table class="rfq-bids" #generalbiddingDt [columns]="general_product?.productDetails?.biddingCols" [value]="general_product?.productDetails?.supplierBids" [paginator]="true" [rows]="5" [totalRecords]="general_product?.productDetails?.supplierBids?.length" dataKey="id" [rowsPerPageOptions]="rowPerPage" persistState="true" table table-hover>
                                                        <ng-template pTemplate="header" let-columns>
                                                            <tr>
                                                                <th width="11%"></th>
                                                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.colWidth">{{col.header}}
                                                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                                                </th>
                                                                <!-- <th>{{ 'RFQ.LBL_STATUS' | translate}}</th> -->
                                                                <th width="18%">{{ 'RFQ.LBL_PROCESSED_BY' | translate}}</th>
                                                                <th width="25%">{{ 'RFQ.LBL_DECLINE_REASON' | translate}}</th>
                                                                <th width="25%" class="rating">{{ 'RFQ.LBL_RATING' | translate}}</th>
                                                                <th width="12%" *ngIf="data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' &&  data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REJECTED' && data.operationType!='REPORT'">{{ 'DATATABLE.HEAD_ACTION' | translate}}</th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded" let-rowIndex="rowIndex">
                                                            <tr id="bids_list_general_{{rowData.id}}">
                                                                <td> <span id="toggle" (click)="bidExpand(rowData,generalbiddingDt,'bids_list_general_'+rowData.id)">
                                                                        <span *ngIf="expanded" class="add-new fa fa-minus-circle"></span>
                                                                    <span *ngIf="!expanded" class="add-new fa fa-plus-circle"></span>
                                                                    </span>
                                                                </td>
                                                                <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
                                                                <td>{{rowData.bidAcceptedBy}}</td>
                                                                <td>{{rowData.bidDeclineReason}}</td>
                                                                <td *ngIf="rowData.bidStatus != 'DECLINED'">
                                                                    <p-rating [(ngModel)]="rowData.ratings" [disabled]=true [cancel]=false></p-rating>
                                                                </td>

                                                                <td *ngIf="data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' &&  data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REJECTED' && data.operationType!='REPORT'">
                                                                    <button *ngIf="rowData.bidStatus != 'DECLINED' && data.operationType!='REJECTED'" class="btn-icon-theme" title="{{'TOOLTIP_TITLE.LBL_EDIT_BID' | translate }}" (click)="onEditBid(rowData.id,general_product, generalType)">
                                                                        <span class="fa fa-edit icon-theme"></span>
                                                                    </button>
                                                                    <button title="{{'RFQ.LBL_ACCEPT' | translate}}" *ngIf="rowData.bidStatus == 'YET_TO_PROCESS' || rowData.bidStatus == 'REJECTED'" type="button" class="btn-icon-theme" name="btn-accept" (click)="onAcceptBid(rowData.id,general_product)"> <span class="fa fa-check-square-o icon-theme"></span>
                                                                    </button>
                                                                    <button *ngIf="rowData.bidStatus == 'YET_TO_PROCESS' && data.operationType!='REJECTED' && !data.isOrder" class="btn-icon-theme" title="{{'SUPPLIER_RFQ_PRODUCT.LBL_DECLINE_RFQ' | translate}}" name="btn-decline" (click)="onDeclineBid(rowData.id,general_product)"> <span class="fa fa-times-circle-o icon-theme"></span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="rowexpansion" let-childData let-columns="columns">
                                                            <tr>
                                                                <td [attr.colspan]="8" class="pro-inner-content">
                                                                    <div class="row">
                                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix">
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pro-img-dtl-area">
                                                                                <ul class="pro-img-dtl">
                                                                                    <li *ngIf="childData.supplierName"> <span class="pro-title-name">{{ 'ORDER.LBL_SUPPLIER' | translate }}</span>
                                                                                        <span class="pro-data-name">{{childData.supplierName}}</span>
                                                                                    </li>
                                                                                    <li *ngIf="childData.bidDeclineReason"> <span class="pro-title-name">{{ 'RFQ.LBL_DECLINE_REASON' | translate }}</span>
                                                                                        <span class="pro-data-name">{{childData.bidDeclineReason}}</span>
                                                                                    </li>
                                                                                    <li *ngIf="childData.bidPriceStr"> <span class="pro-title-name">{{ 'ORDER.BID_PRICE' | translate }}</span>
                                                                                        <span class="pro-data-name">{{childData.bidPriceStr}}</span>
                                                                                    </li>
                                                                                    <li *ngIf="childData.deliveryTime"> <span class="pro-title-name">{{ 'ORDER.LBL_DELIVERY_TIME' | translate }}</span>
                                                                                        <span class="pro-data-name">{{childData.deliveryTime}}</span>
                                                                                    </li>
                                                                                    <!-- <li> <span class="pro-title-name">1-{{ 'ORDER.LBL_PAPER_DTLS' | translate }}</span>
                                                                                        <span class="pro-data-name" [innerHTML]="childData.supplierPaperDetails"></span>
                                                                                    </li> -->
                                                                                    <li *ngIf="childData.bidComment"> <span class="pro-title-name">{{ 'TOOLTIP_TITLE.LBL_COMMENTS' | translate }}</span>
                                                                                        <span class="pro-data-name">{{childData.bidComment}}</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorleft" let-state>{{'RFQ.LBL_TABLE_FOOTER_SHOWING' | translate}} {{ generalbiddingDt.totalRecords
                                                            < 1 ? 0 :(state.first + 1)}} {{ 'RFQ.LBL_TABLE_FOOTER_TO' | translate}} {{(state.page + 1)*(state.rows)>= generalbiddingDt.totalRecords ? generalbiddingDt.totalRecords : (state.page + 1)*(state.rows)}} {{'RFQ.LBL_TABLE_FOOTER_OF' | translate}} {{generalbiddingDt.totalRecords}} {{'RFQ.LBL_TABLE_FOOTER_ENTRIES' | translate}}</ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="this.userData.type=='SUPPLIER_USER' && type==='RFQ'">
                                    <form method="post" [formGroup]="general_product?.productDetails?.supplierGeneralRFQForm" (ngSubmit)="onSubmitGeneralProduct(general_product.productDetails.generalProductId, general_product.productDetails, general_product.productDetails.supplierGeneralRFQForm, j)">
                                        <div class="pro-price-fields">
                                            <div class="supplier-selection">
                                                <div class="row">
                                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>{{'SUPPLIER_RFQ_PRODUCT.LBL_PRODUCT_PRICE' | translate}} <small class="madatory-star">*</small>
                                                        </label>
                                                        <input [(ngModel)]="general_product.productDetails.generalProductPrice" class="txt-theme form-control" placeholder="{{'SUPPLIER_RFQ_PRODUCT.LBL_PRODUCT_PRICE' | translate}}" type="text" currencyMask [options]="currency_params" id="price" formControlName="productPrice">
                                                        <div *ngIf="general_product?.productDetails?.supplierGeneralRFQForm.hasError('required',['productPrice']) && !general_product.productDetails.generalBidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                        <div *ngIf="general_product.productDetails.showBidZeroError" class="alert alert-danger">
                                                            {{'VALIDATION.BID_PRODUCT_PRICE_GRATERTHAN_ZERO' | translate}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <label>{{'RFQ.LBL_PAY_CURRENCY' | translate}} <small class="madatory-star">*</small>
                                                        </label>
                                                        <p-dropdown #currency styleClass="searchable-select txt-theme form-control" [(ngModel)]="general_product.productDetails.bidGeneralCurrency" [options]="data.currencies" formControlName="payCurrencyId" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" [filter]="false"></p-dropdown>
                                                        <div *ngIf="general_product?.productDetails?.supplierGeneralRFQForm.hasError('required',['payCurrencyId']) && !general_product.productDetails.generalBidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                    </div>
                                                    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <label>{{'SUPPLIER_RFQ_PRODUCT.LBL_DELIVERY_BY' | translate}} <small class="madatory-star">*</small>
                                                        </label>
                                                        <p-dropdown #expected_days styleClass="searchable-select txt-theme form-control" [(ngModel)]="general_product.productDetails.bidGeneralExpectedDays" [options]="data.produtionOptions" formControlName="productionTime" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" [filter]="false"></p-dropdown>
                                                        <div *ngIf="general_product?.productDetails?.supplierGeneralRFQForm.hasError('required',['productionTime']) && !general_product.productDetails.generalBidFormSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>{{'SUPPLIER_RFQ_PRODUCT.LBL_QUATATION_REFERENCE' | translate}}</label>
                                                        <input class="txt-theme form-control" placeholder="{{'SUPPLIER_RFQ_PRODUCT.LBL_QUATATION_REFERENCE' | translate}}" type="text" [(ngModel)]="general_product.productDetails.generalquotationReferenceNumber" id="quotationReferenceNumber" formControlName="quotationReferenceNumber">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>{{'SUPPLIER_RFQ_PRODUCT.LBL_COMMENT' | translate}}</label>
                                                        <input [(ngModel)]="general_product.productDetails.generalComments" class="txt-theme form-control" placeholder="{{'SUPPLIER_RFQ_PRODUCT.LBL_COMMENT' | translate}}" type="text" id="comments" formControlName="comments">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>
                                                            <p-checkbox binary="true" inputId="binary" [(ngModel)]="general_product.productDetails.generalTermsAndCondition" formControlName="termsAndCondition" name="termsAndCondition" id="termsAndCondition"></p-checkbox> <span>{{'RFQ.ACCEPT_TERMS_CONDITION_TEXT' | translate}}<a class='theme-link' (click)='termsAndConditionPopup()'>{{'RFQ.TERMS_AND_CONDITION' | translate}}</a>{{'RFQ.TERMS_ACCEPT' | translate}}</span> 
                                                        </label>
                                                    </div>
                                                    <div class="btn-area">
                                                        <div class="col-md-12 col-sm-12 col-xs-12 text-right">
                                                            <button type="submit" class="btn-theme margin-right-10" id="btn_add" name="btn_add">{{'SUPPLIER_RFQ_PRODUCT.LBL_BTN_SUBMIT' | translate}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 product-upload-area" *ngIf="(this.userData.type=='SUPPLIER_USER' && data.operationType!='PUBLISHED' && data.operationType!='COMPLETED') || ((this.userData.type=='CUSTOMER_USER' || this.userData.type=='PIPELINE_USER') && data.operationType!='REJECTED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED')">

                                    
                                <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 product-upload-area" *ngIf="((this.userData.type=='SUPPLIER_USER' && data.operationType!='PUBLISHED' && data.operationType!='COMPLETED') || (data.operationType!='REJECTED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED'))"> -->

                                    <input type="hidden" name="product_id" [(ngModel)]="general_product.id">
                                    <div class="pro-upload-file" *ngIf="data.operationType!='REPORT'">
                                        <div class="drag-drop-area">
                                            <p-fileUpload #generalFileInput [showUploadButton]="false" [maxFileSize]="fileSizeLimit" mode="advanced" [multiple]="true" name="product_file[]" [accept]="allowedFileExt" (onSelect)="onGeneralFileSelect($event,general_product.id,general_product,'',generalType, j)" (onRemove)="handleGeneralFileRemove()">
                                                <ng-template pTemplate="content">
                                                    <div class="upload-placeholder" *ngIf="showGeneralPlaceHolder"> <i class="fa fa-cloud-upload"></i>
                                                        <p>{{ 'ORDER.UPLOAD_PLACEHOLDER' | translate}}</p>
                                                    </div>
                                                </ng-template>
                                            </p-fileUpload>
                                        </div>
                                    </div>
                                </div>
                                <div class="bidWrapper" *ngIf="general_product?.productDetails?.files.length>0">
                                    <p-accordion [multiple]="true" *ngIf="general_product.productDetails && general_product.productDetails.files && ((this.userData.type=='SUPPLIER_USER' && data.isOrder) || this.userData.type == 'PIPELINE_USER' || this.userData.type == 'CUSTOMER_USER')">
                                        <p-accordionTab header="{{'RFQ.LBL_FILES' | translate}}" [selected]="data.isOrder">
                                            <div class="table-responsive">
                                                <p-table id="file-detail-table1" #generalFileDt [columns]="generalProductFileCols" [value]="general_product?.productDetails?.files" [paginator]="true" [rows]="5" [totalRecords]="general_product?.productDetails?.files?.length" dataKey="no" [rowsPerPageOptions]="rowPerPage" persistState="false" rowExpandMode="single">
                                                    <ng-template pTemplate="header" let-columns>
                                                        <tr>
                                                            <th width="6%"></th>
                                                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.colWidth">{{col.header}}
                                                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                                            </th>
                                                            <th width="15%" *ngIf="this.userData.type=='PIPELINE_USER'">{{ 'ORDER.LBL_DISPLAY_STATUS' | translate}}</th>
                                                            <th width="10%">{{ 'DATATABLE.HEAD_ACTION' | translate}}</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-childRowData let-childColumns="columns" let-childExpanded="expanded" let-childRowIndex="rowIndex">
                                                        <tr id="file_row_print_{{childRowData.id}}" [ngClass]="{'highlight-bids': childRowData.fileSizeDiscrepencies && childRowData.fileSizeDiscrepencies == 1 }">
                                                            <td> <span id="toggle" (click)="fileExpand(childRowData, generalFileDt, general_product.productDetails, childRowIndex,'general',childExpanded,'file_row_print_'+childRowData.id)">
                                                            <span *ngIf="childExpanded" class="add-new fa fa-minus-circle"></span>
                                                                <span *ngIf="!childExpanded" class="add-new fa fa-plus-circle"></span>
                                                                </span>
                                                            </td>
                                                            <td *ngFor="let col of childColumns">{{childRowData[col.field]}}</td>
                                                        
                                                            <td *ngIf="this.userData.type=='PIPELINE_USER'" [innerHtml]="childRowData.fileStatusSupplier"></td>
                                                            <td class="action-items text-right">
                                                                <button *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DOWNLOAD_FILE' | translate}}" (click)="downloadFile(childRowData?.id,'PROCESSED',childRowData)"> <span class="fa fa-cloud-download icon-theme"></span>
                                                                </button>
                                                                <button type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DOWNLOAD_ORIGINAL_FILE' | translate}}" (click)="downloadFile(childRowData?.id,'ORIGINAL',childRowData)" *ngIf="!childRowData.pitstopStatus || childRowData?.fileExtension!='pdf'"> <span class="fa fa-download icon-theme"></span>
                                                                </button>
                                                                <button *ngIf="this.userData.type=='CUSTOMER_USER' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}" (click)="deleteFiles(print_product,'',childRowData?.id, i)"> <span class="fa fa-trash-o icon-theme"> </span>
                                                                </button>
                                                                <button *ngIf="this.userData.type=='SUPPLIER_USER' && childRowData.userType =='SUPPLIER_USER' && data.operationType!='COMPLETED' && childRowData.rfqOrderFileCurrentStatus!='APPROVED'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}" (click)="deleteFiles(print_product,'',childRowData?.id, i)"> <span class="fa fa-trash-o icon-theme"> </span>
                                                                    </button>
                                                                <!-- {{childRowData | json}} -->
                                                                <div class="dropdown action-icon" *ngIf="this.userData.type=='PIPELINE_USER'">
                                                                    <button title="{{'RFQ.TOOLTIP_MORE_ACTIONS'|translate}}" (click)="toggleActions(childRowData?.id)" class="dropbtn"><span class="fa fa-ellipsis-v icon-theme"> </span>
                                                                    </button>
                                                                    <div id="actions_dropdown_{{childRowData.id}}" class="dropdown-content">
                                                                        <!-- File Share Icon -->
                                                                        <!-- {{childRowData | json}} -->
                                                                        <button *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_SHARE_OUTPUT_FILE' | translate}}" (click)="openCommentsPopup(childRowData?.pitstopReportSystemFileId, childRowData?.fileName,'print_output')"> <span class="fa fa-share-square-o icon-theme"></span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_SHARE_OUTPUT_FILE' | translate}}</label>
                                                                        </button>
                                                                        <button *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_SHARE_PROCESSED_FILE' | translate}}" (click)="openCommentsPopup(childRowData?.pitstopOutputSystemFileId, childRowData?.fileName, 'PROCESSED')"> <span class="fa fa-share-alt icon-theme"></span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_SHARE_PROCESSED_FILE' | translate}}</label>
                                                                        </button>
                                                                        <button type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_SHARE_ORIGINAL_FILE' | translate}}" (click)="openCommentsPopup(childRowData?.systemFileId, childRowData?.fileName, 'ORIGINAL')"> <span class="fa fa-share icon-theme"></span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_SHARE_ORIGINAL_FILE' | translate}}</label>
                                                                        </button>
                                                                        <!-- File Share Icon -->
                                                                        <button type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DOWNLOAD_ORIGINAL_FILE' | translate}}" (click)="downloadFile(childRowData?.id,'ORIGINAL',childRowData)" *ngIf="childRowData?.pitstopStatus && childRowData?.fileExtension=='pdf'"> <span class="fa fa-download icon-theme"></span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_DOWNLOAD_ORIGINAL_FILE' | translate}}</label>
                                                                        </button>
                                                                        <!-- type--{{this.userData.type}} -->
                                                                        <!-- status--{{childRowData?.fileStatus}}
                                                                        discard--{{childRowData?.fileDiscardedStatus}} -->
                                                                        <!-- operation--{{data.operationType}} -->
                                                                        <button *ngIf="this.userData.type=='PIPELINE_USER' && (childRowData?.fileStatus===false) && (childRowData?.fileDiscardedStatus!='DECLINED') && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && childRowData.userType!=supplierUserType" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_CONVERT_SHOW' | translate}}" (click)="showFile(childRowData?.id, childRowData?.fileStatus)"> <span class="fa fa-eye icon-theme"></span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_CONVERT_SHOW' | translate}}</label>
                                                                        </button>

                                                                        <button *ngIf="this.userData.type=='PIPELINE_USER' && 
                                                                        (childRowData?.fileStatus===true) &&  
                                                                        (childRowData?.fileDiscardedStatus!='DECLINED') && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && childRowData.userType!=supplierUserType" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_CONVERT_HIDDEN' | translate}}" (click)="showFile(childRowData?.id, childRowData?.fileStatus)"> <span class="fa fa-eye-slash icon-theme"></span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_CONVERT_HIDDEN' | translate}}</label>
                                                                        </button>
                                                                        <button *ngIf="this.userData.type!='SUPPLIER_USER' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CUSTOMER_REJECTED'" type="button" class="btn-icon-theme" title="{{ 'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}" (click)="deleteFiles(print_product,'',childRowData?.id, i)"> <span class="fa fa-trash-o icon-theme"> </span>
                                                                            <label>{{'TOOLTIP_TITLE.LBL_DELETE_FILE' | translate}}</label>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="rowexpansion" let-childData let-columns="columns">
                                                        <tr>
                                                            <!-- {{userData.type}} -->
                                                            <td [attr.colspan]="4" class="pro-inner-content" *ngIf="userData.type=='PIPELINE_USER'">
                                                                <div class="row">
                                                                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-4 text-right">
                                                                        <!-- {{images | json}} -->
                                                                        <div class="pro-img" *ngIf="images[childData.id]">
                                                                            <!-- hello-{{images[childData.id].type}} -->
                                                                            <p-galleria  *ngIf="(images[childData.id].type==1)" class="gallery-img" [(value)]="images[childData.id].thumbs" [responsiveOptions]="responsiveOptions"  [showItemNavigators]="true" 
                                                                            [showThumbnails]="false">
                                                                                <ng-template pTemplate="item" let-item>
                                                                                    <img [src]="item.title" style="width: 100%; display: block;" (click)="displayPreview(item.source, item.pitstopReportFileId)"/>
                                                                                </ng-template>
                                                                            </p-galleria>
                                                                            
                                                                            <div *ngIf="(images[childData.id].type==2 || images[childData.id].type==4 || images[childData.id].type==3)">
                                                                                <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                    <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-8 pro-img-dtl-area">
                                                                        <ul class="pro-img-dtl">
                                                                            <li *ngIf="childData.fileDtls.uploadedFileDate"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_UPLOADED' | translate }}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.uploadedFileDate}}</span>
                                                                            </li>
                                                                            <li *ngIf="childData.fileDtls.uploadedFileSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_SIZE' | translate }}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.uploadedFileSizeStr}}</span>
                                                                            </li>
                                                                            <li *ngIf="childData.fileDtls.fileFinishedSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_FINISHED_SIZE' | translate}}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.fileFinishedSizeStr}}</span>
                                                                            </li>
                                                                            <li *ngIf="childData.fileDtls.rfqOrderFileCurrentStatusStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_STATUS' | translate }}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.rfqOrderFileCurrentStatusStr}}</span>
                                                                            </li>
                                                                            
                                                                            <li *ngIf="fileExtention"> <span class="pro-title-name">{{'RFQ.LBL_RFQ_TYPE' | translate}}</span>
                                                                                <span class="pro-data-name">{{fileExtention}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                                        <div class="alert-msgs">
                                                                            <div class="error-msgs msg-combo" *ngIf="childData.fileDtls.pitstopErrorMessages != null ">
                                                                                <h4>{{'COMMON.ERRORS' | translate}}</h4>
                                                                                <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopErrorMessages;  index as i">
                                                                                    <p>{{i+1}} {{data}}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="warning-msgs msg-combo" *ngIf="childData.fileDtls.pitstopWarningMessages != null">
                                                                                <h4>{{'COMMON.WARNINGS' | translate}}</h4>
                                                                                <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopWarningMessages;  index as i">
                                                                                    <p>{{i+1}} {{data}}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12 text-right">
                                                                        <div class="btn-groups" *ngIf="(childData.fileDtls.rfqOrderFileCurrentStatus == 'PITSTOP_PROCESSED' || childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalStatus || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalPipelineStatus) || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingPipelineApprovalStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalCustomerStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalClearOnStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingClearOnApprovalStatus) || childData.fileDtls.rfqOrderFileCurrentStatus==pendingPipelineClearOnApprovalStatus) && data.operationType!='COMPLETED' && 
                                                                        userData.type=='PIPELINE_USER' && data.operationType!='CUSTOMER_COMPLETED' && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REJECTED'">
                                                                            <button type="button" class="btn-theme" id="btn_add_cust" name="btn_add_cust" (click)="changeFileStatus(childData.id,general_product,'','APPROVE')">{{ 'ORDER.LBL_BTN_APPROVE_RES' | translate }}</button>
                                                                            <button type="button" class="btn-theme-decline" id="" name="btn_add_cust" (click)="changeFileStatus(childData.id,general_product,'','DECLINE')">{{ 'ORDER.LBL_BTN_DECLINE_RES' | translate }}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td [attr.colspan]="3" class="pro-inner-content" *ngIf="userData.type!='PIPELINE_USER'">
                                                                <div class="row">
                                                                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-4 text-right">
                                                                        <div class="pro-img" *ngIf="images[childData.id]">
                                                                            <p-galleria *ngIf="(images[childData.id].type==1)" class="gallery-img" [(value)]="images[childData.id].thumbs" [responsiveOptions]="responsiveOptions"  [showItemNavigators]="true" [showThumbnails]="false">
                                                                                <ng-template pTemplate="item" let-item>
                                                                                    <img [src]="item.title" style="width: 100%; display: block;" (click)="displayPreview(item.source, item.pitstopReportFileId)"/>
                                                                                </ng-template>
                                                                            </p-galleria>
                                                                            <div *ngIf="(images[childData.id].type==2 || images[childData.id].type==4)">
                                                                               <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                    <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="(images[childData.id].type==3)">
                                                                                <div class="pipeline-file-preview" *ngFor="let img of images[childData.id].thumbs; let i=index">
                                                                                    <img *ngIf="i==0" class="example-image" src="{{img.source}}" alt="image-1" (click)="open(i,childData.id)"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-8 pro-img-dtl-area">
                                                                        <ul class="pro-img-dtl">
                                                                            <li *ngIf="childData.fileDtls.uploadedFileDate"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_UPLOADED' | translate }}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.uploadedFileDate}}</span>
                                                                            </li>
                                                                            <li *ngIf="childData.fileDtls.rfqOrderFileCurrentStatusStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_STATUS' | translate }}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.rfqOrderFileCurrentStatusStr}}</span>
                                                                            </li>
                                                                            <li *ngIf="childData.fileDtls.uploadedFileSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_SIZE_MB' | translate }}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.uploadedFileSizeStr}}</span>
                                                                            </li>
                                                                            <li *ngIf="childData.fileDtls.fileFinishedSizeStr"> <span class="pro-title-name">{{ 'RFQ.LBL_FILE_FINISHED_SIZE' | translate}}</span>
                                                                                <span class="pro-data-name">{{childData.fileDtls.fileFinishedSizeStr}}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                                        <div class="alert-msgs">
                                                                            <div class="error-msgs msg-combo" *ngIf="childData.fileDtls.pitstopErrorMessages != null ">
                                                                                <h4>{{'COMMON.ERRORS' | translate}}</h4>
                                                                                <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopErrorMessages;  index as i">
                                                                                    <p>{{i+1}} {{data}}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="warning-msgs msg-combo" *ngIf="childData.fileDtls.pitstopWarningMessages != null">
                                                                                <h4>{{'COMMON.WARNINGS' | translate}}</h4>
                                                                                <div class="msgs" *ngFor="let data of childData.fileDtls.pitstopWarningMessages;  index as i">
                                                                                    <p>{{i+1}} {{data}}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-sm-12 text-right">

                                                                        <div class="btn-groups" *ngIf="(childData.fileDtls.rfqOrderFileCurrentStatus == 'PITSTOP_PROCESSED' || childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalStatus || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingApprovalPipelineStatus) || (userData.type=='PIPELINE_USER' && childData.fileDtls.rfqOrderFileCurrentStatus == pendingPipelineApprovalStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalCustomerStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingApprovalClearOnStatus) || (userData.type=='CUSTOMER_USER' && childData.fileDtls.rfqOrderFileCurrentStatus ==pendingClearOnApprovalStatus) || childData.fileDtls.rfqOrderFileCurrentStatus==pendingPipelineClearOnApprovalStatus) && data.operationType!='COMPLETED' && data.operationType!='CUSTOMER_COMPLETED' && (
                                                                            userData.type=='PIPELINE_USER' || userData.type=='CUSTOMER_USER') && data.operationType!='SUPPLIER_COMPLETED' && data.operationType!='CONVERTED' && data.operationType!='VIEW' && data.operationType!='REJECTED' && data.operationType!='CUSTOMER_REJECTED'">
                                                                            <button type="button" class="btn-theme" id="btn_add_cust" name="btn_add_cust" (click)="changeFileStatus(childData.id,general_product,'','APPROVE')">{{ 'ORDER.LBL_BTN_APPROVE_RES' | translate }}</button>
                                                                            <button type="button" class="btn-theme-decline" id="" name="btn_add_cust" (click)="changeFileStatus(childData.id,general_product,'','DECLINE')">{{ 'ORDER.LBL_BTN_DECLINE_RES' | translate }}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="paginatorleft" let-state>{{'RFQ.LBL_TABLE_FOOTER_SHOWING' | translate}} {{generalFileDt.totalRecords
                                                        < 1 ? 0 : (state.first + 1)}} {{ 'RFQ.LBL_TABLE_FOOTER_TO' | translate}} {{(state.page + 1)*(state.rows)>= generalFileDt.totalRecords ? generalFileDt.totalRecords : (state.page + 1)*(state.rows)}} {{'RFQ.LBL_TABLE_FOOTER_OF' | translate}} {{generalFileDt.totalRecords}} {{'RFQ.LBL_TABLE_FOOTER_ENTRIES' | translate}}</ng-template>
                                                </p-table>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>
                                <!--  -->
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </ng-template>
    </p-tabPanel>
</p-tabView>
<div class="container margintop10" *ngIf="data.operationType == acceptanceConstant">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            <div class="form-group">
                <div class="checkbox chk-field">
                    <label>
                        <p-checkbox (onChange)="changeAcceptTerms($event)" name="accept_terms" id="accept_terms"></p-checkbox> <span>{{'RFQ.ACCEPT_TERMS_CONDITION_TEXT' | translate}}<a class='theme-link' (click)='termsAndConditionPopup()'>{{'RFQ.TERMS_AND_CONDITION' | translate}}</a>{{'RFQ.TERMS_ACCEPT' | translate}}</span> 
                    </label>
                    <div class="alert alert-danger" *ngIf="!isAcceptedTerms && submitted">{{'VALIDATION.FIELD_VALIDATE' | translate }}</div>
                    <div *ngIf="isCheckedProduct" class="alert alert-danger">{{ 'VALIDATION.PRODUCT_VALIDATION' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="text-right btn-accept-reject">
                <button type="button" class="btn-theme" name="btn-submit" (click)="onAccept(data.id,data)">{{ 'RFQ.LBL_ACCEPT' | translate}}</button>
                <button (click)="openRejectPopup(data)" type="button" class="btn-theme-decline" name="btn-submit">{{ 'RFQ.LBL_REJECT' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- <p-confirmDialog [baseZIndex]="10001" #cd header="{{actionDialogBoxTitle}}" [style]="{width: '50vw'}" class="modalBox">
    <ng-template pTemplate="footer">
        <button type="button" class="cancelButton" pButton icon="pi pi-times" label="{{ 'FIELDS.BTN_NO' | translate }}" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="{{ 'FIELDS.BTN_YES' | translate }}" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog> -->
<p-dialog class="add-notes-popup add-bid-popup declineDialogBox" [baseZIndex]="10001" [style]="{width: '50vw', height: '20vw'}" [(visible)]="showDeclinePopup" showEffect="fade" [modal]="true">
    <p-header>{{'RFQ.DECLINE_BID' | translate}}</p-header>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 product-dtls">
        <div class="pro-price-fields">
            <div class="pricing-area">
                <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>{{ 'RFQ.LBL_DECLINE_REASON' | translate }} <small class="madatory-star">*</small>
                    </label>
                    <p-dropdown styleClass="searchable-select txt-theme form-control" [(ngModel)]="declineReason" [options]="declineReasonOption" placeholder="{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}" [filter]="false" (onChange)="checkDeclineReason()"></p-dropdown>
                    <div *ngIf="showDeclineReasonError" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 text-right">
                <button type="button" class="cancelButton" pButton icon="pi pi-times" label="{{ 'FIELDS.BTN_NO' | translate }}" (click)="this.showDeclinePopup = false"></button>
                <button type="button" pButton icon="pi pi-check" label="{{ 'FIELDS.BTN_YES' | translate }}" (click)="onSubmitDecline()" [disabled]="!declineReason"></button>
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-dialog class="add-notes-popup" [style]="{width: '50vw'}" [(visible)]="showDecline" showEffect="fade" [modal]="true">
    <p-header>{{ 'RFQ.REJECT_RFQ' | translate}}</p-header>
    <div class="notes-content">
        <div class="add-notes">
            <div class="row">
                <div class="col-mg-12 col-sm-12 col-xs-12">
                    <form id="frm_add_notes" [formGroup]="rejectRfqForm?.getForm()" (ngSubmit)="onRejectionRfq(data.id,data)" method="post">
                        <div class="form-group">
                            <label>{{ 'RFQ.LBL_REJECTION_REASON' | translate }} <small class="madatory-star">*</small>
                            </label>
                            <select #selectElem class="txt-theme form-control" formControlName="rejectionReason" [(ngModel)]="selectedDeclineReason" id="rejectionReason" name="rejectionReason" (change)="onRejectionReasonChange()">
                                <option *ngFor="let type of rejectionReasonOptions" [value]="type.value">{{type.label}}</option>
                            </select>
                            <div *ngIf="rejectRfqForm?.hasError('rejectionReason')" class="alert alert-danger">{{rejectRfqForm?.getError('rejectionReason')}}</div>
                        </div>
                        <div class="form-group">
                            <label>{{ 'TOOLTIP_TITLE.LBL_COMMENTS' | translate }}</label>
                            <textarea [(ngModel)]="filledcomments" class="txt-theme form-control" name="rfqRejectionComments" id="rfqRejectionComments" formControlName="rfqRejectionComments" placeholder="{{ 'TOOLTIP_TITLE.LBL_COMMENTS' | translate }}" rows="3"></textarea>
                            <div *ngIf="rejectRfqForm?.hasError('rfqRejectionComments')" class="alert alert-danger">{{rejectRfqForm?.getError('rfqRejectionComments')}}</div>
                            <div *ngIf="isCommentValidate && (filledcomments == null || filledcomments == '')  && !isRejectionSubmitted" class="alert alert-danger">{{'VALIDATION.FIELD_VALIDATE' | translate}}</div>
                        </div>
                        <div class="form-group">
                            <div class="btn-area btn-accept-reject">
                                <button type="submit" class="btn-theme" name="btn_add">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_BTN_SUBMIT' | translate}}</button>
                                <button type="button" (click)="cancelDeclineRequest()" class="btn-theme-decline" name="btn_decline">{{ 'SUPPLIER_RFQ_PRODUCT.LBL_BTN_CANCEL' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <p-footer></p-footer>
</p-dialog>
<p-dialog class="set-margin-popup" [(visible)]="showAddCommentsPopup" showEffect="fade" [modal]="true">
    <p-header *ngIf="original">{{ 'FILE_SHARING.ORIGINAL_FILE_MESSAGE_POPUP' | translate }}</p-header>
    <p-header *ngIf="processed">{{ 'FILE_SHARING.PRINT_FILE_DOWNLOAD_MESSAGE_POPUP' | translate }}</p-header>
    <p-header *ngIf="print_output">{{ 'FILE_SHARING.PRINT_FILE_OUTPUT_MESSAGE_POPUP' | translate }}</p-header>
    <div class="change-password-fields">
        <form [formGroup]="addCommentsForm?.getForm()" (ngSubmit)="shareFile($event,addCommentsForm.value)" method="post">
            <div class="form-group password-control">
                <label>{{ 'FILE_SHARING.LBL_MESSAGE' | translate }}</label>
                <input class="txt-theme form-control" formControlName="comments" name="comments" placeholder="{{ 'FILE_SHARING.LBL_MESSAGE' | translate }}" type="text" />
                <div *ngIf="isInvalid" class="alert alert-danger">{{validationErrors['comments']}}</div>
                <div *ngIf="addCommentsForm?.hasError('comments')" class="alert alert-danger">{{addCommentsForm?.getError('comments')}}</div>
                <br/>{{'FILE_SHARING.LBL_INFO' | translate }}</div>
            <input type="hidden" formControlName="systemFileId">
            <!-- <input type="hidden" formControlName="sharing_type"> -->
            <div class="btn-area">
                <button class="btn-theme" id="btn-login" type="submit">{{ 'CHANGE_PSWD.BTN_LBL_CHANGE' | translate }}</button>
            </div>
        </form>
    </div>
</p-dialog>
<!-- File Sharing dialog starts -->
<p-dialog class="paper-dtl-dialog fileSharingBox" [(visible)]="showFileSharingDialog" showEffect="fade" [modal]="true">
    <p-header>{{'FILE_SHARING.FILE_SHARING_HEADER' | translate}}</p-header>
    <p>{{'FILE_SHARING.FILE_DOWNLOAD_TEXT' | translate}}</p>
    <b>{{downloadableFileName}}</b>
    <br/>
    <br/>
    <br/>
    <br/>
    <p>{{'FILE_SHARING.FILE_COPY_PASTE_TEXT' | translate}}</p>
    <div class="copyLink">
        <code>{{fileSharingLink}}</code> 
    </div>
    <button type="button" class="btn-theme" (click)="copyMessage(fileSharingLink)" value="{{'CONT_PER.BTN_LBL_CONT_PER_COPY' | translate}}">{{'CONT_PER.BTN_LBL_CONT_PER_COPY' | translate}}</button>
    <p-footer>
        <div class="btn-area">
            <button type="button" class="btn-theme" (click)="emailShareableLink()">{{'FILE_SHARING.NEW_EMAIL_BTN_TEXT' | translate}}</button>
            <button type="button" class="btn-theme" (click)="closeShareablePopup()">{{'COMMON.OK_LABEL' | translate}}</button>
        </div>
    </p-footer>
</p-dialog>
<!-- File Sharing dialog ends -->
<p-dialog class="paper-dtl-dialog" [(visible)]="newTestFileInput?.currentFiles?.length>0 && showExtraFieldForFile" showEffect="fade" [modal]="true" [closable]="false">
    <p-header>{{ 'RFQ.LBL_FILE_INFO' | translate}}</p-header>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p-table #FileXtraInfoDt [columns]="fileXtraInfoCols" [value]="fileExtraInfo" [paginator]="true" [rows]="100" dataKey="no" [rowsPerPageOptions]="" persistState="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let col of fileXtraInfoCols" [pSortableColumn]="col.field" [style.width]="col.colWidth">{{col.header}}</th>
                            <th width="20%">{{'RFQ.LBL_FILE_TYPE' | translate}}</th>
                            <th width="20%" *ngIf="selectedFilesProductType!=generalType">{{'RFQ.LBL_PAPER_OPTION' | translate}}</th>
                            <th width="20%" *ngIf="userData.type=='PIPELINE_USER' && 
                            (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType">{{'RFQ.LBL_PROFILE_TYPE' | translate}}</th>
                            <th *ngIf="userData.type=='PIPELINE_USER' && (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType" width="10%">{{'RFQ.LBL_TRAY' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-childRowData let-childColumns="columns" let-childRowIndex="rowIndex">
                        <tr *ngIf="newTestFileInput.currentFiles && newTestFileInput.currentFiles[childRowIndex]">
                            <td *ngFor="let col of fileXtraInfoCols">{{ childRowData[col.field] }}</td>
                            <td>
                                <select #fileType class="txt-theme form-control" [(ngModel)]="fileTypeVal[childRowIndex]" (change)="getFileType(fileType.value,childRowIndex)" id="fileType_{{childRowIndex}}">
                                    <option value="">{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}</option>
                                    <option *ngFor="let type of uploadedFileType" [value]="type.value">{{type.label}}</option>
                                </select>
                                <div id="error_fileType_{{childRowIndex}}" class="red"></div>
                            </td>
                            <td *ngIf="newTestFileInput.currentFiles[childRowIndex].type==fileTypePdf && selectedFilesProductType!=generalType">
                                <select #paperType class="txt-theme form-control" [(ngModel)]="paperTypeVal[childRowIndex]" (change)="getPaperType(paperType.value,childRowIndex)" id="paperType_{{childRowIndex}}">
                                    <option value="">{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}</option>
                                    <option *ngFor="let type of paper_type" [value]="type.val">{{type.key}}</option>
                                </select>
                                <div id="error_paperType_{{childRowIndex}}" class="red"></div>
                            </td>
                            
                            <td *ngIf="newTestFileInput.currentFiles[childRowIndex].type!=fileTypePdf && (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType">N/A</td>
                            <td *ngIf="newTestFileInput.currentFiles[childRowIndex].type==fileTypePdf &&  (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType" [style.display]="userData.type=='PIPELINE_USER' ? 'block' : 'none'">
                                <select #profileType class="txt-theme form-control" [(ngModel)]="profileTypeVal[childRowIndex]" (change)="getProfileType(profileType.value,childRowIndex)" id="profileType_{{childRowIndex}}">
                                    <option value="">{{'COMMON.DEFAULT_SELECT_TEXT' | translate}}</option>
                                    <option *ngFor="let proType of profile_type" [value]="proType">{{proType}}</option>
                                </select>
                                <div id="error_profileType_{{childRowIndex}}" class="red"></div>
                            </td>
                            <td *ngIf="newTestFileInput.currentFiles[childRowIndex].type!=fileTypePdf && userData.type=='PIPELINE_USER' && (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType">N/A</td>
                            <td *ngIf="newTestFileInput.currentFiles[childRowIndex].type==fileTypePdf && userData.type=='PIPELINE_USER' && (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType" id="tray_{{childRowIndex}}">{{trayVal[childRowIndex]}}</td>
                            <td *ngIf="newTestFileInput.currentFiles[childRowIndex].type!=fileTypePdf && userData.type=='PIPELINE_USER' && (selectedProductPrintType!=looseCouponPrinting && selectedProductPrintType!=gluedCouponPrinting) && selectedFilesProductType!=generalType">N/A</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <p-footer>
        <button type="button" class="btn-theme" (click)="submitFileInfo($event, fileProductId)">{{'SUPPLIER_RFQ_PRODUCT.LBL_BTN_SUBMIT' | translate}}</button>
    </p-footer>
</p-dialog>
<p-dialog class="add-notes-popup add-bid-popup" [baseZIndex]="10002" [style]="{width: '50vw'}" [(visible)]="showTermsAndConditionPopup" showEffect="fade" [modal]="true">
     <p-header> {{'RFQ.POPUP_TITLE_TERMS_AND_CONDITION' | translate}} </p-header>
     {{'RFQ.TERMS_CONDITION_BODY' | translate}}
</p-dialog>