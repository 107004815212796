import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ValidationManager } from 'ng2-validation-manager';
import { ConfirmationService } from 'primeng/api';
import { GeneralFunction } from 'src/app/common/general-function';
import { ValidationRules } from 'src/app/common/validationRules-constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Constants } from 'src/app/constant/constants';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { FilehandlerService } from 'src/app/service/filehandler.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import * as fileObj from 'file-saver';
import { LightboxConfig, Lightbox } from 'ngx-lightbox';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent} from '@angular/common/http';

@Component({
  selector: 'app-rfq-order-child-data',
  templateUrl: './rfq-order-child-data.component.html',
  styleUrls: ['./rfq-order-child-data.component.css']
})
export class RfqOrderChildDataComponent implements OnInit {
    @Output() loadingChildProp = new EventEmitter <any> ();
    @Input() data: any;
    @Input() type: any;
    @ViewChild('fileInput') fileInput: any;
    @ViewChild('generalFileInput') generalFileInput: any;
    currencySEK: any = Constants.CURRENCY_VAL;
    fileTypePdf: any = Constants.PDF_FILE_TYPE;
    showExtraFieldForFile: boolean = false;
    fileXtraInfoCols: any[] = [];
    profileTypeVal: any[] = [];
    trayVal: any[] = [];
    paperTypeVal: any[] = [];
    fileTypeVal: any[] = [];
    currExt: any;
    finalPaperMstID: any[] = [];
    progress: any[] = [];
    fileList: any[] = [];
    fileExtraInfo: any[] = [];
    showPlaceHolder: any = true;
    showGeneralPlaceHolder: any = true;
    showTermsAndConditionPopup:boolean=false;
    uploadedGeneralFiles: any = 0;
    paper_type: any[] = [];
    selectedPaperType: string[] = [];
    fileProductId: any;
    fileIndex: any[] = [];
    profile_type: any[] = ['COATED', 'UNCOATED'];
    coverValue: any[] = [];
    contentValue: any[] = [];
    defaultValue: any[] = [];
    seletedPaperType: any;
    paperMstID: any[] = [];
    newTestFileInput: any;
    newTestGeneralFileInput: any;
    shareFileResult: any;
    lblPipeLineUser: any;
    showFileSharingDialog: boolean = false;
    downloadableFileName: any;
    fileSharingLink: any;
    addCommentsForm: any;
    showAddCommentsPopup: boolean = false;
    original: boolean = false;
    print_output: boolean = false;
    processed: boolean = false;
    fileName: any;
    fileSharingId: any;
    shareLinkToEmail: any;
    dateFormat: any = Constants.datePlaceholderforCalender;
    yearRange: any = Constants.yearRange;
    userData: any;
    apiData: any;
    moment: any = moment;
    todayDate: any = new Date();
    deliveryDate: any;
    seletedFileType:any;
    printingDate: any;
    materialDate: any;
    productFileCols: any;
    generalProductFileCols:any;
    acceptanceConstant: any = Constants.ORDER_RFQ_TYPE['ACCEPTANCE'];
    convertedConstant: any = Constants.ORDER_RFQ_TYPE['CONVERTED'];
    rejectedConstant: any = Constants.ORDER_RFQ_TYPE['REJECTED'];
    customerAcceptanceConstant: any = Constants.ORDER_RFQ_TYPE['CUSTOMER_ACCEPTANCE'];
    fileSizeLimit: any = Constants.FILE_SIZE_LIMIT;
    bidComment: any;
    bidQuotationRefNo: any;
    bidPrice: any;
    allowedFileExt: any = Constants.allowedFileExt;
    supplierName: any;
    deliveryTime: any;
    additionalCopyPrice: any;
    rowPerPage: any = Constants.ROW_PER_PAGE;
    supplierContentPaperDetails: any;
    supplierCoverPaperDetails: any;
    @Output() valueChange = new EventEmitter();
    @Output() valueEdit = new EventEmitter();
    @Output() valueSetMargin = new EventEmitter();
    @Output() valueSetBid = new EventEmitter();
    @Output() valueNotesClick = new EventEmitter();
    @Output() valueMessagePopup = new EventEmitter();
    @Output() valueRefreshTab = new EventEmitter();
    @Output() valueEditBid = new EventEmitter();
    loading: any;
    requestDate: any;
    expiryDate: any;
    index: any = 0;
    actionDialogBoxTitle: any;
    modelMsg: any;
    parentTableData: any;
    declineReasonOption: any;
    declineReason: any = '';
    //Constants.DECLINE_REASON.OTHER_SUPPLIER;
    // declineOtherReason: any;
    showDeclineReasonError: any = false;
    // showDeclineOtherReasonError: any = false;
    showDeclinePopup: boolean = false;
    selectedBidId: any;
    product_ids: any = [];
    isCheckedProduct: boolean = false;
    isAcceptedTerms: any = false;
    submitted: any = false;
    acceptBidData: any;
    product_ids_needded: boolean = false;
    showDecline: any = false;
    rejectRfqForm: any;
    isCommentValidate: boolean;
    filledcomments: any;
    isRejectionSubmitted: any = true;
    rejectionReasonOptions: any = [];
    rejectedData: any;
    selectedDeclineReason: any = '';
    rfqOrderExpandData: any;
    curriencesOptions: any;
    bidFormSubmitted: any = [];
    termsAndCondition: any = [];
    paperSupplierArray: any = [];
    bidCurrency: any = [];
    bidExpectedDays: any = [''];
    currency_params = Constants.CURRENCY_CONVERSION_PARAMS;
    currency_params_price = Constants.CURRENCY_CONVERSION_PARAMS_PRICE;
    currency_params_percentage = Constants.CURRENCY_CONVERSION_PARAMS_PERCENTAGE;
    formData: any;
    headers: any;
    fileData: any;
    selectedFilesProductType: any='';
    selectedFilesProductInfo: any;
    selectedFilesProductIndex: any;
    isInvalid: boolean = false;
    fileDtlsRes: any;
    images: any[] = [];
    rowData: any;
    dt: any;
    confirmMsg: any;
    fileExtention: any;
    sharableType: any;
    tokenToShare: any;
    rfqOrderProductFileId:any;
    selectedAccordianView: any = true;
    selectedPrintProduct: any = [];
    totalSelectedPrintProduct: number = 0;
    selectedOtherProduct: any = [];
    totalSelectedOtherProduct: number = 0;
    selectedProductPrintType:any='';
    pendingApprovalStatus = Constants.FILE_VIEW_STATUS['PENDING_APPROVED'];
    pendingApprovalPipelineStatus = Constants.FILE_VIEW_STATUS['PENDING_APPROVAL_PIPELINE'];
    pendingApprovalCustomerEndCustomerStatus= Constants.FILE_VIEW_STATUS['PENDING_APPROVAL_CUSTOMER_END_CUSTOMER'];
    pendingApprovalCustomerStatus= Constants.FILE_VIEW_STATUS['PENDING_APPROVAL_CUSTOMER'];
    pendingApprovalClearOnStatus= Constants.FILE_VIEW_STATUS['PENDING_APPROVAL_CLEARON'];
    pendingPipelineApprovalStatus = Constants.FILE_VIEW_STATUS['PENDING_PIPELINE_APPROVAL'];
    pendingClearOnApprovalStatus= Constants.FILE_VIEW_STATUS['PENDING_CLEARON_APPROVAL'];
    pendingPipelineClearOnApprovalStatus = Constants.FILE_VIEW_STATUS['PENDING_APPROVAL_PIPELINE_CUSTOMER'];
    gluedCouponPrinting = Constants.PRINT_TYPES['GLUED_COUPON_PRINTING'];
    looseCouponPrinting = Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING'];
    _album: any[] = [];
    supplierUserType:any=Constants.USER_TYPE['SUPPLIER_USER'];
    uploadedFileType:any=[];
    generalType:any=Constants.PRODUCT_TYPES['GENERAL'];
    clearonType=Constants.CLEARON;

    constructor(private translate: TranslateService, private responseHandler: ResponseHandlerService, private apiCallingService: ApiCallingService, private cdRef: ChangeDetectorRef, public fileservice: FilehandlerService, private confirmationService: ConfirmationService, public formBuilder: FormBuilder, private _lightboxConfig: LightboxConfig, private _lightbox: Lightbox) {
        this.userData = localStorage.getItem(Constants.SESSION_NAME);
        var sessionData: any = localStorage.getItem(Constants.SESSION_NAME);
        this.userData = JSON.parse(sessionData);
        console.log('this.userData', this.userData);
        if (this.userData != null) {
            translate.setDefaultLang(this.userData.i18nCode);
        } else {
            translate.setDefaultLang(Constants.EN);
        }
    }
    ngOnInit(): void {
        this.rejectRfqForm = new ValidationManager(ValidationRules.rejectRfqForm);
        this.addCommentsForm = new ValidationManager({
            'comments': 'maxLength:500',
            'systemFileId': 'required',
        });
        this.fetchRFQOrderDetails();
        this.productFileCols = [
            {
                field: 'fileName',
                header: 'File Name',
                colWidth: '17%'
            },{
                field: 'systemFileTypeStr',
                header: 'File Type',
                colWidth:'17%'
            }
        ];

        this.generalProductFileCols = [
            {
                field: 'fileName',
                header: 'File Name',
                colWidth: '45%'
            },{
                field: 'systemFileTypeStr',
                header: 'File Type',
                colWidth:'24%'
            }
        ];
        
        this.translate.get('DECLINE_REASON').subscribe((text: any) => {
            this.declineReasonOption = [
            {
                value: '',
                label: text.SELECT,
            },
            {
                value: Constants.DECLINE_REASON.OTHER_SUPPLIER,
                label: text.OTHER_SUPPLIER,
            }];
        });
        this.translate.get('DECLINE_REASON_RFQ').subscribe((text: any) => {
            this.rejectionReasonOptions = [{
                value: '',
                label: text.SELECT,
            }, {
                value: Constants.DECLINE_REASON_RFQ.UPDATION_OF_RFQ_IS_REQUIRED,
                label: text.UPDATION_OF_RFQ_IS_REQUIRED,
            }, {
                value: Constants.DECLINE_REASON_RFQ.PRICE_IS_TOO_HIGH,
                label: text.PRICE_IS_TOO_HIGH,
            }, {
                value: Constants.DECLINE_REASON_RFQ.DELIVERY_IS_TOO_MUCH_DELAYED,
                label: text.DELIVERY_IS_TOO_MUCH_DELAYED,
            }, {
                value: Constants.DECLINE_REASON_RFQ.OTHER_REASONS,
                label: text.OTHER_REASONS,
            }];
        });
    }
    setLoading(loading: any): void {
        this.loadingChildProp.emit(loading);
    }
    fetchRFQOrderDetails() {
        this.loadRfqOrderExpandData(this.data.id);
        this.requestDate = new Date(this.data.rfqRequestDate);
        this.deliveryDate = new Date(this.data.supplierDeliveryDate);
        this.printingDate = new Date(this.data.supplierPrintingDate);
        this.materialDate = new Date(this.data.customerMaterialDate);
        this.expiryDate = new Date(this.data.pipelineExpiryDate);

        // console.log('Data', this.data);
    }
    loadRfqOrderExpandData(id: any) {
        this.setLoading(true);
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, ApiEndpointConstant.expandGetDataAPI(id)).subscribe(result => {
            if (result.resultCode == Constants.resultCode.SUCCESS) {
                this.rfqOrderExpandData = result
                this.curriencesOptions = GeneralFunction.getDropDownDataForPrimeng(this.rfqOrderExpandData.currencies)
                this.setLoading(false);
            } else {
                this.setLoading(false);
                this.responseHandler.setErrorPopupMessage(result.message);
            }
        }, error => {
            this.setLoading(false);
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }
    myUploader(event: any) {
        console.log(JSON.stringify(event));
    }
    initBidProduct(rowDataObj: any = null) {
        if (rowDataObj) {
            return this.formBuilder.group({
                'pageType': [rowDataObj.pageTypeSupplier, []],
                'paperName': [rowDataObj.printPaperNameSupplier, []],
                'paperWeight': [rowDataObj.paperWeightSupplier, []]
            });
        } else {
            return this.formBuilder.group({
                'pageType': ['CONTENT', []],
                'paperName': ['', []],
                'paperWeight': ['', []]
            });
        }
    }
    initBidProductEdit(rowDataObj: any = null) {
        if (rowDataObj) {
            return this.formBuilder.group({
                'pageType': [rowDataObj.pageType, []],
                'paperName': [rowDataObj.paperName, []],
                'paperWeight': [rowDataObj.paperWeight, []]
            });
        } else {
            return this.formBuilder.group({
                'pageType': ['CONTENT', []],
                'paperName': ['', []],
                'paperWeight': ['', []]
            });
        }
    }
    updateData(field: any, value: any, rfqOrderId: any, isDate: any) {
        let newValue: any = value;
        if (isDate) {
            newValue = moment(value).format(Constants.datePlaceholder);
        }
        this.setLoading(true);
        let dataToUpdate: any = {
            "field": field,
            "value": newValue
        };
        this.apiCallingService.callAuthAPI(dataToUpdate, Constants.requestType.PUT, ApiEndpointConstant.editRfqOrderFieldData(rfqOrderId)).subscribe(result => {
            if (result.resultCode == Constants.resultCode.SUCCESS) {
                this.responseHandler.setSuccessPopupMessage(result.message);
                let parentDataToUpdate: any = {}
                if (dataToUpdate.field == 'REQUEST_DATE') {
                    parentDataToUpdate['field'] = 'rfqRequestDate';
                    this.requestDate = new Date(newValue);
                } else if (dataToUpdate.field == 'EXPIRY_DATE') {
                    parentDataToUpdate['field'] = 'pipelineExpiryDate';
                    this.expiryDate = new Date(newValue);
                } else if (dataToUpdate.field == 'EXPECTED_DELIVERY_DATE') {
                    parentDataToUpdate['field'] = 'expDeliveryDate';
                } else if (dataToUpdate.field == 'CUSTOMER_MATERIAL_DATE') {
                    parentDataToUpdate['field'] = 'customerMaterialDate';
                    this.materialDate = new Date(newValue);
                } else if (dataToUpdate.field == 'SUPPLIER_PRINTING_DATE') {
                    this.printingDate = new Date(newValue);
                    parentDataToUpdate['field'] = 'deliveryDate';
                } else if (dataToUpdate.field == 'SUPPLIER_DELIVERY_DATE') {
                    this.deliveryDate = new Date(newValue);
                    parentDataToUpdate['field'] = 'printingDate';
                } else if (dataToUpdate.field == 'CUSTOMER_DELIVERY_DATE') {
                    parentDataToUpdate['field'] = 'customerDeliveryDate';
                } else if (dataToUpdate.field == 'PROJECT_MANAGER') {
                    parentDataToUpdate['field'] = 'projectManagerId';
                    let projectManagerName: any = this.data.project_manager_opt.filter((projectId: any) => {
                        return projectId.value === parseInt(newValue);
                    });
                    if (projectManagerName.length > 0) {
                        this.data.projectManagerName = projectManagerName[0].label
                    }
                } else if (dataToUpdate.field == 'ACCOUNT_MANAGER') {
                    parentDataToUpdate['field'] = 'accountManagerId';
                    let accountManagerName: any = this.data.account_manager_opt.filter((accountId: any) => {
                        return accountId.value === parseInt(newValue);
                    });
                    if (accountManagerName.length > 0) {
                        this.data.accountManagerName = accountManagerName[0].label
                    }
                } else if (dataToUpdate.field == 'SALES_MANAGER') {
                    parentDataToUpdate['field'] = 'salesManagerId';
                    let salesManagerName: any = this.data.sales_manager_opt.filter((salesId: any) => {
                        return salesId.value === parseInt(newValue);
                    });
                    if (salesManagerName.length > 0) {
                        this.data.salesManagerName = salesManagerName[0].label
                    }
                } else if (dataToUpdate.field == 'VAT') {
                    parentDataToUpdate['field'] = 'vatPercentage';
                    this.data.vatPercentage = newValue;
                }
                parentDataToUpdate['value'] = dataToUpdate.value;
                this.triggerRefresh(parentDataToUpdate);
            }
            this.setLoading(false);
        }, error => {
            this.setLoading(false);
        });
    }
    updateVatData(value: any, rfqOrderId: any) {
        console.log(value);
        console.log(this.data.perviousVatPercentage);
        let newValue: any = value;
        let dataToUpdate: any = {
            "field": 'VAT',
            "value": newValue
        };
        if (value != this.data.perviousVatPercentage) {
            this.setLoading(true);
            this.apiCallingService.callAuthAPI(dataToUpdate, Constants.requestType.PUT, ApiEndpointConstant.editRfqOrderFieldData(rfqOrderId)).subscribe(result => {
                if (result.resultCode == Constants.resultCode.SUCCESS) {
                    this.responseHandler.setSuccessPopupMessage(result.message);
                    let parentDataToUpdate: any = {}                
                    parentDataToUpdate['field'] = 'vatPercentage';
                    this.data.vatPercentage = newValue;
                    this.data.perviousVatPercentage = newValue;
                    parentDataToUpdate['value'] = dataToUpdate.value;
                    this.triggerRefresh(parentDataToUpdate);
                }
                this.setLoading(false);
            }, error => {
                this.setLoading(false);
            });
        }
    }
    bidExpand(data: any, dt: any, id: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(data)) {
            this.bidComment = data.bidComment;
            this.bidQuotationRefNo = data.bidQuotationRefNo;
            this.bidPrice = data.bidPriceStr;
            this.supplierName = data.supplierName;
            this.deliveryTime = data.deliveryTime;
            this.supplierContentPaperDetails = data.supplierContentPaperDetails;
            this.supplierCoverPaperDetails = data.supplierCoverPaperDetails;
            this.additionalCopyPrice = data.additionalCopyPrice;
            dt.toggleRow(data);
            // if ($("#" + id).offset()) {
            //     $('html, body').animate({
            //         scrollTop: ($("#" + id).offset().top - 60)
            //     }, 1000);
            // }
        }
    }
    oncheckboxChecked(event: any, id: any, type: any) {
        if (type === Constants.PRODUCT_TYPES.PRINT) {
            if (event.checked) {
                if (this.selectedPrintProduct.indexOf(id) == -1) {
                    this.selectedPrintProduct.push(id);
                }
            } else {
                var index = this.selectedPrintProduct.indexOf(id);
                if (index !== -1) this.selectedPrintProduct.splice(index, 1);
            }
            this.totalSelectedPrintProduct = this.selectedPrintProduct.length;
        } else if (type === Constants.PRODUCT_TYPES.GENERAL) {
            if (event.checked) {
                if (this.selectedOtherProduct.indexOf(id) == -1) {
                    this.selectedOtherProduct.push(id);
                } else {
                    var index = this.selectedOtherProduct.indexOf(id);
                    if (index !== -1) this.selectedOtherProduct.splice(index, 1);
                }
            }
            this.totalSelectedOtherProduct = this.selectedOtherProduct.length;
        }
        if (event.checked) {
            if (this.product_ids.indexOf(id) == -1) {
                this.product_ids.push(id);
                this.isCheckedProduct = false;
            }
        } else {
            var index = this.product_ids.indexOf(id);
            if (index !== -1) this.product_ids.splice(index, 1);
        }
    }
    checkPropogation(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }
    setMarginClick(data: any, event: any, type: any) {
        this.valueSetMargin.emit({
            id: data,
            eventData: event,
            type: type
        });
    }
    triggerRefresh(dataToUpdate: any, refresh = false) {
        this.valueRefreshTab.emit({
            parentTableData: this.data.parentTableData,
            refresh: refresh
        });
    }
    setBidClick(data: any, event: any, type: any) {
        console.log(type);
        this.valueSetBid.emit({
            id: data,
            eventData: event,
            type: type
        });
    }
    onEditBid(id: any, data: any, type: any) {
        this.valueEditBid.emit({
            id: id,
            data: data,
            type: type
        });
    }
    onAcceptBid(bidId: any, data: any) {
        this.parentTableData = data.parentTableData;
        this.actionDialogBoxTitle = this.translate.instant('COMMON.CONFIRM_HEADER');
        this.modelMsg = this.translate.instant('RFQ.CONFIRM_BID_ACCEPT_RFQ_MSG');
        setTimeout(() => {
            this.confirmationService.confirm({
                message: this.modelMsg,
                acceptLabel: this.translate.instant('FIELDS.BTN_YES'),
                rejectLabel: this.translate.instant('FIELDS.BTN_NO'),
                icon: 'fa fa-question-circle',
                accept: () => {
                    this.setLoading(true);
                    this.apiCallingService.callAuthAPI({}, Constants.requestType.PUT, ApiEndpointConstant.acceptBid(bidId)).subscribe(result => {
                        if (result.resultCode == Constants.resultCode.SUCCESS) {
                            this.responseHandler.setSuccessPopupMessage(result.message);
                            this.triggerRefresh(this.parentTableData);
                        } else {
                            this.responseHandler.setErrorPopupMessage(result.message);
                        }
                        this.setLoading(false);
                    }, error => {
                        this.setLoading(false);
                        this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
                    });
                },
            });
        }, Constants.validationTimeout);
    }
    toggleActions(id: any) {
        $('.dropdown-content').each(function() {
            if ($(this).hasClass('show')) $(this).removeClass('show');
        })
        if (!$('#actions_dropdown_' + id).hasClass('show')) {
            $('#actions_dropdown_' + id).addClass('show');
        } else $('#actions_dropdown_' + id).removeClass('show');
    }
    
    onSubmitPrintProduct(productId: any, productDetails: any, supplierForm, index) {
        if (supplierForm.valid && productDetails.termsAndCondition) {
            const body = supplierForm.value;
            if (body.productPrice > 0) {
                productDetails.showBidZeroError = false;
            } else {
                productDetails.showBidZeroError = true;
            }
            if (productDetails.isAdditionalCopiesAvailable && body.additionalCopiesProductPrice > 0) {
                productDetails.showAdditionalBidZeroError = false;
            } else if (!productDetails.isAdditionalCopiesAvailable) {
                productDetails.showAdditionalBidZeroError = false;
            } else {
                productDetails.showAdditionalBidZeroError = true;
            }
            if (!productDetails.showAdditionalBidZeroError && !productDetails.showBidZeroError) {
                this.translate.get('SUPPLIER_RFQ_PRODUCT').subscribe((text: any) => {
                    this.modelMsg = text.CONFIRM_BID_SUBMIT;
                });
                this.confirmationService.confirm({
                    message: this.modelMsg,
                    icon: 'fa fa-question-circle',
                    accept: () => {
                        productDetails.bidFormSubmitted = true;
                        this.setLoading(true);
                        if (!productDetails.isAdditionalCopiesAvailable) {
                            delete body.additionalCopiesProductPrice
                        }
                        body.supplierOrganizationId = this.userData.organizationId;
                        body.notifySupplier = false;
                        delete body.termsAndCondition;
                        let url: any;
                        let methodType: any;
                        if (productDetails.editBid) {
                            url = ApiEndpointConstant.editSupplierBid(productDetails.editSupplierBid);
                            methodType = Constants.requestType.PUT;
                        } else {
                            url = ApiEndpointConstant.addSupplierBid(productId);
                            methodType = Constants.requestType.POST;
                        }
                        this.apiCallingService.callAuthAPI(body, methodType, url).subscribe(result => {
                            if (result.resultCode == Constants.resultCode.SUCCESS) {
                                this.triggerRefresh(this.data.parentTableData);
                                productDetails.editBid = true;
                                productDetails.editSupplierBid = result?.object;
                                this.responseHandler.setSuccessPopupMessage(result.message);
                            } else {
                                this.responseHandler.setErrorPopupMessage(result.message);
                            }
                            this.setLoading(false);
                        }, error => {
                            this.setLoading(false);
                        });
                    },
                });
            }
        } else {
            productDetails.bidFormSubmitted = false;
        }
        this.cdRef.detectChanges();
    }
    onSubmitGeneralProduct(productId: any, productDetails: any, supplierForm, index) {
        if (supplierForm.valid && productDetails.generalTermsAndCondition) {
            const body = {
                supplierOrganizationId: this.userData.organizationId,
                productPrice: supplierForm.get('productPrice').value,
                payCurrencyId: supplierForm.get('payCurrencyId').value,
                productionTime: supplierForm.get('productionTime').value,
                quotationReferenceNumber: supplierForm.get('quotationReferenceNumber').value,
                comments: supplierForm.get('comments').value,
                notifySupplier: false,
            };        
            if (body.productPrice > 0) {
                productDetails.showBidZeroError = false;
            } else {
                productDetails.showBidZeroError = true;
            }
            if (!productDetails.showBidZeroError) {
                this.translate.get('SUPPLIER_RFQ_PRODUCT').subscribe((text: any) => {
                    this.modelMsg = text.CONFIRM_BID_SUBMIT;
                });
                this.confirmationService.confirm({
                    message: this.modelMsg,
                    icon: 'fa fa-question-circle',
                    accept: () => {
                        productDetails.generalBidFormSubmitted = true;
                        this.setLoading(true);
                        let url: any;
                        let methodType: any;
                        if (productDetails.editGeneralBid) {
                            url = ApiEndpointConstant.editSupplierBid(productDetails.editGeneralSupplierBid);
                            methodType = Constants.requestType.PUT;
                        } else {
                            url = ApiEndpointConstant.addSupplierBid(productId);
                            methodType = Constants.requestType.POST;
                        }
                        this.apiCallingService.callAuthAPI(body, methodType, url).subscribe(result => {
                            if (result.resultCode == Constants.resultCode.SUCCESS) {
                                this.responseHandler.setSuccessPopupMessage(result.message);
                                this.triggerRefresh(this.data.parentTableData);
                                productDetails.editGeneralSupplierBid = result?.object;
                            } else {
                                this.responseHandler.setErrorPopupMessage(result.message);
                            }
                            this.setLoading(false);
                        }, error => {
                            this.setLoading(false);
                        });
                    },
                });
            }
        } else {
            productDetails.generalBidFormSubmitted = false;
        }
    }
    checkDeclineReason() {
        // if (this.declineReason === Constants.DECLINE_REASON.OTHER_REASONS) {
        //   this.showDeclineOtherReasonError = true;
        // } else {
        //   this.showDeclineOtherReasonError = false;
        // }
        if (this.declineReason) {
            this.showDeclineReasonError = false;
        } else {
            this.showDeclineReasonError = true;
        }
    }
    onDeclineBid(id: any, data: any) {
        this.selectedBidId = id;
        this.showDeclinePopup = true;
        this.parentTableData = data.parentTableData;
    }
    onSubmitDecline() {
        if (!this.showDeclineReasonError) {
            this.setLoading(true);
            this.apiCallingService.callAuthAPI({}, Constants.requestType.PUT, ApiEndpointConstant.declineBid(this.selectedBidId, this.declineReason)).subscribe(result => {
                if (result.resultCode == Constants.resultCode.SUCCESS) {
                    this.showDeclinePopup = false;
                    this.responseHandler.setSuccessPopupMessage(result.message);
                    this.triggerRefresh(this.parentTableData);
                } else {
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
                this.setLoading(false);
            }, error => {
                this.setLoading(false);
            });
        } else {
            this.showDeclineReasonError = true;
        }
        this.cdRef.detectChanges();
    }
    changeAcceptTerms(event) {
        this.isAcceptedTerms = event.checked;
    }
    onAccept(id: any, data: any) {
        this.submitted = true;
        this.acceptBidData = data.parentTableData;
        if (this.isAcceptedTerms && id) {
            if (!GeneralFunction.checkForNullUndefinedBlank(this.product_ids)) {
                this.actionDialogBoxTitle = this.translate.instant('COMMON.CONFIRM_HEADER');
                if (this.totalSelectedOtherProduct > 0 && this.totalSelectedPrintProduct > 0) {
                    this.modelMsg = this.translate.instant('RFQ.CONFIRM_RFQ_CUSTOMER_PRINT_GENERAL_ACCEPT_MSG', {totalPrintProduct: this.totalSelectedPrintProduct, totalGeneralProduct: this.totalSelectedOtherProduct});
                } else if (this.totalSelectedOtherProduct > 0) {
                    this.modelMsg = this.translate.instant('RFQ.CONFIRM_RFQ_CUSTOMER_GENERAL_ACCEPT_MSG', {totalGeneralProduct: this.totalSelectedOtherProduct});
                } else if (this.totalSelectedPrintProduct > 0) {
                    this.modelMsg = this.translate.instant('RFQ.CONFIRM_RFQ_CUSTOMER_PRINT_ACCEPT_MSG', {totalPrintProduct: this.totalSelectedPrintProduct});
                }
                setTimeout(() => {
                    this.confirmationService.confirm({
                        message: this.modelMsg,
                        acceptLabel: this.translate.instant('FIELDS.BTN_YES'),
                        rejectLabel: this.translate.instant('FIELDS.BTN_NO'),
                        icon: 'fa fa-question-circle',
                        accept: () => {
                            this.setLoading(true);
                            const body = {
                                productIds: this.product_ids
                            }
                            this.apiCallingService.callAuthAPI(body, Constants.requestType.POST, ApiEndpointConstant.acceptRfq(id)).subscribe(result => {
                                if (result.resultCode == Constants.resultCode.SUCCESS) {
                                    this.responseHandler.setSuccessPopupMessage(result.message);
                                    this.triggerRefresh(this.acceptBidData, true);
                                } else {
                                    this.responseHandler.setErrorPopupMessage(result.message);
                                }
                                this.setLoading(false);
                            }, error => {
                                this.setLoading(false);
                                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
                            });
                        },
                    });
                }, Constants.validationTimeout);
            } else {
                this.product_ids_needded = true;
                this.isCheckedProduct = true;
            }
        }
    }
    openRejectPopup(data: any) {
        this.showDecline = true;
    }
    cancelDeclineRequest() {
        this.showDecline = false;
        this.rejectRfqForm.reset();
    }
    onRejectionReasonChange() {
        if (this.selectedDeclineReason === Constants.DECLINE_REASON_RFQ.OTHER_REASONS) {
            this.isCommentValidate = true;
        } else {
            this.isCommentValidate = false;
        }
    }
    onRejectionRfq(rfqId: any, data: any) {
        this.rejectedData = data.parentTableData;
        const formData = this.rejectRfqForm.getData();
        let ValidForm = true;
        if (this.isCommentValidate && GeneralFunction.checkForNullUndefinedBlank(formData.rfqRejectionComments)) {
            ValidForm = false;
        }
        if (ValidForm && this.rejectRfqForm.isValid()) {
            this.setLoading(true);
            this.apiCallingService.callAuthAPI(formData, Constants.requestType.POST, ApiEndpointConstant.rejectRfq(rfqId)).subscribe(result => {
                if (result.resultCode == Constants.resultCode.SUCCESS) {
                    this.responseHandler.setSuccessPopupMessage(result.message);
                    this.triggerRefresh(this.rejectedData, true);
                } else {
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
                this.setLoading(false);
            }, error => {
                this.setLoading(false);
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
        } else {
            this.isRejectionSubmitted = false;
        }
    }
    // file handling related function 
    openCommentsPopup(file_id: any, file_name: any, file_type: any) {
        this.showAddCommentsPopup = true;
        this.addCommentsForm.reset();
        if (file_type == Constants.ORIGINAL) {
            this.original = true;
            this.print_output = false;
            this.processed = false;
        } else if (file_type == Constants.PROCESSED) {
            this.processed = true;
            this.original = false;
            this.print_output = false;
        } else if (file_type == "print_output") {
            this.print_output = true;
            this.original = false;
            this.processed = false;
        }
        this.addCommentsForm.setValue({
            systemFileId: file_id,
        });
        this.fileName = file_name;
        this.sharableType = file_type;
    }
    emailShareableLink() {
        this.setLoading(true);
        var url = ApiEndpointConstant.emailFileShare(this.tokenToShare);
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, url).subscribe(result => {
            if (result.resultCode == Constants.resultCode.SUCCESS) {
                this.showFileSharingDialog = false;
                this.responseHandler.setSuccessPopupMessage(result.message);
            } else {
                this.responseHandler.setErrorPopupMessage(result.message);
            }
            this.setLoading(false);
        }, error => {
            this.setLoading(false);
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }
    closeShareablePopup() {
        this.showFileSharingDialog = false;
    }
    copyMessage(val: string) {
        this.responseHandler.setSuccessPopupMessage(this.translate.instant('RFQ.BTN_LBL_CONT_PER_COPIED'));
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.innerText = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
    shareFile(event: any, formData: any) {
        this.formData = this.addCommentsForm.getData();
        var file_type = this.sharableType;
        if (this.addCommentsForm.isValid()) {
            this.setLoading(true);
            this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.POST, ApiEndpointConstant.fileShare).subscribe(result => {
                this.setLoading(false);
                this.shareFileResult = result;
                if (result.resultCode == Constants.resultCode.SUCCESS) {
                    this.showAddCommentsPopup = false;
                    this.showFileSharingDialog = true;
                    this.fileSharingId = this.shareFileResult.fileShareId;
                    this.downloadableFileName = this.fileName;
                    this.tokenToShare = this.shareFileResult.fileShareToken;
                    this.responseHandler.setSuccessPopupMessage(result.message);
                    if (file_type == Constants.ORIGINAL) {
                        this.fileSharingLink = Constants.DEV_LINK + 'file/hook?share_type=original&token=' + this.shareFileResult.fileShareToken;
                    } else if (file_type == Constants.PROCESSED) {
                        this.fileSharingLink = Constants.DEV_LINK + 'file/hook?share_type=processed&token=' + this.shareFileResult.fileShareToken;
                    } else if (file_type == 'print_output') {
                        this.fileSharingLink = Constants.DEV_LINK + 'file/cloudproof?token=' + this.shareFileResult.fileShareToken;
                    }
                } else {
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
            }, error => {
                this.setLoading(false);
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
        }
    }
    fileExpand(rowData: any, dt: any, data: any = null, childRowIndex: any = null, type: any = null, expanded: any = null, id: any = null) {
        this.fileDtlsRes = [];
        this.images = [];
        this._album=[];
        this.rowData = rowData;
        this.dt = dt;
        this.setLoading(true);
        if (!expanded) {
            this.apiCallingService.callAuthAPI('', Constants.requestType.GET, ApiEndpointConstant.getFileDtlsById(rowData.id)).subscribe(result => {
                this.fileDtlsRes = result;
                this.rfqOrderProductFileId=rowData.id;
                if (result.resultCode == Constants.resultCode.SUCCESS) {
                    rowData.fileDtls = this.fileDtlsRes;
                    let errorsArray = [];
                    let warningArray = [];

                    if (!GeneralFunction.checkForNullUndefinedBlank(this.fileDtlsRes.thumbnailImages)) {
                        var extension: any = this.fileDtlsRes.fileExtension;
                        this.fileExtention = extension.toUpperCase();
                        this.images[rowData.id] = {
                            'id': '',
                            'pitstopReportFileId':'',
                            'alt': '',
                            'type': '',
                            'thumbs': []
                        };
                        for (var img = 0; img < this.fileDtlsRes.thumbnailImages.length; img++) {
                            var type:any = this.checkFilePreviewStatus(this.fileExtention, this.fileDtlsRes.rfqOrderFileCurrentStatus);
                            // if (img > 9) { break; }
                            console.log('type',type);
                            this.images[rowData.id].thumbs.push({
                                source: this.fileDtlsRes.thumbnailImages[img],
                                title: this.fileDtlsRes.previewImages[img],
                                page_no: img + 1,
                                id: this.fileDtlsRes.uploadedFileId,
                                pitstopReportFileId:this.fileDtlsRes.pitstopReportFileId,
                                alt: this.fileExtention,
                                type: type
                            });

                            this._album.push({
                                 src: this.fileDtlsRes.previewImages[img],
                                 caption: '',
                                 thumb: this.fileDtlsRes.thumbnailImages[img]
                              });


                            this.images[rowData.id].id = this.fileDtlsRes.uploadedFileId;
                            this.images[rowData.id].alt = this.fileExtention;
                            this.images[rowData.id].type = type;
                            // console.log('Images',this.images[rowData.id].thumbs);
                        }


                    }
                    if (this.fileExtention == "PDF") {
                        console.log('this.fileDtlsRes', this.fileDtlsRes);
                        var filePaperDtls = '<b>'+this.translate.instant('RFQ.LBL_PAPER_TYPE')+':</b> ' + this.fileDtlsRes.paperType + '<br/>';
                        if (this.userData.type != "SUPPLIER") {
                            filePaperDtls += '<b>'+this.translate.instant('RFQ.LBL_PAPER_PROFILE_TYPE')+': </b>' + this.fileDtlsRes.printPaperProfileStr + '<br/>';
                        }
                        if (this.userData.type == "PIPELINE_USER") {
                            filePaperDtls += '<b>'+this.translate.instant('RFQ.LBL_TITLE_TRAY')+':</b> ' + this.fileDtlsRes.printTray;
                        }
                    } else {
                        var filePaperDtls = '';
                    }
                    var description = "<b>"+this.translate.instant('ORG.LBL_ORG_TYPE')+": </b>" + this.fileExtention + "</br>";
                    description += "<b>"+this.translate.instant('COMMON.SIZE')+": </b>" + this.fileDtlsRes.uploadedFileSizeStr + "</br>";
                    description += "<b>"+this.translate.instant('COMMON.STATUS')+": </b>" + this.fileDtlsRes.supplierVisibilityStatusStr;
                    var description1 = '<span>' + description + '</span>';
                    if (type == 'print') {
                        data.files[childRowIndex].description = description1;
                        data.files[childRowIndex].filePaperDtls = filePaperDtls;
                    } else if (type == 'general') {
                        data.files[childRowIndex].description = description1;
                    }
                    this.loading = false;
                    if (id) {
                        // $('html, body').animate({
                        //     scrollTop: ($("#" + id).offset().top - 60)
                        // }, 1000);
                    }
                    console.log('data.files', data.files);
                } else {
                    this.responseHandler.setErrorPopupMessage(result.message);
                }
                this.setLoading(false);
            }, error => {
                this.setLoading(false);
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
        } else {
            this.setLoading(false);
        }
        dt.toggleRow(this.rowData);
    }
    showFile(fileId: any, fileStatus: any) {
        // console.log('fileId', fileId);
        // console.log('fileStatus', fileStatus);
        if (!fileStatus) {
            var status: any = Constants.FILE_VIEW_STATUS['VISIBLE'];
        } else {
            var status: any = Constants.FILE_VIEW_STATUS['HIDDEN'];
        }
        this.modelMsg = this.translate.instant('RFQ.CONFIRM_FILE_STATUS_MSG');
        this.confirmationService.confirm({
            icon: 'fa fa-question-circle',
            message: this.modelMsg,
            acceptLabel: this.translate.instant('FIELDS.BTN_YES'),
            rejectLabel: this.translate.instant('FIELDS.BTN_NO'),
            accept: () => {
                this.setLoading(true);
                let url = ApiEndpointConstant.changeSupplierVisibilty(fileId, status)
                this.apiCallingService.callAuthAPI('', Constants.requestType.PUT, url).subscribe(result => {
                    if (result.resultCode == Constants.resultCode.SUCCESS) {
                        this.triggerRefresh(this.data.parentTableData);
                        this.responseHandler.setSuccessPopupMessage(result.message);
                    } else {
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.setLoading(false);
                }, error => {
                    this.setLoading(false);
                });
            }
        });
    }
    deleteFiles(product: any, price: any, fileId: any, productIndex: any) {
        this.modelMsg = this.translate.instant('RFQ.CONFIRM_FILE_DELETE_MSG');
        this.confirmationService.confirm({
            icon: 'fa fa-question-circle',
            message: this.modelMsg,
            acceptLabel: this.translate.instant('FIELDS.BTN_YES'),
            rejectLabel: this.translate.instant('FIELDS.BTN_NO'),
            accept: () => {
                this.setLoading(true);
                let url = ApiEndpointConstant.deleteFile(fileId);
                this.apiCallingService.callAuthAPI('', Constants.requestType.DELETE, url).subscribe(result => {
                    if (result.resultCode == Constants.resultCode.SUCCESS) {
                        this.triggerRefresh(this.data.parentTableData);
                        this.responseHandler.setSuccessPopupMessage(result.message);
                    } else {
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.setLoading(false);
                }, error => {
                    this.setLoading(false);
                });
            }
        });
    }
    changeFileStatus(fileId: any, product: any, price: any, status: any) {
        if (status == 'APPROVE') {
            this.modelMsg = this.translate.instant('RFQ.CONFIRM_FILE_STATUS_CHANGE_APPROVE_MSG');
        } else if (status == 'DECLINE') {
            this.modelMsg = this.translate.instant('RFQ.CONFIRM_FILE_STATUS_CHANGE_DISCARD_MSG');
        }
        this.confirmationService.confirm({
            icon: 'fa fa-question-circle',
            message: this.modelMsg,
            acceptLabel: this.translate.instant('FIELDS.BTN_YES'),
            rejectLabel: this.translate.instant('FIELDS.BTN_NO'),
            accept: () => {
                this.setLoading(true);
                let url = ApiEndpointConstant.changeFileStatus(fileId, status)
                this.apiCallingService.callAuthAPI('', Constants.requestType.PUT, url).subscribe(result => {
                    if (result.resultCode == Constants.resultCode.SUCCESS) {
                        this.triggerRefresh(this.data.parentTableData);
                        this.responseHandler.setSuccessPopupMessage(result.message);
                    } else {
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.setLoading(false);
                }, error => {
                    this.setLoading(false);
                });
            }
        });
    }
    handleGeneralFileRemove() {}
    onGeneralFileSelect(event: any, productId: any, product: any, price: any, type: any, productIndex: any){
        this.newTestFileInput = event;
        this.generalFileInput = event;
        this.fileIndex = [];
        this.selectedFilesProductType = type;
        this.selectedFilesProductInfo = product;
        this.selectedFilesProductIndex = productIndex;
        this.getFileTypeDropDownValues(this.selectedFilesProductType);
        var i = 0;
        for (let file of this.newTestFileInput.files) {
            this.fileIndex.push(i);
            this.fileExtraInfo.push({
                'no': i,
                'name': file.name,
                'size': this.formatBytes(file.size)
            });
            if (GeneralFunction.checkForNullUndefinedBlank(this.fileTypeVal[i])) {
                this.fileTypeVal[i] = '';
            }
            i++;
        }
        if (!GeneralFunction.checkForNullUndefinedBlank(this.fileExtraInfo)) {
            this.showExtraFieldForFile = true;
            this.fileXtraInfoCols = [{
                field: 'name',
                header: 'Name',
                colWidth: '20%'
            }, {
                field: 'size',
                header: 'Size',
                colWidth: '10%'
            }];
            this.fileProductId = product.id;
        }
        if (this.newTestFileInput.files.length > 0) {
            this.showPlaceHolder = false;
            this.fileTypeVal[0] = '';
        }
        // setTimeout(() => {
            // this.onFileUpload(event, productId, product, price, type, productIndex);
        // }, Constants.validationTimeout);
        
    }
    onFileUpload(event: any, productId: any, product: any, price: any, type: any, productIndex: any, paperVal?:any) {
        console.log('product', product);
        $(".p-fileupload-row").each(function(key, elem) {
            if (type != Constants.PRODUCT_TYPES['GENERAL']) {
                $(this).find('div:nth-child(5)').after('<div class="progress-bar-custom"><small id="progress_val_' + key + '"></small><div class="progress" style="width:0%;" id="progress_' + key + '"></div></div>');
                $(this).find('button').hide();
            } else if (type == Constants.PRODUCT_TYPES['GENERAL']) {
                $(this).find('div:nth-child(3)').after('<div class="progress-bar-custom"><small id="progress_val_' + key + '"></small><div class="progress" style="width:0%;" id="progress_' + key + '"></div></div>');
                $(this).find('button').hide();
            }
        });
        var formData = new FormData();
        for (let f = 0; f < event.currentFiles.length; f++) {
            formData.set('rfqOrderId', this.data.id);
            formData.set('file', event.currentFiles[f]);
            formData.set('rfqOrderProductId', productId);

            if (type != this.generalType) {
                formData.set('printPaperProfile', this.profileTypeVal[f]);
                formData.set('rfqOrderPrintProductPageDtlId', (this.finalPaperMstID[f] && this.finalPaperMstID[f]!='undefined') ? this.finalPaperMstID[f] : '');
                formData.set('pageType', paperVal[f]);
                formData.set('printType', this.selectedProductPrintType);
                formData.set('customerOrganizationId', this.data.customerOrganizationId);
                formData.set('fileType', this.fileTypeVal[f]);
            } else {
                formData.set('printPaperProfile', 'COATED');
                formData.set('rfqOrderPrintProductPageDtlId', '');
                formData.set('customerOrganizationId', this.data.customerOrganizationId);
                formData.set('pageType', '');
                formData.set('printType', product.generalType);
                formData.set('fileType', this.fileTypeVal[f]);
            }
            var url = ApiEndpointConstant.uploadFileURL;
            this.apiCallingService.callAuthAPIWithMultipart(formData, url).subscribe(result => {
                this.progress[f] = this.progressBarVal(result, f);
                this.fileData = result;
                if (this.fileData.type==4) {
                    if (type != this.generalType && this.newTestFileInput) {
                        this.fileInput.clear();
                        this.newTestFileInput = '';
                    } else if (type == this.generalType && this.newTestGeneralFileInput) {
                        this.generalFileInput.clear();
                        this.newTestGeneralFileInput = '';
                    }
                    this.handleFileRemove(type);
                    this.triggerRefresh(this.parentTableData);
                    this.fileExtraInfo = [];
                    this.paperTypeVal = [];
                    this.profileTypeVal = [];
                    this.trayVal = [];
                }
                if((result.body) && (result.body.resultCode) && (result.body.resultCode != Constants.resultCode.SUCCESS)){
                    this.responseHandler.setErrorPopupMessage(result.body.message);
                }
            }, error => {
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
        }
    }
    onSelect(fileInput: any, product: any, productIndex: any, type: any) {     
        this.newTestFileInput = fileInput;
        this.fileInput = fileInput;
        this.paper_type = [];
        this.fileExtraInfo = [];
        this.paperMstID = [];
        this.coverValue = [];
        this.fileIndex = [];
        this.selectedFilesProductType = Constants.PRODUCT_TYPES['PRINT'];
        this.selectedFilesProductInfo = product;
        this.selectedFilesProductIndex = productIndex;
        this.selectedProductPrintType = product.printType;
        this.getFileTypeDropDownValues(this.selectedFilesProductType, this.selectedProductPrintType);
        var paperProfile = [{
            "unCoatedPrintTray": "F47",
            "uncoatedProfile": "UNCOATED",
            "coatedProfile": "COATED",
            "defaultProfileSeleted": "UNCOATED",
            "coatedPrintTray": "F39"
        }, {
            "unCoatedPrintTray": "F47",
            "uncoatedProfile": "UNCOATED",
            "coatedProfile": "COATED",
            "defaultProfileSeleted": "UNCOATED",
            "coatedPrintTray": "F39"
        }];
        for (var j = 0; j < paperProfile.length; j++) {
            this.coverValue.push({
                'COATED_print_tray': paperProfile[j].coatedPrintTray,
                'COATED_profile': paperProfile[j].coatedProfile,
                'UNCOATED_print_tray': paperProfile[j].unCoatedPrintTray,
                'UNCOATED_profile': paperProfile[j].uncoatedProfile,
                'defaultProfileSeleted': paperProfile[j].defaultProfileSeleted
            });
        }
        if (product && product.pages && product.pages.length > 0) {
            var paperType = product.pages;
            for (var i = 0; i < paperType.length; i++) {
                this.paper_type.push({
                    'val': paperType[i].pageTypeSupplier,
                    'key': paperType[i].pageTypeSupplierStr + ' (' + paperType[i].printPaperNameSupplier + '-' + paperType[i].paperWeightSupplier + ')'
                });
                this.paperMstID.push(paperType[i].pageId);
            }
        }
        var i = 0;
        for (let file of this.newTestFileInput.files) {
            this.fileIndex.push(i);
            this.fileExtraInfo.push({
                'no': i,
                'name': file.name,
                'size': this.formatBytes(file.size)
            });
            if (GeneralFunction.checkForNullUndefinedBlank(this.paperTypeVal[i])) {
                this.paperTypeVal[i] = '';
            }
            if (GeneralFunction.checkForNullUndefinedBlank(this.profileTypeVal[i])) {
                this.profileTypeVal[i] = '';
            }
            if (GeneralFunction.checkForNullUndefinedBlank(this.fileTypeVal[i])) {
                this.fileTypeVal[i] = '';
            }
            i++;
        }
        if (!GeneralFunction.checkForNullUndefinedBlank(this.fileExtraInfo)) {
            this.showExtraFieldForFile = true;
            this.fileXtraInfoCols = [{
                field: 'name',
                header: 'Name',
                colWidth: '20%'
            }, {
                field: 'size',
                header: 'Size',
                colWidth: '10%'
            }];
            this.fileProductId = product.id;
        }
        if (this.newTestFileInput.files.length > 0) {
            this.showPlaceHolder = false;
            this.paperTypeVal[0] = '';
            this.profileTypeVal[0] = '';
            this.fileTypeVal[0] = '';
        }
    }
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    handleFileRemove(type: any = null) {}
    handleClear() {
        console.log("clear");
        this.showPlaceHolder = true;
    }
    downloadFile(file_id: any, file_type: any, fileData: any) {
        this.setLoading(true);
        if (file_type == Constants.ORIGINAL) {
            var url: any = fileData.originalFileDownloadLink;
        } else if (file_type == Constants.PROCESSED) {
            var url: any = fileData.pitstopOutputFileDownloadLink;
        }
        this.apiCallingService.callDownloadFile(url).subscribe(result => {
            fileObj.saveAs(result, fileData.fileName);
            this.setLoading(false);
        }, error => {
            this.setLoading(false);
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }
    submitFileInfo(e: any, productId: any) {
        var paperVal = this.paperTypeVal;
        var profileType = this.profileTypeVal;
        var fileType = this.fileTypeVal;
        var fileIndex = this.fileIndex;
        var showExtraFieldForFile = this.showExtraFieldForFile;
        var fileInput = this.newTestFileInput.currentFiles;
        var paperMstID = this.paperMstID;
        var isValid = true;
        var selectedProductPrintType = this.selectedProductPrintType;

        console.log('this.selectedProductPrintType2121',this.selectedProductPrintType);

        if(this.selectedFilesProductType!==this.generalType){
            $(".p-fileupload-row").each(function(key, elem) {
                if ((fileInput[key].type == Constants.PDF_FILE_TYPE) && $.inArray(key, fileIndex) != -1) {
                    if (!GeneralFunction.checkForNullUndefinedBlank(paperVal[key]) && !GeneralFunction.checkForNullUndefinedBlank(profileType[key]) && !GeneralFunction.checkForNullUndefinedBlank(fileType[key])) {
                        console.log('enter0');
                        $(this).find('.paper_val').remove();
                        $(this).find('.profile_val').remove();

                        if(!GeneralFunction.checkForNullUndefinedBlank(selectedProductPrintType) && (selectedProductPrintType!==Constants.PRINT_TYPES['GLUED_COUPON_PRINTING'] && selectedProductPrintType!==Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING'])){
                            $(this).find('div:nth-child(3)').after('<div class="paper_val">' + paperVal[key] + '</div><div class="profile_val">' + profileType[key] + '</div>');    
                        }else{
                            $(this).find('div:nth-child(3)').after('<div class="paper_val"></div><div class="profile_val"></div>');
                        }
                        
                        showExtraFieldForFile = false;
                    } else{
                        if(GeneralFunction.checkForNullUndefinedBlank(paperVal[key])) {
                            console.log('enter1');
                            $('#paperType_' + key).focus();
                            $('#error_paperType_' + key).html('Please Select Paper Type');
                            showExtraFieldForFile = true;
                            isValid = false;
                        } 
                        if (GeneralFunction.checkForNullUndefinedBlank(profileType[key])) {
                            console.log('enter2');
                            $('#error_profileType_' + key).html('Please Select Profile Type');
                            $('#profileType' + key).focus();
                            showExtraFieldForFile = true;
                            isValid = false;
                        } 
                        if (GeneralFunction.checkForNullUndefinedBlank(fileType[key])) {
                            console.log('enter3');
                            $('#error_fileType_' + key).html('Please Select File Type');
                            $('#fileType' + key).focus();
                            showExtraFieldForFile = true;
                            isValid = false;
                        }
                    }
                } else {
                    console.log('enter4');
                    $(this).find('div:nth-child(3)').after('<div></div><div></div>');
                    showExtraFieldForFile = false;
                }
            });
        }else{
            showExtraFieldForFile = false;
        }
        
        this.showExtraFieldForFile = showExtraFieldForFile;
        if (!showExtraFieldForFile) {
            if(this.selectedFilesProductType!==this.generalType){
                this.onFileUpload(this.newTestFileInput, this.selectedFilesProductInfo.id, this.selectedFilesProductInfo, '', Constants.PRODUCT_TYPES['PRINT'], this.selectedFilesProductIndex, paperVal);
            }else{
                this.onFileUpload(this.newTestFileInput, this.selectedFilesProductInfo.id, this.selectedFilesProductInfo, '', this.generalType, this.selectedFilesProductIndex);
            }
        }
    }
    getPaperType(val: any, index: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(val)) {
            this.seletedPaperType = val;
            this.paperTypeVal[index] = val;
            var selectedIndex = ($('#paperType_' + index).prop('selectedIndex') - 1);
            $('#profileType_' + index).val(this.coverValue[selectedIndex].defaultProfileSeleted);
            this.trayVal[index] = this.coverValue[selectedIndex][this.coverValue[selectedIndex].defaultProfileSeleted + '_print_tray'];
            this.profileTypeVal[index] = this.coverValue[selectedIndex].defaultProfileSeleted;
            this.finalPaperMstID[index] = this.paperMstID[selectedIndex];
            $('#error_paperType_' + index).html('');
            $('#error_profileType_' + index).html('');
        } else {
            $('#paperType_' + index).focus();
            $('#error_paperType_' + index).html('Please Select Paper Type');
            this.trayVal[index] = '';
        }
    }
    getFileType(val: any, index: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(val)) {
            this.seletedFileType = val;
            this.fileTypeVal[index] = val;
            var selectedIndex = ($('#fileType_' + index).prop('selectedIndex') - 1);
            // $('#profileType_' + index).val(this.coverValue[selectedIndex].defaultProfileSeleted);
            // this.trayVal[index] = this.coverValue[selectedIndex][this.coverValue[selectedIndex].defaultProfileSeleted + '_print_tray'];
            // this.profileTypeVal[index] = this.coverValue[selectedIndex].defaultProfileSeleted;
            // this.finalPaperMstID[index] = this.paperMstID[selectedIndex];
            $('#error_fileType_' + index).html('');
            // $('#error_profileType_' + index).html('');
        } else {
            $('#fileType_' + index).focus();
            $('#error_fileType_' + index).html('Please Select File Type');
            // this.trayVal[index] = '';
        }
    }
    getProfileType(val: any, index: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(val)) {
            this.profileTypeVal[index] = val;
            var selectedIndex = ($('#paperType_' + index).prop('selectedIndex') - 1);
            if (!GeneralFunction.checkForNullUndefinedBlank(this.paperTypeVal[index])) {
                this.trayVal[index] = this.coverValue[selectedIndex][val + '_print_tray'];
            }
            $('#error_profileType_' + index).html('');
        } else {
            $('#error_profileType_' + index).html('Please Select Profile Type');
            $('#profileType' + index).focus();
            this.trayVal[index] = '';
        }
    }
    getFileList(fileArray: any) {
        let fileList = [];
        if (fileArray[0].ids != null) {
            for (var pf = 0; pf < fileArray[0].ids.length; pf++) {
                var showFile = fileArray[0].fileStatus[pf] == Constants.FILE_VIEW_STATUS['APPROVED'] ? true : false;
                var pistop_status;


                if (fileArray[0].fileStatus[pf] === Constants.FILE_VIEW_STATUS['PITSTOP_PROCESSED'] || fileArray[0].fileStatus[pf] === Constants.FILE_VIEW_STATUS['APPROVED'] || fileArray[0].fileStatus[pf] === Constants.FILE_VIEW_STATUS['DISCARD'] || fileArray[0].fileStatus[pf]==Constants.FILE_VIEW_STATUS['PENDING_APPROVED']) {
                    pistop_status = true
                } else {
                    pistop_status = false;
                }
                var description = GeneralFunction.makeFileDescription(fileArray[0], pf);
                if (fileArray[0].fileExts[pf] == "pdf") {
                    var filePaperDtls = '<b>Paper Type:</b> ' + fileArray[0].fileUploadedFor[pf] + '<br/>';
                    if (this.userData.role != Constants.ORG_TYPES['SUPPLIER']) {
                        filePaperDtls += '<b>Profile Type: </b>' + fileArray[0].fileProfile[pf] + '<br/>';
                    }
                    if (this.userData.role == Constants.USER_TYPE['PIPELINE_USER']) {
                        filePaperDtls += '<b>Tray:</b> ' + fileArray[0].filePrintTray[pf];
                    }
                } else {
                    var filePaperDtls = '';
                }
                fileList.push({
                    "fileName": fileArray[0].fileNames[pf],
                    "no": pf + 1,
                    "id": fileArray[0].ids[pf],
                    "description": description,
                    "fileDtls": [],
                    "pitstopStatus": pistop_status,
                    "showFile": showFile,
                    "fileStatusSupplier": fileArray[0].fileStatusSupplier[pf],
                    "fileStatus": fileArray[0].fileHidden[pf],
                    "fileDiscardedStatus": fileArray[0].fileStatus[pf],
                    "filePaperDtls": filePaperDtls,
                    "fileSizeDiscrepencies": fileArray[0].fileSizeDiscrepencies[pf]
                });
            }
        }
        return fileList;
    }
    progressBarVal(res: any, f: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(res) && (res.type) && (!GeneralFunction.checkForNullUndefinedBlank(res.loaded))) {
            var loaded: any = res.loaded;
            var total: any = res.total;
            console.log(loaded + '<=' + total);
            if (loaded <= total) {
                let percentDone = Math.round(100 * loaded / total);
                if (percentDone < 100) {
                    $('#progress_' + f).width(percentDone + '%');
                } else if (percentDone == 100) {
                    $('#progress_' + f).width(percentDone + '%');
                    return percentDone;
                }
            }
        }
    }
    getFileListByOrderRFQId(rfqOrderId: any) {
        this.apiCallingService.callAuthAPI('', Constants.requestType.GET, ApiEndpointConstant.getFileListByOrderRFQId(rfqOrderId)).subscribe(result => {
            if (result.resultCode == Constants.resultCode.SUCCESS) {
                this.fileservice.prepareFileListByFilesArray(result?.object);
            } else {
                this.responseHandler.setErrorPopupMessage(result.message);
            }
            this.setLoading(false);
        }, error => {
            this.setLoading(false);
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }

    checkFilePreviewStatus(type:any , status:any){
        if(type=='PDF'){
            if(status==Constants.FILE_VIEW_STATUS['PITSTOP_PROCESSED'] || status === Constants.FILE_VIEW_STATUS['APPROVED'] || status === Constants.FILE_VIEW_STATUS['DISCARD']){
                return 1;
            }else if(status===Constants.FILE_VIEW_STATUS['PENDING_APPROVED'] || status===Constants.FILE_VIEW_STATUS['PENDING_APPROVAL_CUSTOMER_END_CUSTOMER']){
                return 4;
            }else {
                return 2;
            }
        }else{
            return 3;
        }
        
    }

    displayPreview(event, id) {
        var hasPreviewImage = this.showPreviewOrNot(event.split('.').pop());
        if (hasPreviewImage) {
            window.open(Constants.DEV_LINK + "file/preview-file?show_box=true&page_no=1&fileId="+id, "_blank");
            // window.open(Constants.DEV_LINK + "preview-file?show_box=true&page_no=" + event.image.page_no +"&type=" + event.image.type + "&file_id=" + event.image.id, "_blank");
        }
    }

    showPreviewOrNot(imageExt: any) {
        this.currExt = imageExt;
        if (Constants.FILE_EXT.includes(this.currExt)) {
            return true;
        }
        return false;
    }
    termsAndConditionPopup(){
        this.showTermsAndConditionPopup = true;
    }

    zoomImage(){
        console.log('Zoom');
        $(".lb-outerContainer").click(function(){    
            $(".lb-outerContainer").animate({height: "750px",width: "750px"});
        });
    }
    open(index: number, image:any): void {
        this._lightbox.open(this._album, index, { wrapAround: true, showZoom: true});
    }

    close(): void {
        this._lightbox.close();
    }

    getFileTypeDropDownValues(productType:any, printType?:any){
        if(this.userData.type==Constants.USER_TYPE['PIPELINE_USER']){
            if(productType===Constants.PRODUCT_TYPES['PRINT']){
                if(printType===Constants.PRINT_TYPES['GLUED_COUPON_PRINTING'] || printType===Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING']){
                    this.translate.get('FIELDS').subscribe((text: any) => {
                        this.uploadedFileType = [{
                            value: Constants.uploadedFileType.MATERIAL_FILE,
                            label: text.MATERIAL_FILE,
                        }, {
                            value: Constants.uploadedFileType.COUPON_CODE_FILE,
                            label: text.COUPON_CODE_FILE,
                        }, {
                            value: Constants.uploadedFileType.PRINT_PROOF_FILE,
                            label: text.PRINT_PROOF_FILE,
                        },{
                            value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                            label: text.DISTRIBUTION_FILE,
                        }, {
                            value: Constants.uploadedFileType.OTHER_FILE,
                            label: text.OTHER_FILE,
                        }];
                    });
                }else{
                    this.translate.get('FIELDS').subscribe((text: any) => {
                        this.uploadedFileType = [{
                            value: Constants.uploadedFileType.MATERIAL_FILE,
                            label: text.MATERIAL_FILE,
                        }, {
                            value: Constants.uploadedFileType.PRINT_PROOF_FILE,
                            label: text.PRINT_PROOF_FILE,
                        },{
                            value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                            label: text.DISTRIBUTION_FILE,
                        }, {
                            value: Constants.uploadedFileType.OTHER_FILE,
                            label: text.OTHER_FILE,
                        }];
                    });
                }
            }else{
                this.translate.get('FIELDS').subscribe((text: any) => {
                    this.uploadedFileType = [{
                        value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                        label: text.DISTRIBUTION_FILE,
                    }, {
                        value: Constants.uploadedFileType.OTHER_FILE,
                        label: text.OTHER_FILE,
                    }];
                });
            } 
        }else if(this.userData.type==Constants.USER_TYPE['SUPPLIER_USER']){
            if(productType===Constants.PRODUCT_TYPES['PRINT']){
                this.translate.get('FIELDS').subscribe((text: any) => {
                    this.uploadedFileType = [{
                        value: Constants.uploadedFileType.PRINT_PROOF_FILE,
                        label: text.PRINT_PROOF_FILE,
                    },{
                        value: Constants.uploadedFileType.OTHER_FILE,
                        label: text.OTHER_FILE,
                    }];
                });
            }else{
                this.translate.get('FIELDS').subscribe((text: any) => {
                    this.uploadedFileType = [{
                        value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                        label: text.DISTRIBUTION_FILE,
                    }, {
                        value: Constants.uploadedFileType.OTHER_FILE,
                        label: text.OTHER_FILE,
                    }];
                });
            }
        }else if(this.userData.type==Constants.USER_TYPE['CUSTOMER_USER']){
            if(productType===Constants.PRODUCT_TYPES['PRINT']){
                if(printType===Constants.PRINT_TYPES['GLUED_COUPON_PRINTING'] || printType===Constants.PRINT_TYPES['LOOSE_COUPON_PRINTING']){
                    this.translate.get('FIELDS').subscribe((text: any) => {
                        this.uploadedFileType = [{
                            value: Constants.uploadedFileType.MATERIAL_FILE,
                            label: text.MATERIAL_FILE,
                        }, {
                            value: Constants.uploadedFileType.COUPON_CODE_FILE,
                            label: text.COUPON_CODE_FILE,
                        },{
                            value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                            label: text.DISTRIBUTION_FILE,
                        }, {
                            value: Constants.uploadedFileType.OTHER_FILE,
                            label: text.OTHER_FILE,
                        }];
                    });
                }else{
                    this.translate.get('FIELDS').subscribe((text: any) => {
                        this.uploadedFileType = [{
                            value: Constants.uploadedFileType.MATERIAL_FILE,
                            label: text.MATERIAL_FILE,
                        }, {
                            value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                            label: text.DISTRIBUTION_FILE,
                        }, {
                            value: Constants.uploadedFileType.OTHER_FILE,
                            label: text.OTHER_FILE,
                        }];
                    });
                }
            }else{
                this.translate.get('FIELDS').subscribe((text: any) => {
                    this.uploadedFileType = [{
                        value: Constants.uploadedFileType.DISTRIBUTION_FILE,
                        label: text.DISTRIBUTION_FILE,
                    }, {
                        value: Constants.uploadedFileType.OTHER_FILE,
                        label: text.OTHER_FILE,
                    }];
                });
            }
        }
    }
}
