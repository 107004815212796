import { Component, OnInit, ChangeDetectorRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ValidationManager} from "ng2-validation-manager";
import {Validators,FormControl,FormGroup,FormBuilder,FormArray} from '@angular/forms';
import {HttpClient, HttpRequest, HttpEvent,HttpHeaders} from '@angular/common/http';
import 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { Constants} from '../../constant/constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Urls } from 'src/app/constant/urls';
import {ConfirmationService} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { GeneralFunction } from '../../common/general-function';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { ValidationFields, ValidationRules } from '../../common/validationRules-constants';
import { MessageService } from 'primeng/api';
import { Messages} from '../../common/messages';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.css']
})
export class AddContactPersonComponent implements OnInit {
   
  @Input() addFromPopup: any;
  @Input() selectedCustomer:any;
  @Output() loadingOrg = new EventEmitter<any>();
  userData:any;
  contactPersonForm:any;
  userRoleOpt:any[]=[];
  isAdd:boolean=true;
  loading:boolean=false;
  back = GeneralFunction.goBack;
  sub:any;
  contactPersonId:any;
  isInvalid:boolean=false;
  validationErrors:any;
  countryOpt:any;
  selectedCountry:any;
  selectedCity:any;
  contactDtls:any;
  phoneCodeOpt:any;
  contactNumberNotEmptyErrorMsg:any;
  langOpt:any;
  selectedRole:any='';
  role_opt:any;
  filterOrgs:any;
  selectedLang:any=1;
  user_type:boolean=false;
  contactArray:any;
  contact_dtls:any;
  data:any;
  contactIsBlank:any;
  selectedJobTitle:any;
  organizationOpt:any;
  items:any;
  selectedOrg:any;
  length: any;
  formData:any;
  oldPassword:boolean=true;
  oldtype:any='password';
  addContactDtlsForm:any;
  removeIndex: any;
  isContactFormValid = true;
  contactForm: FormGroup;
  isEditProfile: boolean = false;
  isEmailDisabled: boolean = false;
  emailValue: any;
  contactFormValid: any = [];
  isSupplierOrCustomerUserAdmin:boolean=false;
  contactCode:any=205
  constructor(private formBuilder: FormBuilder, public router: Router, private translate: TranslateService, private http: HttpClient,private titleService: Title, private route: ActivatedRoute, private messageService: MessageService, private cdRef : ChangeDetectorRef, private responseHandler: ResponseHandlerService,private apiCallingService: ApiCallingService, private authenticationService: AuthService) { 
    
    this.userData = localStorage.getItem(Constants.SESSION_NAME);
    var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
    this.userData = JSON.parse(sessionData);
    if((this.userData['type']==Constants.USER_TYPE['SUPPLIER_USER'] || this.userData['type']==Constants.USER_TYPE['CUSTOMER_USER']) && this.userData['role'] == Constants.SUPPLIER_ROLES['ADMIN']){
        this.isSupplierOrCustomerUserAdmin = true;
        this.selectedOrg = parseInt(this.userData['organizationId']);
    }


    if (this.userData != null) {
        translate.setDefaultLang(this.userData.i18nCode);
    } else {
        translate.setDefaultLang(Constants.DEFAULT_LANG);
    }
    this.isInvalid = false;
    this.contactForm = this.formBuilder.group({
        'contact_maps': this.formBuilder.array([
            this.createContact(),
        ]),
    });
    if (this.router.url === Constants.URLS['EDIT_SUPPLIER_PROFILE']) {
        this.isEditProfile = true;
        this.contactPersonId = this.userData.userLoginId;
        this.isEmailDisabled = true;
        this.isAdd = false;
        setTimeout(() => {
            this.titleService.setTitle(this.translate.instant("MENU.SUB_MNU_EDIT_PROFILE") + Constants.PIPELINE_TITLE);
        }, Constants.validationTimeout);
    }else if (this.router.url === Constants.URLS['EDIT_CUSTOMER_PROFILE']) {
        this.isEditProfile = true;
        this.contactPersonId = this.userData.userLoginId;
        this.isEmailDisabled = true;
        this.isAdd = false;
        setTimeout(() => {
            this.titleService.setTitle(this.translate.instant("MENU.SUB_MNU_EDIT_PROFILE") + Constants.PIPELINE_TITLE);
        }, Constants.validationTimeout);
    } else {
        this.isAdd = true;
        this.isEditProfile = false;
        this.isEmailDisabled = false;
    }
    this.sub = this.route.params.subscribe(params => {
        if (params['contactPersonId'] && !this.isEditProfile) {
            this.isAdd = false;
            this.isEmailDisabled = false;
            this.contactPersonId = params['contactPersonId'];
            var apiEndPoint:any = ApiEndpointConstant.contactPerson+this.contactPersonId+'/edit';
            this.getAddUpdateData(apiEndPoint);
            setTimeout(() => {
                  this.titleService.setTitle(this.translate.instant("CONT_PER.EDIT_CONT_PER") + Constants.PIPELINE_TITLE);
            }, Constants.validationTimeout);
        }else if (!this.isEditProfile){
            var apiEndPoint:any = ApiEndpointConstant.addContactPerson;
            this.getAddUpdateData(apiEndPoint);
            setTimeout(() => {
                  this.titleService.setTitle(this.translate.instant("CONT_PER.ADD_CONT_PER") + Constants.PIPELINE_TITLE);
            }, Constants.validationTimeout);
        }
    });
    if(this.contactPersonId || this.isEditProfile){
        this.contactPersonForm = new ValidationManager(ValidationRules.editContactPersonRules);
    }else{
        this.contactPersonForm = new ValidationManager(ValidationRules.contactPersonRules);
    }
    this.getInitData();
    this.translateValidationErrorMessage(); 
  }

    setLoading(val: any): void {
        console.log('val',val);
        this.loadingOrg.emit(val);
    }

    getSelectedOrgAddress(orgId:any){
        if(this.organizationOpt && this.organizationOpt.length > 0){
            for(var i=0;i < this.organizationOpt.length ; i++){
                if(this.organizationOpt[i].id==orgId){
                    this.contactPersonForm.setValue({
                        addressLine1: !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].addressLine1) ? this.organizationOpt[i].addressLine1 : '',
                        addressLine2: !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].addressLine2) ? this.organizationOpt[i].addressLine2 : '',
                        addressLine3: !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].addressLine3) ? this.organizationOpt[i].addressLine3 : '',
                        zipCode: !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].zipCode) ? this.organizationOpt[i].zipCode : '',
                        city: !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].cityName) ? this.organizationOpt[i].cityName : '',
                    });
                    this.selectedLang = !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].defaultLocaleId) ? this.organizationOpt[i].defaultLocaleId : 1;
                    this.selectedCountry = !GeneralFunction.checkForNullUndefinedBlank(this.organizationOpt[i].countryId) ? this.organizationOpt[i].countryId : '';
                }
            }
        }
    }


    translateValidationErrorMessage() {
        setTimeout(() => {
            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.email, Constants.validationType.email, this.translate.instant("VALIDATION_MESSAGE.EMAIL", {field: this.translate.instant("FIELDS.EMAIL")}));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.email, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.EMAIL")}));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.email, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.EMAIL"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.contactPersonForm.setErrorMessage(ValidationFields.contactPersonFields.organizationId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ORGANIZATION")}));


                        
            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.firstName, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.FIRST_NAME")}));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.firstName, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.FIRST_NAME"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.lastName, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.LAST_NAME"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));


            // this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine1, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ADDRESS_LINE_1")}));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine1, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_1"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine2, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_2"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.addressLine3, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ADDRESS_LINE_3"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.zipCode, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.ZIP_CODE"),
                maxlength: Constants.fieldTypes.maxStringLength50
            }));

            // this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.zipCode, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ZIP_CODE")}));

            // this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.city, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
            //     field: this.translate.instant("FIELDS.CITY")}));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.city, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.CITY"),
                maxlength: Constants.fieldTypes.maxStringLength250
            }));

            // this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.countryId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.COUNTRY")}));

            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.localeId, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
                field: this.translate.instant("FIELDS.LANGUAGE")
                
            }));

            if(GeneralFunction.checkForNullUndefinedBlank(this.contactPersonId)){
                this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.password, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {
                field: this.translate.instant("FIELDS.PASSWORD")
                
                }));
                this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.password, Constants.validationType.rangeLength, this.translate.instant("VALIDATION_MESSAGE.RANGE", {field: this.translate.instant("FIELDS.PASSWORD"), startRange: Constants.fieldTypes.startRange, endRange: Constants.fieldTypes.endRange}));
            }
            
            this.contactPersonForm.setErrorMessage(ValidationFields.pipelineUserfields.role, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.ROLE")}));

        }, Constants.validationTimeout);
    }

    ngOnInit(): void {

      this.translate.get('FIELDS').subscribe((text: any) => {
            this.userRoleOpt = [
                {label: text.SELECT, value: ''},
                {label: text.ADMIN, value: Constants.SUPPLIER_ROLES['ADMIN']},
                {label: text.USER, value: Constants.SUPPLIER_ROLES['USER']}
            ];
        });
    }
    onEmailChange(value: any){
        if(value){
            this.loading = true;
            if(this.contactPersonId){
                var endPoint:any = ApiEndpointConstant.changeUserStatus+this.contactPersonId+'/chkEmail/'+value;
            }else{
                var endPoint:any = ApiEndpointConstant.checkUniqueEmail+value;
            }
            this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, endPoint).subscribe(result => {
                this.data = result;
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                }else{
                    this.responseHandler.setErrorPopupMessage(this.data.message);
                    this.emailValue = '';
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
            });
        }
    }

    ngAfterViewInit(){
        if(this.selectedCustomer && this.selectedCustomer!='' && this.selectedCustomer!='undefined'){
            this.selectedOrg = this.selectedCustomer;
        }
    }

   
    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }


    createContact(): FormGroup {
        return this.formBuilder.group({
            countryId: [205],
            contactNumber: ['']
        });
        
    }

    createEmptyItem(): FormGroup {
        this.contactFormValid.push(true);
        return this.formBuilder.group({
            countryId: [205, Validators.required],
            contactNumber: ['', Validators.required]
        });
    }

    get contact_maps(): FormArray {
        return this.contactForm.get('contact_maps') as FormArray;
    }

    addContactInfo(index:any){
        var formLength:any = this.contact_maps.length-1;
        var formArrayVal:any = this.contactForm.get('contact_maps') as FormArray;
        var contactDtlsData:any = this.contactForm?.value?.contact_maps;
        if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
            $('#contact_error_'+index).hide();
            this.contactNumberNotEmptyErrorMsg ='';
            this.contact_maps.insert(this.contact_maps.length,this.createEmptyItem());
            this.contactFormValid[index] = true;
        }else{
            if(GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                $('#contact_error_'+index).show();
                this.isContactFormValid = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.COUNTRY"));
            }else if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                $('#contact_error_'+index).show();
                this.isContactFormValid = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
            }else{
                $('#contact_error_'+index).show();
                this.isContactFormValid = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
            }
            
        }    
    }

    removeContactInfo(index: any){
        this.removeIndex = index;
        this.contact_maps.removeAt(this.removeIndex);
        this.contactFormValid.splice(index, 1);
    }

    getInitData() {
        this.contactPersonForm.reset();
        this.isAdd = true;
        this.loading = true;
        if (!this.contactPersonId){
            this.isEmailDisabled = false
            var apiEndPoint:any = ApiEndpointConstant.addContactPerson;
            this.getAddUpdateData(apiEndPoint);
        } else if (this.contactPersonId) {
            this.isAdd = false;
            var apiEndPoint:any = ApiEndpointConstant.contactPerson+this.contactPersonId+'/edit';
            this.getAddUpdateData(apiEndPoint);
        }
    }

    getAddUpdateData(apiEndPoint:any){
        this.loading = true;        
        this.contactArray = [];
        this.contactIsBlank = false;
        this.contact_dtls = [];
        this.selectedJobTitle=[];
        this.contact_dtls.push({
            isShow: true
        });
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, apiEndPoint)
        .subscribe(result => {
            this.data = result;
            if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                this.countryOpt = [];
                this.phoneCodeOpt = [];
                this.langOpt = [];
                this.countryOpt = this.data.countries;
                this.langOpt = this.data.locales;
                this.phoneCodeOpt = GeneralFunction.getPhoneCode(this.data.countries);
                this.organizationOpt = this.data.organizations;
                if (this.contactPersonId) {
                    this.contactPersonForm.setValue({
                        firstName: this.data.firstName,
                        lastName: this.data.lastName,
                        userName: this.data.userName,
                        email: this.data.email,
                        addressLine1: this.data.addressLine1,
                        addressLine2: this.data.addressLine2,
                        addressLine3: this.data.addressLine3,
                        zipCode: this.data.zipCode,
                        city: this.data.cityName,
                        role:this.data.role
                    });
                    this.selectedOrg = this.data.organizationId;
                    this.selectedLang = (this.data.localeId) ? this.data.localeId : 1;
                    this.selectedCountry = this.data.countryId?this.data.countryId:205;
                    this.contact_dtls = [];
                    var contactDtls:any[] = this.data.contactDtls;
                    
                    if(contactDtls && contactDtls?.length > 0) {
                        this.setValue(contactDtls);
                    }
                }
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }

    setValue(items: any) {
        const formArray = new FormArray([]);
        for (let x of items) {
          formArray.push(this.formBuilder.group({
            countryId: x.countryId?x.countryId:205,
            contactNumber: x.contactNumber
          }));
        }
        this.contactForm.setControl('contact_maps', formArray);
      }

    onSubmit(){
        var formLength:any = this.contact_maps.length-1;
        var formArrayVal:any = this.contactForm.get('contact_maps') as FormArray;
        var contactDtlsData:any = this.contactForm?.value?.contact_maps;
        var contactFormValid: any;
        console.log('Test', contactDtlsData[formLength]);
        contactDtlsData.forEach((contact: any, index: any) => {
            if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                $('#contact_error_'+index).hide();
                this.contactNumberNotEmptyErrorMsg ='';
                this.contactFormValid[index] = true;
            }else{
                if(GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && 
                    !GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                    $('#contact_error_'+index).show();
                    this.contactFormValid[index] = false;
                    this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.COUNTRY_CODE"));
                }else if(!GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].countryId) && GeneralFunction.checkForNullUndefinedBlank(contactDtlsData[formLength].contactNumber)){
                    $('#contact_error_'+index).show();
                    this.contactFormValid[index] = false;
                    this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
                }else{
                    // this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
                     $('#contact_error_'+index).hide();
                    this.contactNumberNotEmptyErrorMsg ='';
                    this.contactFormValid[index] = true;
                }
            }
        });
        let formSubmit: any;
        if (this.contactFormValid.includes(false)) {
            formSubmit = false;
        } else {
            formSubmit = true;
        }
        if (this.contactPersonForm.isValid() && this.contactForm.valid && formSubmit) {
            this.formData = this.contactPersonForm.getData();
            this.loading = true;
            var contactDtlsData:any = this.contactForm?.value?.contact_maps;
            if(contactDtlsData && contactDtlsData[0] && contactDtlsData[0]?.countryId == '' && contactDtlsData[0]?.contactNumber == ''){
                this.formData.contactDtls = [];    
            }else{
                this.formData.contactDtls = contactDtlsData;
            }
            if (GeneralFunction.checkForNullUndefinedBlank(this.contactPersonId)) {
                //Add contact form
                this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.POST, ApiEndpointConstant.addContactPerson)
                .subscribe(result => {
                    this.data = result;
                    if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                        if(!this.addFromPopup){
                            localStorage.setItem('redirectMsg', this.data.message);
                            const url = Constants.URLS['CONTACT_PER_MANAGEMENT'];
                            this.router.navigateByUrl(url);    
                        }else{
                            this.setLoading(result.object);
                        }
                    }else{
                        this.responseHandler.setErrorPopupMessage(this.data.message);
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.isInvalid = true;
                    this.validationErrors = [];
                    this.validationErrors = GeneralFunction.getValidationError(error);
                });
                
            } else { //Update contact form
                
                this.apiCallingService.callAuthAPI(this.formData, Constants.requestType.PUT, ApiEndpointConstant.contactPerson+this.contactPersonId+'/edit')
                .subscribe(result => {
                    this.data = result;
                    if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                        if (!this.isEditProfile) {
                            localStorage.setItem('redirectMsg', this.data.message);
                            const url = Constants.URLS['CONTACT_PER_MANAGEMENT'];
                            this.router.navigateByUrl(url);
                        } else {
                            this.setLocalStorage(this.formData);
                            localStorage.setItem('redirectLanguageMsg', 'EDIT_PROFILE.SUCCESS');
                            const url = Constants.URLS['SUPPLIER_ORDERS'];
                            this.router.navigateByUrl(url);
                            this.authenticationService.setHeaderReload(true);
                        }
                    }else{
                        this.responseHandler.setErrorPopupMessage(result.message);
                    }
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.isInvalid = true;
                    this.validationErrors = [];
                    this.validationErrors = GeneralFunction.getValidationError(error);
                });
                
            }
        }else {
            var contactDtlsData:any = this.contactForm?.value?.contact_maps;
            if(contactDtlsData.length>1){
                this.isContactFormValid = false;
                this.contactNumberNotEmptyErrorMsg = this.translate.instant("VALIDATION_MESSAGE.REQUIRED").replace('{{field}}', this.translate.instant("FIELDS.CONTACT"));
            }
            
        }
    }
    setLocalStorage(formData: any) {
        this.userData.fname = formData.firstName;
        this.userData.lname = formData.lastName;
        let localeId: any = formData.localeId;
        this.userData.localeId = localeId
        let localeValue: any = Constants.language.filter(function (locale: any) {
            return locale.id === parseInt(localeId);
        });
        if (localeValue.length > 0) {
            this.userData.i18nCode = localeValue[0].i18nCode;
            this.userData.localeUtf8Code = localeValue[0].localeUtf8Code;
            localStorage.setItem(Constants.SESSION_NAME, JSON.stringify(this.userData));
            localStorage.setItem(Constants.acceptLanguage, this.userData.localeUtf8Code);
            localStorage.setItem(Constants.sessionLocale, this.userData.i18nCode);
        }
    }
    resetForm(){
        if(!this.isSupplierOrCustomerUserAdmin){
            this.contactPersonForm.reset();    
        }else{
            this.contactPersonForm.setValue({
                firstName:'',
                lastName:'',
                role:'',
                email:'',
                userName:'',
                password:'',
                localeId:'',
                addressLine1:'',
                addressLine2:'',
                addressLine3:'',
                zipCode:'',
                countryId:'',
                city:'',
            });
        }
    }

    getSelectedOrgValue(val:any){

    }

    togglePassword(status:any) {
        if (status) {
            this.oldPassword = false;
            this.oldtype = Constants.text;
        } else {
            this.oldPassword = true;
            this.oldtype = Constants.password;
        }
    }
}
