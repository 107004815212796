import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'pipeline-web';
  isShow:boolean = false;
  currentRoute:any;
  userDetailsSessionData:any;
  notShowHeaderLinks: any[] = [
    '/',
    '/login',
    '/forgot-password',
    '/invalid-token',
    '/expired-token',
    '/preview-file',
    '/customer-proposal',
    '/customer-invoice',
    '/supplier-confirmation',
    'cookie-policies'
  ]

  constructor(private router: Router, private activatedRoute: ActivatedRoute){
    localStorage.setItem('isShow', this.isShow.toString());
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event:any) => {
      this.currentRoute = event['url'];
      if(!this.notShowHeaderLinks.includes(this.currentRoute)){
        if (
          this.currentRoute === '/' ||
          this.currentRoute.includes('/reset-password') ||
          this.currentRoute.includes('/login') ||
          this.currentRoute.includes('/forgot-password') ||
          this.currentRoute.includes('/invalid-token') ||
          this.currentRoute.includes('/expired-token') ||
          this.currentRoute.includes('/preview-file') ||
          this.currentRoute.includes('/hook') ||
          this.currentRoute.includes('/cloudproof') ||
          this.currentRoute.includes('/customer-proposal') ||
          this.currentRoute.includes('/customer-invoice') ||
          this.currentRoute.includes('/supplier-confirmation') ||
          this.currentRoute.includes('/clearon/customer/order')
        ) {
          this.isShow = false;
          localStorage.setItem('isShow', this.isShow.toString());
        } else {
          this.isShow = true;
          localStorage.setItem('isShow', this.isShow.toString());
        }
      } else {
        this.isShow = false;
        localStorage.setItem('isShow', this.isShow.toString());
      }
    });
  }
}


