import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ValidationManager } from 'ng2-validation-manager';
import { ConfirmationService } from 'primeng/api';
import { GeneralFunction } from 'src/app/common/general-function';
import { ValidationRules } from 'src/app/common/validationRules-constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Constants } from 'src/app/constant/constants';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { FilehandlerService } from 'src/app/service/filehandler.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import * as fileObj from 'file-saver';

@Component({
  selector: 'app-hook',
  templateUrl: './hook.component.html',
  styleUrls: ['./hook.component.css']
})
export class HookComponent implements OnInit {
  showFileSharingDialog:boolean=true;
    loading: boolean;
    shareType:any;
    token:any;
    fileInfo:any;

    fileName:any;
    fileSharedByUserName:any;
    fileSharedTime:any;
    fileSize:any;
    fileId:any;
    msg: any;
    validationErrors: any;
    isInvalid: boolean;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    fileExtensionThumbailPath:any;
    downloadText:any;
    expiryTokenFlag:boolean=false;
    invalidTokenFlag:boolean=false;
    successFlag:boolean=false;
    fileExtensionImgLink:any;

    constructor( public router: Router, 
                 private route: ActivatedRoute,
                 private httpRes: ResponseHandlerService,
                 private apiCallingService: ApiCallingService,
                 private translate: TranslateService,
                 private responseHandler: ResponseHandlerService,
    ){
        this.route.queryParams.subscribe(params => {
            this.shareType = params['share_type'];
            this.token = params['token'];
        }); 
        console.log('shareType', this.shareType);
        console.log('token', this.token);
    }

    ngOnInit() {
        this.getFileInfo();
    }

    getFileInfo() {
        var url:any = ApiEndpointConstant.validateFileShareToken(this.token);
        this.loading = true;
        this.apiCallingService.callAuthAPI('', Constants.requestType.GET, url)
          .subscribe(result => {
              console.log('result', result);
            this.loading = false;
            this.fileInfo = result;
            if (this.fileInfo.resultCode == Constants.resultCode.SUCCESS) {
                    this.successFlag = true;
                    this.expiryTokenFlag = false;
                    this.invalidTokenFlag = false;
                    this.fileName = this.fileInfo.fileName;
                    this.fileSharedByUserName = this.fileInfo.fileSharedByUserName;
                    this.fileSharedTime = this.fileInfo.fileSharedOn;
                    this.fileSize = this.fileInfo.fileSizeStr;
                    this.fileExtensionImgLink = this.fileInfo.fileExtensionImgLink;
                    // this.fileId = this.fileInfo.fileId;
                    if(this.shareType==Constants.FILE_SHARE_TYPE['ORIGINAL']){
                        this.downloadText = 'Download File';
                        // this.translate.instant('COMMON.DOWNLOAD_FILE')

                    }else{
                        this.downloadText = 'Download Print File';
                        // this.translate.instant('COMMON.DOWNLOAD_PRINT_FILE')

                    }
                    
                    this.fileExtensionThumbailPath = this.fileInfo.fileDownloadLink;
            } else if (this.fileInfo.resultCode == Constants.resultCode.EXPIRED_TOKEN) {
                console.log('test1', this.fileInfo.expiryTokenFlag);
                this.expiryTokenFlag = true;
                this.successFlag = false;
                this.invalidTokenFlag = false;
            } else if (this.fileInfo.resultCode == Constants.resultCode.INVALID_TOKEN){
                console.log('test2', this.fileInfo.invalidTokenFlag);
                this.expiryTokenFlag = false;
                this.successFlag = false;
                this.invalidTokenFlag = true;
            }
        }, error => {
          this.loading =false;
          this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }

    downloadFile(file_id, file_name) {
        this.loading = true;
        var url:any=ApiEndpointConstant.downloadSharedFile(this.token);
        this.apiCallingService.callDownloadFile(url)
        .subscribe(result => {
            fileObj.saveAs(result, file_name);
        this.loading=false;
      }, error => {
        this.loading=false;
        this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
      });
    }

}
