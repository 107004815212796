import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Constants } from 'src/app/constant/constants';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor() { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token =  localStorage.getItem(Constants.Authorization);
        if (token) {
            req = req.clone({
                setHeaders: {
                    'Accept': 'application/json',
                    // 'Content-Type':  'application/json',
                    'Authorization': token,
                    'Accept-Language':localStorage.getItem(Constants.acceptLanguage)
            }, withCredentials : true });
        }
        return next.handle(req);
    }
}
