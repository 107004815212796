import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Constants} from '../../constant/constants';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpRequest, HttpEvent,HttpHeaders,HttpParams} from '@angular/common/http';
import { GeneralFunction } from '../../common/general-function';
import { ActivatedRoute, Router } from '@angular/router';
import { NgcInitializeEvent ,NgcCookieConsentService } from 'ngx-cookieconsent';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { AuthService } from 'src/app/service/auth.service';
import { MessageService } from 'primeng/api';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
// import {FilehandlerService} from  '../../services/filehandler.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
    username: any;
    isPipelineUser: boolean = false;
    isSupplier: boolean = false;
    isCustomer: boolean = false;
    version: any;
    userData: any;
    notificationCount: any;
    data: any;
    isNotification: boolean = false;
    msg: any;
    isAdmin: boolean = false;
    navigationLink: any;
    loading: boolean = false;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    sessionName: any;
    rfqOrderid: any;
    module: any;
    dataRes: any;
    subData: any;
    getRedirection: any;
    notifications_list: boolean = false;
    notShowHeaderLinks: any[] = [
        '/login',
        '/forgot-password',
        '/invalid-token',
        '/expired-token',
        '/customer-proposal',
        '/customer-invoice',
        '/supplier-confirmation'
    ];
    logoLink:any='';
    menu:any[]=[];
    headerReload: boolean=false;
    isShow:boolean=false;
    notificationHeader: any =  '<button class="btn btn-default btn-lg btn-bell"><span class="fa fa-bell notification-icon"></span></button>';
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private translate: TranslateService,
        private http: HttpClient,
        private responseHandler: ResponseHandlerService,
        private apiCallingService: ApiCallingService,
        private authenticationService: AuthService,
        private cdRef : ChangeDetectorRef
        ) {
        this.setHeaderAndLocalStorage();
        this.authenticationService.getHeaderReload.subscribe((value) => {
            this.headerReload = value;
            var locale:any = GeneralFunction.setUserLocale();
            translate.setDefaultLang(locale);
            translate.use(locale);
            this.setHeaderAndLocalStorage();
        });
    }
    

    ngOnInit(): void {
        this.subData = this.route.queryParams.subscribe(params => {
            if (JSON.stringify(params).length !== 2) {
                this.rfqOrderid = params['id'];
                this.module = params['module'];
                if (!GeneralFunction.checkForNullUndefinedBlank(params['notifications_list'])) {
                    this.notifications_list = params['notifications_list'];
                }
            }
        });
        this.version = Constants.CONNECT_VERSION;
        this.isPipelineUser = false;
        this.isSupplier = false;
        this.isCustomer = false;
        this.isAdmin = false;
        if (this.userData && this.userData.type && this.userData.type == Constants.USER_TYPE['PIPELINE_USER']) {
            this.isPipelineUser = true;
            this.navigationLink = Constants.URLS['PIEPLINE_ORDERS'];
        } else if (this.userData && this.userData.type && this.userData.type == Constants.USER_TYPE['SUPPLIER_USER']) {
            this.isSupplier = true;
            this.navigationLink = Constants.URLS['SUPPLIER_ORDERS'];
        } else if (this.userData && this.userData.type && this.userData.type == Constants.USER_TYPE['CUSTOMER_USER']) {
            this.isCustomer = true;
            this.navigationLink = Constants.URLS['CUSTOMER_ORDERS'];
        }
        if (this.userData && this.userData.role && this.userData.role == Constants.SUPPLIER_ROLES['ADMIN']) {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        this.username = (this.userData && this.userData.fname) ? this.userData.fname :'';
    }
    setHeaderAndLocalStorage() {
        var sessionData: any = localStorage.getItem(Constants.SESSION_NAME);
        this.userData = JSON.parse(sessionData);
        this.notificationCount = 0;
        this.msg = [];
        var locale:any = GeneralFunction.setUserLocale();
        this.translate.setDefaultLang(locale);
        this.translate.use(locale);
        if (localStorage.getItem('isShow') === 'true') {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
        this.getUserMenu();
    }
    ngAfterViewInit() {
        if (!GeneralFunction.checkForNullUndefinedBlank(localStorage.getItem(Constants.SESSION_NAME))) {
            if (!Constants.NOTIFICATION_COUNTER) {
                this.callNotification();
                let timer = setInterval(() => {
                    if (!GeneralFunction.checkForNullUndefinedBlank(localStorage.getItem(Constants.SESSION_NAME))) {
                        this.callNotification();
                    }
                }, Constants.NOTIFICATION_INTERVAL);
                Constants.NOTIFICATION_COUNTER = true;
            }
        }
        this.getUserMenu();
        this.cdRef.detectChanges();
    }
    
    getUserMenu(){
        if (this.isShow && this.userData && this.userData.type) {
            // console.log('type', this.userData.type);
            // console.log('role', this.userData.role);
            if (this.userData.type === Constants.USER_TYPE['PIPELINE_USER']) {
                this.logoLink = Constants.URLS['ORDERS'];
            } else if(this.userData.type === Constants.USER_TYPE['SUPPLIER_USER']) {
                this.logoLink = Constants.URLS['SUPPLIER_ORDERS'];
            } else if(this.userData.type === Constants.USER_TYPE['CUSTOMER_USER']) {
                this.logoLink = Constants.URLS['CUSTOMER_ORDERS'];
            }
            this.translate.get('MENU').subscribe((text: any) => {
                if(this.userData.type === Constants.USER_TYPE['PIPELINE_USER']){
                    if (this.userData.role === Constants.SUPPLIER_ROLES['ADMIN']) {
                        this.menu = [
                            {
                                label: text.MNU_DATA_MNGT,
                                items: [
                                    {
                                        label: text.SUB_MNU_DATA_ORG_MNGT,
                                        routerLink: [Constants.URLS['ORG_MANAGEMENT']],
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_ADDRESS,
                                        routerLink: [Constants.URLS['MANAGE_ADDRESS']],
                                    },
                                    {
                                        label: text.SUB_MNU_DATA_CONT_PERS_MNGT,
                                        routerLink: [Constants.URLS['CONTACT_PER_MANAGEMENT']],
                                    },
                                    {
                                        label: text.SUB_MNU_DATA_PIPELINE_USR_MNGT,
                                        routerLink: [Constants.URLS['PIPELINE_USER_MANAGEMENT']],
                                    },
                                    {
                                        label: text.SUB_MNU_DATA_PAPER_MNGT,
                                        routerLink: [Constants.URLS['PRIT_PAPER_MGMT']],
                                    },
                                    {
                                        label: text.SUB_MNU_DATA_GROUP_MNGT,
                                        routerLink: [Constants.URLS['SUPPLIER_GROUP_MGMT']],
                                    }
                                ],
                            },
                            {
                                label: text.MNU_RFQ,
                                routerLink: [Constants.URLS['RFQS']],
                            },
                            {
                                label: text.MNU_PRODUCTION,
                                routerLink: [Constants.URLS['ORDERS']],
                            },
                            {
                                label: text.BULK_INVOICE_GENERATE,
                                routerLink: [Constants.URLS['BULK_INVOICE_GENERATE']],
                            },
                            {
                                label: text.MNU_REPORTS,
                                items: [
                                    {
                                        label: text.MNU_ORDER_REPORT,
                                        command: () => {this.redirectTo(Constants.URLS['REPORT_ORDER'])},
                                    },
                                    {
                                        label: text.MNU_OFFER_REPORT,
                                        command: () => {this.redirectTo(Constants.URLS['REPORT_OFFER'])},
                                    },
                                    {
                                        label: text.MNU_MARGIN_REPORT,
                                        command: () => {this.redirectTo(Constants.URLS['REPORT_MARGIN'])},
                                    },
                                    {
                                        label: text.MNU_DELIVERY_REPORT,
                                        command: () => {this.redirectTo(Constants.URLS['REPORT_DELIVERY'])},
                                    },
                                    {
                                        label: text.MNU_INVOICE_REPORT,
                                        command: () => {this.redirectTo(Constants.URLS['REPORT_INVOICE'])},
                                    }
                                ]
                            },
                            {
                                label: this.notificationHeader,
                                escape: false,
                                routerLink: ['/notification'],
                                command: () => { this.clickNotification()},
                            },
                            {
                                label: text.HI_TEXT +this.userData.fname,
                                items: [{
                                        label: text.SUB_MNU_EDIT_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_PIPELINE_PROFILE']],
        
                                    },
                                    {
                                        label: text.SUB_MNU_CHANGE_PSWD,
                                        routerLink: [Constants.URLS['CHANGE_PASSWORD']],
                                    },
                                    {
                                        label: text.SUB_MNU_LOGOUT,
                                        command: () => { this.logout()},
        
                                    }
                                ]
                            }
                        ];
                    }else{
                        this.menu = [
                            {
                                label: text.MNU_RFQ,
                                routerLink: [Constants.URLS['RFQS']],
                            },
                            {
                                label: text.MNU_PRODUCTION,
                                routerLink: [Constants.URLS['ORDERS']],
                            },
                            {
                                label: this.notificationHeader,
                                escape: false,
                                routerLink: ['/notification'],
                                command: () => { this.clickNotification()},
                            },
                            {
                                label: text.HI_TEXT +this.userData.fname,
                                items: [{
                                        label: text.SUB_MNU_EDIT_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_PIPELINE_PROFILE']],
        
                                    },
                                    {
                                        label: text.SUB_MNU_CHANGE_PSWD,
                                        routerLink: [Constants.URLS['CHANGE_PASSWORD']],
                                    },
                                    {
                                        label: text.SUB_MNU_LOGOUT,
                                        command: () => { this.logout()},
        
                                    }
                                ]
                            }
                        ];
                    }
                } else if(this.userData.type === Constants.USER_TYPE['CUSTOMER_USER']){
                    if (this.userData.role === Constants.SUPPLIER_ROLES['ADMIN']) {
                        this.menu = [
                            {
                                label: text.MNU_PRODUCTION,
                                routerLink: [Constants.URLS['CUSTOMER_ORDERS']],
                            },
                            {
                                label: text.MNU_REPORT,
                                routerLink: [Constants.URLS['REPORT_ORDER']],
                            },
                            {
                                label: this.notificationHeader,
                                escape: false,
                                routerLink: ['/notification'],
                                command: () => { this.clickNotification()},
                            },
                            {
                                label: text.HI_TEXT+this.userData.fname,
                                items: [
                                    {
                                        label: text.SUB_MNU_EDIT_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_CUSTOMER_PROFILE']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_ORGANIZATION_PROFILE']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_MEMBERS,
                                        routerLink: [Constants.URLS['CONTACT_PER_MANAGEMENT']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_ADDRESS,
                                        routerLink: [Constants.URLS['MANAGE_ADDRESS']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_CHANGE_PSWD,
                                        routerLink: [Constants.URLS['CHANGE_PASSWORD']],
                                    },
                                    {
                                        label: text.SUB_MNU_LOGOUT,
                                        command: () => { this.logout()},
    
                                    }
                                ]
                            }
                        ];
                    } else {
                        this.menu = [
                            {
                                label: text.MNU_PRODUCTION,
                                routerLink: [Constants.URLS['CUSTOMER_ORDERS']],
                            },
                            {
                                label: this.notificationHeader,
                                escape: false,
                                routerLink: ['/notification'],
                                command: () => { this.clickNotification()},
                            },
                            {
                                label: text.HI_TEXT+this.userData.fname,
                                items: [
                                    {
                                        label: text.SUB_MNU_EDIT_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_CUSTOMER_PROFILE']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_CHANGE_PSWD,
                                        routerLink: [Constants.URLS['CHANGE_PASSWORD']],
                                    },
                                    {
                                        label: text.SUB_MNU_LOGOUT,
                                        command: () => { this.logout()},
    
                                    }
                                ]
                            }
                        ];
                    }
                } else if(this.userData.type === Constants.USER_TYPE['SUPPLIER_USER']){
                    if (this.userData.role === Constants.SUPPLIER_ROLES['ADMIN']) {
                        this.menu = [
                            {
                                label: text.MNU_PRODUCTION,
                                routerLink: [Constants.URLS['SUPPLIER_ORDERS']],
                            },
                            {
                                label: text.MNU_RFQ,
                                routerLink: [Constants.URLS['SUPPLIER_RFQS']],
                            },
                            {
                                label: this.notificationHeader,
                                escape: false,
                                routerLink: ['/notification'],
                                command: () => { this.clickNotification()},
                            },
                            {
                                label: text.HI_TEXT+this.userData.fname,
                                items: [
                                    {
                                        label: text.SUB_MNU_EDIT_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_SUPPLIER_PROFILE']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_ORGANIZATION_PROFILE']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_MEMBERS,
                                        routerLink: [Constants.URLS['CONTACT_PER_MANAGEMENT']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_MANAGE_ORG_ADDRESS,
                                        routerLink: [Constants.URLS['MANAGE_ADDRESS']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_CHANGE_PSWD,
                                        routerLink: [Constants.URLS['CHANGE_PASSWORD']],
                                    },
                                    {
                                        label: text.SUB_MNU_LOGOUT,
                                        command: () => { this.logout()},
    
                                    }
                                ]
                            }
                        ];
                    } else {
                        this.menu = [
                            {
                                label: text.MNU_PRODUCTION,
                                routerLink: [Constants.URLS['SUPPLIER_ORDERS']],
                            },
                            {
                                label: text.MNU_RFQ,
                                routerLink: [Constants.URLS['SUPPLIER_RFQS']],
                            },
                            {
                                label: this.notificationHeader,
                                escape: false,
                                routerLink: ['/notification'],
                                command: () => { this.clickNotification()},
                            },
                            {
                                label: text.HI_TEXT+this.userData.fname,
                                items: [
                                    {
                                        label: text.SUB_MNU_EDIT_PROFILE,
                                        routerLink: [Constants.URLS['EDIT_SUPPLIER_PROFILE']],
    
                                    },
                                    {
                                        label: text.SUB_MNU_CHANGE_PSWD,
                                        routerLink: [Constants.URLS['CHANGE_PASSWORD']],
                                    },
                                    {
                                        label: text.SUB_MNU_LOGOUT,
                                        command: () => { this.logout()},
    
                                    }
                                ]
                            }
                        ];
                    }
                }
            });
        }
    }

    callNotification() {
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, ApiEndpointConstant.notification+'getSystemNotificationIconUnclickedCount').subscribe(result => {
            if (result.resultCode == Constants.resultCode.SUCCESS) {
                this.notificationCount = result.object;
                if (this.notificationCount == 0) {
                    this.isNotification = false;
                } else {
                    this.isNotification = true;
                }
                let bellIcon = '<button class="btn btn-default btn-lg btn-bell"><span class="fa fa-bell notification-icon"></span>';
                if (this.isNotification) {
                    bellIcon += '<span class="badge badge-notify">'+this.notificationCount+'</span></button>';
                } else {
                    bellIcon +='</button>';
                }
                this.notificationHeader = bellIcon;
                this.getUserMenu();
            }
        }, error => {
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
        this.getUserMenu();
    }

    logout() {
        this.loading = true;
        this.apiCallingService.callAuthAPI({}, Constants.requestType.POST, ApiEndpointConstant.logout)
        .subscribe(result => {
        localStorage.removeItem(Constants.SESSION_NAME);
        localStorage.setItem('logoutMsg', this.translate.instant("LOGIN.LOGOUT_MSG"));
        localStorage.removeItem(Constants.sessionLocale);
        localStorage.removeItem(Constants.Authorization);
        // localStorage.clear();
        this.router.navigateByUrl(Constants.URLS.LOGIN_URL);                
        this.loading = false;
        }, error => {
            this.loading = false;
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
    }
    clickNotification() {
        this.notificationHeader = '<button class="btn btn-default btn-lg btn-bell"><span class="fa fa-bell notification-icon"></span></button>';
        this.getUserMenu();
        this.router.navigateByUrl(Constants.URLS.NOTIFICATIONS);
    }
    redirectTo(uri: any){
        this.router.navigateByUrl(uri, {replaceUrl: true});
    }
}
