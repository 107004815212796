<div id="top" class="headerNavigation">
    <p-toast position="top-right"></p-toast>
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="container-fluid">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <a routerLink="{{navigationLink}}" class="navbar-brand">
                    <div class="connect-logo"> <img src="assets/img/connect-2.png" /> <span class="version">{{version}}</span> </div>
                </a>
            </div>
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <p-menubar [model]="menu"></p-menubar> 
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-->
    </nav>
</div>
<div class="my-container">
    <ngx-loading [show]="loading"></ngx-loading>
</div>