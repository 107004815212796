import {Component,OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, AfterViewChecked} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ValidationManager} from "ng2-validation-manager";
import { HttpClient, HttpRequest, HttpResponse, HttpEvent, HttpHeaders, HttpClientModule, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {TranslateService, TranslatePipe, TranslateLoader}from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {Constants} from '../../constant/constants';
import 'rxjs/Rx';
// import {AuthInterceptor} from '../../auth-interceptor';
import { FormsModule, ReactiveFormsModule, Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import * as $ from 'jquery';
import {Router,ActivatedRoute,NavigationStart} from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

import { GeneralFunction } from '../../common/general-function';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { ApiEndpointConstant } from '../../constant/apiEndpoints-constants';
import { ValidationFields, ValidationRules } from '../../common/validationRules-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

   public isLogin: boolean = false;
    loginform: any;
    formData: any;
    data: any;
    userData: any;
    msg: any;
    userTypePopup: boolean = false;
    userType: any;
    userTypeform: any;
    userTypeOpt: any;
    sessionName: any;
    deviceInfo: any;
    selectedUserType: any;
    title: any;
    loading: boolean = false;
    version: any;
    isInvalid: boolean = false;
    validationErrors: any;
    mailToLink: any;
    returnUrl: any;
    userLoginId: any;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    showPassword: boolean = true;
    type: any = 'password';
    userDetails:any;

  constructor(private formBuilder: FormBuilder, public router: Router, private translate: TranslateService, private http: HttpClient,private titleService: Title, private route: ActivatedRoute, private messageService: MessageService, private ccService: NgcCookieConsentService, private cdRef : ChangeDetectorRef, private responseHandler: ResponseHandlerService,private apiCallingService: ApiCallingService) {
      translate.setDefaultLang(Constants.DEFAULT_LANG);
      translate.use(Constants.DEFAULT_LANG);
      this.httpErrorMsgDisplay = false;
      this.sessionName = Constants.SESSION_NAME;
  }

  ngOnInit(): void {
        this.userTypeform = new ValidationManager(ValidationRules.setUserRoleRules);
        this.loginform = new ValidationManager(ValidationRules.loginRules);
        this.responseHandler.getIsValid.subscribe((value) => {
            this.isInvalid = value;
        });
        this.responseHandler.getValidationErrors.subscribe((value) => {
            this.validationErrors = value;
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.translateValidationErrorMessage();

        this.mailToLink = Constants.CONTACT_US_MAIL;
        this.version = Constants.CONNECT_VERSION;
        this.loading = false;
        this.userTypePopup = false;
        this.userTypeOpt = []; // options for user type select box
        this.userTypeOpt.push({
            label: "--- Select ---",
            value: ''
        });
        this.userTypeOpt.push({
            label: "Supplier",
            value: Constants.USER_TYPE['SUPPLIER_USER']
        });
        this.userTypeOpt.push({
            label: "Customer",
            value: Constants.USER_TYPE['CUSTOMER_USER']
        });
        this.selectedUserType = "";
        this.titleService.setTitle('Login '+ Constants.PIPELINE_TITLE);
        var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
        this.userData = JSON.parse(sessionData); 
        if (this.userData != null) {
            if (this.userData.type === Constants.USER_TYPE['PIPELINE_USER']) {
                if (GeneralFunction.checkForNullUndefinedBlank(localStorage.getItem('rfqBy'))) {
                    localStorage.setItem('rfqBy', 'SELF');
                }
                if (GeneralFunction.checkForNullUndefinedBlank(localStorage.getItem('orderBy'))) {
                    localStorage.setItem('orderBy', 'SELF');
                }
                this.router.navigate([Constants.URLS['ORDERS']]);
            } else if (this.userData.type === Constants.USER_TYPE['SUPPLIER_USER']) {
                this.router.navigate([Constants.URLS['SUPPLIER_ORDERS']]);
            } else if (this.userData.type === Constants.USER_TYPE['CUSTOMER_USER']) {
                 this.router.navigate([Constants.URLS['CUSTOMER_ORDERS']]);
            }
        }
  }

    ngAfterViewInit() {
        var sessionData:any = localStorage.getItem(Constants.SESSION_NAME);
        this.userDetails = JSON.parse(sessionData); 
        this.loading = true;
        if (this.userDetails) {
            if (this.userDetails.type === Constants.USER_TYPE['PIPELINE_USER']) {
                this.router.navigate([Constants.URLS['ORDERS']]);
            } else if (this.userData.type === Constants.USER_TYPE['SUPPLIER_USER']) {
                this.router.navigate([Constants.URLS['SUPPLIER_ORDERS']]);
            } else if (this.userData.type === Constants.USER_TYPE['CUSTOMER_USER']) {
                this.router.navigate([Constants.URLS['CUSTOMER_ORDERS']]);
            }
            setTimeout(() => {
                this.cdRef.detectChanges();
            }, 250);
        } else {
            this.loading = false;
        }
        this.loading = false;
        this.cdRef.detectChanges();
        setTimeout(() => {
            if (localStorage.getItem('logoutMsg')) {
                let logoutMsg:any = localStorage.getItem('logoutMsg');
                this.messageService.add({
                    severity: Constants.responseStatus.SUCCESS,
                    summary: Constants.responseStatusMessage.SUCCESS,
                    detail: logoutMsg,
                    life:5000,
                });
                localStorage.removeItem('logoutMsg');
                localStorage.removeItem(Constants.SESSION_NAME);
                localStorage.removeItem(Constants.sessionLocale);
                localStorage.removeItem(Constants.Authorization);
                logoutMsg = '';
            }
            if (localStorage.getItem('redirectMsg')) {
                let redirectMsg :any= localStorage.getItem('redirectMsg');
                this.messageService.add({
                    severity: Constants.responseStatus.SUCCESS,
                    summary: Constants.responseStatusMessage.SUCCESS,
                    detail: redirectMsg,
                    life:5000,
                });
                localStorage.removeItem('redirectMsg');
                redirectMsg = '';
            }
            if (localStorage.getItem('loginExpire')) {
                let loginExpire:any = localStorage.getItem('loginExpire');
                this.messageService.add({
                    severity: Constants.responseStatus.ERROR,
                    summary: Constants.responseStatusMessage.ERROR,
                    detail: loginExpire,
                    life:5000,
                });
                localStorage.removeItem('loginExpire');
                localStorage.removeItem(Constants.userSessionPipeline);
                localStorage.removeItem(Constants.loginType);
                localStorage.removeItem(Constants.roleTypeSession);
                localStorage.removeItem(Constants.loginOption);
                loginExpire = '';
            }
           // this.cdRef.detectChanges();
        }, 500);
    }
    // ngAfterContentChecked() :void {
    //     if (localStorage.getItem('loginExpire')) {
    //         let loginExpire:any = localStorage.getItem('loginExpire');
    //         this.messageService.add({
    //             severity: Constants.responseStatus.ERROR,
    //             summary: Constants.responseStatusMessage.ERROR,
    //             detail: loginExpire
    //         });
    //         localStorage.removeItem('loginExpire');
    //         localStorage.removeItem(Constants.userSessionPipeline);
    //         localStorage.removeItem(Constants.loginType);
    //         localStorage.removeItem(Constants.roleTypeSession);
    //         localStorage.removeItem(Constants.loginOption);
    //         loginExpire = '';
    //     }
    //     this.cdRef.detectChanges();
    // }
    translateValidationErrorMessage() {
        setTimeout(() => {
            this.userTypeform.setErrorMessage(ValidationFields.setUserfields.user_type, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("LOGIN.USER_TYPE")}));

            this.loginform.setErrorMessage(ValidationFields.loginfields.emailUsername, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.EMAIL")}));
            this.loginform.setErrorMessage(ValidationFields.loginfields.emailUsername, Constants.validationType.maxlength, this.translate.instant("VALIDATION_MESSAGE.MAXLENGTH", {
                field: this.translate.instant("FIELDS.EMAIL"),
                maxlength: Constants.fieldTypes.maxStringLength500
            }));
            this.loginform.setErrorMessage(ValidationFields.loginfields.password, Constants.validationType.required, this.translate.instant("VALIDATION_MESSAGE.REQUIRED", {field: this.translate.instant("FIELDS.PASSWORD")}));
            this.loginform.setErrorMessage(ValidationFields.loginfields.password, Constants.validationType.rangeLength, this.translate.instant("VALIDATION_MESSAGE.RANGE", {field: this.translate.instant("FIELDS.PASSWORD"), startRange: Constants.fieldTypes.startRange, endRange: Constants.fieldTypes.endRange}));
        }, Constants.validationTimeout);
    }
  /**
     * ON SUBMIT
     * @param $event
     * @param value
     */
    onSubmit() {
        if (this.loginform.isValid()) {
            this.loading = true;
            const body = this.loginform.getData();
            this.apiCallingService.callLoginAPI(body, Constants.requestType.POST, ApiEndpointConstant.login)
            .subscribe((result: HttpResponse<any>) => {
                this.data = result;
                var token:any  = result.headers.get(Constants.AUTH_TOKEN);
                var acceptLanguage:any  = result.headers.get(Constants.acceptLanguage);
                if(token){
                    localStorage.removeItem(Constants.Authorization);
                    localStorage.setItem(Constants.Authorization, token);
                    localStorage.setItem(Constants.acceptLanguage, acceptLanguage);
                }
                this.onLoginSuccessHandling(result);
                this.loading = false;
            }, error => {
                this.loading = false;
                if(error.error.errors){
                    this.isInvalid = true;
                    this.validationErrors = [];
                    for (var i = 0; i < error.error.errors.length; i++) {
                        this.validationErrors[error.error.errors[i].field] = error.error.errors[i].message;
                    }
                }
            });
        }
    }


    onLoginSuccessHandling(result:any){
        if(!GeneralFunction.checkForNullUndefinedBlank(result.body)){
            if (result.body.resultCode == Constants.resultCode.SUCCESS) {
                if (result.body.type != Constants.USER_TYPE['BOTH_USER']) {
                    this.sessionName = Constants.SESSION_NAME;
                    let sessionData:any = GeneralFunction.set_session_data(result.body);
                    localStorage.setItem(this.sessionName, JSON.stringify(sessionData));                    
                }
                if (result.body.type === Constants.USER_TYPE['BOTH_USER']) {
                    this.userTypePopup = true;
                    this.userLoginId = result.body.id;
                } else if (result.body.firstTimeLogin) {
                    this.returnUrl = Constants.URLS['FIRST_CHANGE_PWD'];
                    this.router.navigateByUrl(this.returnUrl);
                } else if (result.body.type === Constants.USER_TYPE['PIPELINE_USER']) {
                    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Constants.URLS['ORDERS'];
                    this.router.navigateByUrl(this.returnUrl);
                } else if (result.body.type === Constants.USER_TYPE['SUPPLIER_USER']) {
                    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Constants.URLS['SUPPLIER_ORDERS'];
                    this.router.navigateByUrl(this.returnUrl);
                } else if (result.body.type === Constants.USER_TYPE['CUSTOMER_USER']) {
                    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Constants.URLS['CUSTOMER_ORDERS'];
                    this.router.navigateByUrl(this.returnUrl);
                }
            } else {
                this.messageService.add({
                    severity: Constants.responseStatus.ERROR,
                    summary: Constants.responseStatusMessage.ERROR,
                    detail: result.body.message,
                    life:5000,
                });
            }
        }
        this.cdRef.detectChanges();
    }

    /**
     * ON SUBMIT
     * @param $event
     * @param value
     */
    onuserTypeSubmit() {
        if (this.userTypeform.isValid()) {
            this.userTypePopup = false;
            this.userType = this.userTypeform.getData();
            this.apiCallingService.callUserTypeChangeAPI({}, Constants.requestType.POST, ApiEndpointConstant.setRole+this.selectedUserType).subscribe((result: HttpResponse<any>) => {
                this.data = result.body;
                console.log('this.data',this.data);
                if (this.data.resultCode == Constants.resultCode.SUCCESS) {
                    var token:any  = result.headers.get(Constants.AUTH_TOKEN);
                    var acceptLanguage:any  = result.headers.get(Constants.acceptLanguage);
                    if(token){
                        localStorage.removeItem(Constants.Authorization);
                        localStorage.setItem(Constants.Authorization, token);
                        localStorage.setItem(Constants.acceptLanguage, acceptLanguage);
                    }
                    this.sessionName = Constants.SESSION_NAME;
                    let sessionData:any = GeneralFunction.set_session_data(result.body);
                    localStorage.setItem(this.sessionName, JSON.stringify(sessionData));
                    if (this.data.firstTimeLogin) {
                        this.returnUrl = Constants.URLS['FIRST_CHANGE_PWD'];
                        this.router.navigateByUrl(this.returnUrl);
                    } else if (this.data.type === Constants.USER_TYPE['PIPELINE_USER']) {
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Constants.URLS['ORDERS'];
                        this.router.navigateByUrl(this.returnUrl);
                    } else if (this.data.type === Constants.USER_TYPE['SUPPLIER_USER']) {
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Constants.URLS['SUPPLIER_ORDERS'];
                        this.router.navigateByUrl(this.returnUrl);
                    } else if (this.data.type === Constants.USER_TYPE['CUSTOMER_USER']) {
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Constants.URLS['CUSTOMER_ORDERS'];
                        this.router.navigateByUrl(this.returnUrl);
                    }
                } else {
                    this.messageService.add({
                        severity: Constants.responseStatus.ERROR,
                        summary: Constants.responseStatusMessage.ERROR,
                        detail: result.body.message,
                        life:5000,
                    });
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                if(error.error.errors){
                    this.isInvalid = true;
                    this.validationErrors = [];
                    for (var i = 0; i < error.error.errors.length; i++) {
                        this.validationErrors[error.error.errors[i].field] = error.error.errors[i].message;
                    }
                }
            });
        }
        this.cdRef.detectChanges();
    }
    togglePassword(status:any) {
        if (status) {
            this.showPassword = false;
            this.type = Constants.text;
        } else {
            this.showPassword = true;
            this.type = Constants.password;
        }
    }
    
}
