import {Component, OnInit} from '@angular/core';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { Constants } from 'src/app/constant/constants';
import {HttpClient, HttpRequest, HttpEvent,HttpHeaders,HttpParams} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralFunction } from 'src/app/common/general-function';
import {Observable } from 'rxjs/Observable';
import * as fileObj from 'file-saver';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';


@Component({
  selector: 'app-cloudproof',
  templateUrl: './cloudproof.component.html',
  styleUrls: ['./cloudproof.component.css'],
  providers: [ResponseHandlerService]
})
export class CloudproofComponent implements OnInit {
	loading: boolean;
    shareType:any;
    token:any;
    fileInfo:any;

    fileName:any;
    fileSharedByUserName:any;
    fileSharedTime:any;
    fileSize:any;
    fileId:any;
    msg: any;
    validationErrors: any;
    isInvalid: boolean;
    httpErrorMsgDisplay: any;
    httpErrorMsg: any;
    httpErrorCode: any;
    fileExtensionThumbailPath:any;
    downloadText:any;
    expiryTokenFlag:boolean=false;
    successFlag:boolean=false;
    invalidTokenFlag:boolean=false;

  constructor( private route: ActivatedRoute, 
              private router: Router, 
              private http: HttpClient,
              private apiCallingService: ApiCallingService,
              private responseHandler: ResponseHandlerService,
    ){
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        }); 
    }

  ngOnInit() {
    this.getFileInfo();
  }

  infoBtnClicked(){
    this.router.navigate([Constants.URLS['CLOUDPROOF_PREVIEW']], {
        queryParams: {
            token: this.token,
            // file_id:this.fileId,
            page_no:1
        }
    });
  }

  getFileInfo() {
        var url:any = ApiEndpointConstant.validateFileShareToken(this.token);
        this.loading = true;
        this.apiCallingService.callAuthAPI({}, Constants.requestType.GET, url).subscribe(result => {
          this.loading = false;
          this.fileInfo = result;
            if (result.resultCode == Constants.resultCode.SUCCESS) {
              this.successFlag=true;
              this.fileName = this.fileInfo.originalFileName;
              this.fileSharedByUserName = this.fileInfo.fileSharedByUserName;
              this.fileSharedTime = this.fileInfo.fileSharedTime;
              this.fileSize = this.fileInfo.fileSize;
              this.fileId = this.fileInfo.fileId;
              if(this.shareType=='original'){
                  this.downloadText = 'download file';
              }else{
                  this.downloadText = 'download print file'
              }
              this.fileExtensionThumbailPath = this.fileInfo.fileExtensionThumbailPath;
              
            } else if (this.fileInfo.resultCode == Constants.resultCode.EXPIRED_TOKEN) {
                console.log('test1', this.fileInfo.expiryTokenFlag);
                this.expiryTokenFlag = true;
                this.successFlag = false;
                this.invalidTokenFlag = false;
            } else if (this.fileInfo.resultCode == Constants.resultCode.INVALID_TOKEN){
                console.log('test2', this.fileInfo.invalidTokenFlag);
                this.expiryTokenFlag = false;
                this.successFlag = false;
                this.invalidTokenFlag = true;
            }else {
              this.loading=false;
              this.responseHandler.setErrorPopupMessage(result.message);
            }
          }, error => {
            this.loading=false;
            this.responseHandler.setErrorPopupMessage(this.responseHandler.handleError(error));
        });
  }

}
